import React, { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { emailValidation } from "../../../helpers/Common";
import CustomAutocomplete from "./inputs/CustomAutoComplete";
import { useDispatch } from "react-redux";
import { addParticipant, removeParticipant } from "../ConversationSlice";

const Root = styled('div')(({ theme }) => ({
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
}));

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '2px'
}));

const AutoSelectGroup = styled('div')(({ theme }) => ({
    width: '80%',
    marginRight: '8px'
}));

const InlineParticipantsRow = styled('div')(({ theme }) => ({
    width: '100%',
    marginTop: '4px',
    display: 'flex',
}));

const Name = styled(Typography)(({ theme }) => ({
    minWidth: 36,
    fontWeight: 600,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'start',
    marginTop: '14px'
}));

const Value = styled('div')(({ theme }) => ({
    marginLeft: 2,
    width: '100%'
}));

const ButtonSection = styled('div')(({ theme }) => ({
    marginTop: '8px',
    display: 'flex',
    alignItems: 'start',
    padding: 4,
    width: '20%',
}));

const Participants = ({ messageAction, participants, errors, setErrors }) => {
    const [showCcInput, setShowCcInput] = useState(false);
    const [showBccInput, setShowBccInput] = useState(false);
    const [inputValues, setInputValues] = useState({ to: '', cc: '', bcc: '' });
    const dispatch = useDispatch();

    useEffect(() => {
        if (participants.cc.length > 0) {
            setShowCcInput(true);
        }
        if (participants.bcc.length > 0) {
            setShowBccInput(true);
        }
    }, [participants]);

    const handleKeyDown = (event, type) => {
        if (event.key === 'Enter' || (event.type === "blur" && inputValues[type].length > 0)) {
            event.preventDefault();
            const email = inputValues[type].trim();
            const validationResult = emailValidation(email);
            if (validationResult === "") {
                if (!participants[type].includes(email)) {
                    dispatch(addParticipant({ type, email }));
                    setInputValues((prev) => ({
                        ...prev,
                        [type]: ''
                    }));
                    setErrors((prev) => ({
                        ...prev,
                        [type]: ''
                    }));
                } else {
                    setErrors((prev) => ({
                        ...prev,
                        [type]: 'Email already added'
                    }));
                }
            } else {
                setErrors((prev) => ({
                    ...prev,
                    [type]: validationResult
                }));
            }
        }
    };

    const handleInputChange = (event, type, newValue) => {
        if (event.key !== "Enter") {
            setInputValues((prev) => ({
                ...prev,
                [type]: newValue
            }));
        }
        setErrors((prev) => ({
            ...prev,
            [type]: ''
        }));
    };

    const handleDelete = (email, type) => {
        dispatch(removeParticipant({ type, email }));
        setErrors((prev) => ({
            ...prev,
            [type]: ''
        }));
    };

    return (
        <Root>
            <Container>
                <AutoSelectGroup>
                    <InlineParticipantsRow>
                        <Name variant="body2" color="textSecondary">To :</Name>
                        <Value>
                            <CustomAutocomplete
                                messageAction={messageAction}
                                value={participants.to}
                                inputValue={inputValues.to}
                                onInputChange={(e, newValue) => handleInputChange(e, "to", newValue)}
                                onKeyDown={(event) => handleKeyDown(event, 'to')}
                                handleBlur={(event) => handleKeyDown(event, 'to')}
                                error={Boolean(errors.to)}
                                helperText={errors.to}
                                onDelete={handleDelete}
                                type="to"
                            />
                        </Value>
                    </InlineParticipantsRow>
                    {(messageAction === "reply" || messageAction === "forward") && (
                        <>
                            {showCcInput && (
                                <InlineParticipantsRow>
                                    <Name variant="body2" color="textSecondary" onClick={() => setShowCcInput(false)}>Cc :</Name>
                                    <Value>
                                        <CustomAutocomplete
                                            value={participants.cc}
                                            inputValue={inputValues.cc}
                                            onInputChange={(e, newValue) => handleInputChange(e, "cc", newValue)}
                                            onKeyDown={(event) => handleKeyDown(event, 'cc')}
                                            handleBlur={(event) => handleKeyDown(event, 'cc')}
                                            error={Boolean(errors.cc)}
                                            helperText={errors.cc}
                                            onDelete={handleDelete}
                                            type="cc"
                                        />
                                    </Value>
                                </InlineParticipantsRow>
                            )}
                            {showBccInput && (
                                <InlineParticipantsRow>
                                    <Name variant="body2" color="textSecondary" onClick={() => setShowBccInput(false)}>Bcc :</Name>
                                    <Value>
                                        <CustomAutocomplete
                                            value={participants.bcc}
                                            inputValue={inputValues.bcc}
                                            onInputChange={(e, newValue) => handleInputChange(e, "bcc", newValue)}
                                            onKeyDown={(event) => handleKeyDown(event, 'bcc')}
                                            handleBlur={(event) => handleKeyDown(event, 'bcc')}
                                            error={Boolean(errors.bcc)}
                                            helperText={errors.bcc}
                                            onDelete={handleDelete}
                                            type="bcc"
                                        />
                                    </Value>
                                </InlineParticipantsRow>
                            )}
                        </>
                    )}
                </AutoSelectGroup>
                <ButtonSection>
                    {!showCcInput && (
                        <Button disableElevation onClick={() => setShowCcInput(true)}>Cc</Button>
                    )}
                    {!showBccInput && (
                        <Button disableElevation onClick={() => setShowBccInput(true)}>Bcc</Button>
                    )}
                </ButtonSection>
            </Container>
        </Root>
    );
}

export default Participants;

import React from 'react';
import Thread from './Thread';
import { useSelector } from 'react-redux';

const Threads = () => {
    const { threads } = useSelector(state => state.conversation.current);

    if (threads?.length === 0 || threads === null || threads === undefined)
        return <></>

    return (
        <>
            {
                threads.map((thread) => (
                    <Thread key={thread.id} {...thread} />
                ))
            }
        </>
    )
}

export default Threads;

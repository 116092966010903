import { Plugin, ButtonView } from 'ckeditor5';
import { createRoot } from 'react-dom/client';
import AiMenu from '../ai/AiMenu';
import React from 'react'

const aiMenuIcon ='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25zm0 6-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25zm-7.5-5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12zm-1.51 3.49L9 15.17l-.99-2.18L5.83 12l2.18-.99L9 8.83l.99 2.18 2.18.99z" fill="#6264a7"></path></svg>'

export default class AiMenuPlugin extends Plugin {
    constructor(editor) {
        super(editor);
        this.callback = null;
    }

    setCallback(callback) {
        this.callback = callback;
    }

    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add('aiMenu', locale => {
            const buttonView = new ButtonView(locale);

            buttonView.set({
                label: 'AI',
                withText: false,
                tooltip: true,
                icon: aiMenuIcon
            });

            buttonView.on('execute', (e) => {
                const aiMenuContainer = document.createElement('div');
                aiMenuContainer.id = "ai-modal-id";
                document.body.appendChild(aiMenuContainer);
                const root = createRoot(document.getElementById('ai-modal-id'));

                root.render(
                    <AiMenu
                        anchorEl={e.source.element}
                        open={true}
                        handleClose={() => {
                            root.unmount(aiMenuContainer);
                            document.body.removeChild(aiMenuContainer);
                        }}
                        setSelectedAiOption={this.callback}
                    />,
                    aiMenuContainer
                );
            });
            return buttonView        
        });
    }
}
import { IconButton, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Backspace } from '@mui/icons-material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { HandleServerResponse } from '../../helpers/Common';
import { CallService } from '../../services/CallService';
import { useTheme } from '@mui/styles';
import ButtonContainer from './dialpad/ButtonContainer';
import { QueueService } from '../../services/QueueService';
import CallDropdown from './dialpad/CallDropdown';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8
    },
}));

const Dialpad = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const styles = {
        textfield: {
            '& .MuiInputBase-input': {
                textAlign: 'center',
            },
        },
        iconButton: {
            paddingRight: theme.spacing(0),
        },
        icon: {
            '& .MuiSvgIcon-root': {
                marginBottom: theme.spacing(0.5),
            }
        },
    }

    const [number, setNumber] = React.useState('');
    const [error, setError] = React.useState(true);
    const [queues, setQueues] = React.useState([]);

    const handleClick = (value) => {
        setNumber((prev) => {
            if (prev.slice(-1) === '+' && value === 0) {
                return prev;
            }
            return `${prev}${value}`;
        })
    }

    const handleClear = () => {
        let removed = number.slice(0, -1);
        setNumber(removed);
    }

    const handleNumberChange = (e) => {
        setNumber(e.target.value);
    }

    React.useEffect(() => { 
        (async () => {
            let result = await QueueService.getQueuesWithOutboundNumber();
            setQueues(result.data);
        })();
    },[])

    React.useEffect(() => {
        let regx = number.charAt(0) === '+' ? /^\+?([0-9]{3,14})$/ : /^\+?([0-9]{3,15})$/

        if (regx.test(number))
            setError(false);
        else
            setError(true);
    }, [number])

    const createNewCall = (queue=null) => {
        (async () => {
            if (!error) {
                setError(true);
                let newCallPrams = { Phone: number, QueueId: queue }
                var result = await CallService.CreateNewCall(newCallPrams);

                HandleServerResponse(result, `New call request was sent successfully.`, dispatch);
                if (result.success) {
                    setNumber('');
                } else {
                    setError(false);
                }
            }
        })();
    }

    return (
        <div id="dialpad-root" className={classes.root}>
            <TextField
                id="number"
                value={number}
                placeholder="Type a number"
                onChange={handleNumberChange}
                sx={styles.textfield}
                variant="standard"
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            size='small'
                            aria-label="clear-number"
                            onClick={handleClear}
                            sx={styles.iconButton}
                        >
                            <Backspace sx={styles.icon} />
                        </IconButton>
                    </InputAdornment>,
                }}
            />
            <ButtonContainer handleClick={handleClick} />
            <CallDropdown queues={queues} disabled={error} onChange={createNewCall} createCall={createNewCall} />
        </div>
    )
}

export default Dialpad
import React from 'react';
import { Button, Box } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../app/InteractionSlice';
import { getWarningMessage } from '../../../helpers/Common';
import { ConversationStatus } from '../../../helpers/Constant';
import { addFiles } from '../ConversationSlice';

const FileUploadButton = ({ isLoading }) => {
    const dispatch = useDispatch();

    const { userId, status } = useSelector(state => state.conversation.current);

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const validFiles = [];
        let errorMessage = '';

        // Create an array of promises for reading files
        const fileReadPromises = files.map(async (file) => {
            if (file.size > 20 * 1024 * 1024) { // 20MB limit
                errorMessage = `File exceeds the 20MB limit.`;
                dispatch(setMessage(getWarningMessage(errorMessage)));
            } else {
                validFiles.push(file);
            }
        });

        // Wait for all promises to resolve
        await Promise.all(fileReadPromises);

        // Update state only if there are valid files
        if (validFiles.length > 0) {
            dispatch(addFiles(validFiles))
        }
    };

    const handleButtonClick = () => {
        document.getElementById('file-input').click();
    };

    return (
        <Box display="flex" alignItems="center">
            <input
                id="file-input"
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <Button
                disabled={isLoading || !userId ||
                    status === ConversationStatus.Completed ||
                    status === ConversationStatus.Declined}
                variant="outlined"
                color="primary"
                startIcon={<AttachFileIcon />}
                onClick={handleButtonClick}
                sx={{ marginRight: 2 }}
            >
                Attach Files
            </Button>
        </Box>
    );
};

export default FileUploadButton;

import React, { useState } from 'react'
import Summary from './view/Summary'
import GroupBox from '../../components/GroupBox';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { loadConversation } from './ConversationSlice';
import SkeletonPanel from './components/skeletons/SkeletonPanel';
import Threads from './view/Threads';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import ActionBar from './components/ActionBar'
import Message from './view/Message';
import { ConversationType } from '../../helpers/Constant';

const Conversation = (props) => {
    const dispatch = useDispatch();

    const Container = styled('div')(({ theme }) => ({
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px',
        boxSizing: 'border-box',
        overflowY: "auto"
    }));

    const CloseButton = styled('div')(({ theme }) => ({
        width: '100%',
        height: '5%',
        display: 'flex',
        justifyContent: 'flex-end'
    }));

    const loaded = useSelector(state => state.conversation.current.loaded);

    React.useEffect(() => {
        dispatch(loadConversation(props.data, ConversationType.Email));
    }, [props.data])

    if (!loaded) {
        return <SkeletonPanel />
    }

    return (
        <GroupBox
            smallBodyPadding={true}
            showDivider={false}
            gutter
            sx={{ position: 'relative', height: '100%' }}
        >
            <Container>
                {props.isSupervisorView &&
                    <CloseButton>
                        <IconButton
                            aria-label="close"
                            onClick={props.closeConversationTab}
                            sx={{ margin: '5px' }}
                        >
                            <Close />
                        </IconButton>
                    </CloseButton>
                }
                <Summary />
                <ActionBar isSupervisorView={props.isSupervisorView} />
                {!props.isSupervisorView &&
                    <Message />
                }
                <Threads />
            </Container>
        </GroupBox>
    )
}

export default Conversation
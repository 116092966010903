import React from 'react'

const WordIcon = () => {
    return (
        <svg fill="#6264a7" width="16" height="16" style={{ marginRight: '8px', marginLeft: '4px' }} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <title>microsoftword</title>
                <path d="M29.121 8.502v-3.749h-18.745v3.749zM29.121 15.063v-4.686h-13.121v4.686zM29.121 21.623v-4.686h-13.121v4.686zM29.121 27.247v-3.749h-18.745v3.749zM8.546 15.004l1.802 5.917h2.049l1.962-9.842h-2.063l-1.171 5.815-1.656-5.623h-1.742l-1.757 5.652-1.172-5.842h-2.154l1.962 9.84h2.051zM29.751 2.879c0.686 0.003 1.242 0.558 1.245 1.244v23.753c-0.003 0.686-0.558 1.242-1.244 1.245h-20.005c-0.686-0.003-1.242-0.558-1.245-1.244v-4.379h-6.253c-0.005 0-0.011 0-0.016 0-0.338 0-0.644-0.14-0.862-0.366l-0-0c-0.226-0.218-0.366-0.524-0.366-0.862 0-0.006 0-0.011 0-0.017v0.001-12.506c-0-0.005-0-0.011-0-0.016 0-0.338 0.14-0.644 0.366-0.862l0-0c0.218-0.226 0.524-0.366 0.862-0.366 0.006 0 0.011 0 0.017 0h6.252v-4.379c0.003-0.686 0.559-1.242 1.244-1.245h0z"></path>
            </g>
        </svg>
    )
}

export default WordIcon
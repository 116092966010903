import React, { useState } from 'react'
import CustomDialog from '../../components/CustomDialog'
import { Box, Button, Typography } from '@mui/material'
import Input from '../../components/Inputs/Input';
import { Add, Cancel, Delete, Update } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DropDown from '../../components/Inputs/DropDown';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from "../../components/CKEditor5/CustomCKEditor";
import { UserService } from '../../services/UserService';
import { HandleServerResponse } from "../../helpers/Common";
import { useDispatch } from 'react-redux';

const config = {
    toolbar: [
        'fontSize',
        'fontFamily',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'fontColor',
        'fontBackgroundColor',
        'outdent',
        'indent',
        'blockQuote',
        'alignment',
        'link',
        '|',
        'undo',
        'redo'
    ],
    language: "tr",
}
const EditorContainer = styled('div')(({ theme }) => ({
    width: '100%',
    '& .ck-editor__editable_inline': {
        minHeight: '200px',
    },
}));

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
});

const DropDownAndButtons = styled(Box)({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const DropDownParent = styled(Box)({
    width: '80%',
    marginRight: '10px'
});

const ButtonsContainer = styled(Box)({
    width: '20%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between'
});

const initObject = { name: "", content: "" }

const EmailSignatures = ({ open, closeDialog }) => {
    const [loading, setLoading] = useState(false);
    const [createOrUpdateSignature, setCreateOrUpdateSignature] = useState(null);
    const [signatureValues, setSignatureValues] = useState(initObject);
    const [signatures, setSignatures] = useState([]);
    const [reload, setReload] = useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        let active = true;
        (async () => {
            setLoading(true);

            var result = await UserService.GetUserSignatures();

            if (result.success) {
                setSignatures(result.data);
            }

            if (!active) { return; }
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [reload]);

    const handleAddOrUpdateSignature = (action) => {
        if (action === "new") {
            setSignatureValues(initObject)
        }
        setCreateOrUpdateSignature(action);
    }

    const HandleAddOrUpdateSignature = async () => {
        setLoading(true);

        if (createOrUpdateSignature === "new") {
            var payload = {
                Name: signatureValues.name,
                Content: signatureValues.content
            }

            var result = await UserService.AddNewSignature(payload);
            HandleServerResponse(result, "Signature Added Successfully", dispatch);

            if (result.success) {
                setCreateOrUpdateSignature(null);
                setSignatureValues(initObject);
                setReload((prev) => !prev);
            }
        } else {
            var payload = {
                Id: signatureValues.id,
                Name: signatureValues.name,
                Content: signatureValues.content
            }

            var result = await UserService.UpdateSignature(payload);
            HandleServerResponse(result, "Signature Updated Successfully", dispatch);

            if (result.success) {
                setCreateOrUpdateSignature(null);
                setSignatureValues(initObject);
                setReload((prev) => !prev);
            }
        }
        setLoading(false);
    }

    const HandleDeleteSignature = async () => {
        setLoading(true);

        var result = await UserService.DeleteSignature(signatureValues?.id);
        HandleServerResponse(result, "Signature deleted Successfully", dispatch);

        if (result.success) {
            setSignatureValues(initObject);
            setReload((prev) => !prev);
            setLoading(false);
        }
    }

    return (
        <CustomDialog open={open} close={closeDialog} title={"Signatures"}>
            <Container>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography variant='h6'>
                        Email Signature
                    </Typography>
                    <Typography variant='caption' sx={{ display: 'block' }}>
                        You can add and modify signatures that can be added to your emails.
                    </Typography>
                </Box>

                <Box>
                    <Button
                        startIcon={createOrUpdateSignature === null ? <Add /> : <Cancel />}
                        variant="outlined"
                        color={createOrUpdateSignature === null ? "primary" : "grey"}
                        disableElevation
                        onClick={() => {
                            createOrUpdateSignature === null ? handleAddOrUpdateSignature("new") : handleAddOrUpdateSignature(null)
                        }}
                    >
                        {createOrUpdateSignature === null ? "New Signature" : "Cancel"}
                    </Button>
                </Box>

                {(createOrUpdateSignature === "new" || createOrUpdateSignature === "update") && (
                    <Input
                        size="large"
                        label="Signature"
                        name="name"
                        paddingRight={0}
                        sx={{ width: '90%' }}
                        value={signatureValues?.name}
                        onChange={(e) => {
                            setSignatureValues((prev) => ({
                                ...prev,
                                ["name"]: e.target.value
                            }))
                        }}
                    />
                )}
                {(createOrUpdateSignature === null) && (
                    <DropDownAndButtons>
                        <DropDownParent>
                            <DropDown
                                required
                                label="Signatures"
                                size="large"
                                value={signatureValues?.id}
                                disable={loading}
                                onChange={(e) => {
                                    const signature = signatures.find(sig => sig.id === e.target.value);
                                    setSignatureValues({
                                        id: signature.id,
                                        name: signature.name,
                                        content: signature.content
                                    });
                                }}
                                values={signatures}
                            />
                        </DropDownParent>
                        <ButtonsContainer>
                            <Button
                                startIcon={<Update />}
                                variant="contained"
                                color="primary"
                                sx={{ marginRight: '8px', height: '36px', marginTop: '4px' }}
                                disableElevation
                                disabled={!signatureValues?.id}
                                onClick={() => handleAddOrUpdateSignature("update")}
                            >
                                Update
                            </Button>
                            <Button
                                startIcon={<Delete />}
                                variant="contained"
                                color="grey"
                                sx={{ height: '36px', marginTop: '4px' }}
                                disableElevation
                                disabled={!signatureValues?.id}
                                onClick={() => HandleDeleteSignature()}
                            >
                                Delete
                            </Button>
                        </ButtonsContainer>
                    </DropDownAndButtons>
                )}
                <EditorContainer>
                    <CKEditor
                        editor={Editor}
                        data={signatureValues?.content}
                        config={config}
                        disabled={!createOrUpdateSignature || loading}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setSignatureValues((prev) => ({
                                ...prev,
                                ["content"]: data
                            }))
                        }}
                    />
                </EditorContainer>
            </Container>
            <>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginRight: '8px' }}
                    disableElevation
                    disabled={(signatureValues?.content == "" || signatureValues?.name == "") || loading || !createOrUpdateSignature}
                    onClick={HandleAddOrUpdateSignature}
                >
                    {createOrUpdateSignature === "new" ? "Save" : "Update"}
                </Button>
            </>
        </CustomDialog>
    )
}

export default EmailSignatures
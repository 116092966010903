import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import Environment from '../../Environment';

const useStyles = makeStyles(theme => ({
    img: {
        width: 100,
        height: 100
    },
}));

export default function About(props) {
    const classes = useStyles();
    const theme = useTheme();

    const styles = {
        actions: {
            margin: theme.spacing(0, 1, 1, 1),
            '& > .MuiButtonBase-root': {
                padding: theme.spacing(0.5, 2, 0.5, 2),
            },
        },
    }

    return (
        <div>
            <Dialog className={classes.btnStyles} open={props.open} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
                <DialogContent>
                    <Box display="flex" alignItems="center">
                        <Box><img className={classes.img} src={window.location.origin + '/logo192.png'} alt="CentrePal" /></Box>
                        <Box px={2}>
                            <Typography variant="h4">CentrePal</Typography>
                            <Typography variant="body2">CentrePal Contact Centre for Microsoft Teams</Typography>
                            <Typography variant="body2">{`Version  ${Environment.config.VERSION}`}</Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={styles.actions}>
                    <Button
                        onClick={props.closeDialog}
                        startIcon={<Close />}
                        variant="contained"
                        color="grey"
                        disableElevation>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import React from 'react';
import { Chip, Grid } from '@mui/material';
import { PowerBiService } from '../../../services/PowerBiService';
import { useTheme } from '@mui/styles';
import Actions from './compoents/Actions';
import Content from './compoents/Content';
import PowerBIBranding from './compoents/PowerBIBranding';
import GroupBox from '../../../components/GroupBox';

export default function PowerBi(props) {
    const theme = useTheme();

    const styles = {
        root: {
            minHeight: '100vh',
            padding: theme.spacing(1, 1, 1, 1),
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        actions: {
            display: 'flex',
            padding: theme.spacing(0, 0, 1, 0),
            alignItems: "center",
            justifyContent: "space-between"
        },
        button: {
            height: 32,
            ml: theme.spacing(1)
        },
        dropdown: {
            marginTop: theme.spacing(0.5)
        },
        gropBox: {
            width: '100%',
            position: 'relative',
            // padding: theme.spacing(0, 0.5, 1, 0.5)
        },
    }

    const [data, setData] = React.useState(() => ({
        dashboards: [],
        selected: { id: "" }, //set only id to load the dropdown value properly
        loading: true,
        initialLoad: false,
        error: null
    }))

    //TODO add restriction on reload
    const reload = () => {
        (async () => {
            setData((prev) => ({
                ...prev,
                ["loading"]: true
            }));

            let refresh = await PowerBiService.refresh(data?.selected?.id);
            //reload the  dashboard when refresh success

            if (refresh.success) {
                setData((prev) => ({
                    ...prev,
                    ["selected"]: { ...data?.selected, token: refresh.data },
                    ["loading"]: false
                }));
                return;
            }

            setData((prev) => ({
                ...prev,
                ["loading"]: false
            }));
        })();
    }

    const changeDashboard = (id) => {
        (async () => {

            if (id === data?.selected?.id) {
                return;
            }

            setData((prev) => ({
                ...prev,
                ["loading"]: true
            }));

            let result = await PowerBiService.token(id);

            let selected = data.dashboards.find(x => x.id === id);
            selected.token = result.data;

            if (selected.token === "") {
                setData((prev) => ({
                    ...prev,
                    ["loading"]: false
                }));
                return;
            }

            setData((prev) => ({
                ...prev,
                ["selected"]: selected,
                ["loading"]: false
            }))
        })()
    }

    React.useEffect(() => {
        (async () => {
            let result = await PowerBiService.dashboards();
            setData((pre) => {
                return {
                    dashboards: result?.data?.powerBIEmbed,
                    selected: result?.data?.powerBIEmbed[0] ?? pre.selected,
                    loading: false,
                    initialLoad: true,
                    error: result?.data?.error
                }
            })
        })();
    }, [])

    return (
        <Grid item sm={12} md={12} sx={styles.root}>
            <GroupBox smallBodyPadding={true} showDivider={false} sx={styles.gropBox}>
                <div style={styles.actions}>
                    <PowerBIBranding />
                    {data.error && <Chip label={data.error} />}
                    <Actions
                        data={data}
                        changeDashboard={changeDashboard}
                        reload={reload}
                        close={props.closeWallboard}
                        loading={data.loading}
                    />
                </div>
                <div style={{ height: '90vh' }}>
                    <Content {...data} />
                </div>
            </GroupBox>
        </Grid>
    );
}

import { createSlice } from "@reduxjs/toolkit";
import { resetNotifications } from "../../ConversationConnection";
import { ChatParty, ChatStatus } from "../../helpers/Constant";
import { ChatService } from "../../services/ChatService";

export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        agentId: null,
        waiting: [],
        active: [],
        transferred: [],
        current: null,
        dashboard: {
            pending: [],
            active: [],
            transferred: [],
        }
    },
    reducers: {
        setAgentId: (state, action) => {
            state.agentId = action.payload;
        },
        addPendingChats: (state, action) => {
            state.dashboard.pending = action.payload;
        },
        handlePendingChat: (state, action) => {
            if (action.payload.status === ChatStatus.Pending) {
                state.dashboard.pending.push(action.payload);
            } else {
                state.dashboard.pending = state.dashboard.pending.filter(x => x.id !== action.payload.id);
            }
        },
        addWaitingChats: (state, action) => {
            state.waiting = action.payload;
        },
        addWaitingChat: (state, action) => {
            let index = state.waiting.findIndex(x => x.id === action.payload.id);
            if (index !== -1) {
                let chat = { ...state.waiting[index] };
                chat.queueId = action.payload.queueId;
                chat.queue = action.payload.queue;
                state.waiting[index] = { ...chat };
            }
            else {
                state.waiting.push(action.payload);
            }
            if (state.current === null) {
                state.current = action.payload;
            }
        },
        removeWaitingChat: (state, action) => {
            state.waiting = state.waiting.filter(x => x.id !== action.payload.id);
            chatSlice.caseReducers.setCurrentChat(state, null);
        },
        addActiveChats: (state, action) => {
            state.active = action.payload;
        },
        addActiveChat: (state, action) => {
            state.active.push(action.payload);
            if (state.current === null) {
                state.current = action.payload;
            }
        },
        removeActiveChat: (state, action) => {
            state.active = state.active.filter(x => x.id !== action.payload.id);
            state.dashboard.active = state.dashboard.active.filter(x => x.id !== action.payload.id);
            chatSlice.caseReducers.setCurrentChat(state, null);
        },
        addDashboardActiveChats: (state, action) => {
            state.dashboard.active = action.payload;
        },
        handleDashboardActiveChat: (state, action) => {
            if (action.payload.status === ChatStatus.Active) {
                state.dashboard.active.push(action.payload);
            } else if (action.payload.actioned) {
                state.dashboard.active = state.dashboard.active.filter(x => x.id !== action.payload.id)
            } else {
                state.dashboard.active = state.dashboard.active.filter(x => x.id !== action.payload.id)
                state.dashboard.active.push(action.payload);
            }
        },
        acceptChat: (state, action) => {
            state.waiting = state.waiting.filter(x => x.id !== action.payload.id);
            state.transferred = state.transferred.filter(x => x.id !== action.payload.id);
            state.dashboard.transferred = state.dashboard.transferred.filter(x => x.id !== action.payload.id);

            if (state.agentId === action.payload.agentId) {
                state.active.push(action.payload);
                state.current = action.payload;
            } else {
                chatSlice.caseReducers.setCurrentChat(state, null);
            }
        },
        addTransferredChats: (state, action) => {
            state.transferred = action.payload;
        },
        addTransferredChat: (state, action) => {
            state.transferred.push(action.payload);
            if (state.current === null) {
                state.current = action.payload;
            }
        },
        removeTransferredChat: (state, action) => {
            state.transferred = state.transferred.filter(x => x.id !== action.payload.id);
            state.dashboard.transferred = state.dashboard.transferred.filter(x => x.id !== action.payload.id);
            chatSlice.caseReducers.setCurrentChat(state, null);
        },
        addDashboardTransferredChats: (state, action) => {
            state.dashboard.transferred = action.payload;
        },
        handleDashboardTransferredChat: (state, action) => {
            if (action.payload.status === ChatStatus.ActiveAgentTransferred) {
                if (state.agentId === action.payload.agentId) {
                    state.dashboard.active = state.dashboard.active.filter(x => x.id !== action.payload.id)
                }
                state.dashboard.transferred.push(action.payload);
            } else {
                state.dashboard.transferred = state.dashboard.transferred.filter(x => x.id !== action.payload.id)
            }
        },
        setCurrentChat: (state, action) => {
            if (action?.payload !== undefined) {
                state.current = action.payload;
                return;
            }

            if (state.active?.length > 0) {
                state.current = { ...state.active[0] };
                return;
            }

            if (state.transferred?.length > 0) {
                state.current = { ...state.transferred[0] };
                return;
            }

            if (state.waiting?.length > 0) {
                state.current = { ...state.waiting[0] };
                return;
            }

            state.current = null;
        },
        updateCustomerMessage: (state, action) => {
            let index = state.active.findIndex(x => x.id === action.payload.id);
            let selectedChat = { ...state.active[index] };

            if (action.payload?.message.isSummary) {
                //Update existing message if message is summary
                let messageIndex = selectedChat.messages.findIndex(x => x.isSummary);
                selectedChat.messages[messageIndex] = action.payload.message
            } else {
                //Add new message
                selectedChat.messages.push(action.payload?.message);
                selectedChat.notifications = action.payload?.notifications
            }

            if (action.payload.id === state.current?.id) {
                selectedChat.notifications = 0;
                state.current = { ...selectedChat };
            }

            state.active[index] = { ...selectedChat };
        },
        endChat: (state, action) => {
            if (action.payload.status === ChatStatus.Abandoned) {
                //handle waiting/transfer end
                state.waiting = state.waiting.filter(x => x.id !== action.payload.id);
                state.transferred = state.transferred.filter(x => x.id !== action.payload.id);
                state.dashboard.transferred = state.dashboard.transferred.filter(x => x.id !== action.payload.id);
            } else if (action.payload.status === ChatStatus.Completed) {
                if (action.payload.actioned) {
                    state.active = state.active.filter(x => x.id !== action.payload.id);
                    chatSlice.caseReducers.setCurrentChat(state, null);
                }
                else {
                    //handle active end
                    let index = state.active.findIndex(x => x.id === action.payload.id);
                    let chat = { ...state.active[index] };
                    chat.status = action.payload.status;
                    chat.start = action.payload.start
                    state.active[index] = chat;
                }
            }

            if (state.current?.id === action.payload.id && (action.payload.status === ChatStatus.Abandoned)) {
                //handle current chat if abandoned
                chatSlice.caseReducers.setCurrentChat(state, null);
            } else if (state.current?.id === action.payload.id && (action.payload.status === ChatStatus.Completed)) {
                //handle current chat id completed
                let current = { ...state.current };
                current.status = action.payload.status;
                state.current = { ...current }
            }
        },
        resetNotificationCount: (state, action) => {
            let index = state.active.findIndex(x => x.id === action.payload);
            let chat = { ...state.active[index] };
            chat.notifications = 0;
            state.active[index] = { ...chat };
        },
        addChatSummaryMessage: (state, action) => {
            let index = state.active.findIndex(x => x.id === action.payload.id);
            let chat = { ...state.active[index] }

            let customerMessageCount = chat.messages.filter(x => x.party === ChatParty.Customer).length;

            if (customerMessageCount < 4) return;

            chat.messages.push(action.payload?.message);

            if (action.payload.id === state.current?.id) {
                state.current = { ...chat };
            }

            state.active[index] = { ...chat };
        },
        resetChats: (state) => {
            state.dashboard.pending = [];
            state.dashboard.active = [];
            state.dashboard.transferred = [];
            state.waiting = [];
            state.active = [];
            state.transferred = [];
        },
    }
});
export const { setAgentId, addPendingChats,
    handlePendingChat, addWaitingChats,
    addWaitingChat, addActiveChats,
    addDashboardActiveChats, addActiveChat,
    addTransferredChats, addDashboardTransferredChats,
    handleDashboardTransferredChat, addTransferredChat,
    removeTransferredChat, acceptChat,
    handleDashboardActiveChat, acceptTransferredChat,
    setCurrentChat, removeActiveChat,
    removeWaitingChat, updateCustomerMessage,
    updateAgentMessage, endChat,
    resetNotificationCount, addChatSummaryMessage, resetChats } = chatSlice.actions;

export const loadChats = () => async dispatch => {
    //load initial chat data
    var result = await ChatService.LoadChats();

    if (result?.success) {
        dispatch(addPendingChats(result?.data?.pending));
        dispatch(addWaitingChats(result?.data?.waiting));
        dispatch(addActiveChats(result?.data?.active));
        dispatch(addDashboardActiveChats(result?.data?.activeDashboard));
        dispatch(addTransferredChats(result?.data?.transferred));
        dispatch(addDashboardTransferredChats(result?.data?.transferredDashboard));
        dispatch(setCurrentChat());
    }
}

export const markAsRead = (id) => async dispatch => {
    //send signalR message to reset message read status
    resetNotifications(id);
    dispatch(resetNotificationCount(id));
}

export const reLoadChats = () => async dispatch => {
    dispatch(resetChats());

    //load initial chat data
    var result = await ChatService.LoadChats();

    if (result?.success) {
        dispatch(addPendingChats(result?.data?.pending));
        dispatch(addWaitingChats(result?.data?.waiting));
        dispatch(addActiveChats(result?.data?.active));
        dispatch(addDashboardActiveChats(result?.data?.activeDashboard));
        dispatch(addTransferredChats(result?.data?.transferred));
        dispatch(addDashboardTransferredChats(result?.data?.transferredDashboard));
        dispatch(setCurrentChat());
    }
}

export default chatSlice.reducer;
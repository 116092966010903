import React from 'react';
import {Button, TextField } from '@mui/material';
import { ConversationService } from '../../../services/ConversationService';
import { useDispatch, useSelector } from 'react-redux';
import { ConversationType } from '../../../helpers/Constant';
import { HandleServerResponse } from '../../../helpers/Common';
import CustomDialog from '../../../components/CustomDialog';

const AutoReply = ({ open, setOpenAutoReply,  handleReplaceText, handleInsertText }) => {
    const [autoCompleteContent, setAutoCompleteContent] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();
    const selectedConversation = useSelector(state => state.conversation.current);

    React.useEffect(() => {
        let active = true;
        (async () => {
            if (open) {
                GenerateReply();
            }
        })();
        return () => { active = false; }
    }, [open])

    const GenerateReply = async () => {
        setLoading(true);

        let params = {
            Id: selectedConversation.id,
            ConversationType: ConversationType.Email
        }

        var result = await ConversationService.GetAutoResponse(params);
        HandleServerResponse(result, "", dispatch);

        if (result.success) {
            setAutoCompleteContent(result.data)
        }
        setLoading(false);
    }

    const handleClose = () => {
        setOpenAutoReply(false);
    };

    const handleReplaceTextInEditor = () => {
        handleReplaceText(autoCompleteContent);
        handleClose();
    };

    const handleInsertTextInEditor = () => {
        handleInsertText(autoCompleteContent);
        handleClose();
    };

    return(
        <CustomDialog open={open} close={handleClose} title={"Auto Reply"}>
            <>
                <TextField
                    multiline
                    rows={9}
                    variant="outlined"
                    placeholder={!loading ? "Enter your text here..." : "Generating Reply..."}
                    value={autoCompleteContent}
                    onChange={(e) => setAutoCompleteContent(e.target.value)}
                    sx={{
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                />
            </>
            <>
                <Button
                variant="outlined"
                color="primary"
                sx={{ marginRight: '10px' }}
                disabled={loading || !autoCompleteContent}
                onClick={() => GenerateReply()}
                >
                    Try again
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{ marginRight: '10px' }}
                    disabled={loading || !autoCompleteContent}
                    onClick={handleInsertTextInEditor}
                >
                    Insert
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReplaceTextInEditor}
                    disabled={loading || !autoCompleteContent}
                >
                    Replace
                </Button>
            </>
        </CustomDialog>
    )
};

export default AutoReply;

import React from 'react';
import {
    Typography,
    Paper
} from '@mui/material';

import {
    Timeline,
    TimelineDot,
    TimelineItem,
    TimelineContent,
    TimelineSeparator,
    TimelineConnector,
    TimelineOppositeContent
} from '@mui/lab';

import {
    Call,
    Queue,
    PhoneInTalk,
    CallEndRounded,
    NoteAdd,
    PhoneForwarded,
} from '@mui/icons-material';
import { CommunicationType, CallStatus } from "../../helpers/Constant"
import PersonInfo from '../../components/PersonInfo';
import { formatToLongDate, formatToLongTime, isEmptyObject } from '../../helpers/Common';
import { CallDirection } from '../../helpers/Constant';
import { useTranslation } from 'react-i18next';

const TimeLine = props => {
    const styles = {
        paper: {
            padding: '6px 12px',
        },
        alignDate: {
            textAlign: "center"
        },
        timelineTime: {
            '& .MuiTimelineOppositeContent-root': {
                padding: '15px 16px'
            },
            '& .MuiTimelineContent-root': {
                padding: '13px 16px'
            }
        },
    }

    const { t } = useTranslation();
    const [date, setDate] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    let separator = <TimelineConnector />
    
    let incomingCall = props?.data?.direction === CallDirection.Incoming ? true : false;
    let userDataTypeArray = Array.isArray(props.data.userId);

    if(props.type === CommunicationType.invitecall){
        incomingCall = true;
    }

    let timelineInfo = [];

    const handleIncomingCallTimeline = (incoming) => {
        //Set timeline initial data
        timelineInfo.push({ name: props.data.source, key: 'call', startPoint: props.data.callStart });
        timelineInfo.push({ name: t("Assign to {queue}", {queue: props.queueName}), key: 'queue', startPoint: props.data.waitingStart });

        switch(incoming.type){
            case CommunicationType.activecall:
                if(userDataTypeArray){
                    incoming.data.userId.forEach(user => {
                        timelineInfo.push({ name: <PersonInfo id={user.id} />, key: 'phoneInTalk', startPoint: user.start })
                    });
                }else{
                    timelineInfo.push({ name: <PersonInfo id={incoming.data.userId} />, key: 'phoneInTalk', startPoint: incoming.data.activeStart });
                }
                break;
            case CommunicationType.completecall:
                if(incoming.data?.status ?? false){
                    if(incoming.data?.status === CallStatus.transferred){
                        if(userDataTypeArray){
                            incoming.data.userId.forEach(user => {
                                timelineInfo.push({ name: <PersonInfo id={user.id} />, key: 'phoneInTalk', startPoint: user.start })
                            });
                        }else{
                            timelineInfo.push({ name: <PersonInfo id={incoming.data.userId} />, key: 'phoneInTalk', startPoint: incoming.data.activeStart });
                        }
                        timelineInfo.push({ name: t("Call Transferred"), key: 'transferred', startPoint:  incoming.data.end });
                        if(incoming.data.wrapupend !== undefined){
                            timelineInfo.push({ name: t("Wrapup End"), key: 'noteAdd', startPoint: incoming.data.wrapupend})
                        }
                        break;
                    }
                }
                if(userDataTypeArray){
                    incoming.data.userId.forEach(user => {
                        timelineInfo.push({ name: <PersonInfo id={user.id} />, key: 'phoneInTalk', startPoint: user.start })
                    });
                }else{
                    timelineInfo.push({ name: <PersonInfo id={incoming.data.userId} />, key: 'phoneInTalk', startPoint: incoming.data.activeStart });
                }
                timelineInfo.push({ name: t("Call End"), key: 'callEndRounded', startPoint: incoming.data.end });
                if(incoming.data.wrapupend !== undefined){
                    timelineInfo.push({ name: t("Wrapup End"), key: 'noteAdd', startPoint: incoming.data.wrapupend})
                }
                break;
            case CommunicationType.call:
                handlleOldCallNodes(incoming);
                break;
            default:
                break;
        }
    }

    const handleOutgoingCallTimeline = (outgoing) => {
        //Set timeline initial data
        if(userDataTypeArray){
            outgoing.data.userId.forEach(user => {
                timelineInfo.push({ name: <PersonInfo id={user.id} />, key: 'phoneInTalk', startPoint: user.start !== undefined ? user.start : outgoing.data.activeStart})
            });
        }else{
            timelineInfo.push({ name: <PersonInfo id={outgoing.data.userId} />, key: 'phoneInTalk', startPoint: outgoing.data.activeStart });
        }
        
        switch(outgoing.type){
            case CommunicationType.activecall:
                break;
            case CommunicationType.completecall:
                timelineInfo.push({ name: t("Call End"), key: 'callEndRounded', startPoint: outgoing.data.end });
                break;
            case CommunicationType.call:
                handlleOldCallNodes(outgoing)
                break;
            default:
                break;
        }
    }

    const handlleOldCallNodes = (oldCall) => {
        if (oldCall.data.status === CallStatus.completed || oldCall.data.status === CallStatus.shortcall) {
            let wrapuptime = ""
            let personCompoenent = "";
            oldCall.data.userId.forEach(user => {
                if (user.acwEnd !== null) {
                    wrapuptime = user.acwEnd;
                }
                if(oldCall.data.direction !== CallDirection.Outgoing){
                    personCompoenent = <PersonInfo id={user.id} />
                    timelineInfo.push({ name: personCompoenent, key: 'phoneInTalk', startPoint: user.start })
                }
            });
            timelineInfo.push({ name: t("Call End"), key: 'callEndRounded', startPoint: oldCall.data.end })

            if(wrapuptime !== ""){
                timelineInfo.push({ name: t("Wrapup End"), key: 'noteAdd', startPoint: wrapuptime })
            }
        }else if (oldCall.data.status === CallStatus.transferred) {
            let wrapuptime = ""
            let personCompoenent = "";
            if(oldCall.data?.userId ?? false){
                oldCall.data.userId.forEach(user => {
                    if (user.acwEnd !== null) {
                        wrapuptime = user.acwEnd;
                    }
                    if(oldCall.data.direction !== CallDirection.Outgoing){
                        personCompoenent = <PersonInfo id={user.id} />
                        timelineInfo.push({ name: personCompoenent, key: 'phoneInTalk', startPoint: user.start })
                    }
                });
            }
  
            //add transfered item
            timelineInfo.push({ name: t("Call Transferred"), key: 'transferred', startPoint: oldCall.data.end });

            if(wrapuptime !== ""){
                timelineInfo.push({ name: t("Wrapup End"), key: 'noteAdd', startPoint: wrapuptime });
            }
        } else {
            timelineInfo.push({ name: t("Call End"), key: 'callEndRounded', startPoint: oldCall.data.end })
        }
    }

    const handleAbandonedIvrCall = () => {
        timelineInfo.push({ name: props.data.source, key: 'call', startPoint: props.data.callStart });
        timelineInfo.push({ name: t("Call End"), key: 'callEndRounded', startPoint: props.data.end });
    }

    if(props.queueName === null){
        handleAbandonedIvrCall();
    }else if(!isEmptyObject(props.data)){
        incomingCall === true ? handleIncomingCallTimeline(props) : handleOutgoingCallTimeline(props);
    }

    const icons = {
        call: <Call color="primary" />,
        queue: <Queue color="primary" />,
        callEndRounded: <CallEndRounded color="primary" />,
        transferred: <PhoneForwarded color="primary" />,
        phoneInTalk: <PhoneInTalk color="primary" />,
        noteAdd: <NoteAdd color="primary" />
    };

    const getIcon = (key, index) => {
        if ((timelineInfo.length - 1) === index) {
            separator = "";
        }
        return icons[key];
    }

    const getTime = (date) => {
        let time = formatToLongTime(date);
        return time;
    }

    React.useEffect(() => {
        //set user
        setLoading(true)
        if (props.data.callStart !== undefined) {
            let dateTime = formatToLongDate(props.data.callStart);
            setDate(dateTime);
        }
        setLoading(false)
    }, [props])

    return (
        <React.Fragment>
            <Typography variant="body1" color="primary" sx={styles.alignDate}>
                {date}
            </Typography>
            {
                !loading &&
                <Timeline position="alternate">
                    {
                        timelineInfo.map((event, index) => (
                            <TimelineItem key={index} sx={styles.timelineTime}>
                                <TimelineOppositeContent>
                                    <Typography variant="body2" color="textSecondary">
                                        {getTime(event.startPoint)}
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" variant="outlined">
                                        {getIcon(event.key, index)}
                                    </TimelineDot>
                                    {separator}
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper sx={styles.paper} variant="outlined" >
                                        <div style={{ textAlign: "center" }} variant="body2">{event.name}</div>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        ))
                    }
                </Timeline>
            }

        </React.Fragment>
    );
};

export default TimeLine;
import React from 'react';
import { useSelector } from 'react-redux';
import Item from './list/Item';
import GroupBox from '../../components/GroupBox';
import Header from './list/Header';
import { ChatStatus, ConversationType } from '../../helpers/Constant';

export default function ChatList() {
    const { conversations, currentChat } = useSelector((state) => state.conversation);
    const [count, setCount] = React.useState({ active: 0, wrapup: 0 });
    const [activeConversations, setActiveConversations] = React.useState([]);
    const [transferredConversations, setTransferredConversations] = React.useState([]);
    const [waitingConversations, setWaitingConversations] = React.useState([]);

    React.useEffect(() => {
        const wrapupCount = conversations.filter(x => x.type === ConversationType.Chat && !x.actioned && x.status === ChatStatus.Completed).length;
        const activeCount = conversations.filter(x => x.type === ConversationType.Chat && x.status === ChatStatus.Active).length - wrapupCount;

        setCount({ active: activeCount, wrapup: wrapupCount });

        // Filter and set separate state for each conversation type
        setActiveConversations(conversations.filter(x => x.type === ConversationType.Chat && (x.status === ChatStatus.Active || (x.status === ChatStatus.Completed && !x.actioned))));
        setTransferredConversations(conversations.filter(x => x.type === ConversationType.Chat && x.status === ChatStatus.ActiveAgentTransferred));
        setWaitingConversations(conversations.filter(x => x.type === ConversationType.Chat && x.status === ChatStatus.Waiting));
    }, [conversations]);

    const renderItems = (conversations) => {
        return conversations.map((conversation) => (
            <Item data={conversation} key={conversation.id} selected={currentChat?.id === conversation.id} />
        ));
    };

    return (
        <GroupBox
            smallBodyPadding={true}
            showDivider={false}
            gutter
            sx={{ height: '45%', position: 'relative' }}
        >
            <Header waiting={waitingConversations.length} active={count.active} transferred={transferredConversations.length} wrapup={count.wrapup} />
            <div style={{ position: 'absolute', bottom: 1, left: 0, right: 0, top: 35, overflowY: 'auto', marginTop: 4, marginLeft: 7, marginRight: 7 }}>
                {renderItems(activeConversations)}
                {renderItems(transferredConversations)}
                {renderItems(waitingConversations)}
            </div>
        </GroupBox>
    );
}
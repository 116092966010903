import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, DialogTitle, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Done } from '@mui/icons-material';
import { checkUserPermission, emailValidation, HandleServerResponse, LogInformation, validatePhoneNumber } from '../../helpers/Common';
import { TenantService } from '../../services/TenantService';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../components/Inputs/DropDown';
import AutoCompleteSearch from '../../components/Inputs/AutoCompleteSearch';
import UseForm from '../../components/form/UseForm';
import Input from '../../components/Inputs/Input';
import { GetUserContact } from '../../helpers/GraphHelper';
import { IssueService } from '../../services/IssueService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    img: {
        width: 100,
        height: 100
    },
    actions: {
        margin: theme.spacing(0, 1, 1, 1),
        '& > .MuiButtonBase-root': {
            padding: theme.spacing(0.5, 2, 0.5, 2),
        },
    },
}));

const ContactMethods = [
    {
        id: "Email",
        name: "Email"
    },
    {
        id: "Phone",
        name: "Phone"
    }
];

const Availability = [
    {
        id: 'Business Hours',
        name: 'Business Hours'
    },
    {
        id: '24x7',
        name: "24x7"
    }
];

const initialValues = {
    title: '',
    description: '',
    severity: '',
    date: '',
    timeZone: { id: 'default', name: "Default Time Zone" },
    contactMethod: '',
    availability: '',
    additionalEmail: '',
    phone: ''
}

export default function Issue(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [timeZones, setTimeZones] = React.useState([]);
    const [severityLevels, setSeverityLevels] = React.useState([]);
    const [disableBtn, setDisableBtn] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const { user: { permissions } } = useSelector((state) => state.auth);
    const issueWritePermission = checkUserPermission(permissions, ["issue.write"])

    const validate = (fieldValues = values) => {
        let validationProps = { ...errors };

        if ('title' in fieldValues)
            validationProps.title = fieldValues.title ? "" : t("Required")
        if ('severity' in fieldValues)
            validationProps.severity = fieldValues.severity !== "" ? "" : t("Required")
        if ('timeZone' in fieldValues)
            validationProps.timeZone = fieldValues.timeZone ? "" : t("Required")
        if ('date' in fieldValues)
            validationProps.date = fieldValues.date ? "" : t("Required")
        if ('contactMethod' in fieldValues)
            validationProps.contactMethod = fieldValues.contactMethod ? "" : t("Required")
        if ('availability' in fieldValues)
            validationProps.availability = fieldValues.availability ? "" : t("Required")
        if ('description' in fieldValues)
            validationProps.description = fieldValues.description ? "" : t("Required")
        if ('additionalEmail' in fieldValues) {
            if (fieldValues.additionalEmail === "") {
                validationProps.additionalEmail = ""
            } else {
                validationProps.additionalEmail = emailValidation(fieldValues.additionalEmail);
            }
        }
        if ('phone' in fieldValues)
            validationProps.phone = validatePhoneNumber(fieldValues.phone);

        setErrors({
            ...validationProps
        });

        if (fieldValues === values)
            return Object.values(validationProps).every(x => x === "");
    }

    const {
        values,
        setValues,
        handleInputChanges,
        errors,
        setErrors,
    } = UseForm(initialValues, true, validate);

    React.useEffect(() => {
        let active = true;
        (async () => {
            setLoading(true);

            let result = await IssueService.GetIssueReferences();
            if (result.success && active) {
                setTimeZones(result.referrenceData["timeZones"]);
                setSeverityLevels(result.referrenceData["severityLevels"]);
                setValues({ ...values, ["phone"]: result.referrenceData["phone"] })
            }

            if (!active) { return; }
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, []);

    const addNewIssue = (e) => {
        e.preventDefault();
        if (validate()) {
            //call the api
            (async () => {
                setDisableBtn(true)
                let params = {
                    Summary: values.title,
                    Severity: values.severity,
                    TimeZone: values.timeZone?.name,
                    DateTime: values.date,
                    ContactMethod: values.contactMethod,
                    Availability: values.availability,
                    AdditionalEmail: values.additionalEmail,
                    Description: values.description,
                    Phone: values.phone
                }

                let result = await IssueService.ReportIssue(params);

                LogInformation("TenantService.ReportIssue", result);
                HandleServerResponse(result, t("Your issue reported successfully"), dispatch);

                if (result.success) {
                    props.closeDialog();
                }
                setDisableBtn(false)
            })();
        }
    }

    return (
        <div>
            <Dialog className={classes.btnStyles} open={props.open} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
                <DialogTitle id="form-dialog-title">{t("Report an issue")}</DialogTitle>
                <DialogContent>
                    <Box alignItems="center">
                        <Input
                            paddingRight={0}
                            required
                            size="large"
                            name='title'
                            label={t("Issue summary")}
                            onChange={handleInputChanges}
                            value={values.title}
                            error={errors.title}
                        />
                        <DropDown
                            disable={loading}
                            required={true}
                            size="large"
                            label={t("Select severity level")}
                            name={"severity"}
                            onChange={(e) => handleInputChanges(e)}
                            value={values.severity}
                            values={severityLevels}
                            error={errors.severity}
                        />
                        <AutoCompleteSearch
                            required
                            full={true}
                            defaultValue={values.timeZone}
                            disabled={loading}
                            value={values.timeZone}
                            onChange={handleInputChanges}
                            options={timeZones}
                            label={t("Time Zone")}
                            name={"timeZone"}
                            error={errors.timeZone}
                        />
                        <TextField
                            required={true}
                            style={{ width: '100%' }}
                            label={t("When did the problem occur")}
                            name={'date'}
                            value={values.date}
                            onChange={handleInputChanges}
                            variant="outlined"
                            type={"datetime-local"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={errors.date}
                        />
                        <DropDown
                            required={true}
                            size="large"
                            label={t("Preferred contact method")}
                            name={"contactMethod"}
                            onChange={(e) => handleInputChanges(e)}
                            value={values.contactMethod}
                            values={ContactMethods}
                            error={errors.contactMethod}
                        />
                        <DropDown
                            required={true}
                            size="large"
                            label={t("Your availability")}
                            name={"availability"}
                            onChange={handleInputChanges}
                            value={values.availability}
                            values={Availability}
                            error={errors.availability}
                        />
                        <Input
                            required={true}
                            paddingRight={0}
                            size="large"
                            name='phone'
                            label={t("Contact number")}
                            onChange={handleInputChanges}
                            value={values.phone}
                            error={errors.phone}
                        />
                        <Input
                            paddingRight={0}
                            size="large"
                            name='additionalEmail'
                            label={t("Additional email")}
                            onChange={handleInputChanges}
                            value={values.additionalEmail}
                            error={errors.additionalEmail}
                        />
                        <Input
                            required
                            multiline={true}
                            rows={5}
                            size="large"
                            name='description'
                            label={t("Description")}
                            onChange={handleInputChanges}
                            value={values.description}
                            error={errors.description}
                            paddingRight={0}
                        />
                    </Box>
                </DialogContent>
                {!issueWritePermission &&
                    <div style={{ color: 'red', marginLeft: '25px' }}>You do not have permission to report an issue.</div>
                }
                <DialogActions className={classes.actions}>
                    <Button disabled={disableBtn || !issueWritePermission} onClick={(e) => addNewIssue(e)} startIcon={<Done />}
                        variant="contained" color="primary" disableElevation>{t("Submit")}</Button>
                    <Button
                        style={{ marginRight: 8 }}
                        onClick={props.closeDialog}
                        startIcon={<Close />}
                        variant="contained"
                        color="grey"
                        disableElevation>{t("Close")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../../helpers/Common";
import { JiraService } from "../../../services/JiraService";

const JiraConsent = () => {

    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');

    React.useEffect(() => {

        const credentials = localStorage.getItem('jira-credentials')

        if (credentials) {
            (async () => {
                setLoading(true);
                const item = JSON.parse(credentials)

                var url = new URL(window.location.href);
                var search_params = url.searchParams;
                var code = search_params.get('code');

                var params = {
                    Code: code.toString(),
                    ClientId: item.clientId,
                    ClientSecret: item.clientSecret,
                    RedirectUri: item.redirectUri,
                }

                let result = await JiraService.GetAccessToken(params);

                if (result.success) {
                    removeLocalStorage('jira-credentials');
                    setLocalStorage('jira-auth', result.data);
                    setMessage("Authenticated successfully, you can now close this tab");
                } else {
                    removeLocalStorage('jira-credentials');
                    setMessage("Could not authenticate, you can now close this tab");
                }

                setLoading(false)
            })();
        }else{
            (async () => {
                setLoading(true);
                const conectorId = getLocalStorage('jira-connectorId')

                var url = new URL(window.location.href);
                var search_params = url.searchParams;
                var code = search_params.get('code');

                var params = {
                    Code: code.toString(),
                    ConnectorId: conectorId,
                    JiraAuthGrantTypes: 1
                }

                let result = await JiraService.GetAccessToken(params);

                if (result.success) {
                    removeLocalStorage('jira-connectorId');
                    setLocalStorage('jira-auth', result.data);
                    setMessage("Authenticated successfully, you can now close this tab");
                } else {
                    removeLocalStorage('jira-connectorId');
                    setMessage("Could not authenticate, you can now close this tab");
                }

                setLoading(false)
            })();
        }

    }, [])

    return (
        <React.Fragment>
            {
                loading ? <CircularProgress /> : <Typography>{message}</Typography>
            }
        </React.Fragment>
    )
}

export default JiraConsent;
import axios from 'axios';

const all = async (values) => {
    const { data } = await axios.get('/api/blockedNumber/list',{params: values}).then(result => result);
    return data;
};

const get = async (id=null) => {
    const { data } = await axios.get(`/api/blockedNumber/${id}`).then(result => result);
    return data;
}

const create = async (values) => {
    const { data } = await axios.post('/api/blockedNumber',values).then(result => result);
    return data;
}

const block = async (values) => {
    const { data } = await axios.post('/api/blockedNumber/block',values).then(result => result);
    return data;
}

const updateStatus = async (id, status) => {
    const { data } = await axios.post(`/api/blockedNumber/${id}/${status}`).then(result => result);
    return data;
}

const remove = async (id) => {
    const { data } = await axios.delete(`/api/blockedNumber/${id}`).then(result => result);
    return data;
};

export const BlockNumberService = {
    all,
    get,
    create,
    block,
    updateStatus,
    remove,
};
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import timeago from 'epoch-timeago';
import { UserService } from "../../../services/UserService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    date: {
        paddingTop: theme.spacing(1),
        width: "100%",
        textAlign: "center",
        fontSize: "0.66rem"
    },
    tableRoot: {
        width: "100%"
    }
}))


export default function QueueSummary() {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    const { queueSummary: { stats, lastUpdated } } = useSelector((state) => state.interaction);

    React.useEffect(() => {
        async function GetQueueStats() {
            if (stats.length !== 0) {
                setLoading(true);

                await UserService.queueStats();

                setLoading(false);
            }
        }

        GetQueueStats();
        const interval = setInterval(GetQueueStats, 30000);

        return () => clearInterval(interval);
    }, []);

    return (
        <React.Fragment>
            {
                !loading ? (
                    <div className={classes.tableRoot}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("Name")}</TableCell>
                                    <TableCell align="left">{t("Waiting")}</TableCell>
                                    <TableCell align="left">{t("In a Call")}</TableCell>
                                    <TableCell align="left">{t("Avg Consult")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    stats?.length > 0 ?
                                        stats.map((queue, index) =>
                                            <TableRow
                                                key={queue.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">{queue.name}</TableCell>
                                                <TableCell align="left">{queue.waiting}</TableCell>
                                                <TableCell align="left">{queue.inaCall}</TableCell>
                                                <TableCell align="left">{formatSecsToMinute(queue.averageConsult)}</TableCell>
                                            </TableRow>
                                        ) :
                                        <TableRow>
                                            <TableCell sx={{ textAlign: "center" }} colSpan={4} component="th" scope="row">{t("No data available")}</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                        {
                            stats?.length > 0 ?
                                <div className={classes.date}>
                                    <time datetime={new Date(lastUpdated).toISOString()}>{t("Last updated")} : {timeago(lastUpdated)}</time>
                                </div> : null
                        }
                    </div>
                ) : (
                    <div style={{ width: "100%" }}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </div>

                )
            }
        </React.Fragment>
    )
}


const formatSecsToMinute = (value) => {

    value = Number.isNaN(value) ? 0 : value;

    let hours = Math.floor(value / 3600);
    let minutes = Math.floor((value - hours * 3600) / 60);
    let seconds = value - minutes * 60;

    seconds = seconds <= 9 ? `0${Math.round(seconds)}` : Math.round(seconds) > 59 ? 59 : Math.round(seconds);

    if (hours > 0) {
        minutes = minutes <= 9 ? `0${Math.round(minutes)}` : Math.round(minutes);
        return `${hours.toString().slice(0, 2)}:${minutes.toString().slice(0, 2)}:${seconds.toString().slice(0, 2)}`;
    } else {
        minutes = Math.round(minutes)
        return `${minutes.toString().slice(0, 2)}:${seconds.toString().slice(0, 2)}`;
    }
}
import React from 'react';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { useTheme } from '@mui/styles';

export default function RegistrationTimeline({ load }) {
    const theme = useTheme();
    const styles = {
        root: {
            '& .MuiTimelineItem-missingOppositeContent': {
                '&::before': {
                    flex: 0
                }
            },
            padding: theme.spacing(0, 2),
        },
    }

    return (
        <Timeline align="right" sx={styles.root}>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: !load.grantPermission ? '#dedede' : 'green' }} />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Grant Permission</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: !load.organization ? '#dedede' : 'green' }} />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Organisation Details</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: !load.completed ? '#dedede' : 'green' }} />
                </TimelineSeparator>
                <TimelineContent>Finish</TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Paper, Box, Button, MenuItem, Menu, CircularProgress } from '@mui/material';
import { PermIdentity, PersonAdd, Public } from '@mui/icons-material';
import { Providers } from '@microsoft/mgt';
import { Link } from "react-router-dom";
import { getLocalStorage, isMobileDevice, removeLocalStorage, setLocalStorage } from '../../helpers/Common';
import { useTheme } from '@emotion/react';
import { Link as MuiLink } from '@mui/material';
import Environment from '../../Environment';
import Loading from '../../components/Loading';
import { UserService } from '../../services/UserService';

const useStyles = makeStyles(theme => ({
  info: {
    padding: theme.spacing(4, 6, 0, 6),
  },
  infoMobile: {
    padding: theme.spacing(3, 6, 6, 6),
  },
  bottom:{
    display:'flex',
    flexDirection: "column",
    alignItems: 'flex-end',
    paddingTop: theme.spacing(6),
    marginRight: theme.spacing(1),
  },
  version: {
    fontSize: '0.60rem',
    fontWeight: 600,
    fontFamily: 'monospace',
    fontStyle: 'italic',
    textAlign: "end"
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  regionRoot:{
    display: "flex", 
  },
  regionName:{
    display: "flex", 
    alignItems:"center"
  },
}));

var color;

export default function LoginComponent(props) {
  const classes = useStyles();
  const theme = useTheme();

  const styles = {
    root: {
      height: '100vh',
      backgroundColor: theme.palette.background.default,
      '& > *': {
        height: '100%',
      },
    },
    container: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1)

    },
    logo: {
      width: '200px',
      height: '200px',
      margin: theme.spacing(2, 0, 2, -2),
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      m: "auto",
      '& > img': {
        height: '100%',
        width: '100%',
      },
    },
    content: {
      textAlign: "center",
      m: 'auto',
    },
    mobileContent: {
      textAlign: "center",
      width: '100%'
    },
    loading: {
      padding: theme.spacing(1, 6),
      marginBottom: theme.spacing(8),
    },
  }

  if (theme.palette.mode === "light") {
    color = "black";
  } else {
    color = "white";
  }

  const mobile = isMobileDevice();

  const handleSignIn = async () => {
    const provider = Providers.globalProvider;

    if (provider && provider.login) {
      try {
        await provider.login();
      } catch (error) {
        //TODO handle error
      }
    }
  }

  const hanndleUseAnotherAccount = async () => {
    const provider = Providers.globalProvider;

    if (provider && provider.logout) {
      removeLocalStorage("cc.user");
      removeLocalStorage("cc.tabs");
      removeLocalStorage("cc-tabs-index");

      await provider.logout();
    }
  }

  return (
    <Box sx={styles.root}>
      <Box display="flex" justifyContent="center" alignItems="center">
        {props.showSignIn || props.error ?
          <Paper sx={styles.container}>
            <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
              <Paper variant="outlined" sx={styles.logo}>
                <img src={window.location.origin + '/logo192.png'} alt="Logo" />
              </Paper>
              <Box display="flex" flexDirection='column' justifyContent="space-between" sx={mobile ? styles.mobileContent : classes.content}>
                <div className={classes.info}>
                  <LoginContent
                    color={color}
                    error={props.error}
                    register={props.registered}
                    verified={props.verified}
                    multiRegion={props.multiRegion}
                    hanndleUseAnotherAccount={hanndleUseAnotherAccount}
                    handleSignIn={handleSignIn}
                  />
                </div>
                <div className={classes.bottom}>
                  <MultiRegion/>
                  <div className={classes.version}>
                    {
                      props.error === null &&
                      <Typography variant='body2' sx={{ fontStyle: 'initial !important', mb: theme.spacing(0.5), fontSize: '0.7rem' }}>
                        New to CentrePal?
                        <MuiLink sx={{ ml: theme.spacing(0.5), fontSize: '0.73rem' }} onClick={() => window.open("https://www.centrepal.com/contact-centre-live-demo/?utm_source=appsource&utm_medium=web&utm_campaign=app", '_blank')} href="#" variant="body2" color="primary">
                          Learn more
                        </MuiLink>
                      </Typography>
                    }
                    {`Version ${Environment.config.VERSION}`}
                  </div>
                </div>
              </Box>
            </Box>
          </Paper> : <Loading wrapper={true} />
        }
      </Box>
    </Box>
  );
}

const LoginContent = ({ color, error, register, verified, multiRegion, hanndleUseAnotherAccount, handleSignIn }) => {
  const classes = useStyles();

  if (error === null) {
    return (
      <div className={classes.contentWrapper}>
        <Typography variant='body1'>Please sign in to continue</Typography>
        <br />
        <Button
          id='login-button'
          color={color}
          onClick={async () => await handleSignIn()}
          variant="outlined"
          startIcon={<PermIdentity />}>Sign In</Button>
      </div>
    )
  } else {
    if (!register) {
      return (
        <div className={classes.contentWrapper}>
          <Typography variant='body1' fontWeight='bold'>Register a new tenant</Typography>
          <Typography variant='body1' sx={{ width: '20em' }}>
            To use this app, your company needs a valid CentrePal subscription.
            Please contact one of our trusted channel partners or visit <a href='https://www.centrepal.com/' target='_blank'>www.centrepal.com</a> for more information.
          </Typography>
          <br />
          <Button
            color={color}
            component={Link}
            to="/register"
            variant="outlined"
            style={{ width: '11rem' }}
            startIcon={<PersonAdd />}>
            Register
          </Button>
          <Button
            onClick={async () => await hanndleUseAnotherAccount()}
            variant="outlined"
            color={color}
            style={{ width: '11rem', marginTop: 8 }}
            startIcon={<PermIdentity />}>
            Use another account
          </Button>
        </div>
      )
    }

    if (register && !verified) {
      return (
        <div className={classes.contentWrapper}>
          <Typography variant='body1' fontWeight='bold'>Your organization is not verified yet</Typography>
          <Typography variant='body1' sx={{ width: '20em' }}>
            To use this app, your company needs a valid CentrePal subscription.
            Please contact one of our trusted channel partners or visit <a href='https://www.centrepal.com/' target='_blank'>www.centrepal.com</a> for more information.
          </Typography>
          <br />
          <Button
            color='black'
            onClick={async () => await hanndleUseAnotherAccount()}
            variant="outlined"
            startIcon={<PermIdentity />}>Use another account</Button>
        </div>
      );
    }

    if (register && !multiRegion) {
      return (
        <div className={classes.contentWrapper}>
          <Typography color="textPrimary" variant='body1' sx={{ width: '20em', textAlign: 'left' }}>{error}</Typography>
          <br />
          <Button
            color={color}
            onClick={async () => await hanndleUseAnotherAccount()}
            variant="outlined"
            startIcon={<PermIdentity />}>Use another account</Button>
        </div>
      )
    }

    if (register && multiRegion) {
      return (
        <div className={classes.contentWrapper}>
          <Typography variant='body1' fontWeight='bold'>Invalid region selection</Typography>
          <Typography color="textPrimary" variant='body1' sx={{width: '20em',textAlign: 'left' }}>{error}</Typography>
          <br />
          <Button
            color={color}
            onClick={async () => await handleSignIn()}
            variant="outlined"
            startIcon={<PermIdentity />}>Sign In</Button>
        </div>
      )
    }
  }

  return (
    <div className={classes.contentWrapper}>
      <Typography color="textPrimary" variant='body1' sx={{ width: '20em', textAlign: 'left' }}>Something went wrong. Please contact CentrePal.</Typography>
    </div>
  )
}

const MultiRegion = () => {
  const classes = useStyles();
  const [data, setData] = React.useState(() => ({
    anchor: null,
    regions: null,
    value: getLocalStorage("cc-multi-region") ?? Environment.config.REGION,
    loading: false
  }));

  const checkMultiRegion = (e) => {
    (async() => {
      setData((prev) => {
        return {...prev, ["anchor"]: e.currentTarget}
      })
      const urlParams = new URLSearchParams(window.location.search);
      let tid = urlParams.get('tid');
      let upn = urlParams.get('upn');
      if((upn ?? false) && (tid ?? false) && data.regions === null){
        setData((prev) => {
          return {...prev, ["loading"]: true}
        })
        var result = await UserService.regions(tid);
        if(result?.data?.length > 0){
          setData((prev) => {
            return {...prev, ["regions"]: result?.data,  ["loading"]: false}
          })
        }
        setData((prev) => {
          return {...prev, ["loading"]: false}
        })
      }
    })();
  }

  const setSelectedRegion = (val) => {
    setData((prev) => {
      return {...prev, ["anchor"]: null, ["value"]:val}
    })
    setLocalStorage('cc-multi-region', val);
    Environment.Load(val);
  }

  const styles = {
    button:{
      minWidth:"20px",
      p:0, 
      fontSize:"0.7rem",
      border:"none",
    },
    menuItem:{
      fontSize:"0.7rem",
    },
    regionName:{
      p: "0px", 
      fontSize: "0.7rem", 
    },
    regionIcon:{
      fontSize: "12px", 
      ml: "4px", 
      mr:"4px"
    },
    progress:{
      mr:"2px", 
      mt:"4.5px", 
      width: "10px !important", 
      height:"10px !important"
    }
  }

  //This is the region mapping and update when new region spinup
  const regions = {
    LOCAL: "Local",
    STG: "Staging",
    AUEA: "Australia",
    UKSO: "UK & Europe"
  }

  return (
    <>
      <div className={classes.regionRoot}>
        {data.loading && <CircularProgress sx={styles.progress} />}
        <div className={classes.regionName}>
          <Typography variant='caption' sx={styles.regionName}>Region</Typography>
          <Public sx={styles.regionIcon} color='primary' />
        </div>
        <Button
          sx={styles.button}
          color={color}
          onClick={(e) => checkMultiRegion(e)}>
          {regions[data.value]}
        </Button>
      </div>
      <Menu
        sx={{ minWidth: 100 }}
        id="fade-menu"
        anchorEl={data.anchor}
        open={Boolean(data.anchor) && data.regions !== null}
        onClose={() => setData((prev => ({ ...prev, ["anchor"]: null })))}
      >
        {
          data.regions?.map(region => {
            return <MenuItem sx={styles.menuItem} key={region.id} onClick={() => setSelectedRegion(region.id)}>{regions[region.id]}</MenuItem>
          })
        }
      </Menu>
    </>
  )
}
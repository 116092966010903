import React from "react";
import { ConversationOperation, ConversationStatus, ConversationType } from "../../../helpers/Constant";
import { ConversationService } from "../../../services/ConversationService";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { HandleServerResponse } from "../../../helpers/Common";
import { setMessageAction, updateCurrentStatus } from "../ConversationSlice";
import { MoveUpOutlined, NewReleasesOutlined, PersonAddOutlined, TaskAltRounded, TurnLeftOutlined, TurnRightOutlined } from "@mui/icons-material";
import UserOrQueueSelectDialog from "./dialogs/UserOrQueueSelectDialog";
import CompleteDialog from "./dialogs/CompleteDialog";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { styled } from '@mui/material/styles';

const ActionBar = ({ isSupervisorView }) => {
    const Root = styled('div')(({ theme }) => ({
        height: 45,
        width: '100%',
        border: '1px solid #0000001f',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        margin: '10px auto',
        paddingLeft: '4px',
    }));

    const StyledButton = styled(Button)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '5px 10px',
        marginRight: '10px',
        border: '1px solid transparent',
        '& .MuiButton-startIcon': {
            marginRight: 6,
        },
        '& .MuiSvgIcon-root': {
            fontSize: 22,
        },
        '&:hover': {
            border: '1px solid',
        }
    }));

    const Divider = styled('div')({
        height: '80%',
        width: '1px',
        backgroundColor: '#0000001f',
        marginRight: '10px'
    });

    const [openQueueUserTransfer, setOpenQueueUserTransfer] = React.useState(false);
    const [openMarkCompleteDialog, setOpenMarkCompleteDialog] = React.useState(false);
    const [selectedTransferType, setSelectedTransferType] = React.useState(null);
    const [openMarkAsSpam, setOpenMarkAsSpam] = React.useState(false);

    const { id, userId, status, queueId, messageAction } = useSelector(state => state.conversation.current);

    const dispatch = useDispatch();

    const closePopup = () => {
        setOpenQueueUserTransfer(false);
    }

    const openQueueUserTransferDialog = (type) => {
        setSelectedTransferType(type)
        setOpenQueueUserTransfer(true);
    }

    const HandleMessageAction = (action) => {
        dispatch(setMessageAction(action));
    }

    const handleMarkAsSpam = async () => {
        let params = {
            Id: id,
            ConversationType: ConversationType.Email,
            ConversationOperation: ConversationOperation.MarkAsSpam
        }

        var result = await ConversationService.HandleConversation(params);
        HandleServerResponse(result, "Conversation marked as spam", dispatch);

        if (result?.success) {
            dispatch(updateCurrentStatus({id: id, type: ConversationType.Email, status: status}));
        }
        setOpenMarkAsSpam(false)
    };

    return (
        <Root>
            <StyledButton
                disabled={!userId || status === ConversationStatus.Completed || status === ConversationStatus.Declined || isSupervisorView}
                color="primary"
                startIcon={<TaskAltRounded />}
                onClick={() => setOpenMarkCompleteDialog(true)}
            >
                Complete
            </StyledButton>
            <StyledButton
                disabled={!userId || status === ConversationStatus.Completed || status === ConversationStatus.Declined || isSupervisorView}
                color="error"
                startIcon={<NewReleasesOutlined />}
                onClick={() => setOpenMarkAsSpam(true)}
            >
                Spam
            </StyledButton>
            <Divider />
            <StyledButton
                disabled={!userId || status === ConversationStatus.Completed || status === ConversationStatus.Declined || messageAction === "reply" || isSupervisorView}
                color="primary"
                startIcon={<TurnLeftOutlined />}
                onClick={() => HandleMessageAction("reply")}
            >
                Reply
            </StyledButton>
            <StyledButton
                disabled={!userId || status === ConversationStatus.Completed || status === ConversationStatus.Declined || messageAction === "forward" || isSupervisorView}
                color="primary"
                startIcon={<TurnRightOutlined />}
                onClick={() => HandleMessageAction("forward")}
            >
                Forward
            </StyledButton>
            <Divider />
            <StyledButton
                disabled={!userId || status === ConversationStatus.Completed || status === ConversationStatus.Declined || isSupervisorView}
                color="primary"
                startIcon={<PersonAddOutlined />}
                onClick={() => openQueueUserTransferDialog('AgentTransfer')}
            >
                Assign
            </StyledButton>
            <StyledButton
                disabled={!userId || status === ConversationStatus.Completed || status === ConversationStatus.Declined || isSupervisorView}
                color="primary"
                startIcon={<MoveUpOutlined />}
                onClick={() => openQueueUserTransferDialog('QueueTransfer')}
            >
                Transfer
            </StyledButton>
            {openQueueUserTransfer &&
                <UserOrQueueSelectDialog
                    handleClose={closePopup}
                    conversationId={id}
                    conversationStatus={status}
                    queueId={queueId}
                    open={openQueueUserTransfer}
                    type={selectedTransferType}
                    conversationType={ConversationType.Email}
                />
            }
            {openMarkCompleteDialog &&
                <CompleteDialog
                    open={openMarkCompleteDialog}
                    setOpenMarkCompleteDialog={setOpenMarkCompleteDialog}
                    queueId={queueId}
                />
            }
            {openMarkAsSpam &&
                <ConfirmationDialog
                    open={openMarkAsSpam}
                    handleClose={async () => setOpenMarkAsSpam(false)}
                    confirmChange={async () => handleMarkAsSpam()}
                    message={"Are you sure you want to mark this conversation as spam?"}
                />
            }
        </Root>
    );
};

export default ActionBar

import { FilterAlt } from '@mui/icons-material'
import { Button, Checkbox, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material'
import { useTheme } from '@mui/styles';
import React from 'react'

function QueueSelection({onChange, data}) {
  const theme = useTheme();

  const styles = {
    groupButton: {
      height: 28,
      width: 185
    },
    labelButton:{
      width: 140
    },
    actionButton: {
      minWidth: 25
    }, 
    popper: {
      width: 185,
      pr: theme.spacing(2)
    },
    menuItem: {
      height: 30, 
      padding: theme.spacing(0),
      minHeight: 0,
      display: 'flex', 
      alignItems: 'center'
    },
    checkBox: {
      '& .MuiSvgIcon-root': { 
        fontSize: 20 
      }
    }
  }

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [queues, setQueues] = React.useState([]);

  const handleMenuClose = (queueData) => {
    onChange(queueData);
    setOpen(false);
  }

  const handleChange = (item) => {
    let holdQueues = [...queues];
    let index = holdQueues.findIndex(x => x.id === item.id);
    holdQueues[index].checked = !item.checked;

    setQueues([...holdQueues])
  }

  React.useEffect(() => {
    if(queues.length === 0){
      setQueues(data)
    }
  },[data])

  return (
    <React.Fragment>
      <Button
        sx={styles.actionButton}
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={() => setOpen(true)}
        title="Filter by queues"
        ref={anchorRef}
      >
        <FilterAlt />
      </Button>
      <Popper sx={styles.popper} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() =>  handleMenuClose(queues)}>
                <MenuList id="queue-button-menu" >
                  {
                    queues.map((item) => (
                      <MenuItem
                        key={item.id}
                        sx={styles.menuItem}
                        onClick={() => handleChange(item)}
                      >
                          <Checkbox
                            checked={item.checked}
                            sx={styles.checkBox}
                          />
                          <Typography variant='body2'>{item.name}</Typography>
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}

export default QueueSelection
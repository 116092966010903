import React from "react";
import Timer from 'react-compound-timer'

const CountUpTimer = ({ countFrom, hideSeconds = false, hideHours=false, showhhmm=false}) => {
    var now = new Date().getTime();

    let initialTime = 0;

    if(now >= new Date(countFrom).getTime()){
        initialTime = now - new Date(countFrom).getTime();
    }
    
    return (
        <React.Fragment>    
            {
                (hideSeconds && !hideHours) &&
                <Timer initialTime={initialTime} formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                >
                    <Timer.Hours />{showhhmm === true ? 'h': ''}:<Timer.Minutes />{showhhmm === true ? 'm': ''}
                </Timer>
            }
            {
                (!hideSeconds && !hideHours) &&
                <Timer initialTime={initialTime} formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}>
                    <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                </Timer>
            }
            {
                (hideHours) &&
                <Timer initialTime={initialTime} formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}>
                    <Timer.Minutes />:<Timer.Seconds />
                </Timer>
            }
        </React.Fragment>
    )
}

export default CountUpTimer




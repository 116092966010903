import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CommunicationType, ConversationOperation, ConversationStatus, ConversationType } from '../../../helpers/Constant';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Badge, Button, Chip, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import timeago from 'epoch-timeago';
import { assignConversation } from '../ConversationSlice';
import { openTab } from '../../auth/AuthSlice';
import { ConversationService } from '../../../services/ConversationService';
import { HandleServerResponse } from '../../../helpers/Common';
import Priority from '../components/Priority';

const Item = ({ data, selected }) => {
    const dispatch = useDispatch();
    const selectedConversation = data?.id === selected;

    const [isLoading, setIsLoading] = useState(false);
    const { user: { userId }, tabIndex, tabs } = useSelector((state) => state.auth);

    const Container = styled('div')(({ theme }) => ({
        minHeight: '110px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '8px',
        border: '1px solid #ddd',
        margin: theme.spacing(0, 0, 1, 0),
        transition: 'background-color 0.3s',
        borderRadius: '4px',
        backgroundColor: selectedConversation ? theme.palette.action.selected : 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            cursor: 'pointer'
        },
    }));

    const Header = styled('div')(({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 1, 0),
        justifyContent: 'space-between'
    }));

    const HeaderContentRight = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center'
    }));

    const HeaderContentLeft = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
    }));

    const Name = styled(Typography)(({ theme }) => ({
        marginLeft: theme.spacing(1.5),
        fontWeight: 'bold'
    }));

    const Subject = styled(Typography)(({ theme }) => ({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    }));

    const Intent = styled(Typography)(({ theme }) => ({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(0.5)
    }));

    const AssignButton = styled(Button)(({ theme }) => ({
        marginLeft: theme.spacing(2),
        height: '24px'
    }));

    const ChipsContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
    }));

    const ChipTag = styled(Chip)(({ theme }) => ({
        height: '22px',
        borderRadius: '8px'
    }));

    const MailIcon = styled(MailOutlineIcon)(({ theme, status }) => {
        const getColor = () => {
            switch (status) {
                case ConversationStatus.New:
                    return theme.palette.error.light;
                case ConversationStatus.Open:
                case ConversationStatus.Completed:
                    return theme.palette.success.light;
                default:
                    return theme.palette.text.primary;
            }
        };

        return {
            height: '22px',
            borderRadius: '8px',
            color: getColor(),
        };
    });

    const IconComponent = ({ status }) => {
        return (
            <Badge badgeContent={data?.unreadCount} color='primary' anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MailIcon status={status} fontSize='medium' />
            </Badge>
        )
    };

    const handleSelectedConversation = (conversation) => {
        dispatch(openTab({ id: CommunicationType.conversations, type: CommunicationType.conversations, name: 'Conversations', close: true, data: conversation }))
    };

    const handleAssignAgent = async (event) => {
        event.stopPropagation();

        var values = {
            Id: data?.id,
            ConversationType: ConversationType.Email,
            ConversationOperation: ConversationOperation.Assign
        };

        setIsLoading(true);
        var result = await ConversationService.HandleConversation(values);
        HandleServerResponse(result, "Conversation assigned to you successfully", dispatch, true);
        setIsLoading(false);
    }

    return (
        <Container onClick={() => handleSelectedConversation(data)}>
            <Header>
                <HeaderContentRight>
                    <IconComponent status={data?.status} />
                    <Name variant="body1">{data?.from}</Name>
                </HeaderContentRight>
                <HeaderContentLeft>
                    <Typography variant="body1">
                        <time dateTime={new Date(data?.created).toISOString()}>{timeago(new Date(data?.created).getTime())}</time>
                    </Typography>
                    {(data?.userId !== userId) &&
                        <AssignButton variant='contained' onClick={(event) => handleAssignAgent(event)} disabled={isLoading}>
                            Assign
                        </AssignButton>
                    }
                </HeaderContentLeft>
            </Header>
            <Tooltip title={data?.subject} arrow>
                <Subject variant="body2">{data?.subject}</Subject>
            </Tooltip>
            <Tooltip title={data?.intent} arrow>
                <Intent variant="body2">{data?.intent}</Intent>
            </Tooltip>
            <ChipsContainer>
                <ChipTag label={data?.queue} />
                <Priority priority={data?.priority} />
            </ChipsContainer>
        </Container>
    )
}

export default Item
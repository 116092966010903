import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ChatList from './ChatList';
import CurrentChat from './CurrentChat';
import 'react-chat-elements/dist/main.css'
import './styles/index.css'

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 8,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
}));

export default function Chat() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ChatList />
            <CurrentChat />
        </div>
    );
}
import React from "react";
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { clearMessage } from "./InteractionSlice";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";

const MessageBar = props => {
    const theme = useTheme();
    const styles = {
        root: {
            '@media (min-width: 600px)': {
                top: theme.spacing(6.5),
                right: theme.spacing(1),
            },
        }
    }

    const { message } = useSelector(state => state.interaction);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.dispatch(clearMessage());
    };

    return (
        <Snackbar
            open={message !== null}
            onClose={handleClose}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={styles.root}
        >
            <span>
                {message !== null &&
                    <Alert
                        severity={message.type}
                        variant="filled"
                        onClose={handleClose}
                    >
                        {message.text}
                    </Alert>}
            </span>
        </Snackbar>
    );
}

export default MessageBar;
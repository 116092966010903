import React from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import UseForm from '../form/UseForm';
import { BlockNumberService } from '../../services/BlockNumberService';
import { HandleServerResponse } from '../../helpers/Common';
import Form from '../form/Form';
import Input from '../Inputs/Input';

const useStyles = makeStyles(theme => ({
    dialog: {
        margin: 16,
        '& .MuiDialog-paperWidthMd': {
            width: 500
        }
    },
    actions: {
        margin: theme.spacing(2),
    },
}));

const initialValues = {
    id: null,
    name: '',
    description: '',
    type: 1,
    isBlocked: true,
    number: '',
    status: 1,
}

export default function BlockNumberDialog({open, handleClose, phone, directBlock}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [disableAddButton, setDisableAddButton] = React.useState(false);

    const validate = (fieldValues = values) => {
        let validationProps = { ...errors };

        if ('name' in fieldValues)
            validationProps.name = fieldValues.name !== '' ? "" : "Required."
        
        if ('description' in fieldValues)
            validationProps.description = fieldValues.description !== '' ? "" : "Required."

        setErrors({
            ...validationProps
        });

        if (fieldValues === values)
            return Object.values(validationProps).every(x => x === "");
    }

    const {
        values,
        handleInputChanges,
        errors,
        setErrors,
    } = UseForm(initialValues, true, validate);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()){
            (async() => {
                setDisableAddButton(true);
                let data = {
                    Id: values.id,
                    Name: values.name,
                    Description: values.description,
                    Type: values.type,
                    IsBlocked: values.isBlocked,
                    Number: phone,
                    Status: directBlock ? 2 : 1
                }

                let result = await BlockNumberService.block(data);

                HandleServerResponse(result, `${phone} block request was sent successfully.`, dispatch);
                setDisableAddButton(false);

                if (result.success) {
                    handleClose();
                }
            })();
        }
    }

    return (
        <Form >
            <Dialog className={classes.dialog} maxWidth='md' open={open} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    {`Block number (${phone})`}
                </DialogTitle>
                <DialogContent>
                    <Input
                        size="large"
                        label="Name"
                        name="name"
                        paddingRight={0}
                        onChange={(e) => handleInputChanges(e)}
                        value={values.name}
                        error={errors.name}
                    />
                    <Input
                        size="large"
                        label="Description"
                        name={"description"}
                        multiline={true}
                        multilineRows={2}
                        paddingRight={0}
                        onChange={(e) => handleInputChanges(e)}
                        value={values.description}
                        error={errors.description}
                    />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={handleSubmit} disabled={disableAddButton} startIcon={<Add />} variant="contained" color="primary" disableElevation>Block</Button>
                    <Button
                        onClick={handleClose}
                        startIcon={<Close />}
                        variant="contained"
                        color="grey"
                        disableElevation>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Form>
    );
}
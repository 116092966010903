import React from "react";
import { styled } from '@mui/material/styles';
import { Chip } from "@mui/material";
import { ConversationPriority } from "../../../helpers/Constant";

const Priority = ({ priority }) => {
    const PriorityChipTag = styled(Chip)(({ theme }) => {
        const getBackgroundColor = () => {
            switch (priority) {
                case ConversationPriority.Urgent:
                    return theme.palette.warning.main;
                case ConversationPriority.Important:
                    return theme.palette.error.main;
                default:
                    return theme.palette.grey;
            }
        };

        const getColor = () => {
            if (priority === ConversationPriority.Important || priority === ConversationPriority.Urgent) {
                return "white"
            }
        };

        return {
            height: '22px',
            borderRadius: '8px',
            backgroundColor: getBackgroundColor(),
            color: getColor()
        }
    });

    if(priority === null || priority === '') return <></>

    return <PriorityChipTag label={priority} priority={priority} />
}

export default Priority;
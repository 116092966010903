import { Close, Done } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import GroupContainer from '../../components/GroupContainer';
import { HandleServerResponse } from '../../helpers/Common';
import { Widgets } from '../../helpers/Constant';
import { UserService } from '../../services/UserService';
import { updateUserWidgets } from '../auth/AuthSlice';
import { useTranslation } from 'react-i18next';

const ManageWidgets = ({ open, closeDialog, userWidgets }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        root: {
            '& .MuiDialog-paper':{
                // p: theme.spacing(1),
            }
        },
        item: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginRight: theme.spacing(1),
            width: 250,
        },
        itemName: {
            width: 150,
        },
        actions: {
            margin: theme.spacing(1),
        },
    }

    const dispatch = useDispatch();
    const [widgetList, setWidgetList] = React.useState([]);

    const handleChange = (widget) => {
        let holdWidgets = [...widgetList];
        let widgetsObj = { ...widget };
        widgetsObj.value = !widget.value;
        var foundIndex = holdWidgets.findIndex(x => x.key === widget.key);
        holdWidgets[foundIndex] = widgetsObj;
        setWidgetList(holdWidgets);
    }

    const saveChanges = () => {
        (async() => {
            let params = {
                Widgets: widgetList
            }

            let result = await UserService.updateUserWidgets(params);
            HandleServerResponse(result, "Home page widgets updated successfully.", dispatch);

            if (result.success) {
                dispatch(updateUserWidgets(widgetList));
            }

            closeDialog();
        })();
    }

    React.useEffect(() => {
        setWidgetList(userWidgets);
    }, [userWidgets]);

    return (
        <Dialog sx={styles.root} open={open} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
            <DialogTitle id="form-dialog-title">{t("Widget Management")}</DialogTitle>
            <DialogContent>
                <GroupContainer>
                    {
                        widgetList.map((widget) => (
                            <div style={styles.item} key={widget.key}>
                                <Typography variant="body2" sx={styles.itemName}>{t(widget.key)}</Typography>
                                <Switch
                                    checked={widget.value}
                                    onChange={() => handleChange(widget)}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                        ))
                    }
                </GroupContainer>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button
                    onClick={(_e) => saveChanges()}
                    startIcon={<Done />}
                    variant="contained"
                    color="primary"
                    disableElevation>{t("Update")}</Button>
                <Button
                    onClick={closeDialog}
                    startIcon={<Close />}
                    variant="contained"
                    color="grey"
                    disableElevation>{t("Cancel")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ManageWidgets;
import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTheme } from '@mui/styles';
import { CommunicationType } from '../../../helpers/Constant';

const SplitButton = ({ saveCallNote, options, disabled, type }) => {
    const theme = useTheme();
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const styles = {
        buttonGroup: {
            marginTop: theme.spacing(1),
            width: '100%',
            height: '32px'
        },
        button: {
            width: '10%'
        },
        menuItem: {
            justifyContent: 'center',
            paddingRight: theme.spacing(9)
        }
    }

    return (
        <React.Fragment>
            <ButtonGroup
                sx={styles.buttonGroup}
                id="btngrp-id-fncy"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
                variant='contained'
                disabled={disabled}
            >
                <Button sx={{ width: '100%' }} onClick={(event) => saveCallNote(event, false)}>Save</Button>
                <Button
                    sx={styles.button}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select save call note option"
                    aria-haspopup="menu"
                    onClick={() => setOpen(true)}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                    minWidth: anchorRef?.current?.clientWidth ?? '200px',
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement='bottom-end'
                modifiers={[
                    {
                        name: 'flip',
                        enabled: false, // This disables flipping
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: true, // This allows the popper to move to prevent overflow
                            boundary: 'viewport',
                        },
                    },
                ]}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center bottom' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            sx={styles.menuItem}
                                            key={option}
                                            onClick={(event) => saveCallNote(event, true)}
                                            disabled={disabled || (index == 0 && type === CommunicationType.activecall || index == 0 && type === CommunicationType.invitecall)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}

export default SplitButton
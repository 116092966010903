import React from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-root': {
        padding: 0,
        marginTop: '0px'
    },
    '& .MuiInputBase-root.MuiInputBase-sizeSmall': {
        padding: '4px',
        paddingRight: '24px'
    },
    '& .MuiAutocomplete-tag': {
        height: '20px',
        margin: '2px',
        padding: '2px',
    },
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon': {
        fontSize: '18px'
    }
}));

const CustomAutocomplete = ({ value, inputValue, onInputChange, onKeyDown, handleBlur, error, helperText, onDelete, type, messageAction = null }) => {
    return (
        <StyledAutocomplete
            multiple
            id="tags-filled"
            options={[]}
            freeSolo
            disabled={messageAction === "reply"}
            value={value}
            inputValue={inputValue || ''}
            onInputChange={onInputChange}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            onDelete={() => onDelete(option, type)}
                        />
                    );
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Enter emails"
                    helperText={helperText}
                    error={error}
                    onKeyDown={onKeyDown}
                    onBlur={handleBlur}
                />
            )}
        />
    );
}

export default CustomAutocomplete;

import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RegistrationTimeline from './register/RegistrationTimeline';
import GrantPermissions from './register/GrantPermissions';
import RegisterOrganizationInfo from './register/RegisterOrganizationInfo';
import RegistrationReviewAndSuccess from './register/RegistrationReviewAndSuccess';
import { RegisterService } from '../../services/RegisterService';
import RegisterCompleted from './register/RegisterCompleted';
import withAuth from '../../AuthProvider';
import { useTheme } from '@mui/styles';
import Environment from '../../Environment';
import Loading from '../../components/Loading';
import MarketpalceActivation from './register/MarketpalceActivation';
import MarketplaceSuccess from './register/MarketplaceSuccess';

const useStyles = makeStyles(theme => ({
    img: {
        width: 192,
        height: 192,
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    itemOne: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        width: 275
    },
    itemTwo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: 900,
    },
}));

function RegisterComponent(props) {
    const classes = useStyles();
    const theme = useTheme();

    const styles = {
        titleStyles: {
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(10.5),
        }
    }

    const [selectedComponent, setSelectedComponent] = React.useState();
    const [load, setLoad] = React.useState({
        initial: false,
        grantPermission: false,
        organization: false,
        completed: false,
        marketplaceActivationCompleted: false,
    })
    const [orgInformation, setOrgInformation] = React.useState(null);
    const [references, setReferences] = React.useState({
        countries: null,
        timezones: null,
        levels: null,
        region: null,
        registered: false,
    })
    const [saasToken] = React.useState(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('token');
    })

    const handleSelectedComponent = (compo, front = true) => {
        if (compo === 'organisation-info') {
            setLoad((prev) => ({
                ...prev, ["grantPermission"]: true, ["organization"]: false
            }))
        }

        if (compo === 'review') {
            setLoad((prev) => ({
                ...prev, ["organization"]: true, ["completed"]: false
            }))
        }

        if (compo === 'completed') {
            setLoad((prev) => ({
                ...prev, ["completed"]: true
            }))
        }

        setSelectedComponent(compo);
    }

    React.useEffect(() => {
        if (!load.initial) {
            (async () => {
                let params = {
                    SaasToken: saasToken
                };
                var result = await RegisterService.getRegisterRefernces(params);
                setLoad((prev) => ({...prev, ["initial"]: true}));
                setReferences(() => result.data);
                if(!saasToken || !result.data.registered){
                    setSelectedComponent('consent');
                }
                if (result.data.region !== null)
                    Environment.Load(result.data.region);
            })();
        }
    }, [])

    const handleMarketplaceActivation = (completed) => {
        if(completed){
            setLoad((prev) => ({...prev, ["marketplaceActivationCompleted"]: true}));
        }
    }

    const getSelectedComponent = () => {
        if(references.registered && saasToken && !load.marketplaceActivationCompleted){
            return <MarketpalceActivation saasToken={saasToken} completed={handleMarketplaceActivation}/>
        }

        if(references.registered && saasToken && load.marketplaceActivationCompleted){
            return <MarketplaceSuccess/>
        }

        switch (selectedComponent) {
            case 'consent':
                return <GrantPermissions handleSelectedComponent={handleSelectedComponent} />;
            case 'organisation-info':
                return <RegisterOrganizationInfo references={references} orgInformation={orgInformation} handleSelectedComponent={handleSelectedComponent} handleOrganisationInfo={setOrgInformation} />;
            case 'review':
                return <RegistrationReviewAndSuccess saasToken={saasToken} orgInformation={orgInformation} handleSelectedComponent={handleSelectedComponent} />;
            case 'completed':
                return <RegisterCompleted />;
            default:
                return <Loading/>
        }
    }

    if(!load.initial){
        return <Loading/>
    }

    return (
        <div className={classes.root}>
            <div className={classes.itemOne}>
                <img className={classes.img} src={window.location.origin + '/logo192_full.png'} alt="Logo" />
                {selectedComponent && <RegistrationTimeline load={load} />}
            </div>
            <div className={classes.itemTwo}>
                <Typography sx={styles.titleStyles} variant="h3">
                {
                    (!(references.registered && saasToken))
                    ? "New Organisation Registration": "Azure Marketplace Activation Process: CentrePal SaaS Subscription"
                }
                </Typography>
                {
                    getSelectedComponent()
                }
            </div>
        </div>
    );
}

export default withAuth(RegisterComponent);


import React from 'react'
import { useTheme } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import BarChart from '../../components/chart/BarChart'
import GroupBox from '../../components/GroupBox'
import QueueSelection from './components/QueueSelection'
import { Widgets, UserStatus } from '../../helpers/Constant';
import { saveWidgetData } from '../auth/AuthSlice';
import { convertToRGB } from '../../helpers/Common';
import { useTranslation } from 'react-i18next';

const AgentStatus = ({ name, height, cls, hideTitle = false }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { users } = useSelector((state) => state.interaction);
  const { user: { widgets, queues: userQueues } } = useSelector((state) => state.auth);

  const [queues, setQueues] = React.useState(() => {
    let { data } = widgets.find(x => x.key === Widgets.AgentStatus);
    let arr = data === null ? [] : data.split(',');
    let holdUserQueues = [];

    userQueues.map(queue => {
      holdUserQueues.push({
        id: queue.queueId,
        name: queue.name,
        checked: arr.includes(queue.queueId) ? true : false,
      });
    });

    return holdUserQueues;
  });

  const [chartData, setChartData] = React.useState({ labels: [], datasets: [] });

  const setAgentStatusData = (queueList) => {

    let filteredQueus = queueList.filter(x => x.checked === true);
    //show selected queues
    if (filteredQueus.length !== 0) queueList = [...filteredQueus];

    let labels = []

    let datasets = [
      {
        id: UserStatus.inaCall,
        label: t('In a Call'),
        data: [],
        backgroundColor: convertToRGB("#2f3173"),
        stack: '1',
        borderRadius: 5,
      },
      {
        id: UserStatus.wrapUp,
        label: t('Wrap Up'),
        data: [],
        backgroundColor: convertToRGB("#444791"),
        stack: '1',
        borderRadius: 5,
      },
      {
        id: UserStatus.available,
        label: t('Available'),
        data: [],
        backgroundColor: convertToRGB("#5b5fc7"),
        stack: '1',
        borderRadius: 5,
      },
      {
        id: UserStatus.workOffline,
        label: t('Unavailable'),
        data: [],
        backgroundColor: convertToRGB("#7f85f5"),
        stack: '1',
        borderRadius: 5,
      },
      {
        id: UserStatus.offline,
        label: t('Offline'),
        data: [],
        backgroundColor: convertToRGB("#9299f7"),
        stack: '1',
        borderRadius: 5,
      },
    ]

    queueList.forEach((queue, index) => {

      labels.push(queue.name);

      let inaCall = 0;
      let wrapUp = 0;
      let available = 0;
      let unavailable = 0;
      let offline = 0;

      for (var key in users) {

        let userQueueIds = [];

        Object.entries(users[key].queues).forEach(([key]) => {
          userQueueIds.push(key);
        });

        if (!userQueueIds.includes(queue.id)) {
          continue;
        }

        let status = users[key].status;

        if (!(users[key].queues[queue.id])) { 
          status = UserStatus.workOffline;
        }

        if (status === UserStatus.inaCall) {
          inaCall++;
        }

        if (status === UserStatus.wrapUp) {
          wrapUp++;
        }

        if (status === UserStatus.available) {
          available++;
        }

        if (status === UserStatus.workOffline ||
          status === UserStatus.workOfflineCustom1 ||
          status === UserStatus.workOfflineCustom2 ||
          status === UserStatus.onBreak ||
          status === UserStatus.onBreakCustom1 ||
          status === UserStatus.onBreakCustom2) {
          unavailable++;
        }

        if (status === UserStatus.offline) {
          offline++;
        }
      }

      datasets[0].data[index] = inaCall;
      datasets[1].data[index] = wrapUp;
      datasets[2].data[index] = available;
      datasets[3].data[index] = unavailable;
      datasets[4].data[index] = offline;
    });

    setChartData({ labels: [...labels], datasets: [...datasets] })
  }

  React.useEffect(() => {
    setAgentStatusData([...queues]);
  }, [users, queues])

  const handleQueueChange = (items) => {
    setQueues(items);
    dispatch(saveWidgetData(Widgets.AgentStatus, items.map(x => { if (x.checked) return x.id }).toString()));
  }

  //show chart only in control panel
  if (hideTitle)
    return (
      <GroupBox
        headerActions={<QueueSelection onChange={handleQueueChange} data={queues} />}
        showDivider={false}
      >
        <BarChart data={chartData} indexAxis="y" />
      </GroupBox>
    )

  return (
    <GroupBox
      headerActions={<QueueSelection onChange={handleQueueChange} data={queues} />}
      sx={{ minHeight: height }}
      title={t(name)}
      gutter
      className={cls}
    >
      <BarChart data={chartData} indexAxis="y" />
    </GroupBox>
  )
}

export default AgentStatus
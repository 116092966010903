import { createSlice } from "@reduxjs/toolkit";
import { ConversationService } from "../../services/ConversationService";
import { ChatStatus, CommunicationType, ConversationStatus, ConversationType } from "../../helpers/Constant";
import { closeTab } from "../auth/AuthSlice";

const uniqueArray = (arr) => [...new Set(arr)];

//Currently Chat and Email is maintained in two seperate states because it has two spcific UIs.
const initialCurrentData = {
    loaded: false,
    content: "",
    files: [],
    messageAction: null,
    participants: {
        to: [],
        cc: [],
        bcc: []
    },
}
export const conversationSlice = createSlice({
    name: 'conversation',
    initialState: {
        userId: null,
        conversations: [],
        current: initialCurrentData,
        currentChat: null, //merge in fureture
        dashboard: [],
        templates: null //Templates and other data
    },
    reducers: {
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        addConversations: (state, action) => {
            state.conversations = [...action.payload?.conversations, ...action.payload?.chats];  
        },
        addDashboardConversations: (state, action) => {
            state.dashboard = action.payload;
        },
        addNewConversation: (state, action) => {
            state.conversations = [...state.conversations, action.payload];

            if (state.current.id === action.payload.id && state.userId === action.payload.userId) {
                state.current.userId = action.payload.userId
                state.current.status = ConversationStatus.Open
                state.current.queueId = action.payload.queueId
                state.current.queue = action.payload.queue
            }
        },
        addDashboardConversation: (state, action) => {
            state.dashboard = [...state.dashboard, action.payload];
        },
        removeDashboardConversation: (state, action) => {
            state.dashboard = state.dashboard.filter(x => x.id !== action.payload.id);
        },
        updateDashboardConversation: (state, action) => {
            const index = state.dashboard.findIndex(conversation => conversation.id === action.payload.id);

            if (index === -1)
                return;

            state.dashboard[index].status = action.payload.status;
            state.dashboard[index].userId = action.payload.userId;
            state.dashboard[index].queueId = action.payload.queueId;
        },
        addNewMessage: (state, action) => {
            const index = state.conversations.findIndex(conversation => conversation.id === action.payload.id);

            if (index === -1)
                return;

            if (action.payload.type === ConversationType.Email) {
                action.payload.thread.forEach(element => {
                    if (element.read === false) {
                        state.conversations[index].unreadCount += 1
                    }
                });

                if (state.current.id === action.payload.id) {
                    state.current.threads = [...action.payload.thread, ...state.current.threads];
                    state.current.timelineHighlight = action.payload.timelineHighlight ?? [];
                    state.current.sentiment = action.payload.sentiment;
                    state.current.lastEmail = action.payload.lastEmail;
                }
            } else if (action.payload.type === ConversationType.Chat) {
                if (state.currentChat !== null && state.currentChat.id === action.payload.id) {
                    state.currentChat.messages = [...state.currentChat.messages, action.payload.message];
                } else {
                    state.conversations[index].notifications = action.payload.notifications;
                }
            }
        },
        assignConversation: (state, action) => {
            const index = state.conversations.findIndex(conversation => conversation.id === action.payload.id);

            if (index === -1)
                return;

            //Merge in future
            if (state.userId === action.payload.userId) {
                if (state.current.id === action.payload.id && action.payload.type === ConversationType.Email) {
                    state.current.userId = state.userId;
                } else if (action.payload.type === ConversationType.Chat) {
                    state.currentChat.status = ChatStatus.Active;
                    state.conversations[index].notifications = 0;
                }

                state.conversations[index].status = action.payload.type === ConversationType.Email ? ConversationStatus.Open : ChatStatus.Active;
                state.conversations[index].userId = state.userId;
            } else {
                state.conversations = state.conversations.filter(x => x.id !== action.payload.id);
            }
        },
        endChat: (state, action) => {
            const index = state.conversations.findIndex(conversation => conversation.id === action.payload.id);

            if (index === -1)
                return;

            state.dashboard[index].actioned = true;

            if (state.currentChat !== null && state.currentChat.id === action.payload.id) {
                state.currentChat.actioned = true;
            } else {
                state.conversations[index].actioned = true;
            }
        },
        setCurrentConversation: (state, action) => {
            //Merge in future
            if (action.payload.type === ConversationType.Email) {
                state.current = {
                    ...state.current,
                    ...action.payload,
                    loaded: true
                };
            } else if (action.payload.type === ConversationType.Chat) {
                const index = state.conversations.findIndex(conversation => conversation.id === action.payload.id);

                if (index === -1)
                    return;

                if (action.payload.status === ChatStatus.Active) {
                    state.conversations[index].notifications = 0;
                }

                state.currentChat = action.payload
            }
        },
        removeCurrentConversation: (state, action) => {
            if (action.payload === ConversationType.Email) {
                state.current = initialCurrentData
            }
        },
        updateCurrentStatus: (state, action) => {
            if (action.payload.type === ConversationType.Email) {
                const index = state.conversations.findIndex(conversation => conversation.id === action.payload.id);

                if (index === -1)
                    return;

                if (state.current !== null && state.current.id === action.payload.id) {
                    state.conversations = state.conversations.filter(conversation => conversation.id !== state.current?.id);
                    state.current.status = ConversationStatus.Completed;
                } else {
                    state.conversations = state.conversations.filter(conversation => conversation.id !== action.payload.id);
                    state.conversations[index].status = ConversationStatus.Completed;
                }

            } else if (action.payload.type === ConversationType.Chat) {
                const index = state.conversations.findIndex(conversation => conversation.id === action.payload.id);

                if (index === -1)
                    return;

                state.conversations[index].status = ChatStatus.Completed;
                if (state.currentChat != null) {
                    state.currentChat.status = ChatStatus.Completed;
                }
            }
        },
        removeConversation: (state, action) => {
            state.conversations = state.conversations.filter(x => x.id !== action.payload.id);

            if (action.payload.type === ConversationType.Email) {
                state.current.status = ConversationStatus.New;
                state.current.userId = null;
            } else if (action.payload.type === ConversationType.Chat) {
                state.currentChat = null;
            }
        },
        UpdateConversationQueue: (state, action) => {
            const { queueId, queueName, userInQueue } = action.payload;
            let index = state.conversations.findIndex(x => x.id === state.current.id);

            //update queueId in the list
            if (index !== -1) {
                //remove conversation from the list
                if (!userInQueue) {
                    state.conversations = state.conversations.filter(x => x.id !== state.current.id);
                } else {
                    state.conversations[index].userId = null;
                    state.conversations[index].queueId = queueId;
                    state.conversations[index].queue = queueName;
                }
            }

            //update current queue
            state.current.queueId = queueId;
            state.current.queue = queueName;
            state.current.status = ConversationStatus.New;

            if (!userInQueue)
                state.current.userId = null;
        },
        //TODO check and change when signalR implemented
        addThread: (state, action) => {
            state.current.threads.unshift(action.payload);
        },
        updateThread: (state, action) => {
            state.current.threads = action.payload.threads;
        },
        reduceUnreadCount: (state, action) => {
            if (state.current.userId === state.userId) {
                const index = state.conversations.findIndex(x => x.id === state.current.id);

                if (index === -1)
                    return;

                if (state.conversations[index].unreadCount > 0) {
                    state.conversations[index].unreadCount -= 1
                }

                const threadIndex = state.current.threads.findIndex(x => x.id === action.payload.id)

                if (threadIndex === -1)
                    return;

                state.current.threads[threadIndex].read = true
            }
        },
        setMessageAction: (state, action) => {
            if (action.payload === "forward") {
                state.current.participants = {
                    to: [],
                    cc: [],
                    bcc: []
                };
            }
            state.current.messageAction = action.payload;
        },
        updateContent: (state, action) => {
            state.current.content = action.payload;
        },
        addFiles: (state, action) => {
            state.current.files = [...state.current.files, ...action.payload];
        },
        updateFiles: (state, action) => {
            state.current.files = action.payload;
        },
        setParticipants: (state, action) => {
            if (state.current.messageAction === "reply") {
                state.current.participants = {
                    to: action.payload.to,
                    cc: uniqueArray([...state.current.participants.cc, ...(action.payload.cc)]),
                    bcc: uniqueArray([...state.current.participants.bcc, ...(action.payload.bcc)])
                };
            }
        },
        addParticipant: (state, action) => {
            const { type, email } = action.payload;
            if (state.current.participants[type]) {
                state.current.participants[type].push(email);
            }
        },
        removeParticipant: (state, action) => {
            const { type, email } = action.payload;
            if (state.current.participants[type]) {
                state.current.participants[type] = state.current.participants[type].filter(item => item !== email);
            }
        },
        resetParticipants: (state) => {
            state.current.participants = {
                to: [],
                cc: [],
                bcc: []
            };
        },
        addSignatureReferenceData: (state, action) => {
            state.templates = action.payload;
        }
    }
});

export const {
    setUserId, addConversations, assignConversation, setCurrentConversation, removeCurrentConversation,
    updateCurrentStatus, UpdateConversationQueue, setPage, addThread, reduceUnreadCount, updateThread,
    setMessageAction, updateContent, addFiles, updateFiles, setParticipants, addParticipant, removeParticipant,
    resetParticipants, addNewConversation, addNewMessage, endChat, removeConversation, addDashboardConversation, 
    removeDashboardConversation, updateDashboardConversation, addDashboardConversations, addSignatureReferenceData
} = conversationSlice.actions;

export const UpdateStatus = (operation) => async (dispatch) => {
    dispatch(updateCurrentStatus(operation));
}

export const loadConversations = () => async (dispatch, getState) => {
    const authState = getState().auth;

    //TODO set user ID when signalR start
    dispatch(setUserId(authState.user.userId));

    var result = await ConversationService.LoadConversations();

    if (result?.success) {
        dispatch(addConversations(result?.data));
    }
}

export const updateThreadData = () => async (dispatch, getState) => {
    const state = getState().conversation;

    let params = {
        Id: state.current.id,
        ConversationType: ConversationType.Email
    }

    var result = await ConversationService.LoadConversation(params);

    if (result.success) {
        dispatch(updateThread(result.data));
    }
}

export const loadConversation = (conversation) => async (dispatch, getState) => {
    const { current } = getState().conversation;

    if (current !== null && conversation.id === current.id)
        return;

    dispatch(removeCurrentConversation(conversation.type));

    let params = {
        Id: conversation.id,
        ConversationType: conversation.type
    }

    var result = await ConversationService.LoadConversation(params);

    if(result.success){
        dispatch(setCurrentConversation({ ...conversation, ...result.data }));
        dispatch(addSignatureReferenceData(result?.referrenceData));
    }else{
        dispatch(closeTab(CommunicationType.conversations));
    }
};

export const loadDashboardConversations = () => async (dispatch, getState) => {
    var result = await ConversationService.LoadDashboardConversations();

    if (result?.success) {
        dispatch(addDashboardConversations(result?.data));
    }
}

export default conversationSlice.reducer;
import React from 'react';
import { Button, ButtonGroup, ClickAwayListener, Popper, Grow, Paper, MenuItem, MenuList, useTheme } from '@mui/material';
import { Call, ArrowDropDown } from '@mui/icons-material';

export default function CallDropdown({ queues, disabled, createCall }) {
    const theme = useTheme();
    const styles = {
        parent: {
            width: "100%",
        },
        callBtn: {
            width: '95%',
            padding: theme.spacing(0.5)
        },
        selectBtn: {
            width: '5%',
            padding: theme.spacing(0.5)
        },
        menuItem: {
            display: 'flex',
            alignContent: 'center'
        }
    }
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleQueueChange = (e, id) => {
        handleClose(e);
        createCall(id);
    }

    return (
        <>
            <ButtonGroup color="black" variant='outlined' sx={styles.parent} ref={anchorRef} aria-label="split button">
                <Button
                    sx={styles.callBtn}
                    variant='contained'
                    color='primary'
                    startIcon={<Call />}
                    disabled={disabled}
                    onClick={(e) => createCall()}
                >
                    Call
                </Button>
                <Button
                    sx={styles.selectBtn}
                    variant='contained'
                    color='primary'
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    disabled={queues?.length === 0 || disabled}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition sx={{ zIndex: 1, width: document.getElementById("dialpad-root")?.offsetWidth }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" >
                                    {
                                        queues.map((queue, index) => (
                                            <MenuItem
                                                key={index}
                                                value={queue.id}
                                                onClick={(e) => handleQueueChange(e, queue.id)}
                                                sx={styles.menuItem}
                                            >
                                                <Call fontSize='small' sx={{mr: theme.spacing(1)}} />
                                                <p>{`Call as ${queue.name}`} </p>
                                            </MenuItem>
                                        ))
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
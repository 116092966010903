import React from 'react'
import GroupBox from '../../../../components/GroupBox'
import { Grid, IconButton, Skeleton } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { styled } from '@mui/styles'

const SkeletonPanel = () => {

    const SkeletonButton = styled(Skeleton)(({ theme }) => ({
        padding: '8px',
        marginRight: '5px'
    }));

    const SkeletonThread = styled(Skeleton)(({ theme }) => ({
        marginTop: '5px'
    }));

    return (
        <React.Fragment>
            <GroupBox
                smallBodyPadding={true}
                showDivider={false}
                gutter
                sx={{ position: 'relative', height: '100%' }}
            >
                <GroupBox
                    title={"Summary"}
                    flex
                    gutter
                    smallBodyPadding={true}
                    headerActions={
                        <IconButton disabled>
                            <ExpandMore />
                        </IconButton>
                    }
                >
                    <Grid container padding={2} spacing={2}>
                        <Grid item xs={12}>
                            <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <SkeletonButton variant="rounded" width={40} height={40} />
                            <SkeletonButton variant="rounded" width={40} height={40} />
                            <SkeletonButton variant="rounded" width={40} height={40} />
                            <SkeletonButton variant="rounded" width={40} height={40} />
                            <SkeletonButton variant="rounded" width={40} height={40} />
                        </Grid>
                    </Grid>
                </GroupBox>
                <SkeletonThread variant="rounded" width={"100%"} height={200} />
                <SkeletonThread variant="rounded" width={"100%"} height={50} />
                <SkeletonThread variant="rounded" width={"100%"} height={50} />
                <SkeletonThread variant="rounded" width={"100%"} height={50} />
            </GroupBox>
        </React.Fragment>
    )
}

export default SkeletonPanel
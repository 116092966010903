import React from 'react';
import { Checkbox, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { MoreHoriz } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { UserService } from '../../../services/UserService';
import { formatToLongDateTime } from '../../../helpers/Common';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px solid #ccc',
        padding: theme.spacing(0.5, 0, 0.5, 0),
        margin: theme.spacing(0.5, 0, 0.5, 0),
        borderRadius: 4
    },
    infoRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginTop: 4,
    },
    todoDetails: {
        marginBottom: 4
    },
    due: {
        color: 'red'
    }
}));

const GreenCheckbox = withStyles({
    root: {
        color: green[10],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TodoItem = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [announceAnchorEl, setAnnounceAnchorEl] = React.useState(null);

    //open more option dialog
    const handleOpenMoreOptions = (event) => {
        setAnnounceAnchorEl(event.currentTarget);
    };

    //close more option dialog
    const handleCloseMoreOptions = () => {
        setAnnounceAnchorEl(null);
    }

    const handleTodoDelete = (id) => {
        //close more option menu
        handleCloseMoreOptions();
        (async () => {
            let result = await UserService.removeTodo(id);
            if (result.success) {
                props.loadData()
            }
        })();
    }

    const handleTodoStatus = (event) => {
        //update the todo item to latest status
        (async () => {
            let params = { Id: props.todo.id, Completed: !props.todo.completed };
            let result = await UserService.updateTodo(params);
            if (result.success) {
                props.loadData()
            }
        })();
    }

    return (
        <div className={classes.root}>
            <div className={classes.infoRoot}>
                <GreenCheckbox
                    size="small"
                    checked={props.todo.completed}
                    onChange={handleTodoStatus}
                    inputProps={{ 'aria-label': 'checkbox with small size' }}
                />
                <div className={classes.details}>
                    <Typography variant="body2" className={classes.todoDetails}>{props.todo.todo}</Typography>
                    <Typography variant="caption" className={(!props.todo.completed && (new Date(props.todo.due) < new Date()) ? classes.due : '')}>{t("Due")}: {formatToLongDateTime(props.todo.due)}</Typography>
                </div>
                <IconButton onClick={handleOpenMoreOptions} style={{ marginLeft: 8, marginRight: 8 }} size="small" color="primary" title="actions" aria-label="todo" component="span">
                    <MoreHoriz fontSize="small" />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={announceAnchorEl}
                    keepMounted
                    open={Boolean(announceAnchorEl)}
                    onClose={handleCloseMoreOptions}
                    className={classes.menuStyles}
                >
                    <MenuItem onClick={() => handleTodoDelete(props.todo.id)}>
                        <div className={classes.menuItemDiv}>
                            <p className={classes.availabilityText} >{t("Delete")}</p>
                        </div>
                    </MenuItem>
                </Menu>
            </div>
        </div>
    )
}

export default TodoItem
import axios from "axios";

const ReportIssue = async (values) => {
    const { data } = await axios.post('/api/issue/issue', values).then(result => result);
    return data;
}

const GetIssueReferences = async () => {
    const { data } = await axios.get('/api/issue/issue-references').then(result => result);
    return data;
}

export const IssueService = {
    ReportIssue,
    GetIssueReferences,
};
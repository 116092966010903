import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, ErrorOutline } from '@mui/icons-material';

import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
    img: {
        width: 100,
        height: 100
    },
    actions: {
        margin: useTheme().spacing(0, 1, 1, 1),
        '& > .MuiButtonBase-root': {
            padding: useTheme().spacing(0.5, 2, 0.5, 2),
        },
    },
}));

export default function ErrorDialog(props) {
    const classes = useStyles();
    //ErrorOutline
    return (
        <div>
            <Dialog className={classes.btnStyles} open={props.open} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
                <DialogContent>
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <ErrorOutline style={{height:75, width: 75}}/>
                        <Typography variant="h6" component="div" display="inline">We're sorry - we've run into an issue.</Typography>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button color="grey" onClick={props.closeDialog} startIcon={<Close />} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { HandleServerResponse } from '../../helpers/Common';
import { TenantService } from '../../services/TenantService';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'auto', 
        height: '36vh',
        paddingRight: 8,
    },
    longRoot: {
        overflow: 'auto', 
        height: '100%',
        paddingRight: 8,
    },
    logo: {
        backgroundImage: "url('/centrepal.jpg')",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
}));

const Hub = ({islong=false}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [hubText, setHubText] = React.useState("");

    React.useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);

            (async () => {

                let result = await TenantService.getHubText();
                HandleServerResponse(result, "", dispatch);

                if (result.success && active) {
                    let parsedText = JSON.parse(result.data)
                    setHubText(parsedText)
                }
            })();

            if (!active) {
                return;
            }

            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, []);

    return(
        <div className={islong ? classes.longRoot : classes.root} >
            {!loading && <div  dangerouslySetInnerHTML={{ __html: hubText }}></div>}
        </div>
    )
}

export default Hub
import axios from 'axios'

const getAll = async (values) => {
     const { data } = await axios.get('/api/externalconnector/list',{params: values}).then(result => result);
     return data;
};

const get = async (id=null) => {
    const { data } = await axios.get(`/api/externalconnector/${id}`).then(result => result);
    return data;
}

const create = async (values) => {
     const {data} = await axios.post('/api/externalconnector',values).then(result => result);
     return data;
};

const update = async (values) => {
     const {data} = await axios.put('/api/externalconnector',values).then(result => result);
     return data;
};

const disable = async (id) => {
     const { data } = await axios.post(`/api/externalconnector/${id}/disable`).then(result => result);
     return data;
 };

 const enable = async (id) => {
     const { data } = await axios.post(`/api/externalconnector/${id}/enable`).then(result => result);
     return data;
 };

 const remove = async (id) => {
     const { data } = await axios.delete(`/api/externalconnector/${id}`).then(result => result);
     return data;
 };

const validateTwilioSenderDelete = async (id) => {
    const {data} = await axios.get(`/api/externalconnector/twilio/sender/delete/${id}`).then(result => result);
    return data;
};

const sendSms = async (values) => {
    const {data} = await axios.post('/api/externalconnector/sendsms',values).then(result => result);
    return data;
};

const refernceData = async (type) => {
    const {data} = await axios.get(`/api/externalconnector/reference/${type}`).then(result => result);
    return data;
};
export const ExternalConnectorService = {
    getAll,
    get,
    create,
    update,
    disable,
    enable,
    remove,
    sendSms,
    validateTwilioSenderDelete,
    refernceData
};
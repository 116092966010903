import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import GroupBox from '../../components/GroupBox';
import { useSelector } from "react-redux"
import AgentAvailability from "../../components/availability/AgentAvailability"
import CountUpTimer from '../../helpers/CountUpTimer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%'
    },
}));

export default function AgentInfo() {
    const classes = useStyles();
    const { user } = useSelector((state) => state.auth);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <GroupBox flex center gutter showDivider={false}>
                <Box display="flex" alignItems="center" flexDirection="column" className={classes.container}>
                    <Typography style={{ marginBottom: 8 }} color="textPrimary" variant='body2'>{t("Time on Status")} &nbsp;
                        <span style={{ fontWeight: 600 }} key={user.lastAvailabilityChange}>
                            <CountUpTimer countFrom={user.lastAvailabilityChange} hideSeconds={true} showhhmm={true} />
                        </span>
                    </Typography>
                    <AgentAvailability currentAvailability={user.availability} userId={user.userId} />
                </Box>
            </GroupBox>
        </React.Fragment>
    );
}
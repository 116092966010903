import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/styles';

const styles = {
    buttonGroup: {
        minWidth: '200px', 
        height: '32px'
    },
    loadingButton:{
        width: "90%", 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap', 
        lineClamp: 1
    },
    button:{
        width: '10%'
    }
}

export default function FancyDropdown({ items = [], onChange, selected, loading=false }) {
    const theme = useTheme();
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const handleOnChange = (id) => {
        setOpen(false);
        onChange(id);
    }

    return (
        <React.Fragment>
            <ButtonGroup
                sx={styles.buttonGroup}
                id="btngrp-id-fncy"
                variant="outlined"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
                color={theme.palette.mode === "light" ? 'black' : "white"}
            >
                <LoadingButton
                    variant='outlined'
                    sx={styles.loadingButton}
                    loading={loading}
                    loadingPosition="start"
                    onClick={() => setOpen(false)}
                >
                    {loading? "Loading..." : selected?.name ?? ""}
                </LoadingButton>
                <Button
                    sx={styles.button}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => setOpen(true)}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                    minWidth: anchorRef?.current?.clientWidth ?? '200px'
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {items.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            selected={option?.id === selected?.id}
                                            onClick={() => handleOnChange(option.id)}
                                        >
                                            {option?.value}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}
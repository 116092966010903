import React from 'react';
import { makeStyles } from '@mui/styles';
import {Typography} from "@mui/material"
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    formSubHeader: {
        padding: theme.spacing(1, 0.5, 0.5, 0),
    },
}));

export default function FormSubtitle({title, className}) {
    const classes = useStyles();
    return (
        <div className={clsx(className, classes.formSubHeader)}>
            <Typography color="primary" variant="body1">{title}</Typography>
        </div>
    )
}
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { ConversationType } from '../../../helpers/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { ConversationService } from '../../../services/ConversationService';
import { HandleServerResponse } from '../../../helpers/Common';
import CustomDialog from '../../../components/CustomDialog';

const AiEditOrReviewOptions = [
    { id: 0, name: 'Improve writing' },
    { id: 1, name: 'Make shorter' },
    { id: 2, name: 'Make longer' },
    { id: 3, name: 'Simplify Language' },
];

const AiGenerativeOptions = [
    { id: 4, name: 'Summarize' },
    { id: 5, name: 'Continue' },
];

const AiChangeToneOptions = [
    { id: 6, name: 'Professional' },
    { id: 7, name: 'Casual' },
    { id: 8, name: 'Direct' },
    { id: 9, name: 'Confident' },
    { id: 10, name: 'Friendly' },
];

const combinedOptions = [
    { category: 'Edit or review', options: AiEditOrReviewOptions },
    { category: 'Generate from selection', options: AiGenerativeOptions },
    { category: 'Change tone', options: AiChangeToneOptions },
];

const AiDialog = ({ open, setOpenAiDialog, selectedAiOption, selectedText, setSelectedAiOption, handleReplaceText, handleInsertText }) => {
    const [aiText, setAiText] = React.useState(selectedText);
    const [isLoading, setIsLoading] = React.useState(false);

    const dispatch = useDispatch();
    const selectedConversation = useSelector(state => state.conversation.current);

    React.useEffect(() => {
        let active = true;
        (async () => {

            if (aiText !== '') {
                await GetAiGeneratedContent(selectedAiOption);
            }
        })();

        return () => { active = false; }
    }, [selectedAiOption]);

    const GetAiGeneratedContent = async (option) => {
        setIsLoading(true);

        let params = {
            Id: selectedConversation.id,
            ConversationType: ConversationType.Email,
            AIOperationType: option.id,
            Content: selectedText?.length > 0 ? selectedText : aiText
        };

        var result = await ConversationService.AiAssistant(params);
        HandleServerResponse(result, "", dispatch);

        if (result.success) {
            setAiText(result?.data)
        }
        setIsLoading(false)
    }

    const handleClose = () => {
        setOpenAiDialog(false);
    };

    const handleReplaceTextInEditor = () => {
        handleReplaceText(aiText);
        handleClose();
    };

    const handleInsertTextInEditor = () => {
        handleInsertText(aiText);
        handleClose();
    };

    return(
        <CustomDialog open={open} close={handleClose} title={"AI Assistant"}>
            <>
                <TextField
                    multiline
                    rows={9}
                    variant="outlined"
                    placeholder={"Enter your text here..."}
                    value={isLoading ? "Generating AI content..." : aiText}
                    onChange={(e) => setAiText(e.target.value)}
                    sx={{
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                />
                <Box display="flex" marginTop={2} justifyContent="space-between" alignItems="center">
                    <AiMenu
                        selectedAiOption={selectedAiOption}
                        setSelectedAiOption={setSelectedAiOption}
                    />
                    <Box>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ marginRight: '10px' }}
                            disabled={isLoading || !aiText}
                            onClick={() => GetAiGeneratedContent(selectedAiOption)}
                        >
                            Try again
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ marginRight: '10px' }}
                            disabled={isLoading || !aiText}
                            onClick={handleInsertTextInEditor}
                        >
                            Insert
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReplaceTextInEditor}
                            disabled={isLoading || !aiText}
                        >
                            Replace
                        </Button>
                    </Box>
                </Box>
            </>
        </CustomDialog>
    )
}

const AiMenu = ({ selectedAiOption, setSelectedAiOption }) => {
    const handleChange = (event) => {
        setSelectedAiOption(event.target.value);
    };

    return (
        <FormControl sx={{ width: '200px' }} variant="outlined" size="small">
            <InputLabel id="ai-dropdown-label">AI Options</InputLabel>
            <Select
                labelId="ai-dropdown-label"
                value={selectedAiOption}
                onChange={handleChange}
                label="AI Options"
                sx={{
                    height: '32px',
                    '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
                renderValue={(selected) => selected.name}
            >
                {combinedOptions.flatMap((group, groupIndex) => [
                    <Typography key={`group-${groupIndex}`} variant='body1' color='primary' style={{ padding: '8px 16px' }}>
                        {group.category}
                    </Typography>,
                    ...group.options.map((option) => (
                        <MenuItem key={option.id} value={option}>
                            {option.name}
                        </MenuItem>
                    )),
                ])}
            </Select>
        </FormControl>
    );
};

export default AiDialog
import { Box, Button, Switch, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FormSubtitle from '../../../components/form/FormSubtitle';
import AutoCompleteSearch from '../../../components/Inputs/AutoCompleteSearch';
import DropDown from '../../../components/Inputs/DropDown';
import Input from '../../../components/Inputs/Input';
import { getLocalStorage, HandleServerResponse } from '../../../helpers/Common';
import { JiraInputTypes } from '../../../helpers/Constant';
import { JiraService } from '../../../services/JiraService';

const JiraFields = ({
    requestTypeId,
    jiraConnector,
    closeModal,
    requestTypeTemplate,
    issueValues,
    setIssueValues,
    errors,
    setErrors,
    loading,
    chainId,
    referenceId,
}) => {
    const [disableActionBtn, setDisableActionBtn] = React.useState(false);
    const [enableRaiseOnBehalf, setEnableRaiseOnBehalf] = React.useState(false);
    const [nameLoading, setNameLoading] = React.useState(false);
    const [assetsLoading, setAssetsLoading] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [assets, setAssets] = React.useState([]);
    const [customer, setCustomer] = React.useState('');

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const styles = {
        container: {
            width: '100%',
        },
        box: {
            display: "flex",
            float: 'right',
            marginTop: '20px',
        },
        cancelBtn: {
            marginLeft: '5px',
        }
    }

    const handleTextFieldInputs = (e, field) => {
        e.preventDefault();
        const { name, value } = e.target;

        validate({ [name]: value })

        setIssueValues({ ...issueValues, [name]: value })
    }

    const handleNumberFieldInputs = (e, field) => {
        e.preventDefault();
        const { name, value } = e.target;

        validate({ [name]: value })

        setIssueValues({ ...issueValues, [name]: parseInt(value) })
    }

    const handleAutocomplete = (e) => {
        const { name, value } = e.target;

        validate({ [name]: value })

        setIssueValues({ ...issueValues, [name]: value });
    }

    const handleCheckbox = (event) => {
        errors['raiseOnBehalf'] = ""
        setCustomer('');
        setEnableRaiseOnBehalf((prev) => !prev);
    }

    const handleCustomerInputChanges = (e) => {
        const { name, value } = e.target;

        setCustomer(value);
        errors['raiseOnBehalf'] = ""
    }

    const handleCustomersBySearch = (value) => {
        if (value !== undefined && value.length >= 3) {
            (async () => {
                setNameLoading(true);
                let params = {
                    ConnectorId: jiraConnector.connectorId,
                    Query: value
                };

                if (jiraConnector.authType === 1) {
                    const jiraCredentials = getLocalStorage('jira-auth');
                    params['AccessToken'] = jiraCredentials.accessToken;
                }

                let result = await JiraService.GetCustomers(params);
                setCustomers(result.data);
                setNameLoading(false);
            })()
        }
    }

    const handleAssetsBySearch = (value) => {
        if (value !== undefined && value.length >= 3) {
            (async () => {
                setAssetsLoading(true);
                let params = {
                    ConnectorId: jiraConnector.connectorId,
                    SearchTerm: value
                };

                if (jiraConnector.authType === 1) {
                    const jiraCredentials = getLocalStorage('jira-auth');
                    params['AccessToken'] = jiraCredentials.accessToken;
                }

                let result = await JiraService.SearchAssets(params);

                if(result.success){
                    setAssets(result.data);
                }

                setAssetsLoading(false);
            })()
        }
    }

    const validate = (fieldValues = issueValues) => {
        let validationProps = { ...errors };

        requestTypeTemplate.forEach(field => {
            if (field.required) {
                if (field.field in fieldValues) {
                    if (fieldValues[field.field] === "" || fieldValues[field.field] === undefined || fieldValues[field.field] === null) {
                        validationProps[field.field] = t("Required")
                    } else {
                        validationProps[field.field] = ""
                    }
                }
            }
        });

        if (enableRaiseOnBehalf) {
            if (customer == '' || customer == null) {
                validationProps["raiseOnBehalf"] = t("Required")
            } else {
                validationProps["raiseOnBehalf"] = ""
            }
        }

        setErrors({
            ...validationProps
        });

        if (fieldValues === issueValues)
            return Object.values(validationProps).every(x => x === "");

    }

    const handleCreateIssue = (e) => {
        e.preventDefault();
        (async () => {
            setDisableActionBtn(true)
            if (validate()) {
                let fields = {};

                requestTypeTemplate.forEach(field => {
                    for (let key in issueValues) {
                        if (field.field === key) {
                            if (issueValues[key] !== null) {
                                if (field.type === "option" || field.type === "priority") {
                                    fields[key] = { id: issueValues[key] }
                                } else if (field?.items === "cmdb-object-field") {
                                    fields[key] = [{ id: issueValues[key]?.id }]
                                } else {
                                    fields[key] = issueValues[key];
                                }
                            }
                        }
                    }
                });

                let params = {
                    ConnectorId: jiraConnector.connectorId,
                    RequestTypeId: requestTypeId,
                    RaiseOnBehalf: customer.id,
                    requestFieldValues: fields,
                    ChainId: chainId,
                    ReferenceId: referenceId
                };

                if (jiraConnector.authType === 1) {
                    const jiraCredentials = getLocalStorage('jira-auth');
                    params['AccessToken'] = jiraCredentials.accessToken;
                }

                let result = await JiraService.CreateNewIssue(params);

                HandleServerResponse(result, t("Ticket {result} created successfully", { result: result.data }), dispatch);

                if (result.success) {
                    closeModal();
                }
            }

            setDisableActionBtn(false)
        })();
    }

    const getInputFields = (field) => {
        switch (field.type) {
            case JiraInputTypes.Text:
                return <Input
                    required={field.required}
                    style={{ width: "100%" }}
                    label={field.name}
                    name={field.field}
                    multiline={field.field === 'description' ? true : false}
                    rows={field.field === 'description' ? 6 : 1}
                    onChange={(e) => handleTextFieldInputs(e, field)}
                    value={issueValues[field.field]}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={errors[field.field]}
                />;
            case JiraInputTypes.DropdownSingle:
                return <DropDown
                    size="large"
                    label={field.name}
                    value={issueValues[field.field]}
                    onChange={(e) => handleAutocomplete(e, field)}
                    name={field.field}
                    values={field.values}
                    error={errors[field.field]}
                />;
            case JiraInputTypes.Priority:
                return <DropDown
                    size="large"
                    label={field.name}
                    value={issueValues[field.field]}
                    onChange={(e) => handleAutocomplete(e, field)}
                    name={field.field}
                    values={field.values}
                    error={errors[field.field]}
                />;
            case JiraInputTypes.Array:
                if (field.items === "option" || field.items === "component") {
                    return <AutoCompleteSearch
                        required={field.required}
                        full={true}
                        multiple={field.items !== "cmdb-object-field"}
                        defaultValues={issueValues[field.field]}
                        value={issueValues[field.field]}
                        onChange={handleAutocomplete}
                        options={field.values}
                        label={field.name}
                        name={field.field}
                        error={errors[field.field]}
                    />
                } else if (field.items === "cmdb-object-field") {
                    return <AutoCompleteSearch
                        required={field.required}
                        value={issueValues[field.field]}
                        full={true}
                        onChange={handleAutocomplete}
                        onClick={handleAssetsBySearch}
                        options={assetsLoading ? null : assets}
                        label={field.name}
                        name={field.field}
                        error={errors[field.field]}
                        loading={assetsLoading}
                    />
                }
                break;
            case JiraInputTypes.Date:
                return <Input
                    style={{ width: "50%" }}
                    size='large'
                    variant="outlined"
                    label={field.name}
                    type="date"
                    onChange={(e) => handleTextFieldInputs(e, field)}
                    name={field.field}
                    value={moment(new Date(issueValues[field.field] === null ? setIssueValues({ ...issueValues, [field.field]: moment().format('YYYY-MM-DD') }) : issueValues[field.field])).format('YYYY-MM-DD')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={errors[field.field]}
                />;
            case JiraInputTypes.DateTime:
                return <Input
                    style={{ width: "50%" }}
                    size='large'
                    variant="outlined"
                    label={field.name}
                    type="datetime-local"
                    onChange={(e) => handleTextFieldInputs(e, field)}
                    name={field.field}
                    value={moment(new Date(issueValues[field.field] === null ? setIssueValues({ ...issueValues, [field.field]: moment().format('YYYY-MM-DD[T]HH:mm') }) : issueValues[field.field])).format('YYYY-MM-DD[T]HH:mm')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={errors[field.field]}
                />;
            case JiraInputTypes.Number:
                return <Input
                    style={{ width: "100%" }}
                    size='large'
                    variant="outlined"
                    label={field.name}
                    type="number"
                    onChange={(e) => handleNumberFieldInputs(e, field)}
                    name={field.field}
                    value={issueValues[field.field]}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={errors[field.field]}
                />;
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            {!loading &&
                <div>
                    <FormSubtitle title={t("Fields")} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography style={{ width: 150 }}>{t("Request on behalf")}</Typography>
                        <Switch
                            checked={enableRaiseOnBehalf}
                            onChange={(e) => handleCheckbox(e)}
                            name={'enableRaiseOnBehalf'}
                            color="primary"
                        />
                    </div>
                    {enableRaiseOnBehalf &&
                        <AutoCompleteSearch
                            required
                            value={customer}
                            full={true}
                            onChange={handleCustomerInputChanges}
                            onClick={handleCustomersBySearch}
                            options={nameLoading ? null : customers}
                            label="Request on behalf"
                            name="raiseOnBehalf"
                            error={errors.raiseOnBehalf}
                            loading={nameLoading}
                        />
                    }
                    {requestTypeTemplate.map((field, index) => (
                        <Box style={{ margin: 0 }} key={field.name} display="flex" alignItems="center" justifyContent="space-between" sx={styles.container}>
                            {
                                getInputFields(field)
                            }
                        </Box>
                    ))}
                    <Box sx={styles.box}>
                        {
                            <Button disabled={requestTypeId === null ? true : false || disableActionBtn} onClick={(e) => handleCreateIssue(e)} variant="contained" disableElevation color="primary" component="span">
                                {t("Create Issue")}
                            </Button>
                        }
                    </Box>
                </div>
            }
        </React.Fragment>
    )
}

export default JiraFields
import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { AppBar, Toolbar, Tabs, Tab, IconButton, Button, Badge } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { Cancel, InsertChart, Call, Settings, AlarmAdd, AlarmOff, PeopleAlt, SettingsPhone, Equalizer, Home, DesktopWindows, Pause, Insights, PermPhoneMsg, MailOutline, MarkEmailRead } from '@mui/icons-material';
import { setActiveTab, closeTab, wrapupExtend, wrapupEnd } from "../auth/AuthSlice";
import { CommunicationType, WallboardType, LicenseType } from '../../helpers/Constant';
import LogoutComponent from '../auth/LogoutComponent'
import Timer from 'react-compound-timer';
import { openTab } from '../auth/AuthSlice';
import { CallService } from '../../services/CallService';
import { checkLicense, checkUserPermission, FormatCallerId, getLocalStorage, HandleServerResponse } from '../../helpers/Common';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  closeTab: {
    display: 'flex',
    alignItems: 'center',
  },
  timer: {
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.warning.main,
  },
  notifyTimer: {
    padding: theme.spacing(0, 1),
    animation: "$blinker 1s linear infinite",
    backgroundColor: theme.palette.warning.main,
  },
  inviteCall: {
    backgroundColor: theme.palette.error.main,
    animation: "$highlighter 1s linear infinite",
  },
  activeCall: {
    backgroundColor: theme.palette.success.main,
    animation: "$highlighter 1s linear infinite",
  },
  holdCall: {
    backgroundColor: theme.palette.warning.main,
    animation: "$highlighter 1s linear infinite",
  },
  "@keyframes highlighter": {
    '0%': {
      filter: "drop-shadow(0px 0px 1px)"
    },
    '35%': {
      filter: "drop-shadow(0px 0px 3px)"
    },
    '70%': {
      filter: "drop-shadow(0px 0px 6px)"
    },
    '100%': {
      filter: "drop-shadow(0px 0px 3px)"
    },
  },
  "@keyframes blinker": {
    from: {
      opacity: 0.4,
    },
    to: {
      opacity: 1,
    },
  },
}));

//Test commit
const agentWallboardPermissions = ["agent.dashboardread"];
const callWallboardPermissions = ["call.dashboardread"];
const conversationPermissions = ["conversation.dashboardread"];
const dashboardPermissions = ["wallboard.read"];

const MainAppBar = (props) => {
  const classes = useStyles();
  const theme = useTheme();


  const styles = {
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      pr: 0,
      pl: 0,
    },
    tabs: {
      minHeight: 48,
      '& .MuiTabs-root': {
        minWidth: 'unset',
      },
      '& .MuiTab-wrapper': {
        flexDirection: "row",
        '& > :first-of-type': {
          paddingRight: 2,
          margin: 0,
        },
      },
      '& .Mui-disabled': {
        width: 0,
      },
      flexGrow: 1,
      '& .MuiBadge-root': {
        '& .MuiBadge-anchorOriginTopRightRectangular': {
          right: theme.spacing(-0.5),
        },
      },
      '& .Mui-disabled': {
        opacity: 0.5,
      },
    },
    tab: {
      minHeight: 48,
    },
    menuButton: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      "& .MuiSvgIcon-root": {
        fontSize: '1.5rem',
      },
    },
    smallIcon: {
      fontSize: '1.3rem',
    },
  }

  const [notify, setNotify] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [pilot] = React.useState(getLocalStorage('P1') ?? false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user: { permissions, mobile } } = useSelector((state) => state.auth);

  const agentWallboardAccess = checkUserPermission(permissions, agentWallboardPermissions);
  const [dashboardAccess] = React.useState(() => {
    if (checkUserPermission(permissions, callWallboardPermissions) || (checkUserPermission(permissions, conversationPermissions)))
      return true;

    return false;
  });

  const wallboardAccess = checkUserPermission(permissions, dashboardPermissions);

  //check license
  const voiceLicense = checkLicense(props.user.assignedLicenses, [LicenseType.Voice, LicenseType.VoicePlusDigital])

  const getIcon = (type, isOnhold = false) => {
    let icon = <Call sx={styles.smallIcon} />;

    if (isOnhold) {
      icon = <Pause sx={styles.smallIcon} />;
    }

    switch (type) {
      case CommunicationType.home:
        icon = <Home sx={styles.smallIcon} />;
        break;
      case CommunicationType.attendentConsole:
        icon = <DesktopWindows sx={styles.smallIcon} />;
        break;
      case CommunicationType.setting:
        icon = <Settings sx={styles.smallIcon} />;
        break;
      case CommunicationType.agentDashboard:
        icon = <PeopleAlt sx={styles.smallIcon} />;
        break;
      case CommunicationType.callDashboard:
        icon = !pilot ? <SettingsPhone /> : <PermPhoneMsg sx={styles.smallIcon} />;
        break;
      case CommunicationType.report:
        icon = <Equalizer sx={styles.smallIcon} />;
        break;
      case CommunicationType.conversations:
        icon = <MailOutline sx={styles.smallIcon} />;
        break;
      case CommunicationType.conversationHistory:
        icon = <MarkEmailRead sx={styles.smallIcon} />;
        break;
      default:
        break;
    }

    return icon;
  }

  const handleTabChange = (event, newValue) => {
    event.stopPropagation();
    props.dispatch(setActiveTab(newValue));
  };

  const handleTabClose = (event, id) => {
    event.stopPropagation();
    props.dispatch(closeTab(id));
  }

  const handleExtend = () => {
    (async () => {
      let obj = {
        ChainId: props.wrapup.chainId,
        UserId: props.user.userId,
        IntervalTime: 30
      }

      let result = await CallService.WrapupExtend(obj);
      HandleServerResponse(result, t("Wrapup time extend by {seconds} seconds.", { seconds: 30 }), dispatch);
      if (result.success) {
        props.dispatch(wrapupExtend());
        setNotify(false);
      }
    })();
  }

  const handleWrapupEnd = () => {
    setOpenDialog(true);
  }

  const confirmWrapupEnd = () => {
    (async () => {
      let obj = {
        ChainId: props.wrapup.chainId,
        UserId: props.user.userId,
      }
      setOpenDialog(false);

      let result = await CallService.WrapupEnd(obj);
      HandleServerResponse(result, t("Wrapup completed."), dispatch);
      if (result.success) {
        //props.dispatch(wrapupEnd());
        setNotify(false);
      }
    })();
  }

  const handleEnd = () => {
    props.dispatch(wrapupEnd());
    setNotify(false);
  }

  const closeConfirmDialog = () => {
    setOpenDialog(false);
  }

  const handleNofify = () => {
    setNotify(true);
  }

  const showWbAgents = () => {
    props.dispatch(openTab({ id: CommunicationType.agentDashboard, type: CommunicationType.agentDashboard, name: 'Agents', close: true, data: {} }))
  }

  const showWbCalls = () => {
    props.dispatch(openTab({ id: CommunicationType.callDashboard, type: CommunicationType.callDashboard, name: !pilot ? 'Calls' : 'Interactions', close: true, data: {} }))
  }

  useEffect(() => {
    if (props.wrapup == null) {
      setNotify(false);
    }
  }, [props.wrapup]);

  return (
    <AppBar position="fixed" elevation={1} sx={styles.appBar}>
      <Toolbar disableGutters={true} sx={styles.toolbar} variant="dense">
        <Tabs
          aria-label="open calls"
          variant="scrollable"
          indicatorColor="secondary"
          value={props.tabIndex}
          sx={styles.tabs}
          onChange={handleTabChange}
          textColor="inherit"
        >
          {
            props.tabs.map(tab => {
              let label = <></>;

              if (tab.type === CommunicationType.invitecall) {
                label = <Badge
                  classes={{ badge: classes.inviteCall }}
                  sx={{ right: theme.spacing(-0.5) }}
                  variant="dot">
                  <span>{FormatCallerId(tab.name)}</span>
                </Badge>;
              } else if (tab.type === CommunicationType.activecall) {
                label = <Badge
                  classes={{ badge: ((tab?.data?.onHold || tab?.data?.holdMusic) ? classes.holdCall : classes.activeCall) }}
                  variant="dot">
                  <span>{FormatCallerId(tab.name)}</span>
                </Badge>;
              } else {
                label = <span>{FormatCallerId(t(tab.name))}</span>;
              }

              if (tab.close) {
                label = <span className={classes.closeTab}>{label}&nbsp;
                  <IconButton onClick={event => handleTabClose(event, tab.id)} size="small" color="inherit">
                    <Cancel sx={styles.smallIcon} />
                  </IconButton>
                </span>
              }

              return <Tab sx={styles.tab} iconPosition="start" key={tab.id} component={"div"} id={tab.id} icon={getIcon(tab.type, (tab?.data?.onHold || tab?.data?.holdMusic))} label={label} disabled={tab?.disabled} />
            })
          }
        </Tabs>
        {props.wrapup !== null &&
          <React.Fragment>
            <IconButton size="small" color="inherit" onClick={handleExtend} title={t("Extend wrapup time by 30 seconds")}><AlarmAdd /></IconButton>
            <span className={notify ? classes.notifyTimer : classes.timer} key={props.wrapup.end}>
              <Timer direction="backward" formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                initialTime={props.wrapup.end - new Date().getTime()}
                checkpoints={[{ time: 0, callback: handleEnd }, { time: 30000, callback: handleNofify }]}>
                <Timer.Minutes />:<Timer.Seconds />
              </Timer>
            </span>
            <IconButton size="small" color="inherit" onClick={handleWrapupEnd} title={t("End wrapup time")}><AlarmOff /></IconButton>
          </React.Fragment>
        }
        {(agentWallboardAccess && voiceLicense && !mobile) && <Button title={t("Agent Dashboard")} size="medium" color="inherit" sx={styles.menuButton} onClick={showWbAgents}><PeopleAlt /></Button>}
        {(dashboardAccess && voiceLicense && !mobile) && <Button title={!pilot ? t("Call Dashboard") : t("Interactions")} size="medium" color="inherit" sx={styles.menuButton} onClick={showWbCalls}>{!pilot ? <SettingsPhone /> : <PermPhoneMsg />}</Button>}
        {(wallboardAccess && voiceLicense && !mobile) && <Button title={t("Wallboard")} size="medium" color="inherit" sx={styles.menuButton} onClick={() => props.openWallboard(WallboardType.CentrePal)}><InsertChart /></Button>}
        <LogoutComponent {...props} />
        {openDialog &&
          <ConfirmationDialog
            open={openDialog}
            handleClose={closeConfirmDialog}
            confirmChange={confirmWrapupEnd}
            message={t("Are you sure you want to end this call wrap up?")}
          />
        }
      </Toolbar>
    </AppBar>
  );
};

export default MainAppBar;
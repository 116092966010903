import { Client } from '@microsoft/microsoft-graph-client';
import { LogInformation, getLocalStorage, setLocalStorage } from "./Common";
import { UserService } from "../services/UserService";

export const GetUsersPresenceByIds = async (ids) => {
    try {
        const token = await getGraphAccessToken(["Presence.Read.All"]);

        if (token === null)
            return null

        const client = Client.init({
            authProvider: (done) => {
                done(null, token);
            }
        });
        
        const presence = {
            ids: ids
        };

         const result  = await client.api('/communications/getPresencesByUserId')
            .post(presence);
        
        return result;
    } catch (error) {
        LogInformation("error - ", error)
    }
}


const getGraphAccessToken = async(scopes) => { 
    let token = getLocalStorage("graph_access_token");

    if (token !== null) { 
        return token
    }

    let params = {
        Scopes: scopes
    }

    let result = await UserService.getGraphAccessToken(params);

    if (result.success) {
        setLocalStorage("graph_access_token", result.data, 50);
        return result.data;
    }

    return null;
}
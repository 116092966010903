import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: 120,
        height: 50,
        position: 'absolute',
        top: '47%',
        left: '46%',
        transform: 'translate(-50 %, -50 %)',
        border: '1px solid #0000001f',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    secondary: {
        border: '3px solid #f3f3f3',
        borderTop: '3px solid #6264a7',
        animation: 'spin 2s linear infinite',
        width: 12,
        height: 12,
        borderRadius: "50%",
        marginRight: 8
    },
    primary: {
        border: '4px solid #f3f3f3',
        borderTop: '4px solid #6264a7',
        borderRadius: "50%",
        width: 24,
        height: 24,
        animation: 'spin 2s linear infinite',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50 %, -50 %)'
    },
    "@keyframes blinker": {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(360deg)'
        },
    },
}));

const Loading = ({ wrapper = false}) => {
    const classes = useStyles();

    if (wrapper) {
        return (
            <div className={classes.wrapper}>
                <div className={classes.secondary}></div>
                <p>Loading...</p>
            </div>
        )
    }

    return <div className={classes.primary}></div>
}

export default Loading
import { createTheme } from '@mui/material/styles';
import { grey, yellow } from '@mui/material/colors';
import { jaJP, enUS } from '@mui/material/locale';
import { languages } from './i18n';

let language = null;
const urlParams = new URLSearchParams(window.location.search);
let locale = urlParams.get('locale');
let theme = urlParams.get('theme');

if (!theme) {
    theme = "light"
} else if (theme === "default") {
    theme = "light"
} else if (theme === "dark") {
    theme = "dark"
} else if (theme === "contrast") {
    theme = "dark"
} else {
    theme = "light"
}

if (locale !== null) {
    var parts = locale.split("-");
    if (parts[0] in languages && parts.length === 2) {
        const code = `${parts[0].toLowerCase()}${parts[1].toUpperCase()}`;

        switch (code) {
            case 'enUS':
                language = enUS
                break;
            case 'jaJP':
                language = jaJP
                break;
            default:
                language = enUS
                break;
        }
    }
}

export default createTheme({
    palette: {
        primary: {
            main: theme === "light" ? '#6264a7' : '#9399f5',
            light: '#9291d9',
            dark: '#333a78',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#c0392b',
            light: '#d32f2f',
            dark: '#890001',
            contrastText: '#ffffff',
        },
        black: {
            main: '#000000de',
            light: grey[300],
            dark: grey[300],
            contrastText: '#000000de'
        },
        white: {
            main: '#FFFFFF',
            light: grey[200],
            dark: grey[200],
            contrastText: '#FFFFFF'
        },
        grey: {
            main: grey[300],
            light: '#f96b55',
            dark: grey[400],
            contrastText: '#333',
            darkest: grey[600],
        },
        juicyYellow: {
            main: yellow[800],
            light: '#ffeb3b',
            dark: '#fbc02d',
            contrastText: '#333',
            darkest: '#f57f17',
        },
        background: {
            default: theme === "dark" ? '#292929' : '#FFF',
            paper: theme === "dark" ? '#242424' : '#FFF',
        },

        mode: theme,
    },
    typography: {
        h1: {
            fontWeight: 600,
            fontSize: "2rem",
            lineHeight: 1.5,
            letterSpacing: "0.0075em",
        },
        h2: {
            fontWeight: 600,
            fontSize: "1.75rem",
            lineHeight: 1.5,
            letterSpacing: "0.0075em",
        },
        h3: {
            fontWeight: 600,
            fontSize: "1.5rem",
            lineHeight: 1.5,
            letterSpacing: "0.0075em",
        },
        h4: {
            fontWeight: 600,
            fontSize: "1.25rem",
            lineHeight: 1.2,
            letterSpacing: "0.0075em",
        },
        h5: {
            fontWeight: 600,
            fontSize: "1rem",
            lineHeight: 1.2,
            letterSpacing: "0.0075em",
        },
        h6: {
            fontWeight: 600,
            fontSize: "1rem",
            lineHeight: 1.2,
            letterSpacing: "0.0075em",
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em",
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em",
        },
        body1: {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
        },
        body2: {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
        },
        button: {
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em",
            textTransform: 'none',
        },
        caption: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
        },
        overline: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase",
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiIconButton: {
            //size: 'small',
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                margin: 'dense',
                size: "small",
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            //size: 'small',
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
                size: "small"
            },
        },
        MuiToolbar: {
            defaultProps: {
                margin: 'dense',
                minHeight: 0
            },
            styleOverrides: {
                root: {
                    width: '100%'
                },
            },
        },
    }
}, language);
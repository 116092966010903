import CryptoJS from 'crypto-js';
import Keys from './Key';

export default class Crypto{
    static decrypt(value){
        if(value ?? false){
            const bytes = CryptoJS.AES.decrypt(value, Keys.cryptoKey);
            return bytes.toString(CryptoJS.enc.Utf8).split('\r').join('');
        }

        return "";
    }
}

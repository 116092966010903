import { SvgIcon } from '@mui/material'
import React from 'react'

function JiraIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M 10.105,14.8 6.825,11.488 10.009,8.3 C 9.745,8.026 9.475,7.74 9.209,7.44 A 5.861,5.861 0 0 1 7.678,3.807 L 0,11.484 l 11.484,11.485 0.7,-0.847 a 4.02,4.02 0 0 0 -0.062,-5.27 C 11.169,15.775 10.114,14.807 10.105,14.8 Z M 12.723,8.169 16,11.48 12.816,14.665 c 0.264,0.273 0.535,0.56 0.8,0.859 a 5.855,5.855 0 0 1 1.531,3.638 L 22.825,11.484 11.344,0 l -0.7,0.847 a 4.018,4.018 0 0 0 0.061,5.27 c 0.954,1.076 2.009,2.045 2.018,2.052 z" />
        </SvgIcon>
    )
}

export default JiraIcon
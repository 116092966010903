import axios from 'axios';
import { Providers } from '@microsoft/mgt';
import { LogInformation, getLocalStorage } from '../helpers/Common';
import Environment from '../Environment';

export const setApiDefault = () => {
    axios.defaults.baseURL = Environment.config.API_BASE_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Accept'] = 'application/json';
}

const initApi = (store) => {
    setApiDefault();

    axios.interceptors.request.use(async request => {
        if (Providers.globalProvider) {
            const token = await Providers.globalProvider.getAccessToken([]);

            if (token !== null) {
                request.headers.Authorization = `Bearer ${token}`;
            }
        }else{
            request.headers.Authorization = `Bearer ${getLocalStorage('access-token')}`;
        }
        return request;
    }, error => {
        LogInformation(error);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(async response => {
        return response;
    }, error => {
        return Promise.reject(error);
    });
}

 export default initApi;
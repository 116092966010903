import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Typography, Button, CircularProgress } from '@mui/material';
import { LoginType, Providers, TeamsProvider } from '@microsoft/mgt';
import { app, authentication } from '@microsoft/teams-js';
import { HandleServerResponse, LogInformation } from '../../../helpers/Common';
import { RegisterService } from '../../../services/RegisterService';
import { useDispatch } from 'react-redux';
import GroupBox from '../../../components/GroupBox';
import { Link } from "react-router-dom";
import { Close, NavigateNext, OpenInNew, VerifiedUser } from '@mui/icons-material';
import Environment from '../../../Environment';
import TeamsAuthProvider from '../../../TeamsAuthProvider';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';

const useStyles = makeStyles(theme => ({
    grantPermission: {
        width: '100%',
        padding: 12
    },
    note: {
        display: 'flex',
        marginBottom: 24
    },
    permissionStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
    },
}));

export default function GrantPermissions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const styles = {
        nextBtn: {
            width: 100,
            marginTop: theme.spacing(2)
        }
    }

    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true);
    const [enableGraphAppBtn, setEnableGraphAppBtn] = React.useState(true);
    const [enableInsightAppBtn, setEnableInsightAppBtn] = React.useState(true);
    const [enableSupplimentaryAppBtn, setEnableSupplimentaryAppBtn] = React.useState(true);

    const [registrationCompleted, setRegistrationCompleted] = React.useState(false);
    const [urlInfo, setUrlInfo] = React.useState({ azureAppConsentUrl: null, graphAppConsentUrl: null, insightBotConsentUrl: null, supplimentaryBotConsentUrl: null });

    const handleRegisterService = () => {
        setEnableGraphAppBtn(false);
        let link = `${urlInfo.graphAppConsentUrl}${window.location.origin}/consent`;
        window.open(link, '_blank');
    }

    const handleRegisterTrackingBot = () => {
        setEnableInsightAppBtn(false);
        let link = `${urlInfo.insightBotConsentUrl}${window.location.origin}/consent`;
        window.open(link, '_blank');
    }

    const handleRegisterSupplimentarygBot = () => {
        setEnableSupplimentaryAppBtn(false);
        let link = `${urlInfo.supplimentaryBotConsentUrl}${window.location.origin}/consent`;
        window.open(link, '_blank');
    }

    const refreshRegisterInfo = () => {
        (async () => {
            setLoading(true);

            let result = await RegisterService.validateApps();
            HandleServerResponse(result, "", dispatch);
            if (result.success) {
                setUrlInfo({ ...result.data });
                // if (result.data.azureAppConsentUrl !== null) {
                //     setEnableAzureAppBtn(true);
                // }
                if (result.data.graphAppConsentUrl !== null) {
                    setEnableGraphAppBtn(true);
                }
                if (result.data.insightBotConsentUrl !== null) {
                    setEnableInsightAppBtn(true);
                }
                if (result.data.supplimentaryBotConsentUrl !== null) {
                    setEnableSupplimentaryAppBtn(true);
                }
                if (result.data.graphAppConsentUrl === null && result.data.insightBotConsentUrl === null && result.data.supplimentaryBotConsentUrl === null) {
                    setRegistrationCompleted(true);
                }
            }
            setLoading(false);
        })();
    }

    React.useEffect(() => {
        let active = true;
        (async () => {
            //const urlParams = new URLSearchParams(window.location.search);
            //let upn = urlParams.get('upn');
            //let upn = null;

            TeamsProvider.microsoftTeamsLib = app;

            if (TeamsProvider.isAvailable) { //new process
                LogInformation("Initializing TeamsProvider Version 2");
                app.initialize();
                authentication.initialize();
                Providers.globalProvider = new TeamsAuthProvider();
            } else {
                LogInformation("Initializing MsalProvider");
                const config = {
                    clientId: Environment.config.AZURE_APP_ID,
                    scopes: [Environment.config.AZURE_APP_EXPOSE_API_SCOPE, "Presence.Read.All"],
                    isMultiAccountEnabled: false,
                    loginType: LoginType.Redirect,
                    redirectUri: window.location.protocol + '//' + window.location.host,
                }
                Providers.globalProvider = new Msal2Provider(config);
            }

            if (Providers.globalProvider ?? false) {
                let result = await RegisterService.validateApps();

                HandleServerResponse(result, "", dispatch);
                if (result.success && active) {
                    setUrlInfo({ ...result.data });
                    if (result.data.graphAppConsentUrl === null && result.data.insightBotConsentUrl === null && result.data.supplimentaryBotConsentUrl === null) {
                        setRegistrationCompleted(true);
                    }
                }
            }

            if (!active) { return; }
            setLoading(false);
        })();
        return () => { active = false; };
    }, [])

    return (
        <GroupBox title="Grant Permissions to Microsoft 365 Services" gutter className={classes.grantPermission}>
            <div className={classes.note}>
                <Typography variant="body2">
                    <span><strong>Note: </strong>
                        Verify that you are signed into the correct Microsoft 365 tenant before granting permissions.
                        We recommend using a Microsoft 365 Global Administrator account.
                    </span>
                </Typography>
            </div>
            <div>
                {!loading ?
                    <React.Fragment>
                        <div className={classes.permissionStyle}>
                            <Typography variant="body2">Grant permission for communication service</Typography>
                            <Button
                                startIcon={urlInfo.graphAppConsentUrl === null ? <VerifiedUser /> : <OpenInNew />}
                                variant="outlined"
                                onClick={handleRegisterService}
                                disabled={urlInfo.graphAppConsentUrl === null || !enableGraphAppBtn}>
                                Grant Permission
                            </Button>
                        </div>
                        <br />
                        <div className={classes.permissionStyle}>
                            <Typography variant="body2">Grant permission for interaction service</Typography>
                            <Button
                                startIcon={urlInfo.insightBotConsentUrl === null ? <VerifiedUser /> : <OpenInNew />}
                                variant="outlined"
                                onClick={handleRegisterTrackingBot}
                                disabled={urlInfo.insightBotConsentUrl === null || !enableInsightAppBtn}>
                                Grant Permission
                            </Button>
                        </div>
                        <br />
                        <div className={classes.permissionStyle}>
                            <Typography variant="body2">Grant permission for supplementary service</Typography>
                            <Button
                                startIcon={urlInfo.supplimentaryBotConsentUrl === null ? <VerifiedUser /> : <OpenInNew />}
                                variant="outlined"
                                onClick={handleRegisterSupplimentarygBot}
                                disabled={urlInfo.supplimentaryBotConsentUrl === null || !enableSupplimentaryAppBtn}>
                                Grant Permission
                            </Button>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <CircularProgress />
                        <br />
                    </React.Fragment>
                }
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <Button endIcon={registrationCompleted === true ? <NavigateNext /> : ''} variant="contained" color="primary" onClick={registrationCompleted === true ? () => props.handleSelectedComponent('organisation-info') : refreshRegisterInfo} sx={styles.nextBtn}>
                        {registrationCompleted === true ? 'Next' : loading === true ? 'Verifying...' : 'Verify'}
                    </Button>
                    <Button
                        endIcon={<Close />}
                        variant="contained"
                        component={Link}
                        to="/"
                        color='grey'
                        sx={styles.nextBtn}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </GroupBox>
    );
}
import { Plugin, ButtonView } from 'ckeditor5';

const autoReply='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="m20 7 .94-2.06L23 4l-2.06-.94L20 1l-.94 2.06L17 4l2.06.94zM8.5 7l.94-2.06L11.5 4l-2.06-.94L8.5 1l-.94 2.06L5.5 4l2.06.94zM20 12.5l-.94 2.06-2.06.94 2.06.94.94 2.06.94-2.06L23 15.5l-2.06-.94zm-2.29-3.38-2.83-2.83c-.2-.19-.45-.29-.71-.29-.26 0-.51.1-.71.29L2.29 17.46c-.39.39-.39 1.02 0 1.41l2.83 2.83c.2.2.45.3.71.3s.51-.1.71-.29l11.17-11.17c.39-.39.39-1.03 0-1.42m-3.54-.7 1.41 1.41L14.41 11 13 9.59zM5.83 19.59l-1.41-1.41L11.59 11 13 12.41z" fill="#6264a7"></path></svg>'

export default class AutoReplyPlugin extends Plugin {
    constructor(editor) {
        super(editor);
        this.callback = null;
    }

    setCallback(callback) {
        this.callback = callback;
    }

    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add('autoReply', locale => {
            const buttonView = new ButtonView(locale);

            buttonView.set({
                label: 'Auto Reply',
                withText: false,
                tooltip: true,
                icon: autoReply
            });

            buttonView.on('execute', (e) => {
                this.callback()
            });

            return buttonView;       
        });
    }
}
import { Add, LockOpen, Lock, OpenInNew } from "@mui/icons-material";
import { Box, Button, Typography, Divider, Link, CircularProgress } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import FormSubtitle from "../../../components/form/FormSubtitle";
import { JiraService } from "../../../services/JiraService";
import Input from '../../../components/Inputs/Input';
import { useTranslation } from "react-i18next";
import SwitchBtn from "../../../components/Inputs/SwitchBtn";
import timeago from 'epoch-timeago';
import { HandleServerResponse, getLocalStorage } from "../../../helpers/Common";
import DropDown from "../../../components/Inputs/DropDown";
import { useDispatch } from "react-redux";

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    box: {
        marginTop: '10px',
    },
    button: {
        float: 'right',
        marginTop: '20px',
    },
    inputs: {
        marginTop: '10px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'no-wrap',
        marginBottom: '10px',
        paddingRight: '10px'
    },
    errorAndLoading: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const useStyles = makeStyles(theme => ({
    dropdownStyle: {
        height: 25,
        marginLeft: theme.spacing(0.5)
    },
    comment: {
        display: 'flex',
        marginBottom: theme.spacing(0.5),
        justifyContent: 'space-between',
        paddingRight: theme.spacing(1)
    }

}));

const JiraViewIssue = ({ data, jiraConnector }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [payload, setPayload] = React.useState(() => data);
    const [transitions, setTransitions] = React.useState([]);
    const [transition, setTransition] = React.useState('1');
    const [values, setValues] = React.useState({ comment: '', internal: false })
    const [disable, setDisable] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);

    const { t } = useTranslation();

    const handleComment = (event) => {
        event.preventDefault();
        setValues((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    }

    const handleCheckbox = (event) => {
        setValues((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    }

    const handleSubmit = (e) => {
        (async () => {
            if (values.comment === '') {
                return
            }

            let params = {
                ConnectorId: jiraConnector.connectorId,
                Id: payload.id,
                Comment: values.comment,
                Internal: values.internal
            }

            let result = await JiraService.AddComment(params);

            HandleServerResponse(result, t("Comment added successfully"), dispatch);

            if (result.success) {
                setPayload((prev) => {
                    let Obj = {
                        body: result?.data?.body,
                        author: result?.data?.author,
                        date: result?.data?.created,
                        id: result?.data?.id,
                        public: result?.data?.public,
                    }

                    let holdValues = { ...prev };
                    let data = { ...holdValues?.data };
                    let comments = [...data.comments];

                    comments = [Obj, ...comments];
                    data.comments = comments;
                    holdValues.data = data;

                    return holdValues;
                })

                setValues((prev) => ({
                    comment: '',
                    internal: false
                }));
            }
        })();
    }

    React.useEffect(() => {
        let active = true;
        (async () => {

            let currentStatus = data?.status ?? null;

            if (payload.data === null) {
                setLoading(() => true);

                let params = {
                    ConnectorId: jiraConnector.connectorId,
                    SearchValue: payload.id,
                }
                if (jiraConnector.authType === 1) {
                    const jiraCredentials = getLocalStorage('jira-auth')
                    params['AccessToken'] = jiraCredentials.accessToken;
                }

                let result = await JiraService.ViewIssue(params);

                if (result.success) {
                    currentStatus = result.data?.status;
                    setPayload((prev) => {
                        return { ...prev, data: result.data }
                    });
                }
                setShowErrorMessage(() => !result?.success);

                setLoading(() => false);
            }

            let commmentParams = {
                ConnectorId: jiraConnector.connectorId,
                Id: payload.id
            }

            //load transitions
            let result = await JiraService.GetTransitions(commmentParams);

            if (result.success && active) {
                if (result.data?.transitions.length > 0) {
                    setTransitions(() => {
                        return [...result?.data?.transitions ?? [], { id: '1', name: currentStatus }]
                    })
                }
            }

            if (!active) { return; }
        })();

        return () => {
            active = false
        }
    }, [])

    const handleStatusDropdown = (e) => {
        const { name, value } = e.target;

        if (value !== '1') {
            (async () => {
                setTransition(() => value)

                let params = {
                    ConnectorId: jiraConnector.connectorId,
                    Id: payload.id,
                    TransitionId: value
                }

                let result = await JiraService.PerformTransition(params);

                HandleServerResponse(result, t("Ticket status updated successfully"), dispatch);

                setDisable(() => result?.success);
            })();
        }
    }

    return (
        <>
            {payload?.data !== null &&
                <>
                    <div style={styles.container}>
                        {
                            Object.entries(payload?.data).map(([key, value]) => {
                                if (key !== 'webUrl') {
                                    if (!Array.isArray(value)) {
                                        return <JiraField
                                            field={toTitleCase(key)}
                                            value={value}
                                            webUrl={key === "issueKey" ? payload?.data["webUrl"] : ""}
                                            transitions={key === "status" ? transitions : []}
                                            transition={transition}
                                            handleStatusDropdown={handleStatusDropdown}
                                            disable={disable}
                                            key={key}
                                        />
                                    }
                                }
                            })
                        }
                        {
                            payload?.data?.subValues !== null &&
                            payload?.data.subValues.map((item) => {
                                if (item?.fieldId !== 'description') {
                                    return <JiraField
                                        field={item.label}
                                        value={item?.value}
                                    />
                                }
                            })
                        }
                    </div>
                    {
                        payload?.data?.subValues !== null &&
                        payload?.data.subValues.map((item) => {
                            if (item?.fieldId === 'description') {
                                return <JiraField
                                    field={item.label}
                                    value={item?.value}
                                    full={true}
                                />
                            }
                        })
                    }
                    <FormSubtitle title={t("Comments ({length})", { length: payload?.data?.comments?.length })} />
                    <Box sx={styles.inputs}>
                        <div style={{ width: '95%' }}>
                            <Input
                                style={{ width: "100%" }}
                                label={t("Comment")}
                                name={"comment"}
                                onChange={(e) => handleComment(e)}
                                value={values.comment}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <SwitchBtn
                                size="large"
                                label={"Public"}
                                value={values.internal}
                                onChange={(e) => handleCheckbox(e)}
                                name="internal"
                            />
                        </div>
                        <Button
                            onClick={(e) => handleSubmit(e)}
                            sx={{ height: '36px', mt: theme.spacing(1), ml: theme.spacing(0.5) }}
                            disabled={values.comment === '' ? true : false}
                            startIcon={<Add />}
                            variant="contained"
                            color="primary"
                            disableElevation
                            component="span">
                            {t("Add")}
                        </Button>
                    </Box>
                    <Divider sx={{ mt: theme.spacing(3), mb: theme.spacing(3), mr: theme.spacing(2) }} />
                    {
                        payload?.data?.comments.map((comment) => (
                            <Comment
                                comment={comment}
                            />
                        ))
                    }
                </>
            }
            {loading &&
                <div style={styles.errorAndLoading}><CircularProgress /></div>
            }
            {(showErrorMessage && !loading) &&
                <div style={styles.errorAndLoading}>Something went wrong while trying to load ticket...</div>
            }
        </>
    )
}

const toTitleCase = (str) => {
    const result = str.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}

const JiraField = ({ field, value, transition, handleStatusDropdown, disable, webUrl = "", full = false, transitions = [] }) => {
    const theme = useTheme();
    const fieldStyles = {
        field: {
            width: full ? '100%' : '50%',
            marginBottom: theme.spacing(2)
        },
        label: {
            fontWeight: 550,
            marginBottom: theme.spacing(0.25)
        },
        value: { overflowWrap: 'break-word' }
    }

    return (
        <div style={fieldStyles.field}>
            <Typography variant="body2" sx={fieldStyles.label}>{field}</Typography>
            <FieldValue
                value={value}
                webUrl={webUrl}
                field={field}
                transitions={transitions}
                transition={transition}
                handleStatusDropdown={handleStatusDropdown}
                disable={disable}
            />
        </div>
    )
}

const FieldValue = ({ value, webUrl, field, transition, transitions, handleStatusDropdown, disable }) => {
    const theme = useTheme();
    const classes = useStyles();

    const fieldStyles = {
        root: {
            display: 'flex',
            alignItems: 'center'
        },
        value: {
            overflowWrap: 'break-word'
        },
        link: {
            ml: theme.spacing(1),
            height: '16px'
        },
        icon: {
            fontSize: '18px'
        }
    }

    if (field === 'Status' && transitions.length > 0) {
        return (
            <div>
                <DropDown
                    disable={disable}
                    style={{ marginTop: 0, marginBottom: 0, width: '200px' }}
                    className={classes.dropdownStyle}
                    size="small"
                    value={transition}
                    name='transition'
                    onChange={(e) => handleStatusDropdown(e)}
                    values={transitions}
                />
            </div>
        )
    }

    if (webUrl !== "") {
        return (
            <div style={fieldStyles.root}>
                <Typography variant="body2" sx={fieldStyles.value}>{value}</Typography>
                <Link sx={fieldStyles.link} href={webUrl} target="_blank" rel="noopener noreferrer">
                    <OpenInNew sx={fieldStyles.icon} />
                </Link>
            </div>
        )
    }

    return <div dangerouslySetInnerHTML={{ __html: value }}></div>
}

const Comment = ({ comment }) => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <>
            <div className={classes.comment}>
                <div>
                    <Typography sx={{ fontWeight: 'bold' }}>{comment?.author}</Typography>
                </div>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                    <Typography sx={{ fontStyle: 'italic' }}>{comment?.date}</Typography>
                    {comment.public ? <LockOpen sx={{ fontSize: '16px', ml: theme.spacing(2) }} /> : <Lock sx={{ fontSize: '16px', ml: theme.spacing(2) }} />}
                </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: comment?.body }}></div>
            <Divider sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }} />
        </>
    )
}

export default JiraViewIssue;
import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LogInformation, generateRandomText } from '../../helpers/Common';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    modalWidth: {
        width: 500
    },
    actions: {
        margin: theme.spacing(1),
    },
}));

export default function ConfirmNumberDialog({ open, handleMessageOpenClose, phone }) {
    const classes = useStyles();
    const [number, setNumber] = React.useState(phone);
    const [message] = React.useState(generateRandomText(5));

    const handleChange = (event) => {
        event.persist();
        setNumber(event.target.value);
      };

    const handleSendMessage = () => {
        LogInformation(`number ${number} : massage ${message}`)
        handleMessageOpenClose();
    }

    return (
        <div>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Verify callee number</DialogTitle>
                <DialogContent>
                    <TextField
                        value={number}
                        variant="outlined"
                        margin="dense"
                        id="number"
                        label="Phone number"
                        type="text"
                        fullWidth
                        name="number"
                        onChange={ handleChange}
                    />
                    <TextField
                        style={{marginTop:20}}
                        value={message}
                        variant="outlined"
                        margin="dense"
                        id="message"
                        label="Message"
                        type="text"
                        fullWidth
                        name="message"
                    />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={handleSendMessage} variant="contained" color="primary" disableElevation>
                        Send
                    </Button>
                    <Button onClick={handleMessageOpenClose} variant="contained" color="grey" disableElevation>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import React from 'react'
import Header from './list/Header';
import Item from './list/Item';
import GroupBox from '../../components/GroupBox';
import { useSelector } from 'react-redux';
import ConversationListSkeleton from './components/skeletons/SkeletonList';
import { styled } from '@mui/styles';
import { ConversationStatus, ConversationType } from '../../helpers/Constant';

const ConversationList = () => {
    const Container = styled('div')(({ theme }) => ({
        position: 'absolute',
        top: 10,
        left: 0,
        right: 0,
        bottom: 50,
        overflowY: 'auto',
        marginTop: 4,
        marginLeft: 7,
        marginRight: 7
    }));

    const { conversations, current } = useSelector((state) => state.conversation);

    const sortConversationsByDate = (conversations) => {
        return conversations.sort((a, b) => new Date(b.created) - new Date(a.created));
    };

    const filterConversations = () => {
        const emailsWithUserId = conversations.filter(
            (x) => x.type === ConversationType.Email && x.userId !== null
        );
        const openEmailsWithoutUserId = conversations.filter(
            (x) => x.type === ConversationType.Email && x.userId === null && x.status === ConversationStatus.Open
        );
        const newEmails = conversations.filter(
            (x) => x.type === ConversationType.Email && x.status === ConversationStatus.New
        );

        return {
            emailsWithUserId: sortConversationsByDate(emailsWithUserId),
            openEmailsWithoutUserId: sortConversationsByDate(openEmailsWithoutUserId),
            newEmails: sortConversationsByDate(newEmails),
        };
    };

    const renderConversationItems = (filteredConversations) => {
        return filteredConversations.map((conversation) => (
            <Item data={conversation} key={conversation.id} selected={current?.id} />
        ));
    };

    const filteredConversations = filterConversations();

    return (
        <React.Fragment>
            <Header />
            <GroupBox
                smallBodyPadding={true}
                showDivider={false}
                gutter
                sx={{ position: 'relative', height: '92%' }}
            >
                <Container>
                    {conversations.length === 0 && <></>}
                    {renderConversationItems(filteredConversations.emailsWithUserId)}
                    {renderConversationItems(filteredConversations.openEmailsWithoutUserId)}
                    {renderConversationItems(filteredConversations.newEmails)}
                </Container>
            </GroupBox>
        </React.Fragment>
    );
};

export default ConversationList;

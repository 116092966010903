import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material';
import { HighlightOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const RootDialog = styled(Dialog)(({ theme }) => ({
    padding: 8,
    minHeight: 400
}));

const RootDialogContent = styled(DialogContent)(({ theme }) => ({
    paddingBottom: 0
}));

const RootDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: "#6264a7",
    padding: '8px 12px 8px 24px',
    marginBottom: 8,
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const CloseIcon = styled(HighlightOff)(({ theme }) => ({
    color: '#ffffff'
}));

const RootDialogActions = styled(DialogActions)(({ theme }) => ({
    padding: 8,
    margin: 8
}));

const Header = React.memo(({ title, close }) => {

    return (
        <RootDialogTitle id="form-dialog-title">
            <Typography color="#ffffff" variant="h5">{title}</Typography>
            <IconButton aria-label="close" size="small" onClick={close}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </RootDialogTitle>
    );
});

const Actions = React.memo(({ children }) => {

    return (
        <RootDialogActions>
            {children}
        </RootDialogActions>
    );
});

const CustomDialog = ({ open, close, title, children }) => {
    const [childOne, childTwo] = React.Children.toArray(children);

    return (
        <RootDialog open={open} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <Header close={close} title={title} />
            <RootDialogContent>
                {childOne}
            </RootDialogContent>
            <Actions>
                {childTwo}
            </Actions>
        </RootDialog>
    );
};

export default CustomDialog;
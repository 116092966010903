import { AccountCircle } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import React from 'react'

const FormSummary = ({ data }) => {
    const theme = useTheme();
    const keys = Object.keys(data);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
                <AccountCircle color='lightgray' fontWeight="bold" sx={{ padding: 0, fontSize: '30px', position: 'absolute', top: '-20px', color: 'lightgray' }} />
            </div>
            <div>
                {
                    keys.map((key, index) => {
                        return (
                            data[key] !== "" &&
                            <div key={index} style={{ marginBottom: 8 }}>
                                <div style={{ color: theme.palette.mode === "light" ? 'gray' : 'lightGray' }}>{`${key}:`}</div>
                                <div>{`${data[key]}`}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div >
    )
}

export default FormSummary
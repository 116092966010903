import { Divider, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import cx from 'clsx';
import ShowMore from './ShowMore';
import { TenantService } from '../../../services/TenantService';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-around',
        gap: theme.spacing(1),
    },
    status: {
        width: 60,
        height: 20,
        borderRadius: 4,
        color: 'white',
        textAlign: 'center',
        fontSize: 9,
        paddingTop: theme.spacing(0.5),
        fontWeight: 600,
    },
    header:
    {
        display: 'flex',
        justifyContent: 'space-between'
    },
    customBorder: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.palette.error.main,
        marginBottom: theme.spacing(1),
    },
    new: {
        backgroundColor: theme.palette.success.main
    },
    improved: {
        backgroundColor: theme.palette.info.main,
    },
    fixed: {
        backgroundColor: theme.palette.error.main
    },
    dividerNew: {
        lineHeight: '0rem',
    },
    description: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical'
    }
}));

const Section = ({ item, index, showMore }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        subject: {
            fontWeight: 600, 
        },
        customDivider: {
            width: '94%',
            backgroundColor: theme.palette.error.main
        },
        dividerText: {
            lineHeight: '0rem',
        },
        normalDivider: {
            marginBottom: theme.spacing(1),
        },
        caption: {
            color: '#9698a2'
        },
    }

    const getDivider = () => {
        if (index === 0) {
            return (
                <div className={classes.customBorder}>
                    <Divider sx={styles.customDivider} />
                    <Typography sx={styles.dividerText} variant="caption" display="block">{t("New")}</Typography>
                </div>
            )
        }

        if (index === 1) {
            return <Divider sx={styles.normalDivider} />
        }
    }

    const getReleaseDate = (date) => {
        var startDate = new Date(date);
        var endDate = new Date();

        var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

        var days = Math.floor(seconds / (3600 * 24));

        if (days > 365) {
            return `${Math.ceil(days / 365)} year(s) ago`
        }

        if (days > 30) {
            return `${Math.ceil(days / 30)} month(s) ago`
        }

        if (days > 7) {
            return `${Math.ceil(days / 7)} week(s) ago`
        }

        if (days > 0) {
            return `${days} day(s) ago`
        }

        var hrs = Math.floor(seconds % (3600 * 24) / 3600);
        return `${hrs} hour(s) ago`
    }

    return (
        <div className={classes.root}>
            <div className={cx(classes.status, classes[item.type.toLowerCase()])}>{t(item.type.toUpperCase())}</div>
            <div className={classes.header}>
                <Typography variant="body2" sx={styles.subject} >{item.subject}</Typography>
                <Typography sx={styles.caption} variant="caption" display="block">{getReleaseDate(item.releaseDate)}</Typography>
            </div>
            <div>
                <Typography className={classes.description} variant="body2">{item.description}</Typography>
                <Link
                    component="button"
                    variant="caption"
                    onClick={(e) => showMore({ ...item })}
                >
                    {t("show more...")}
                </Link>
                {getDivider()}
            </div>
        </div>
    )
}

const ReleaseNote = () => {
    const [data, setData] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            let result = await TenantService.getReleaseNotes();
            setData(result.data);
        })();
    }, [])

    return (
        <React.Fragment>
            {
                data && data.map((item, key) => (
                    <Section key={key} index={key} item={item} showMore={setSelectedItem} />
                ))
            }
            {selectedItem !== null && <ShowMore open={selectedItem !== null} closeDialog={() => setSelectedItem(null)} {...selectedItem} />}
        </React.Fragment>
    )
}

export default ReleaseNote;
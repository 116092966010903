import axios from 'axios'

const GetIntegrationData = async (phone, chainId, referenceId, queueId, dtmf=null) => {
    const {data} = await axios.get(`/api/integration/entity`,{params: {'phone': phone, 'userInput': dtmf, 'chainId': chainId, 'referenceId': referenceId, 'queueId': queueId}}).then(result => result);//encodeURIComponent
    return data;
};

const DownloadSalesforceCRT = async (id) => {
    const { data } = await axios.get(`/api/integration/download/salesforcedigitalcertificate`,{responseType: 'blob'}).then(result => result);
    return data;
};

const Types = async () => {
     const {data} = await axios.get('/api/integration/types').then(result => result);
     return data;
};

const IntegrationTemplates = async (type) => {
     const {data} = await axios.get(`/api/integration/template/${type}`).then(result => result);
     return data;
};

const TestIntegration = async (values) => {
    const {data} = await axios.post(`/api/integration/testconnection`,values).then(result => result);
    return data;
};

export const IntegrationService = {
    GetIntegrationData,
    DownloadSalesforceCRT,
    Types,
    IntegrationTemplates,
    TestIntegration
};
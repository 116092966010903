import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CallService } from '../../services/CallService';
import { useDispatch } from 'react-redux';
import { HandleServerResponse, validatePhoneNumber } from '../../helpers/Common';
import { updateActiveCall, updateWaitingCall } from '../../features/app/InteractionSlice';
import { updateUserCall } from '../../features/attendent-console/AcSlice';
import { useTheme } from '@mui/material/styles';
import Input from '../Inputs/Input';
import UseForm from '../form/UseForm';
import CountryCodes from '../Inputs/CountryCodes';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    modalWidth: {
        width: '100%',
        display:'flex'
    },
    smallModal: {
        width: '100%',
        display:'flex'
    },
}));

const initialValues = {
    pstnNumber: '',
    countryCode: ''
}

export default function TransferToPstnDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { open, chainId, tenantId, ac = false, callTab = false, small = false } = props;
    const { t } = useTranslation();

    const styles = {
        actions: {
            margin: theme.spacing(1),
        },
        pstnfield:{
            width:'100%',
        }
    }

    const validate = (fieldValues = values) => {
        let validationProps = { ...errors };

        if ('pstnNumber' in fieldValues) {
            validationProps.pstnNumber = validatePhoneNumber(fieldValues.pstnNumber, false);

            if(!fieldValues.pstnNumber.startsWith(values.countryCode)){
                validationProps.pstnNumber = t("Please enter your country code")
            }
        }

        setErrors({
            ...validationProps
        });

        if (fieldValues === values)
            return Object.values(validationProps).every(x => x === "");
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
    } = UseForm(initialValues, true, validate);

    const updateCallTrasfering = () => {
        if (ac) {
            if (!props?.isActive) {
                dispatch(updateWaitingCall({ chainId: chainId, transferring: true }))
            } else {
                dispatch(updateUserCall({ chainId: chainId, transferring: true }));
            }
        }
        else if (callTab) {
            dispatch(updateActiveCall({ chainId: chainId, transferring: true }))
        }
    }

    const handleTransferToPstn = async () => {
        if (validate()) {
            let transferToQueueParams = { QueueId: values.pstnNumber, TenantId: tenantId, ChainId: chainId };
            let transfered = false;
            let result;

            //remove if the 1st digit after the country code is 0 for non emergency numbers in australia (ex: in +61000, the first 0 should no be removed)
            if(values.pstnNumber.charAt(values.countryCode.length) === "0" && values.pstnNumber.length > 6){
                transferToQueueParams.QueueId = values.countryCode + values.pstnNumber.substring(values.countryCode.length + 1)
            }

            if(props?.isActive){
                result = await CallService.TransferActiveToQueue(transferToQueueParams);
            }
            else{
                result = await CallService.TransferWaitingToQueue(transferToQueueParams);
            }
            
            if (result.success){
                transfered = true;
                HandleServerResponse(result, t("Call transfer request to external number was sent successfully."), dispatch);
            }

            if (transfered) updateCallTrasfering();
            props.handleClose()
        }
    }

    const handleCountryCodeChanges = (e) => {
        let { name, value } = e.target;

        let newValues = {
            pstnNumber: value,
            countryCode: value
        }
        setValues(newValues)
        validate({ [name]: value })
    }

    const handlenumberChange = (e) => {
        let { name, value } = e.target;

        if (value.length === 0) {
            setValues({ ...values, [name]: values.countryCode })
        }

        if (value.length + 1 > values.countryCode.length) {
            setValues({ ...values, [name]: value })
        }

        validate({ [name]: value })
    }


    return (
        <div>
            <Dialog
                open={open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">{t("External Transfer")}</DialogTitle>
                <DialogContent>
                    <div className={small ? classes.smallModal : classes.modalWidth}>
                        <CountryCodes
                            handleCountryCodeChanges={handleCountryCodeChanges}
                            countryCode={values.countryCode}
                            setValues={setValues}
                        />
                        <Input
                            required
                            name='pstnNumber'
                            label={t("External Number")}
                            onChange={(e) => handlenumberChange(e)}
                            value={values.pstnNumber}
                            error={errors.pstnNumber}
                            style={styles.pstnfield}         
                        />
                    </div>
                </DialogContent>
                <DialogActions sx={styles.actions}>
                    <Button onClick={handleTransferToPstn} variant="contained" color="primary" disableElevation>
                        {t("Transfer")}
                    </Button>
                    <Button onClick={props.handleClose} variant="contained" color="grey" disableElevation>
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
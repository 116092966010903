import React from "react";
import { app, authentication } from "@microsoft/teams-js";
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { TeamsProvider } from '@microsoft/mgt';
import { LoginType, Providers, ProviderState} from "@microsoft/mgt-element";
import LoginComponent from "./features/auth/LoginComponent";
import { LogInformation, getLocalStorage, removeLocalStorage, setLocalStorage, setLocale } from "./helpers/Common";
import { login, logoutSuccess } from "./features/auth/AuthSlice";
import TeamsAuthProvider from "./TeamsAuthProvider";
import Environment from "./Environment";

const withAuth = (HocComponent) => {
    return class extends React.Component { 
        constructor(props) { 
            super(props);

            this.state = {
                providerState: ProviderState.Loading,
                rootPath: props?.match?.path === "/"
            }
        }

        async getUser() { 
            const account = await Providers.globalProvider.getActiveAccount();
            const oid = account?.id?.split(".");

            return {
                oid: oid[0],
                tid: account?.tenantId
            }
        }

        async componentDidMount() { 
            TeamsProvider.microsoftTeamsLib = app;

            if (TeamsProvider.isAvailable) {
                app.initialize();
                authentication.initialize();
                Providers.globalProvider = new TeamsAuthProvider();
                Providers.globalProvider.setState(getLocalStorage("cc-teams-signin") ? ProviderState.SignedIn : ProviderState.SignedOut);
            } else {
                const config = {
                    clientId: Environment.config.AZURE_APP_ID,
                    scopes: [Environment.config.AZURE_APP_EXPOSE_API_SCOPE, "Presence.Read.All"],
                    isMultiAccountEnabled: false,
                    loginType: LoginType.Redirect,
                    redirectUri: window.location.protocol + '//' + window.location.host,
                }
                Providers.globalProvider = new Msal2Provider(config);
            }

            //set local time format
            if (navigator.languages.length > 0) {
                setLocale(navigator.languages[0]);
            }

            this.setState({
                providerState: Providers.globalProvider.state,
            });

            if (Providers.globalProvider.state === ProviderState.SignedIn && this.state.rootPath) {
                this.props.dispatch(login(await this.getUser(), await Providers.globalProvider.getAccessToken([]), TeamsProvider.isAvailable));
            } else if (Providers.globalProvider.state === ProviderState.SignedOut && this.state.rootPath) {
                //Remove user if token is expired
                LogInformation("Initial ProviderState is SignedOut");
                removeLocalStorage("cc.user");
                this.props.dispatch(logoutSuccess());
            }

            Providers.globalProvider.onStateChanged(async (e) => {
                this.setState({
                    providerState: Providers.globalProvider.state
                });
                setLocalStorage("cc-teams-signin", Providers.globalProvider.state === ProviderState.SignedIn)

                if (Providers.globalProvider.state === ProviderState.SignedIn && this.state.rootPath) {
                    this.props.dispatch(login(await this.getUser(), await Providers.globalProvider.getAccessToken([]), TeamsProvider.isAvailable));
                } else if (Providers.globalProvider.state === ProviderState.SignedOut && this.state.rootPath) {
                    //Remove user if token is expired
                    removeLocalStorage("cc.user");
                    this.props.dispatch(logoutSuccess());
                }
            });
        }

        render() { 
            if (this.state.providerState === ProviderState.SignedIn && (this.props.isAuthUser || !this.state.rootPath)) {
                return <HocComponent {...this.props} />;
            } else {
                return <LoginComponent
                    showSignIn={this.state.providerState === ProviderState.SignedOut}
                    error={this.state.rootPath ? this.props.error : null}
                    registered={this.state.rootPath ? this.props.registered : false}
                    verified={this.state.rootPath ? this.props.verified : false}
                    multiRegion={this.state.rootPath ? this.props.multiRegion : false}
                />;
            }
        }
    }
}

export default withAuth;
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Typography, Box } from '@mui/material';
import { Group } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import GroupBox from '../../../components/GroupBox';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
        width: '140px',
        flexGrow: 0,
        '& .caption': {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '2px',
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
                marginRight: '2px',
            },
        },
        '& h1.available': {
            color: theme.palette.success.main,
        },
        '& h1.busy': {
            color: theme.palette.error.main,
        },
        '& h1.away': {
            color: theme.palette.warning.main,
        },
        '& h1.wrapup': {
            color: theme.palette.info.main,
        },
        '& h1.offline': {
            color: theme.palette.text.disabled,
        },
    },
    statusAvailable: {
        color: theme.palette.success.main,
    },
    statusBusy: {
        color: theme.palette.error.main,
    },
    statusAway: {
        color: theme.palette.warning.main,
    },
    statusWrapup: {
        color: theme.palette.info.main,
    },
    statusOffline: {
        color: theme.palette.text.disabled,
    },
}));

export default function AgentSummary() {
    const classes = useStyles();
    const { t } = useTranslation();

    const icons = {
        total: <Group />,
        available: <Group className={classes.statusAvailable} />,
        busy: <Group className={classes.statusBusy} />,
        away: <Group className={classes.statusAway} />,
        wrapup: <Group className={classes.statusWrapup} />,
        offline: <Group className={classes.statusOffline} />,
    };

    const { users, offlineUsers, awayUsers, workOfflineUsers, wrapupUsers, inaCallUsers, availableUsers,
        onBreakCustom1Users, onBreakCustom2Users, workOfflineCustom1Users, workOfflineCustom2Users } = useSelector((state) => state.interaction);

    const items = [
        { key: "total", name: "Total Agent", count: Object.keys(users).length === undefined ? 0 : Object.keys(users).length },
        { key: "available", name: "Available", count: availableUsers?.length ?? 0 },
        { key: "busy", name: "In a Call", count: inaCallUsers?.length ?? 0 },
        { key: "wrapup", name: "Wrap Up", count: wrapupUsers?.length ?? 0 },
        { key: "away", name: "Unavailable", count:((awayUsers?.length ?? 0) + (onBreakCustom1Users?.length ?? 0) + (onBreakCustom2Users?.length ?? 0) + (workOfflineUsers?.length ?? 0) + (workOfflineCustom1Users?.length ?? 0) + (workOfflineCustom2Users?.length ?? 0))},
        { key: "offline", name: "Offline", count: offlineUsers?.length ?? 0 },
    ];

    const getIcon = (key) => {
        return icons[key];
    }

    return (
        <GroupBox title={t("Agent Summary")} flex center gutter>
            {items.map(item => (
                <Box className={classes.item} textAlign="center" key={item.key}>
                    <Typography variant="h1" classes={{ "h1": item.key }}>{item.count}</Typography>
                    <Divider light variant="middle" />
                    <div className="caption" display="flex">
                        {getIcon(item.key)}
                        <Typography variant="caption" component="div" display="inline">{t(item.name)}</Typography>
                    </div>
                </Box>
            ))}
        </GroupBox>
    );
}
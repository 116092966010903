import { RefreshOutlined, Search, SearchOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import React from 'react'
import { useDispatch } from 'react-redux';
import { loadConversations } from '../ConversationSlice';

const useStyles = makeStyles(theme => ({
    panel: {
        display: "flex",
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center'
    },
    searchbar: {
        padding: theme.spacing(1, 0),
        width: '90%',
        marginRight: '5px'
    },
    refreshButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid lightgray',
        borderRadius: '4px',
        height: '37px',
        width: '10%'
    }
}))

const Header = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <div className={classes.panel}>
            <div className={classes.searchbar}>
                <OutlinedInput
                    fullWidth
                    placeholder={t("Search") + "..."}
                    margin="dense"
                    //onChange={handleSearchChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    }
                />
            </div>
            <div className={classes.refreshButton}>
                <IconButton size="medium" color="primary" title="Refresh" onClick={() => dispatch(loadConversations())}>
                    <RefreshOutlined />
                </IconButton>
            </div>
        </div>
    )
}

export default Header
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import Environment from "./Environment";
import { acceptChat, addActiveChat, addTransferredChat, addWaitingChat, endChat, handleDashboardActiveChat, handleDashboardTransferredChat, handlePendingChat, loadChats, removeActiveChat, removeTransferredChat, removeWaitingChat, setAgentId, updateCustomerMessage } from "./features/chat/ChatSlice";
import { LogInformation, checkUserPermission } from "./helpers/Common";
import { Providers } from "@microsoft/mgt";

var connection = null;
var groups = [];

const onUserNotification = (data, storeApi) => {
    LogInformation("user", data)
    if (data.type === "message") {
        storeApi.dispatch(updateCustomerMessage(data.message));
    } else if (data.type === "end") {
        storeApi.dispatch(endChat(data.end));
    } else if (data.type === "transferToAgent") {
        storeApi.dispatch(addTransferredChat(data?.transferToAgent));
    } else if (data.type === "transferRejected") {
        storeApi.dispatch(addActiveChat(data?.transferRejected));
    } else if (data.type === "timedout") {
        storeApi.dispatch(removeTransferredChat(data?.timedOut));
    }
}

const onGroupNotification = (data, storeApi) => {
    LogInformation("group", data)
    if (data.type === "waiting") {
        storeApi.dispatch(addWaitingChat(data?.waiting));
    } else if (data.type === "accepted") {
        storeApi.dispatch(acceptChat(data?.accepted));
    } else if (data.type === "transferToQueue") {
        storeApi.dispatch(removeWaitingChat(data?.transferToQueue));
    } else if (data.type === "end") {
        storeApi.dispatch(endChat(data.end));
    }
}

const onDashboardNotification = (data, storeApi) => {
    LogInformation("dashboard", data)
    if (data.type === "pending") {
        storeApi.dispatch(handlePendingChat(data?.pending));
    } else if (data.type === "accepted") {
        storeApi.dispatch(handleDashboardActiveChat(data?.accepted));
    } else if (data.type === "transferToAgent") {
        storeApi.dispatch(handleDashboardTransferredChat(data?.transferToAgent));
    } else if (data.type === "transferToQueue") {
        storeApi.dispatch(removeActiveChat(data?.transferToQueue));
    } else if (data.type === "end") {
        storeApi.dispatch(handleDashboardActiveChat(data?.end));
    }
}

const joinGroups = (connection, storeApi) => {
    //Add to groups
    connection.invoke('JoinGroups', groups);

    storeApi.dispatch(loadChats());
};

const startChatConnection = (connection, storeApi) => connection.start()
    .then(() => {
        //Join to groups
        console.log("chat connection started");
        joinGroups(connection, storeApi);
    })
    .catch(err => {
        console.error('Chat Connection Error: ', err);
        setTimeout(() => startChatConnection(connection, storeApi), 10000);
    });

export const conversationConnection = (storeAPI) => {
    try {
        const { user } = storeAPI.getState().auth;

        storeAPI.dispatch(setAgentId(user.userId));

        var chatPermissions = checkUserPermission(user.permissions, ["conversation.chat"])
        var dashboardPermissions = user.permissions.includes("conversation.dashboardread");
        var chatEnabled = user.chatEnabled

        if (!chatEnabled || !chatPermissions) {
            return;
        }

        if (dashboardPermissions) {
            //assign supervisor to dashboard related groups
            groups.push(`${user.tenantId}-pending-chat-dashboard`)
        }

        if (Array.isArray(user.queues)) {
            user.queues.forEach((queue) => {
                if (queue.optIn) {
                    groups.push(queue.queueId);

                    if (dashboardPermissions) {
                        groups.push(`${queue.queueId}-active-chat-dashboard`)
                        groups.push(`${queue.queueId}-transfer-chat-dashboard`)
                        groups.push(`${queue.queueId}-wrapup-end-chat-dashboard`)
                    }
                }
            })
        }

        let hubUrl = Environment.config.MESSAGE_HUB_URL;

        connection = new HubConnectionBuilder()
            .withUrl(hubUrl, { accessTokenFactory: async () => await Providers.globalProvider.getAccessToken([]) })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        connection.on('user', data => onUserNotification(data, storeAPI));
        connection.on('group', data => onGroupNotification(data, storeAPI));
        connection.on('dashboard', data => onDashboardNotification(data, storeAPI));

        connection.onreconnected(connectionId => {
            joinGroups(connection, storeAPI);
        });

        connection.onclose(error => {
            if (error) {
                const message = `Chat connection closed due to error "${error}". Try refreshing the page to restart the connection.`;
                console.error(message);
            }
        });

        startChatConnection(connection, storeAPI);
    } catch (error) {
        console.log("Chat is not loaded. Please contact CentrePal ", error)
    }
}

export const sendAgentMessage = async (data) => {
    if (connection !== null) {
        await connection.invoke("SendAgentMessage", data);
    }
}

export const resetNotifications = async (id) => {
    if (connection !== null) {
        await connection.invoke("MarkAsRead", id);
    }
}


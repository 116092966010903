import { Check, CloseOutlined } from '@mui/icons-material'
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React, { useState } from 'react'
import AutoCompleteExtended from '../../../../components/Inputs/AutoCompleteExtended';
import Input from '../../../../components/Inputs/Input';
import { t } from 'i18next';
import { CallService } from '../../../../services/CallService';
import { styled } from '@mui/styles';
import { ConversationOperation } from '../../../../helpers/Constant';
import { ConversationService } from '../../../../services/ConversationService';
import { HandleServerResponse } from '../../../../helpers/Common';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentStatus, UpdateStatus } from '../../ConversationSlice';

const CompleteDialog = ({ open, setOpenMarkCompleteDialog, queueId }) => {
    const ButtonContainer = styled('div')(({ theme }) => ({
        width: '100%',
        height: '40px',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center'
    }));

    const [values, setValues] = useState({ note: "", outcomeId: "" });
    const [loading, setLoading] = useState(false);
    const [outcomes, setOutcomes] = useState([]);
    const conversation = useSelector((state) => state.conversation.current);

    const dispatch = useDispatch();

    React.useEffect(() => {
        let active = true;
        (async () => {

            setLoading(true);
            var result = await CallService.GetOutcomesByQueue(queueId, false);

            if (result.success) {
                setOutcomes(result.data?.outcomes)
            }
            setLoading(false);

        })();

        return () => { active = false; }
    }, [])

    const handleClose = () => {
        setOpenMarkCompleteDialog(false);
    };

    const handleAutoComplete = (event) => {
        setValues(x => ({ ...x, [event.target.name]: event.target.value?.id ?? "", dirty: true }));
    }

    const findObjectById = (items, id) => {
        let item = items.find(x => x.id === id);

        if (item !== undefined) {
            return item;
        }

        return null;
    }

    const handleChange = (event) => {
        event.preventDefault();
        setValues(x => ({ ...x, [event.target.name]: event.target.value, dirty: true }));
    }

    var handleCompleteSubmit = async () => {
        let params = {
            Id: conversation?.id,
            ConversationType: 1,
            ConversationOperation: ConversationOperation.Complete,
            Note: {
                Outcome: values.outcomeId,
                Summary: values.note
            }
        }

        setLoading(true)
        var result = await ConversationService.HandleConversation(params);
        HandleServerResponse(result, "Conversation successfully marked as complete", dispatch);

        if (result.success) {
            HandleServerResponse(result, "Conversation successfully marked as complete", dispatch);
            setOpenMarkCompleteDialog(false);
            dispatch(updateCurrentStatus(conversation));
        }
        setLoading(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>
                Mark as Complete
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 5,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <AutoCompleteExtended
                    paddingRight={0}
                    full={true}
                    multiple={false}
                    disabled={loading}
                    value={findObjectById(outcomes, values.outcomeId)}
                    onChange={(e) => handleAutoComplete(e)}
                    options={outcomes}
                    label="Outcome"
                    name="outcomeId"
                />
                <Input
                    paddingRight={0}
                    multiline={true}
                    disable={loading}
                    size="large"
                    name='note'
                    label={t("Summary")}
                    onChange={handleChange}
                    value={values.note}
                />
                <ButtonContainer>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Check />}
                        disabled={loading}
                        onClick={handleCompleteSubmit}
                    >
                        Complete
                    </Button>
                </ButtonContainer>
            </DialogContent>
        </Dialog>
    )
}

export default CompleteDialog
import { useState } from 'react';

export default function UseForm(initialValues, validateOnChange = false, validate) {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const handleInputChanges = (e, integerValue = false) => {
        let { name, value } = e.target;

        if (integerValue) {
            value = value.replace('.', '');
        }

        setValues({ ...values, [name]: value })

        if (validateOnChange) {
            validate({ [name]: value })
        }
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChanges
    }
}
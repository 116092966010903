import React from "react";
import { makeStyles, useTheme } from '@mui/styles';
import { Button } from "@mui/material";
import { Close, Refresh } from "@mui/icons-material";
import FancyDropdown from "../../../../components/Inputs/FancyDropdown";

const useStyles = makeStyles(theme => ({
    dropdown: {
        height: '32px',
    },
    loading:{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%' 
    },
    actions:{
        display: 'flex',
        alignItems: "center",
    }
}));

const Actions = ({data, changeDashboard, reload, close, loading}) => {
    const classes = useStyles();
    const theme = useTheme();
    const styles = {
        button:{
            height: 32,
            ml: theme.spacing(1)
        },
        dropdown:{
            marginTop: theme.spacing(0),
            marginBottom:theme.spacing(0),
        }
    }

    return <div className={classes.actions}>
        {
            (data.initialLoad && !data.error) &&
            <>
                <FancyDropdown
                    items={data.dashboards.map(dashboard => ({ id: dashboard.id, value: dashboard.name }))}
                    selected={data.selected}
                    onChange={changeDashboard}
                    loading={loading}
                />
                <Button color={theme.palette.mode === "light" ? 'black' : "white"} variant="outlined" size="small" sx={styles.button} onClick={reload}>
                    <Refresh />
                </Button>
            </>
        }
        <Button color={theme.palette.mode === "light" ? 'black' : "white"} variant="outlined" size="small" sx={styles.button} onClick={close} >
            <Close />
        </Button>
    </div>
}

export default Actions;

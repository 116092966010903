import { Plugin, ButtonView } from 'ckeditor5';

const messageTemplate ='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M14 2H4c-1.1 0-2 .9-2 2v10h2V4h10zm4 4H8c-1.1 0-2 .9-2 2v10h2V8h10zm2 4h-8c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2m0 10h-8v-8h8z" fill="#6264a7"></path></svg>'

export default class MessageTemplatePlugin extends Plugin {
    constructor(editor) {
        super(editor);
        this.callback = null;
    }

    setCallback(callback) {
        this.callback = callback;
    }

    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add('messageTemplate', locale => {
            const buttonView = new ButtonView(locale);

            buttonView.set({
                label: 'Message Template',
                withText: false,
                tooltip: true,
                icon: messageTemplate
            });

            buttonView.on('execute', (e) => {
                this.callback()
            });

            return buttonView;       
        });
    }
}
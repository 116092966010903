import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Typography, Box } from '@mui/material';
import { Call, CallReceived, CallEnd, CallMissed, AddIcCall, Block, Timer } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import GroupBox from '../../../components/GroupBox';
import { UserService } from '../../../services/UserService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
        width: '150px',
        flexGrow: 0,
        '& .caption': {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '2px',
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
                marginRight: '2px',
            },
        },
        '& h1.answered': {
            color: theme.palette.info.main,
        },
        '& h1.abandon': {
            color: theme.palette.error.main,
        },
        '& h1.inacall': {
            color: theme.palette.success.main,
        },
        '& h1.waiting': {
            color: theme.palette.warning.main,
        },
        '& h1.decline': {
            color: theme.palette.text.disabled,
        },
        '& h1.wrapup': {
            color: theme.palette.info.main,
        },
        '& h1.dropcall': {
            color: theme.palette.grey.darkest,
        },
    },
}));

export default function AgentSummary() {
    const classes = useStyles();
    const { userStats } = useSelector((state) => state.interaction);
    const { t } = useTranslation();

    const icons = {
        total: <CallReceived />,
        answered: <CallEnd />,
        abandon: <CallMissed />,
        inacall: <Call />,
        waiting: <AddIcCall />,
        decline: <Block />,
        wrapup: <Timer />,
        dropcall: <CallMissed />,
    };

    const items = [
        { key: "total", name: t("Attempts"), count: userStats.inviteCalls, type: "count" },
        { key: "inacall", name: t("Answered"), count: userStats.activeCalls, type: "count" },
        { key: "abandon", name: t("Abandoned Ringing"), count: userStats.failedCalls, type: "count" },
        { key: "dropcall", name: t("Call Drops"), count: userStats.shortCalls, type: "count" },
        { key: "inacall", name: t("Total Consult"), count: userStats.activeTime, type: "total" },
        { key: "wrapup", name: t("Total Wrap Up"), count: userStats.wrapupTime, type: "total" },
        { key: "inacall", name: t("Avg Consult"), count: userStats.activeTime, type: "avgConsult" },
        { key: "wrapup", name: t("Avg Wrap Up"), count: userStats.wrapupTime, type: "avgWrapup" }
    ];

    const getIcon = (key) => {
        return icons[key];
    }

    const formatSecsToMinute = (value, type) => {
        if (type === 'avgConsult') {
            let val = userStats.activeCalls > 0 ? userStats.activeCalls : 1
            value = value / val;
        }
        if (type === 'avgWrapup') {
            let val = userStats.wrapups > 0 ? userStats.wrapups : 1
            value = value / val;
        }

        let hours = Math.floor(value / 3600);
        let minutes = Math.floor((value - hours * 3600) / 60);
        let seconds = value - minutes * 60;


        seconds = seconds <= 9 ? `0${Math.round(seconds)}` : Math.round(seconds) > 59 ? 59 : Math.round(seconds);

        if (hours > 0) {
            minutes = minutes <= 9 ? `0${Math.round(minutes)}` : Math.round(minutes);
            return `${hours.toString().slice(0, 2)}:${minutes.toString().slice(0, 2)}:${seconds.toString().slice(0, 2)}`;
        } else {
            minutes = Math.round(minutes)
            return `${minutes.toString().slice(0, 2)}:${seconds.toString().slice(0, 2)}`;
        }
    }

    return (
        <React.Fragment>
            {
                items.map((item, index) => (
                    <Box className={classes.item} textAlign="center" key={index}>
                        <Typography variant="h1" classes={{ "h1": item.key }}>{item.type === "count" ? item.count : formatSecsToMinute(item.count, item.type)}</Typography>
                        <Divider light variant="middle" />
                        <div className="caption" display="flex">
                            {getIcon(item.key)}
                            <Typography variant="caption" component="div" display="inline">{item.name}</Typography>
                        </div>
                    </Box>
                ))
            }
        </React.Fragment>
    );
}
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Header from './current/Header';
import Composer from './current/Composer';
import { MessageList } from 'react-chat-elements';
import { ChatParty, ChatStatus, ConversationType } from '../../helpers/Constant';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import GroupBox from '../../components/GroupBox';
import PartyAndDateTime from './current/PartyAndDateTime';
import FormSummary from './current/FormSummary';
import { useTheme } from '@mui/styles';
import ChatSummary from './current/ChatSummary';
import { addNewMessage, loadConversation } from '../conversation/ConversationSlice';
import { NewGuid } from '../../helpers/Common';
import { resetNotifications, sendAgentMessage } from '../../SignalRMiddleware';

const useStyles = makeStyles(theme => ({
    chatList: {
        position: 'absolute',
        top: '50px',
        bottom: '50px',
        left: 0,
        right: 0,
        width: '100%',
    }
}));

const CurrentChat = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { currentChat } = useSelector((state) => state.conversation);
    const { user: { tenantId, permissions } } = useSelector((state) => state.auth);
    const [chat, setChat] = React.useState(null);
    const ref = React.useRef();

    React.useEffect(() => {
        if (currentChat?.id) {
            dispatch(loadConversation(currentChat, ConversationType.Chat));
        }
    }, [currentChat?.id])

    React.useEffect(() => {
        if (currentChat?.id) {
            setChat((prev) => {
                let selected = { ...currentChat };
                selected.messages = formatMessages({ ...selected });
                return selected;
            })
        }
    }, [currentChat?.id, currentChat?.messages, currentChat?.status])

    React.useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }

        //reset read flag true for new messages of current chat
        if (currentChat?.status === ChatStatus.Active) {
            (async () => {
                await resetNotifications({id: currentChat?.id, tenantId: tenantId});
            })();
        }
    }, [currentChat?.messages]);

    const formatMessages = (chatData) => {
        if (chatData?.messages === null) {
            return [];
        }
        let formattedMessages = [];

        if (chatData?.submitedForm ?? false) {

            formattedMessages.push({
                position: "left",
                type: "text",
                text: <FormSummary data={chatData.submitedForm} />,
                notch: false,
                updated: true,
                className: theme.palette.mode === "light" ? 'form-data-box' : 'form-data-box-dark'
            })
        }

        for (let index = 0; index < chatData?.messages?.length; index++) {
            let message = { ...chatData?.messages[index] };

            let formatted = formatMessage(message, chatData?.bot, chatData?.customer)
            formattedMessages.push(formatted);
        }
        return formattedMessages;
    }

    const formatMessage = (message, bot, customer) => {
        let leftorRight = 'left';
        let title = bot ?? "Bot";

        if (message?.party === ChatParty.Customer) {
            title = customer ?? "Customer";
        } else if (message?.party === ChatParty.Agent) {
            title = "You";
            leftorRight = 'right';
        }

        if (message.isSummary) {
            return {
                position: leftorRight,
                type: "text",
                title: <PartyAndDateTime title={title} time={message.time} />,
                text: <ChatSummary message={message?.message} />,
                notch: false,
            }
        }

        return {
            position: leftorRight,
            type: "text",
            title: <PartyAndDateTime title={title} time={message.time} />,
            text: message?.message,
            notch: false,
            className: theme.palette.mode === "dark" ? 'message-box-dark' : ''
        }
    }

    const sendMessage = (message) => {
        if (message.length < 256) {
            (async () => {
                let params = {
                    id: chat.id,
                    tenantId: tenantId,
                    message: message
                }
                await sendAgentMessage(params);

                let newMessage = {
                    id: chat.id,
                    type: ConversationType.Chat,
                    notifications: 0,
                    message: {
                        id: NewGuid(),
                        message: message,
                        time: new Date().toUTCString(), 
                        party: ChatParty.Agent,
                        read: true
                    }
                }
                dispatch(addNewMessage(newMessage));
            })();
        }
    }

    if (currentChat !== null) {
        return (
            <GroupBox
                smallBodyPadding={true}
                showDivider={false}
                sx={{ background: theme.palette.mode === "light" ? '#f6f6f7' : '', height: '55%', position: 'relative' }}
            >
                <Header
                    conversationType={currentChat?.type}
                    id={chat?.id}
                    customer={chat?.customer}
                    status={chat?.status}
                    queueId={currentChat?.queueId}
                    actioned={currentChat?.actioned}
                />
                <div className={classes.chatList}>
                    <MessageList
                        referance={ref}
                        toBottomHeight={"100%"}
                        className='message-list'
                        dataSource={chat?.messages ?? []}
                    />
                </div>
                {/* TODO typing indicator */}
                <Composer send={sendMessage} status={chat?.status} permissions={permissions} />
            </GroupBox>
        );
    }
}

export default CurrentChat;
import React, { Suspense } from "react";
import { CommunicationType } from './../../helpers/Constant';
import CallDetails from "../calls/CallDetails";
import Home from "./Home";
import Loading from "../../components/Loading";
import lazyLoad from "../../LazyLoad";
import Conversation from "../conversation/Conversation";

const Setting = lazyLoad(() => import('../settings/Setting'));
const CallsHistory = lazyLoad(() => import('../history/CallsHistory'));
const ConversationHistory = lazyLoad(() => import('../history/ConversationHistory'));
const Reports = lazyLoad(() => import('../reports/Reports'));
const AttendentConsole = lazyLoad(() => import('../attendent-console/AttendentConsole'));
const Interaction = lazyLoad(() => import('../dashboard/Interaction'));
const Agent = lazyLoad(() => import('../dashboard/Agent'));


const Communication = props => {
    let component = null;
    switch (props.type) {
        case CommunicationType.attendentConsole:
            component = <Suspense fallback={<Loading />}><AttendentConsole {...props} /></Suspense>;
            break;

        case CommunicationType.home:
            component = <Home {...props} />;
            break;

        case CommunicationType.setting:
            component = <Suspense fallback={<Loading />}><Setting {...props} /></Suspense>;
            break;

        case CommunicationType.agentDashboard:
            component = <Suspense fallback={<Loading />}><Agent /></Suspense>;
            break;

        case CommunicationType.callDashboard:
            component = component = <Suspense fallback={<Loading />}><Interaction /></Suspense>;
            break;

        case CommunicationType.callHistory:
            component = <Suspense fallback={<Loading />}><CallsHistory /></Suspense>;
            break;

        case CommunicationType.conversationHistory:
            component = <Suspense fallback={<Loading />}><ConversationHistory /></Suspense>;
            break;

        case CommunicationType.invitecall:
        case CommunicationType.waitingcall:
        case CommunicationType.activecall:
        case CommunicationType.completecall:
            component = <CallDetails {...props} />;
            break;

        case CommunicationType.call:
            component = <CallDetails {...props} />;
            break;

        case CommunicationType.report:
            component = component = <Suspense fallback={<Loading />}><Reports {...props} /></Suspense>;
            break;

        case CommunicationType.conversations:
            component = <Suspense fallback={<Loading />}><Conversation {...props} /></Suspense>;
            break;

        default:
            break;
    }

    return (
        <React.Fragment>
            {component}
        </React.Fragment>
    );
};

export default Communication;
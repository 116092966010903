import React from 'react';
import { Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TodoItem from './TodoItem';
import NewTodo from './NewTodo';
import { UserService } from '../../../services/UserService';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '36vh',
    },
    longRoot: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    todoHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 4,
        marginBottom: 4,
    },
    todoInfo:{
        display: 'flex', 
        flexDirection: 'column', 
        marginRight: 4,
        overflowY: 'auto',
        minHeight: '30vh'
    },
    longTodoInfo:{
        display: 'flex', 
        flexDirection: 'column', 
        marginRight: 4,
        height: '100%',
        overflowY: 'auto',
    }
}));

const ToDos = ({islong=false}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [todos, setTodos] = React.useState([]);
    const [openNewTodoDialog, setOpenNewTodoDialog] = React.useState(false);

    const loadTodos = () => {
        (async () => {
            let result = await UserService.getAllTodos();
            if (result.success && result.data !== null) {
                setTodos(result.data)
            }
        })();
    }

    const handleOpenAdd = () => {
        setOpenNewTodoDialog(true);
    }

    const handleOpenClose = (added) => {
        if (added !== undefined) {
            loadTodos();
        }
        setOpenNewTodoDialog(false);
    }

    React.useEffect(() => {
        loadTodos();
    }, [])

    return (
        <div className={islong ? classes.longRoot : classes.root}>
            <div className={classes.todoHeader}>
                <Typography variant="body2">{t("Tasks")}</Typography>
                <Button startIcon={<Add/>} onClick={handleOpenAdd} variant="contained" color="primary" >{t("New")}</Button>
            </div>
            <div className={islong ? classes.longTodoInfo : classes.todoInfo}>
                {
                    todos.map((todo) => (
                        <TodoItem key={todo.id} todo={todo} loadData={loadTodos} />
                    )) 
                }
            </div>
            { openNewTodoDialog && <NewTodo open={openNewTodoDialog} handleClose={handleOpenClose} /> }
        </div>
    )
}

export default ToDos
import { Checkbox, Grow, MenuItem, MenuList, Paper, Popper, Typography, useTheme } from '@mui/material'
import React from 'react'
import { setLocalStorage } from '../../../../helpers/Common';

const CallDirectionSelector = ({ openDirection, anchorRef, callDirections, onChange }) => {
    const theme = useTheme();

    const styles = {
        paper: {
            width: '200px',
            padding: '10px'
        },
        popper: {
            width: 185,
            marginLeft: 30,
            pr: theme.spacing(2)
        },
        menuItem: {
            height: 30,
            padding: theme.spacing(0),
            minHeight: 0,
            display: 'flex',
            alignItems: 'center'
        },
        checkBox: {
            '& .MuiSvgIcon-root': {
                fontSize: 20
            },
            padding: 0
        },
        text: {
            marginLeft: '10px'
        }
    }

    const handleChange = (item, index) => {
        item.checked = !item.checked
        callDirections[index] = item
        onChange([...callDirections]);

        setLocalStorage('cc-leaderboard-directions', [...callDirections]);
    }

    return (
        <React.Fragment>
            <Popper sx={styles.popper} open={openDirection} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper sx={styles.paper}>
                            <MenuList id="queue-button-menu" >
                                {
                                    callDirections.map((item, index) => (
                                        <MenuItem
                                            key={item.id}
                                            sx={styles.menuItem}
                                            onClick={() => handleChange(item, index)}
                                        >
                                            <Checkbox
                                                checked={item?.checked ?? false}
                                                sx={styles.checkBox}
                                            />
                                            <Typography variant='body2' sx={styles.text}>{item.name}</Typography>
                                        </MenuItem>
                                    ))
                                }
                            </MenuList>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}

export default CallDirectionSelector
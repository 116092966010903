import { CircularProgress } from '@mui/material'
import React from 'react'

function ChatSummary({message}) {
    if(message === ''){
        return <CircularProgress />
    }

    return message
}

export default ChatSummary
import React from "react";
import {TextField} from "@mui/material";

export default function Input(props){
    const {name, label, error=null, value = "", onChange, onEnter, size, disable, required=false, multiline=false, multilineRows=3, type="text", paddingRight=16, keyUp=false, id=null, ...other} = props;
    let inputWidth = '50%';
    if(size === 'small'){
        inputWidth = '30%'
    }
    else if(size === 'large'){
        inputWidth = '100%'
    } 
    else if(size === '45%'){
        inputWidth = '45%'
    }
    else if(size === '35%'){
        inputWidth = '35%'
    }
    else if(size === '20%'){
        inputWidth = '20%'
    }

    return(
        <TextField
            id={id}
            type={type}
            required={required}
            rows={multilineRows}
            multiline={multiline}
            disabled={disable || false}
            style={{width: inputWidth, paddingRight: paddingRight}}
            label= {label}
            name={name}
            value= {value}
            onChange={onChange}
            onKeyUp={(e) => { 
                if (e.key === "Enter" && keyUp) { 
                    onEnter(e.target.value);
                }
            }}
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            {...(error && {error:true, helperText: error})}
            {...other}
        />
    )
}
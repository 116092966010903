import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react'

const AiEditOrReviewOptions = [
    {
        id: 0,
        name: 'Improve writing'
    },
    {
        id: 1,
        name: 'Make shorter'
    },
    {
        id: 2,
        name: 'Make longer'
    },
    {
        id: 3,
        name: 'Simplify Language'
    },
];

const AiGenerativeOptions = [
    {
        id: 4,
        name: 'Summarize'
    },
    {
        id: 5,
        name: 'Continue'
    },
]

const AiChangeToneOptions = [
    {
        id: 6,
        name: 'Professional'
    },
    {
        id: 7,
        name: 'Casual'
    },
    {
        id: 8,
        name: 'Direct'
    },
    {
        id: 9,
        name: 'Confident'
    },
    {
        id: 10,
        name: 'Friendly'
    },
];

const AiMenu = ({ anchorEl, open, handleClose, selectedAiOption, setSelectedAiOption }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: 80 * 4.5,
                    width: '240px',
                },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Typography variant='body1' color='primary' style={{ padding: '8px 16px' }}>Edit or review</Typography>
            {AiEditOrReviewOptions.map((option, index) => (
                <MenuItem
                    key={index}
                    onClick={() => {
                        setSelectedAiOption(option);
                        handleClose();
                    }}
                >
                    <Typography variant='body2'>{option.name}</Typography>
                </MenuItem>
            ))}
            <Divider />
            <Typography variant='body1' color='primary' style={{ padding: '8px 16px' }}>Generate from selection</Typography>
            {AiGenerativeOptions.map((option, index) => (
                <MenuItem
                    key={index}
                    onClick={() => {
                        setSelectedAiOption(option);
                        handleClose();
                    }}
                >
                    <Typography variant='body2'>{option.name}</Typography>
                </MenuItem>
            ))}
            <Divider />
            <Typography variant='body1' color='primary' style={{ padding: '8px 16px' }}>Change tone</Typography>
            {AiChangeToneOptions.map((option, index) => (
                <MenuItem
                    key={index}
                    onClick={() => {
                        setSelectedAiOption(option);
                        handleClose();
                    }}
                >
                    <Typography variant='body2'>{option.name}</Typography>
                </MenuItem>
            ))}
        </Menu>
    )
}

export default AiMenu
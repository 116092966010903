import axios from 'axios'

const validateApps = async () => {
    const { data } = await axios.post('/api/validateapplications').then(result => result);
    return data;
};

const register = async (values) => {
    const { data } = await axios.post('/api/register', values).then(result => result);
    return data;
};

const getRegisterRefernces = async (values) => {
    const { data } = await axios.post(`/api/registerreference`, values).then(result => result);
    return data;
};


const MarketPlaceExistingCustomer = async (values) => {
    const { data } = await axios.post(`/api/marketplaceexistingcustomer`, values).then(result => result);
    return data;
};

export const RegisterService = {
    validateApps,
    register,
    getRegisterRefernces,
    MarketPlaceExistingCustomer,
};
import React from 'react'
import "../styles/index.css"
import { Button, Divider, IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChatStatus, ChannelType, ConversationType } from '../../../helpers/Constant';
import { CallMade, CancelOutlined, ChatBubbleOutline, CheckCircleOutline, LowPriority, MoreVert } from '@mui/icons-material';
import { ChatService } from '../../../services/ChatService';
import { HandleServerResponse } from '../../../helpers/Common';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChatNote from './ChatNote';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { removeConversation, updateCurrentStatus } from '../../conversation/ConversationSlice';
import UserOrQueueSelectDialog from '../../conversation/components/dialogs/UserOrQueueSelectDialog';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
        height: theme.spacing(3.8),
    },
    customerName: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '45%',
    },
    moreActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '50%'
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    iconText: {
        fontSize: 12
    },
    waiting: {
        color: theme.palette.error.main,
    },
    active: {
        color: theme.palette.success.main,
    },
    transferred: {
        color: theme.palette.warning.main,
    },
    complete: {
        color: theme.palette.grey.dark,
    },
    menuItemDiv: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    availabilityText: {
        marginLeft: theme.spacing(2.5),
    }
}));

const Header = ({ conversationType, id, customer, status, queueId, actioned }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    const [openSummary, setOpenSummary] = React.useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [openQueueTransfer, setOpenQueueTransfer] = React.useState(false);
    const [openUserTransfer, setOpenUserTransfer] = React.useState(false);
    const [openCancelRequest, setOpenCancelRequest] = React.useState(false);

    const styles = {
        customerTypography: {
            marginLeft: theme.spacing(1),
            fontWeight: 600,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    }

    const iconColor = () => {
        switch (status) {
            case ChatStatus.Waiting:
                return classes.waiting;
            case ChatStatus.Active:
                return classes.active;
            case ChatStatus.ActiveAgentTransferred:
                return classes.transferred;
            case ChatStatus.Completed:
                return classes.complete;
            default:
                return classes.waiting;
        }
    }

    const icon = (type) => {
        const className = iconColor();
        switch (type) {
            case ChannelType.WebChat:
                return <ChatBubbleOutline fontSize='small' className={className} />
            default:
                return <ChatBubbleOutline fontSize='small' className={className} />
        }
    }

    const chatAction = () => {
        (async () => {
            if (status === ChatStatus.Waiting || status === ChatStatus.ActiveAgentTransferred) {
                var result = await ChatService.AcceptChat(id);
                if (!result.success) {
                    HandleServerResponse(result, t("Chat no longer exists."), dispatch); //TODO apply proper warning message
                }
            } else if (status === ChatStatus.Active) {
                let result = await ChatService.EndChat(id);
                if (result.success) {
                    let obj = {
                        id: id,
                        type: ConversationType.Chat
                    }
                    dispatch(updateCurrentStatus(obj));
                }
                HandleServerResponse(result, t("Chat complete request was sent successfully."), dispatch);
            }
            else if (status === ChatStatus.Completed) {
                setOpenSummary(true);
            }
        })();
    }

    const confirmCancel = () => {
        (async () => {
            let result = await ChatService.RejectChat(id);
            HandleServerResponse(result, t("Chat rejected successfully."), dispatch);
            if (result.success) {
                let prop = {
                    id: id,
                    type: ConversationType.Chat,
                }
                dispatch(removeConversation(prop));
            }
        })();
        closePopup();
    }

    const btnName = () => {
        switch (status) {
            case ChatStatus.Waiting:
            case ChatStatus.ActiveAgentTransferred:
                return t("Accept");
            case ChatStatus.Active:
                return t("End");
            case ChatStatus.Completed:
                return t("Close");
            default:
                return t("Accept");
        }
    }

    const btnIcon = () => {
        switch (status) {
            case ChatStatus.Waiting:
            case ChatStatus.ActiveAgentTransferred:
                return <CheckCircleOutline />;
            case ChatStatus.Active:
            case ChatStatus.Completed:
                return <CancelOutlined />
        }
    }

    const closePopup = () => {
        setOpenSummary(false);
        setOpenUserTransfer(false);
        setOpenQueueTransfer(false);
        setOpenCancelRequest(false);
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.customerName}>
                    {icon()}
                    <Typography sx={styles.customerTypography} variant="body2">{customer}</Typography>
                </div>

                <div className={(status === ChatStatus.Waiting || status === ChatStatus.Active) ? classes.moreActions : classes.actions}>
                    {!actioned &&
                        <Button
                            sx={{ width: 'auto', height: '26px' }}
                            onClick={chatAction}
                            startIcon={btnIcon()}
                            variant="contained"
                            color={status === ChatStatus.Completed ? "grey" : "primary"}
                            disableElevation
                        >
                            {btnName()}
                        </Button>
                    }
                    {
                        (status === ChatStatus.Waiting || status === ChatStatus.Active || status === ChatStatus.ActiveAgentTransferred) &&
                        <React.Fragment>
                            <IconButton
                                onClick={(e) => { setMenuAnchorEl(e.currentTarget) }}
                                size="small"
                                title="Chat Actions"
                                aria-label="Chat Actions" >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="chat-actions"
                                anchorEl={menuAnchorEl}
                                keepMounted
                                open={Boolean(menuAnchorEl)}
                                onClose={() => setMenuAnchorEl(null)}
                                className={classes.menuStyles}
                            >
                                {
                                    (status === ChatStatus.Waiting || status === ChatStatus.Active) &&
                                    <MenuItem onClick={() => { setMenuAnchorEl(null); setOpenQueueTransfer(true); }}>
                                        <div className={classes.menuItemDiv}>
                                            <LowPriority color="primary" fontSize="small" />
                                            <p className={classes.availabilityText} >{t("Transfer to Queue")}</p>
                                        </div>
                                    </MenuItem>
                                }
                                {
                                    status === ChatStatus.Active &&
                                    <MenuItem onClick={() => { setMenuAnchorEl(null); setOpenUserTransfer(true); }}>
                                        <div className={classes.menuItemDiv}>
                                            <CallMade color="primary" fontSize="small" />
                                            <p className={classes.availabilityText} >{t("Transfer to User")}</p>
                                        </div>
                                    </MenuItem>
                                }
                                {
                                    status === ChatStatus.ActiveAgentTransferred &&
                                    <MenuItem onClick={() => { setMenuAnchorEl(null); setOpenCancelRequest(true); }}>
                                        <div className={classes.menuItemDiv}>
                                            <CancelOutlined color="primary" fontSize="small" />
                                            <p className={classes.availabilityText} >{t("Reject")}</p>
                                        </div>
                                    </MenuItem>
                                }
                            </Menu>
                        </React.Fragment>
                    }
                </div>
            </div>
            <Divider />
            {openSummary && <ChatNote handleClose={closePopup} chatId={id} open={openSummary} />}
            {openQueueTransfer && <UserOrQueueSelectDialog handleClose={closePopup} conversationType={conversationType} conversationId={id} queueId={queueId} isActive={false} open={openQueueTransfer} type={"QueueTransfer"} />}
            {openUserTransfer && <UserOrQueueSelectDialog handleClose={closePopup}conversationType={conversationType} conversationId={id} queueId={queueId} searchable open={openUserTransfer} type={"AgentTransfer"} />}
            {openCancelRequest && <ConfirmationDialog handleClose={closePopup} open={openCancelRequest} confirmChange={confirmCancel} message={"Are you sure you want to cancel this request?"} />}
        </>
    )
}

export default Header
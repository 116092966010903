import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import cx from 'clsx';
import { formatToLongDate } from '../../../helpers/Common';

import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    header:{
        display: 'flex', 
        alignItems: 'center'
    },
    status: {
        width: 60,
        height: 20,
        borderRadius: 4,
        color: 'white',
        textAlign: 'center',
        fontSize: 9,
        paddingTop: theme.spacing(0.5),
        fontWeight: 600,
    },
    new: {
        backgroundColor: theme.palette.success.main
    },
    improved: {
        backgroundColor: theme.palette.info.main,
    },
    fixed: {
        backgroundColor: theme.palette.error.main
    },
}));

const ShowMore = ({ open, closeDialog, type, subject, description, releaseDate }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        root: {
            '& .MuiDialogContent-root':{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'space-around',
                gap: theme.spacing(1),
            }
        },
        releaseDate: {
            marginLeft: theme.spacing(1),
        },
        subject:{
            fontWeight: 600,
        },
        actions: {
            margin: theme.spacing(0, 1, 1, 1),
            '& > .MuiButtonBase-root': {
                padding: theme.spacing(0.5, 2, 0.5, 2),
            },
        },
    }

    return (
        <Dialog sx={styles.root} open={open} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
            <DialogContent>
                <div className={classes.header}>
                    <div className={cx(classes.status, classes[type.toLowerCase()])}>{t(type.toUpperCase())}</div>
                    <Typography sx={styles.releaseDate} variant="caption">{formatToLongDate(releaseDate)}</Typography>
                </div>
                <Typography sx={styles.subject} variant="body2">{subject}</Typography>
                <Typography variant="body2">{description}</Typography>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button
                    onClick={closeDialog}
                    startIcon={<Close />}
                    variant="contained"
                    color="grey"
                    disableElevation>{t("Close")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShowMore;
import { ProviderState, Providers, SimpleProvider } from "@microsoft/mgt-element"
import { app } from "@microsoft/teams-js";
import { authentication } from "@microsoft/teams-js";

export default class TeamsAuthProvider extends SimpleProvider { 
    constructor() { 
        super();
    }

    login() { 
        Providers.globalProvider.setState(ProviderState.SignedIn);
    }

    logout() { 
        Providers.globalProvider.setState(ProviderState.SignedOut);
    }

    async getAccessToken() { 
        return await authentication.getAuthToken();
    }

    async getActiveAccount() { 
        const context = await app.getContext()
        return {
            id: `${context?.user?.id}.${context?.user?.tenant?.id}`, //userId.tenantId
            mail: context?.user?.userPrincipalName,
            name: context?.user?.displayName ?? "",
            tenantId: context?.user?.tenant?.id,
        }
    }
}

import React from "react";
import { TextField, FormControl } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoComplte({ options, label, full, name, onChange, onClick = null, multiple = false, error = null, defaultValue, disabled = false, required = false, id=null }) {
    let inputWidth = '50%';

    if (full) {
        inputWidth = '100%'
    }

    const convertToDefEventParam = (event, selected, name) => {
        event.persist();
        let value = null;

        if (!multiple) {
            value = selected
        } else {
            value = [...selected]
        }

        return {
            target: {
                name, value
            }
        }
    }

    const changeValue = (event, values) => {
        if (onClick !== null && event !== null) {
            event.preventDefault();
            onClick(values);
        }
    }

    return (
        <FormControl style={{ width: inputWidth, margin: 0, paddingBottom: 0 }}>
            <Autocomplete
                key={options.length ?? label}
                multiple={multiple}
                defaultValue={defaultValue}
                // value={defaultValue}
                disabled={disabled}
                id={id}
                size="small"
                // freeSolo
                options={options === null ? [] : options}
                onChange={(event, newValue) => onChange(convertToDefEventParam(event, newValue, name))}
                onInputChange={(event, newInputValue) => changeValue(event, newInputValue)}
                getOptionLabel={(option) => option.name === undefined ? "" : option.name}
                renderOption={(props, option) => (
                    <li id={`autocomplete-id-${option.id}`} {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                renderInput={(params) => (
                    <TextField
                        required={required}
                        style={{ width: '100%', paddingRight: 0 }}
                        {...params}
                        variant="outlined"
                        label={label}
                        {...(error && { error: true, helperText: error })}
                    />
                )}
            />
        </FormControl>
    )
}
import axios from 'axios'

const get = async (id=null) => {
    const { data } = await axios.get(`/api/tenant/${id}`).then(result => result);
    return data;
}

const update = async (values) => {
    const { data } = await axios.put('/api/tenant/settings',values).then(result => result);
    return data;
}

const getHubText = async (id=null) => {
    const { data } = await axios.get(`/api/tenant/hubtext`).then(result => result);
    return data;
}

const updateHubText = async (values) => {
    const { data } = await axios.post('/api/tenant/updatehubtext',values).then(result => result);
    return data;
}

const getAppIds = async (id=null) => {
    const { data } = await axios.get(`/api/tenant/getclientids`).then(result => result);
    return data;
}

const getApplicationUsers = async (keyword) => {
    const { data } = await axios.get(`/api/tenant/applicationusers/search/${keyword}`).then(result => result);
    return data;
}

const getMailboxAccounts = async (keyword, values) => {
    const { data } = await axios.get(`/api/tenant/applicationusers/mailboxes/search/${keyword}`, {params: values}).then(result => result);
    return data;
}

const getReleaseNotes = async () => {
    const { data } = await axios.get(`/api/tenant/releasenotes`).then(result => result);
    return data;
}

export const TenantService = {
    get,
    update,
    getHubText,
    updateHubText,
    getAppIds,
    getApplicationUsers,
    getReleaseNotes,
    getMailboxAccounts
};
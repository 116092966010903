import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GroupContainer from '../../components/GroupContainer';
import { Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { changeUserDetails } from './AuthSlice';
import { UserService } from '../../services/UserService';
import { Close, Done } from '@mui/icons-material';
import { HandleServerResponse } from '../../helpers/Common';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menuItemDiv: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginRight: 5,
        width: 270,
    },
}));

export default function AssignQueues(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        itemName: {
            width: 190,
        },
        actions: {
            margin: theme.spacing(1),
        },
    }

    const dispatch = useDispatch();

    const [queueList, setQueueList] = React.useState(props.user.queues);

    const handleChange = (queue) => {
        let queueListArr = [...queueList];
        let queueObj = { ...queue };

        queueObj.optIn = !queue.optIn;

        var foundIndex = queueListArr.findIndex(x => x.queueId === queue.queueId);
        queueListArr[foundIndex] = queueObj;

        setQueueList(queueListArr);
    }

    const saveChanges = (e) => {
        e.preventDefault();

        // update redux state
        let userObj = { ...props.user };
        userObj.queues = [...queueList];

        (async () => {

            //data to update API
            let userQueues = [];

            queueList.forEach(item => {
                let dataObj = {
                    QueueId: item.queueId,
                    OptIn: item.optIn
                }
                userQueues.push(dataObj);
            })

            let updatedList = {
                UserQueues: userQueues
            }

            let result = await UserService.setAssignQueues(updatedList);
            HandleServerResponse(result, t("Your assigned queues changed successfully."), dispatch);
            if (result.success) {
                dispatch(changeUserDetails(userObj));
            }
        })();

        props.closeDialog();
    }

    React.useEffect(() => {
        (async () => {
            let result = await UserService.getUserQueuesWithName();
            HandleServerResponse(result, "", dispatch);
            if (result.success) {
                setQueueList(result.data);
            }
        })();
    }, [dispatch])

    return (
        <Dialog open={props.open} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
            <DialogTitle id="form-dialog-title">{t("Assign Queues")}</DialogTitle>
            <DialogContent>
                <GroupContainer>
                    {
                        (queueList?.length ?? 0) > 0 ?
                            queueList.map((queue) => (
                                <div className={classes.menuItemDiv} key={queue.queueId}>
                                    <Typography variant="body2" sx={styles.itemName}>{queue.name}</Typography>
                                    <Switch
                                        // disabled={!props.writePermission}
                                        checked={queue.optIn}
                                        onChange={() => handleChange(queue)}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </div>
                            ))
                            : t("You don't have any queues assigned.")
                    }
                </GroupContainer>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                {
                    props.writePermission &&
                    <Button onClick={(e) => saveChanges(e)} startIcon={<Done />}
                        variant="contained" color="primary" disableElevation>{t("Change")}</Button>
                }
                <Button
                    onClick={props.closeDialog}
                    startIcon={<Close />}
                    variant="contained"
                    color="grey"
                    disableElevation>{t("Cancel")}</Button>
            </DialogActions>
        </Dialog>
    );
}
import { CircularProgress } from "@mui/material";
import React from "react";
import Embed from "../compoents/Embed";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    loading:{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%' 
    },
}));

const Content = ({ loading, selected }) => {
    const classes = useStyles();

    if (loading) {
        return <div className={classes.loading}><CircularProgress /></div>
    }

    return <Embed
        {...selected}
    />
}

export default Content;
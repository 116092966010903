import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { makeStyles, useTheme } from "@mui/styles"
import { AccessTime, CheckCircle, HighlightOff, RemoveCircle } from '@mui/icons-material';
import { UserAvailability } from '../../helpers/Constant';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  menuItemDiv: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  availabilityText: {
    marginLeft: 20,
  }
}));

export default function AvailabilityDropDown(props) {
  const classes = useStyles();
  const theme = useTheme();

  const styles = {
    statusBusy: {
      color: theme.palette.error.main,
    },
    statusAvailable: {
      color: theme.palette.success.main,
    },
    statusAway: {
      color: theme.palette.warning.main,
    },
    offline: {
      color: theme.palette.grey,
    },
    parentWidth: {
      width: 250,
    },
    btnLabel: {
      width: '95%',
    },
    btnAction: {
      width: '5%',
    }
  }
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);

  const handleToggle = () => {
    if (props.supervisor) {
      let modalHeight = user.userAvailabilityList.length > 0 ? user.userAvailabilityList.length * 46 : 50
      props.handleModalWidth(modalHeight);
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (props.supervisor) {
      props.handleModalWidth(50);
    }
    setOpen(false);
  };

  const handleAvailabilityIcon = (name) => {
    switch (name) {
      case UserAvailability.workOffline:
        return <RemoveCircle fontSize="small" sx={styles.statusBusy} />
      case UserAvailability.available:
        return <CheckCircle fontSize="small" sx={styles.statusAvailable} />
      case UserAvailability.onBreak:
      case UserAvailability.onBreakCustom1:
      case UserAvailability.onBreakCustom2:
        return <AccessTime fontSize="small" sx={styles.statusAway} />
      case UserAvailability.offline:
      case UserAvailability.workOfflineCustom1:
      case UserAvailability.workOfflineCustom2:
        return <HighlightOff fontSize="small" sx={styles.offline} />
      default:
        break;
    }
  }

  const handleStatusChange = (e, value) => {
    handleClose(e)
    props.handleAvailablity(value)
  }

  const getSelectedStatus = (status) => {
    let selectedValue = user.userAvailabilityList.find(item => item.availability === status);
    return selectedValue !== undefined ? selectedValue.name : status;
  }

  return (
    <div>
      <ButtonGroup color={theme.palette.mode === "light" ? 'black' : "white"} variant='outlined' sx={styles.parentWidth} ref={anchorRef} aria-label="split button">
        <Button
          sx={styles.btnLabel}
          startIcon={handleAvailabilityIcon(props.selectedValue)}>
          {t(getSelectedStatus(props.selectedValue))}
        </Button>
        <Button
          sx={styles.btnAction}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={props.disabled || false}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition sx={{ zIndex: theme.zIndex.appBar - 1 }} disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" sx={styles.parentWidth} >
                  {
                    user.userAvailabilityList.map((value, index) => (
                      <MenuItem
                        key={index}
                        value={value.key}
                        onClick={(e) => handleStatusChange(e, value)}
                      >
                        <div className={classes.menuItemDiv}>
                          {handleAvailabilityIcon(value.availability)}
                          <p className={classes.availabilityText} >{t(value.name)} </p>
                        </div>
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { OutlinedInput, InputAdornment, Link, Skeleton } from '@mui/material';
import { useDispatch } from "react-redux"
import { Block, CheckCircleOutline, Search } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { formatToShortDateTime, handleDeepLink, HandleServerResponse, isValidGUID, LogInformation } from '../../helpers/Common';
import { CallService } from '../../services/CallService';
import { useTranslation } from 'react-i18next';
import MuiDataGrid from '../../components/MuiDataGrid';

const useStyles = makeStyles(theme => ({
    searchbar: {
        padding: theme.spacing(1, 0),
    },
    gridRoot: {
        '& .MuiDataGrid-iconSeparator': {
            fontSize: 0
        },
    },
    container: {
        width: '100%',
    },
    itemRoot: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    itemSpace: {
        marginRight: 8
    },
    missedCallIcon: {
        color: theme.palette.error.main,
        marginRight: 8,
    },
    answredIncomingIcon: {
        color: theme.palette.success.main,
        marginRight: 8,
    },
    answredOutgoingIcon: {
        color: theme.palette.info.main,
        marginRight: 8,
    },
    menuItemDiv: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    availabilityText: {
        marginLeft: 20,
    },
    timeRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    panel: {
        display: 'flex',
        flexDirection: 'column',
        height: '95%',
    },
}));

const styles = {
    linkBlackTheme: {
        color: "#1aebff",
        textDecorationColor: "#1aebff",
        cursor: "pointer"
    },
    link: {
        cursor: "pointer"
    }
}

const UserNameCell = ({ id }) => {
    const theme = useTheme();
    const [upn, setUpn] = React.useState(null);

    React.useEffect(() => {
        let isCancelled = false;

        (async () => {
            try {
                const result = await CallService.GetInternalUserDisplayName(id);
                if (!isCancelled && result.success) {
                    setUpn(result.data);
                }
            } catch (error) {
                LogInformation("Error fetching display name:", error)
            }
        })();

        return () => {
            isCancelled = true;
        };
    }, [id]);

    let url = `https://teams.cloud.microsoft.com/l/call/0/0?users=${upn}`;

    if (!upn) {
        return (
            <div style={{ width: '100%' }}>
                <Skeleton variant="text" width='100%' sx={{ fontSize: '1.2rem' }} />
            </div>
        )
    }

    return (
        <Link sx={theme.palette.mode === "dark" ? styles.linkBlackTheme : styles.link} onClick={() => handleDeepLink(url)} target="_blank">
            {upn}
        </Link>
    );
};

export default function Callback({ setCount }) {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [page, setPage] = React.useState(0);
    const [sort, setSort] = React.useState({ field: "ScheduledTime", sort: "asc" });
    const [search, setSearch] = React.useState('')
    const [pageSize] = React.useState(12);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [callList, setCallList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [changed, setChanged] = React.useState(false);

    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }

    const handlePageChange = (params) => {
        setPage(params);
    };

    const handleSorting = (params) => {
        if (params.length > 0) {
            setSort(params[0]);
        }
    }

    const handleCallbackAccept = (id) => {
        (async () => {
            let result = await CallService.CallbackAccept(id);
            LogInformation("CallService.CallbackAccept", result);
            HandleServerResponse(result, t("Callback Accepted"), dispatch);
            if (result.success) {
                setChanged((prev) => !prev);
            }
        })();
    }

    const handleCallbackReject = (id) => {
        (async () => {
            let result = await CallService.CallbackReject(id);
            LogInformation("CallService.CallbackReject", result);
            HandleServerResponse(result, t("Callback Rejected"), dispatch);
            if (result.success) {
                setChanged((prev) => !prev);
            }
        })()
    }

    React.useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);

            let params = { PageNumber: page + 1, RowCount: pageSize, SortFiled: sort.field, SortOrder: sort.sort, SearchValue: search };

            let result = await CallService.CallbackList(params);
            HandleServerResponse(result, "", dispatch);

            if (result.success && active) {
                setTotalRecords(result.pagination.totalRecords);
                setCallList(result.data);
                setCount(result.pagination.totalRecords);
            }

            if (!active) { return; }

            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [page, sort, search, pageSize, dispatch, changed]);

    const colums = [
        { field: "id", hide: true },
        { field: 'queueName', headerName: t('Queue'), flex: 1, sortable: false },
        {
            field: "callBackNumber",
            headerName: t('Contact'),
            flex: 1,
            renderCell: (params) => {
                if (isValidGUID(params.row.callBackNumber)) {
                    return <UserNameCell id={params.row.callBackNumber} />
                } else {
                    let encodedPhoneOrUser = encodeURIComponent(params.row.callBackNumber).replace('+', '%20');
                    let url = `https://teams.cloud.microsoft.com/l/call/0/0?users=,4:${encodedPhoneOrUser}`;

                    return (
                        <Link sx={theme.palette.mode === "dark" ? styles.linkBlackTheme : styles.link} onClick={() => handleDeepLink(url)} target="_blank">
                            {params.row.callBackNumber}
                        </Link>)
                }
            }
        },
        {
            field: "scheduledTime",
            headerName: t('Time'),
            sortable: false,
            flex: 1,
            renderCell: (params) => <div>{formatToShortDateTime(params.row.scheduledTime)}</div>
        },
        {
            field: 'actions',
            type: 'actions',
            width: 10,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<CheckCircleOutline color="primary" fontSize="small" />}
                    label="Accept"
                    onClick={() => handleCallbackAccept(params.row.id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<Block color="primary" fontSize="small" />}
                    label="Reject"
                    onClick={() => handleCallbackReject(params.row.id)}
                    showInMenu
                />,
            ],
        },
    ]

    return (
        <div className={classes.panel}>
            <div className={classes.searchbar}>
                <OutlinedInput
                    fullWidth
                    placeholder={t("Search") + "..."}
                    margin="dense"
                    onChange={handleSearchChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    }
                />
            </div>
            <MuiDataGrid
                loading={loading}
                rows={callList}
                columns={colums}
                onPageChange={handlePageChange}
                onSortModelChange={handleSorting}
                pageSize={pageSize}
                page={page}
                rowCount={totalRecords}
                hideColumns={{
                    id: false,
                }}
            />
        </div>
    );
}
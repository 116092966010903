import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    modalWidth: {
        width: 500
    },
    smallModal: {
        width: 250
    },
    actions: {
        margin: theme.spacing(1),
    },
}));

export default function SurveyDialog({ open, surveys, handleClose, selectSurvey, small=false }) {
    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const [isSurveySelected, setIsSurveySelected] = React.useState(true)

    const handleMusicChange = (event, params) => {
        event.persist();
        if (params === null) {
            setValue('');
            setIsSurveySelected(false);
        } else {
            setValue(params.id);
            setIsSurveySelected(true);
        }
    }

    const handleSurvey = () => {
        if (value === '' || value === undefined) {
            setIsSurveySelected(false)
        }else{
            selectSurvey(value)
        }
    }

    return (
        <div>
            <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth={"md"}>
                <DialogTitle id="form-dialog-title">Select a survey</DialogTitle>
                <DialogContent>
                    <div className={small ? classes.smallModal :classes.modalWidth}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={surveys}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => handleMusicChange(event, newValue)}
                            renderInput={(params) => <TextField {...params} label="Survey List" variant="outlined" />}
                        />
                    </div>
                    {!isSurveySelected && <Typography color="error" variant="body2">{`Please select a survey to play`}</Typography>}
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={handleSurvey} variant="contained" color="primary" disableElevation>
                        Play
                    </Button>
                    <Button onClick={handleClose} variant="contained" color="grey" disableElevation>
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Tabs, Tab, Divider, Button } from '@mui/material';
import CallSummary from './CallSummary';
import AgentSummary from './AgentSummary';
import GroupBox from '../../../components/GroupBox';
import { Queue, Close, Refresh } from '@mui/icons-material';
import TabPanel from '../../../components/TabPanel';
import WbQueues from './WbQueues';
import { useDispatch, useSelector } from 'react-redux';
import { loadCalls, loadQueueStats, loadUserAvailability } from '../../app/InteractionSlice';
import { useTheme } from '@mui/styles';
import DoughnutChart from '../../../components/chart/DoughnutChart';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Leaderboard from './Leaderboard';
import { QueueService } from '../../../services/QueueService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    iconLabelWrapper: {
        flexDirection: "row",
        '& :first-of-type': {
            paddingRight: useTheme().spacing(0.5),
            marginBottom: "0!important",
        },
    },
}));

export default function WallBoard(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        root: {
            minHeight: '100vh',
        },
        item1: {
            padding: theme.spacing(1),
        },
        item2: {
            padding: theme.spacing(1, 1, 1, 0),
            display: 'flex',
            flexWrap: 'wrap',
        },
        gropBox: {
            width: '100%',
            position: 'relative',
        },
        close: {
            position: "absolute",
            top: 0,
            right: 0,
            margin: theme.spacing(1, 2, 0, 0),
        },
        reload: {
            position: "absolute",
            top: 0,
            right: 0,
            margin: theme.spacing(1, 11, 0, 0),
        },
        tabs: {
            minHeight: 'unset',
            '& .MuiTab-root': {
                minHeight: 'unset',
                padding: theme.spacing(0.5, 1),
            },
        },
        iconLabelWrapper: {
            flexDirection: "row",
            '& :first-of-type': {
                paddingRight: theme.spacing(0.5),
                marginBottom: "0!important",
            },
        },
    }

    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [reload, setReload] = useState(false);
    const [queues, setQueues] = useState([]);

    const { waitingCalls, activeCalls, queueStats } = useSelector((state) => state.interaction);

    let stats = {
        calls: 0,
        answeredCalls: 0,
        abandonedCalls: 0,
        declinedCalls: 0,
    }

    Object.keys(queueStats).map(key => {
        stats.calls += queueStats[key].tot_calls;
        stats.answeredCalls += queueStats[key].tot_answere + queueStats[key].tot_transfer;
        stats.abandonedCalls += queueStats[key].tot_abandon + queueStats[key].tot_short_abandon;
        stats.declinedCalls += queueStats[key].tot_decline;
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const reloadQueueStats = () => {
        if (value === 0) {
            dispatch(loadQueueStats());
            dispatch(loadUserAvailability());
            dispatch(loadCalls());
        } else {
            setReload((prev) => !prev)
        }
    }

    React.useEffect(() => { 
        (async () => {
            var result = await QueueService.getWallboardQueueData();
            setQueues(result?.data ?? []);
        })();
    },[])

    return (
        <Grid container sx={styles.root}>
            <Grid item sm={12} md={3} sx={styles.item1}>
                <DoughnutChart
                    id="wallboard-sla"
                    labels={["Answered", "Abandoned", "Declined"]}
                    values={
                        [
                            (stats.answeredCalls === 0 && stats.abandonedCalls === 0 && stats.declinedCalls === 0) ? 1 : stats.answeredCalls,
                            stats.abandonedCalls,
                            stats.declinedCalls
                        ]
                    }
                    total={stats.calls}
                    // showCenter={true}
                    bgColors={
                        [
                            theme.palette.info.main,
                            theme.palette.error.main,
                            theme.palette.grey.darkest,
                        ]
                    }
                />
                <CallSummary
                    {...stats}
                    waitingCalls={waitingCalls.length}
                    activeCalls={activeCalls.length}
                />
                <AgentSummary />
            </Grid>
            <Grid item sm={12} md={9} sx={styles.item2}>
                <GroupBox sx={styles.gropBox}>
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        sx={styles.tabs}
                        value={value}
                        onChange={handleChange}
                    >
                        <Tab
                            icon={<Queue />}
                            iconPosition="start"
                            label={t("Queues")}
                            disableRipple
                            classes={{ wrapper: classes.iconLabelWrapper }}
                            id="wb-tab-queues" aria-controls="wb-queues"
                        />
                        <Tab
                            icon={<LeaderboardIcon />}
                            iconPosition="start"
                            label={t("Leaderboard")}
                            disableRipple
                            classes={{ wrapper: classes.iconLabelWrapper }}
                            id="wb-tab-leaderboard" aria-controls="wb-leaderboard"
                        />
                    </Tabs>
                    <Button color={theme.palette.mode === "light" ? 'black' : "white"} variant="outlined" size="small" sx={styles.reload} onClick={reloadQueueStats}>
                        <Refresh />
                    </Button>
                    <Button color={theme.palette.mode === "light" ? 'black' : "white"} variant="outlined" size="small" sx={styles.close} onClick={props.closeWallboard} >
                        <Close />
                    </Button>
                    <Divider light />
                    <TabPanel id="wb-queues" aria-labelledby="wb-tab-queues" value={value} index={0}>
                        <WbQueues queueStats={queueStats} queues={queues} />
                    </TabPanel>
                    <TabPanel id="wb-leaderboard" aria-labelledby="wb-tab-leaderboard" value={value} index={1}>
                        <Leaderboard
                            reload={reload}
                        />
                    </TabPanel>
                </GroupBox>
            </Grid>
        </Grid>
    );
}
import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HandleServerResponse, LogInformation } from '../../helpers/Common';
import {useDispatch} from "react-redux";
import {CallService} from "../../services/CallService";

const useStyles = makeStyles(theme => ({
    modalWidth: {
        width: 500
    },
    smallModal: {
        width: 250
    },
    actions: {
        margin: theme.spacing(1),
    },
}));

export default function AnnouncementAudio({ chainId, open, handleOpenClose, audios, referenceId, small=false }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState('');
    const [isMusicSelected, setIsMusicSelected] = React.useState(true)

    const handleMusicChange = (event, params) => {
        event.persist();
        if (params === null) {
            setValue('');
            setIsMusicSelected(false);
        } else {
            setValue(params.id);
            setIsMusicSelected(true);
        }
    }

    const handlePlayMusic = () => {
        if (value === '' || value === undefined) {
            setIsMusicSelected(false)
        }else{
            (async() => {
                //Set the ReferenceId
                let params = { ReferenceId: referenceId ,MediaGroupId: value, ChainId: chainId }
                let result = await CallService.PlayAnnouncment(params);

                LogInformation("CallService.PlayAnnouncment",result)
                HandleServerResponse(result, "Announcement audio is playing", dispatch);
            })();

            handleOpenClose();
        }
    }

    return (
        <div>
            <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth={"md"}>
                <DialogTitle id="form-dialog-title">Select an announcement audio to play</DialogTitle>
                <DialogContent>
                    <div className={small ? classes.smallModal :classes.modalWidth}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={audios}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => handleMusicChange(event, newValue)}
                            renderInput={(params) => <TextField {...params} label="Audio List" variant="outlined" />}
                        />
                    </div>
                    {!isMusicSelected && <Typography color="error" variant="body2">{`Please select an audio to play`}</Typography>}
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={handlePlayMusic} variant="contained" color="primary" disableElevation>
                        Play
                    </Button>
                    <Button onClick={handleOpenClose} variant="contained" color="grey" disableElevation>
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}
import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HandleServerResponse } from '../../../../helpers/Common';
import { ConversationOperation, ConversationType } from '../../../../helpers/Constant';
import { ConversationService } from '../../../../services/ConversationService';
import { UserService } from '../../../../services/UserService';
import { QueueService } from '../../../../services/QueueService';
import { removeConversation } from '../../ConversationSlice';
import { ChatService } from '../../../../services/ChatService';

const useStyles = makeStyles(theme => ({
    modalWidth: {
        width: 500
    },
    smallModal: {
        width: 250
    },
}));

const UserOrQueueSelectDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { open, type, conversationType, conversationId, queueId, small = false } = props;
    const [isItemSelected, setIsItemSelected] = useState(true)
    const [selectedItem, setSelectedItem] = useState({ id: '', name: '' });
    const [searchItems, setSearchItems] = useState([]);
    const [queues, setqueues] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const styles = {
        actions: {
            margin: theme.spacing(1),
        },
    }

    React.useEffect(() => {
        if (type === "QueueTransfer") {
            LoadQueues();
        }
    }, [])

    const LoadQueues = async () => {
        let active = true;

        (async () => {
            setLoading(true);
            //load queues excluding the current queue from the API
            let queueList = await QueueService.getValidQueues(queueId);
            HandleServerResponse(queueList, "", dispatch);
            if (queueList.success && active) {
                setqueues(queueList.data)
            }

            if (!active) { return; }
            setLoading(false);
        })();
        return () => { active = false; };
    }

    const handleTransfer = async () => {
        if (selectedItem === '' || selectedItem === undefined) {
            setIsItemSelected(false)
        } else {
            if (type === "AgentTransfer") {
                var values = {
                    Id: conversationId,
                    ConversationType: conversationType,
                    TransferUserId: selectedItem.id,
                    ConversationOperation: ConversationOperation.TransferToUser
                };

                let result = conversationType == ConversationType.Email ? await ConversationService.HandleConversation(values) : await ChatService.TransferToAgent(conversationId, selectedItem.id);;
                HandleServerResponse(result, t("Conversation assign request to user was sent successfully."), dispatch);
                if (result.success) {           
                    let prop = {
                        id: conversationId,
                        type: conversationType,
                    }
                    dispatch(removeConversation(prop));
                    props.handleClose()
                }
                else {
                    HandleServerResponse(result, t("Active conversation is already offered to the same user"), dispatch);
                }

            } else if (type === "QueueTransfer") {
                if(conversationType == ConversationType.Email){
                    var values = {
                        Id: conversationId,
                        ConversationType: conversationType,
                        TransferQueueId: selectedItem.id,
                        ConversationOperation: ConversationOperation.TransferToQueue
                    };
                } else if (conversationType == ConversationType.Chat) {
                    var params = { Id: conversationId, TransferQueueId: selectedItem.id };
                }

                let result = conversationType == ConversationType.Email ? await ConversationService.HandleConversation(values) : await ChatService.TransferToQueue(params);
                HandleServerResponse(result, "Conversation transferred to queue successfully.", dispatch);
                if (result.success) {
                    let prop = {
                        id: conversationId,
                        type: conversationType,
                    }
                    dispatch(removeConversation(prop));
                }
                props.handleClose()
            }
        }
    }

    const handleItemChange = (event, value) => {
        event.persist();
        if (value === null) {
            setSelectedItem('');
            setIsItemSelected(false);
        } else {
            setSelectedItem({ id: value.id, name: value.name });
            setIsItemSelected(true)
        }
    }

    const handleSearchTextChange = async (event, value) => {
        if (value !== undefined && value.length >= 3) {
            setLoading(true);
            let params = { QueueId: queueId, Keyword: value };
            let response = await UserService.GetOptInUsersInQueues(params);
            if (Array.isArray(response?.data)) {
                setSearchItems(response.data);
            }
            setLoading(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{t(`Select ${type === "QueueTransfer" ? "a queue to transfer" : "a user to assign"}`)}</DialogTitle>
            <DialogContent>
                <div className={small ? classes.smallModal : classes.modalWidth}>
                    {(type === "AgentTransfer") && <Autocomplete
                        options={searchItems}
                        onChange={handleItemChange}
                        onInputChange={handleSearchTextChange}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t("Agent List")}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }} />}
                    />}
                    {(type === "QueueTransfer") && <Autocomplete
                        options={queues}
                        disabled={loading}
                        getOptionLabel={(option) => option.name}
                        onChange={handleItemChange}
                        renderInput={(params) => <TextField {...params} label={type === "QueueTransfer" ? t("Queue List") : t("User List")} variant="outlined" />}
                    />}
                </div>
                {!isItemSelected && <Typography color="error" variant="body2">{t(`Please select ${type === 'QueueTransfer' ? 'a queue to transfer this conversation' : 'a user to re assign'}`)}</Typography>}
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button onClick={handleTransfer} variant="contained" color="primary" disableElevation>
                    {type === "QueueTransfer" ? t("Transfer") : t("Re-assign")}
                </Button>
                <Button onClick={props.handleClose} variant="contained" color="grey" disableElevation>
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserOrQueueSelectDialog
import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    branding: {
        display: 'flex',
        marginLeft: 4,
        alignItems: 'center',
    },
    img: {
        width: 20,
        height: 20,
        marginRight: 8,
    },
}));

const Branding = () => {
    const classes = useStyles();
    return <div className={classes.branding}>
        <img className={classes.img} src={window.location.origin + '/logo32.png'} alt="CentrePal" />
        <Typography>CentrePal Contact Centre</Typography>
    </div>
}

export default Branding;
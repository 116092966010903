import React from "react";
import { makeStyles } from '@mui/styles';
import GroupBox from "../../components/GroupBox";
import { IconButton, Typography } from "@mui/material";
import { Masonry } from "@mui/lab";
import ToDos from '../widgets/todos/ToDos';
import Hub from '../widgets/Hub';
import { useSelector } from "react-redux";
import FeatureLimit from "./FeatureLimit";
import ControlPanel from "../controlpanel/ControlPanel";
import DialPad from "../widgets/Dialpad";
import ReleaseNote from "../widgets/release-note/ReleaseNote";
import { MoreVert } from "@mui/icons-material";
import ManageWidgets from "../widgets/ManageWidgets";
import { LicenseType, Widgets } from "../../helpers/Constant";
import AgentStatus from "../widgets/AgentStatus";
import CallStatus from "../widgets/CallStatus";
import EmergencyRedirectionOn from "./EmergencyRedirectionOn";
import { useTranslation } from "react-i18next";
import Branding from "../../components/Branding";
import { checkLicense, checkUserPermission } from "../../helpers/Common";

const useStyles = makeStyles(theme => ({
    widget: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    homeHeader: {
        display: 'flex',
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
        // marginTop: theme.spacing(1),
    },
    widgetBtn: {
        marginRight: 4,
    },
    hub: {
        width: '100%',
        minHeight: '45vh',
        marginRight: 8,
        backgroundImage: theme.palette.mode === "light" ? "url('/centrepal.jpg')" : "",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    rightWidgetItem: {
        width: '100%',
    },
    leftWidgetItem: {
        width: '100%',
        marginRight: 8,
    },
    leftDialpad: {
        width: '100%',
        marginRight: 8,
    },
    rightDialpad: {
        width: '100%',
    },
    licenseError: {
        marginBottom: theme.spacing(1),
    }
}));

const dialpadPermissions = ["widget.dialpad"];
const agentStatusPermissions = ["widget.agentstatus"];
const callStatusPermissions = ["widget.callstatus"];

const Home = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { user: { widgets, emergencyMode, emergencyRedirectionTarget, permissions, assignedLicenses, showLicenseLimitAlert } } = useSelector((state) => state.auth);

    const dialpadAccess = checkUserPermission(permissions, dialpadPermissions);
    const agentStatusAccess = checkUserPermission(permissions, agentStatusPermissions);
    const callStatusAccess = checkUserPermission(permissions, callStatusPermissions);

    const voiceLicense = checkLicense(assignedLicenses, [LicenseType.Voice, LicenseType.VoicePlusDigital]);

    const [manageWidgets, setManageWidgets] = React.useState(false);
    const [userWidgets, setUserWidgets] = React.useState([]);

    const handleWidgets = (data) => {
        let list = [
            { key: Widgets.ToDo, value: false, data: null },
            { key: Widgets.ReleaseNote, value: false, data: null },
            { key: Widgets.Dialpad, value: false, data: null },
            { key: Widgets.AgentStatus, value: false, data: null },
            { key: Widgets.CallStatus, value: false, data: null },
        ]

        if (!dialpadAccess || !voiceLicense) {
            list = list.filter(x => x.key !== Widgets.Dialpad);
        }

        if (!agentStatusAccess || !voiceLicense) {
            list = list.filter(x => x.key !== Widgets.AgentStatus);
        }

        if (!callStatusAccess || !voiceLicense) {
            list = list.filter(x => x.key !== Widgets.CallStatus);
        }

        if (data) {
            var currentWidget = [...data];
            for (let i = 0; i < list.length; i++) {
                if (currentWidget[i]?.key === list[i].key) {
                    list[i].value = currentWidget[i].value;
                    list[i].data = currentWidget[i].data;
                }
            }
        }
        return list;
    }

    const getWidget = (name) => {
        switch (name) {
            case Widgets.ToDo:
                return <ToDos />;
            case Widgets.Dialpad:
                return <DialPad />;
            case Widgets.ReleaseNote:
                return <ReleaseNote />;
            default:
                break;
        }
    }

    const getClassName = (name, right = 0) => {
        switch (name) {
            case Widgets.ToDo:
            case Widgets.ReleaseNote:
            case Widgets.AgentStatus:
            case Widgets.CallStatus:
                return right === 0 ? classes.rightWidgetItem : classes.leftWidgetItem;
            case Widgets.Dialpad:
                return right === 0 ? classes.rightDialpad : classes.leftDialpad;
            default:
                break;
        }
    }

    var currentIndex = 0;

    const widgetHeight = {
        "To-Do": "45vh",
        "Dialpad": "40vh",
        "Release Note": 430, //Used fixed with as this is not expanding based on view port
        "Agent Status": "45vh",
        "Call Status": "45vh",
    }

    React.useEffect(() => {
        setUserWidgets(() => {
            return handleWidgets(widgets)
        });
    }, [widgets])

    return (
        < >
            <div className={classes.widget}>
                {/* {
                    showLicenseLimitAlert &&
                    <div className={classes.licenseError}><FeatureLimit /></div>
                } */}
                {
                    emergencyMode && <div className={classes.subscriptionError}><EmergencyRedirectionOn emergencyRedirectionTarget={emergencyRedirectionTarget} /></div>
                }
                <div className={classes.homeHeader}>
                    <Branding />
                    <IconButton onClick={() => setManageWidgets(true)} size="small" color="primary" title="add or remove widget(s)" aria-label="todo" component="span">
                        <MoreVert fontSize="small" />
                    </IconButton>
                </div>
                {
                    props.largeScreen &&
                    <Masonry columns={2} spacing={1}>
                        <GroupBox title={t("Hub")} gutter className={classes.hub}>
                            <Hub />
                        </GroupBox>
                        {
                            (userWidgets !== null) &&
                            userWidgets.map((widget) => {
                                if (widget.value) {
                                    let clsName = getClassName(widget.key, currentIndex % 2);
                                    currentIndex++

                                    if (widget.key === Widgets.AgentStatus)
                                        return <AgentStatus key={widget.key} name={[widget.key]} height={widgetHeight[widget.key]} cls={clsName} />

                                    if (widget.key === Widgets.CallStatus)
                                        return <CallStatus key={widget.key} name={[widget.key]} height={widgetHeight[widget.key]} cls={clsName} />

                                    return (
                                        <GroupBox key={widget.key} sx={{ minHeight: widgetHeight[widget.key] }} title={t(widget.key)} gutter className={clsName}>
                                            {getWidget(widget.key)}
                                        </GroupBox>
                                    )
                                }
                                return <React.Fragment key={widget.key}></React.Fragment>
                            })
                        }
                    </Masonry>
                }
                {!props.largeScreen && <ControlPanel largeScreen={props.largeScreen} />}
                {
                    manageWidgets &&
                    <ManageWidgets
                        open={manageWidgets}
                        closeDialog={() => setManageWidgets(false)}
                        userWidgets={userWidgets}
                    />
                }
            </div>
        </>
    )
}

export default Home;
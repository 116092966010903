import React from "react";
import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import WordIcon from "../../../components/customicons/WordIcon";
import PdfIcon from "../../../components/customicons/PdfIcon";
import FileIcon from "../../../components/customicons/FileIcon";
import PowerpointIcon from "../../../components/customicons/PowerpointIcon";
import ExcelIcon from "../../../components/customicons/ExcelIcon";
import { InsertPhotoOutlined, SmartDisplayOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateFiles } from "../ConversationSlice";

const DisplayFileList = ({ selectedFiles }) => {
    const Root = styled('div')(({ theme }) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        alignContent: 'center',
    }));

    const Content = styled('div')(({ theme }) => ({
        width: '100%',
        borderTop: '1px solid #0000001f',
        paddingTop: '10px',
        paddingBottom: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'end',
        overflow: 'hidden'
    }));

    const dispatch = useDispatch();

    const handleRemoveFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        dispatch(updateFiles(newFiles));
    };

    const getIconByFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'doc':
            case 'docx':
                return <WordIcon />;
            case 'pdf':
                return <PdfIcon />;
            case 'mp4':
            case 'wav':
            case 'avi':
            case 'mov':
                return <SmartDisplayOutlined color="primary" fontSize="small" style={{ marginRight: '4px' }} />;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'svg':
                return <InsertPhotoOutlined color="primary" fontSize="small" style={{ marginRight: '4px' }} />;
            case 'ppt':
            case 'pptx':
            case 'ppsx':
                return <PowerpointIcon />;
            case 'xls':
            case 'xlsx':
                return <ExcelIcon />;
            default:
                return <FileIcon />;
        }
    };

    return (
        <Root>
            {selectedFiles.length > 0 &&
                <Content>
                    {selectedFiles.map((file, index) => {
                        return (
                            <Box key={index} display="flex" alignItems="center" mr={2}>
                                {getIconByFileType(file.name)}
                                <Typography variant="caption" style={{ marginRight: '5px' }}>
                                    {file.name}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={() => handleRemoveFile(index)}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        );
                    })}
                </Content>
            }
        </Root>
    )
}

export default DisplayFileList;
import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonThreadItem = () => {
    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '120px' }}>
                <Skeleton variant="rounded" width={'100px'} height={'20px'} sx={{ mb: '10px', mr: '10px' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={"100%"} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={"60%"} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={"70%"} />
            </div>
            <div style={{ display: 'flex' }}>
                <Skeleton variant="rounded" width={'60px'} height={'20px'} sx={{ mt: '10px', mr: '10px' }} />
                <Skeleton variant="rounded" width={'60px'} height={'20px'} sx={{ mt: '10px', mr: '10px' }} />
            </div>
        </React.Fragment>
    )
}

export default SkeletonThreadItem
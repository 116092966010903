import React from 'react';
import { makeStyles } from '@mui/styles';
import { OutlinedInput, InputAdornment, Link } from '@mui/material';
import { useDispatch } from "react-redux"
import { CallMade, CallMissed, CallMissedOutgoing, CallReceived, Search } from '@mui/icons-material';
import { addOldCallTab } from '../auth/AuthSlice';
import { CallService } from '../../services/CallService';
import { CallDirection, CommunicationType } from '../../helpers/Constant';
import { formatToShortDateTime, HandleServerResponse } from '../../helpers/Common';
import { useTranslation } from 'react-i18next';
import MuiDataGrid from '../../components/MuiDataGrid';

const useStyles = makeStyles(theme => ({
    searchbar: {
        padding: theme.spacing(1, 0),
    },
    gridRoot: {
        '& .MuiDataGrid-iconSeparator': {
            fontSize: 0
        },
    },
    missedCallIcon: {
        color: theme.palette.error.main,
        marginRight: 8,
    },
    answredIncomingIcon: {
        color: theme.palette.success.main,
        marginRight: 8,
    },
    answredOutgoingIcon: {
        color: theme.palette.info.main,
        marginRight: 8,
    },
    panel: {
        display: 'flex',
        flexDirection: 'column',
        height: '95%',
    },
}));

export default function AgentCallHistory() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [page, setPage] = React.useState(1);
    const [sort, setSort] = React.useState({ field: "Start", sort: "desc" });
    const [search, setSearch] = React.useState('')
    const [pageSize] = React.useState(15);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [callList, setCallList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }

    const handlePageChange = (params) => {
        setPage(params + 1);
    };

    const handleSorting = (params) => {
        if (params.length > 0) {
            setSort(params[0]);
        }
    }

    const openCallTab = (id) => {
        (async () => {
            let result = await CallService.GetUserCallRecord(id);
            HandleServerResponse(result, "", dispatch);
            if (result.success) {
                let data = result.data;
                dispatch(addOldCallTab({ id: data.chainId, type: CommunicationType.call, name: data.source, close: true, data: data }))
            }
        })()
    }

    const getIcon = (call) => {
        if (call.inviteFailed > 0) {
            switch (call.direction) {
                case CallDirection.Incoming:
                    return <CallMissed fontSize="small" className={classes.missedCallIcon} /> // incoming missed
                case CallDirection.Outgoing:
                    return <CallMissedOutgoing fontSize="small" className={classes.missedCallIcon} /> // outgoing missed
                default:
                    break;
            }
        } else {
            switch (call.direction) {
                case CallDirection.Incoming:
                    return <CallReceived fontSize="small" className={classes.answredIncomingIcon} /> // incoming answered
                case CallDirection.Outgoing:
                    return <CallMade fontSize="small" className={classes.answredOutgoingIcon} /> // outgoing answered
                default:
                    break;
            }
        }
    }

    React.useEffect(() => {
        let active = true;
        (async () => {
            setLoading(true);

            let params = { PageNumber: page, RowCount: pageSize, SortFiled: sort.field, SortOrder: sort.sort, SearchValue: search }
            let result = await CallService.GetUserCallHitory(params);
            HandleServerResponse(result, "", dispatch);

            if (result.success && active) {
                setTotalRecords(result.pagination.totalRecords);
                setCallList(result.data);
            }

            if (!active) { return; }
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [page, sort, search, pageSize, dispatch]);


    const colums = [
        { field: "id", flex: 1, sortable: false },
        {
            field: "source",
            headerName: t('Contact'),
            flex: 3,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {getIcon(params.row)}
                    <Link onClick={() => openCallTab(params.row.callHistoryId)} href="#">
                        {params.row.inviteFailed > 0 ? `${params.row.source}(${params.row.inviteFailed})` : params.row.source}
                    </Link>
                </div>
            ),
        },
        { field: 'queue', headerName: t('Queue'), flex: 2, sortable: false },
        {
            field: "Start",
            headerName: t('Time'),
            sortable: true,
            flex: 2,
            renderCell: (params) => <div style={{ display: 'flex', flexDirection: 'column' }}> {formatToShortDateTime(params.row.start)}</div>
        },
    ]

    return (
        <div className={classes.panel}>
            <div className={classes.searchbar}>
                <OutlinedInput
                    fullWidth
                    placeholder={t("Search") + "..."}
                    margin="dense"
                    onChange={handleSearchChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    }
                />
            </div>
            <MuiDataGrid
                loading={loading}
                rows={callList}
                columns={colums}
                onPageChange={handlePageChange}
                onSortModelChange={handleSorting}
                pageSize={pageSize}
                rowCount={totalRecords}
                hideColumns={{
                    id: false,
                }}
            />
        </div>
    );
}
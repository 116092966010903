import React, { useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import {
    PersonAddRounded,
    CallEndRounded,
    VolumeUp,
    MoreVert,
    VolumeOff,
    MailOutline,
    PermPhoneMsg,
    PhoneForwarded,
    LowPriority,
    Block,
    MusicOff,
    MusicNote,
    Sms,
    CellTower,
    Person,
    PeopleAlt,
    ThumbsUpDown
} from '@mui/icons-material';
import CountUpTimer from '../../helpers/CountUpTimer'
import { CommunicationType, LicenseType } from './../../helpers/Constant';
import { CallService } from '../../services/CallService';
import { checkLicense, checkUserPermission, getLocalStorage, HandleServerResponse, LogInformation } from '../../helpers/Common';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmNumberDialog from './ConfirmNumberDialog';
import AnnouncementAudio from '../../components/call/AnnouncementAudio';
import InviteTransferDialog from '../../components/call/InviteTransferDialog';
import { MediaService } from '../../services/MediaService';
import SurveyDialog from './SurveyDialog';
import { QueueService } from '../../services/QueueService';
import { selectActiveCallTrasfer } from '../app/InteractionSlice';
import OutboundSmsDialog from './OutboundSmsDialog';
import BlockNumberDialog from '../../components/call/BlockNumberDialog';
import TransferToPstnDialog from '../../components/call/TransferToPstnDialog';


const useStyles = makeStyles(theme => ({
    availabilityText: {
        marginLeft: 20,
    },
}));

const blockedNumberAcceptPermissions = ['call.blocknumber'];

const CallOptions = props => {
    const classes = useStyles();
    const theme = useTheme();

    const styles = {
        iconSize: {
            fontSize: 30
        },
        timerStyles: {
            // marginTop: 8,
            marginLeft: theme.spacing(0),
            fontSize: 20,
            color: 'primary'
        },
        menuStyles: {
            marginTop: theme.spacing(0),
            marginRight: theme.spacing(0),
            '& .MuiPopover-paper': {
                minWidth: 200
            },
        },
        menuItem: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap"
        }
    }

    const dispatch = useDispatch();
    // const [mute, setMute] = useState(false);

    const { user: {permissions, smsIntegration, mobile, tenantId, assignedLicenses} } = useSelector((state) => state.auth);
    const callTransferring = useSelector(state => selectActiveCallTrasfer(state, props.chainId));
    const blockNUmberPermission = checkUserPermission(permissions, blockedNumberAcceptPermissions);

    const [openInvite, setOpenInvite] = useState(false);
    const [openQueueTransfer, setOpenQueueTransfer] = React.useState(false);
    const [openUserTransferPstn, setOpenUserTransferPstn] = React.useState(false);
    const [openTransfer, setOpenTransfer] = useState(false);
    // const [openConsultTransfer, setOpenConsultTransfer] = useState(false);
    const [announceAnchorEl, setAnnounceAnchorEl] = React.useState(null);
    const [announceAnchorE2, setAnnounceAnchorE2] = React.useState(null);
    const [openAnnouncement, setOpenAnnouncement] = React.useState(false);
    const [openMessage, setOpenMessage] = React.useState(false);
    const [loadInitialData, setLoadInitialData] = React.useState(false);
    const [announcementAudios, setAnnouncementAudios] = React.useState([]);
    const [callQueues, setCallQueues] = React.useState([]);
    const [callSurveys, setCallSurveys] = React.useState([]);
    const [openSurvey, setOpenSurvey] = React.useState(false);
    const [blockNumber, setBlockNumber] = React.useState(false);
    const [messageTemplate, setMessageTemplate] = React.useState(null);
    const [messageTemplateReferenceData, setMessageTemplateReferenceData] = React.useState(null);
    const [hasMessageTemplate, setHasMessageTemplate] = React.useState(false);

    const [transferCallUserOption1, setTransferCallUserOption1] = React.useState(null);
    const [transferCallUserOption2, setTransferCallUserOption2] = React.useState(null);

    // const handleMute = async () => {
    //     let params = { TenantId: tenantId, ChainId: props.chainId }
    //     if (mute) {
    //         let result = await CallService.Unmute(params);
    //         HandleServerResponse(result, `Unmuted`, dispatch);
    //         if(result.success){
    //             setMute(!mute);
    //         }
    //     } else {
    //         let result = await CallService.Mute(params);
    //         HandleServerResponse(result, `Muted`, dispatch);
    //         if(result.success){
    //             setMute(!mute);
    //         }
    //     }
    // }

    const handleCallEnd = async () => {
        let params = { TenantId: tenantId, ChainId: props.chainId }
        let result = await CallService.Hangup(params);

        LogInformation("CallService.Hangup", result);
        HandleServerResponse(result, "Call hangup request was sent successfully.", dispatch);
    }

    const handleInvite = () => {
        setOpenInvite(!openInvite)
    }

    //open more option dialog
    const handleOpenMoreOptions = (event) => {
        setAnnounceAnchorEl(event.currentTarget);
    };

    //close more option dialog
    const handleCloseMoreOptions = () => {
        setAnnounceAnchorEl(null);
        setAnnounceAnchorE2(null);
    }

    //close more option dialog in transfer user
    const handleCloseTransferCallQueueOptions = () => {
        setTransferCallUserOption1(null);
        setTransferCallUserOption2(null);
    }

    //handle open and close announcemet audio dialog
    const handleAnnouncementOpenClose = () => {
        setOpenAnnouncement(!openAnnouncement)
    }

    //handle open and close verification message dialog
    const handleMessageOpenClose = () => {
        setOpenMessage(!openMessage)
    }

    const handleAnnouncement = (id) => {
        //close more option menu
        handleCloseMoreOptions();
        //open announcememt audio dialog
        handleAnnouncementOpenClose();
    }

    const cancelAnnouncement = () => {
        (async () => {
            let params = { ChainId: props.chainId }
            let result = await CallService.CancelAnnouncement(params);
            LogInformation("CallService.CancelAnnouncement", result);
            HandleServerResponse(result, "Cancel call announcement audio request was sent successfully.", dispatch);
        })();
        //TODO Revisit the function
        handleCloseMoreOptions();
    }

    const handleVerificationMessage = () => {
        //close more option menu
        handleCloseMoreOptions();
        //open verification message dialog
        handleMessageOpenClose();
    }

    const activateCallSurvey = (id) => {
        (async () => {
            setOpenSurvey(false);
            let params = { TenantId: tenantId, ChainId: props.chainId, CallFlowId: id }
            let result = await CallService.hangupWithCallFlow(params);
            LogInformation("CallService.hangupWithCallFlow", result);
            HandleServerResponse(result, "Call hangup with survey request was sent successfully.", dispatch);
        })();
    }

    const closeSurveyDialog = () => {
        setOpenSurvey(false);
    }

    const handleCallSurvey = () => {
        if (callSurveys.length === 1) {
            activateCallSurvey(callSurveys[0].id);
        } else {
            setOpenSurvey(true);
        }
    }

    const handleCallOnholdOptions = (event) => {
        setAnnounceAnchorE2(event.currentTarget);
    }

    const handleTransferCallUserOptions = (event) => {
        setTransferCallUserOption2(event.currentTarget);
    }

    const handleCallOnhold = (isOnhold) => {
        (async () => {
            let params = { TenantId: tenantId, ChainId: props.chainId, CallId: props.callId, IsOnhold: isOnhold }
            let result = await CallService.callOnhold(params);
            LogInformation("CallService.callOnhold (onhlod)", result);
            HandleServerResponse(result, `Call ${isOnhold ? "hold" : "resume"} request was sent successfully.`, dispatch);
        })();
        handleCloseMoreOptions();
    }

    React.useEffect(() => {
        let active = true;
        if (!loadInitialData) {
            (async () => {
                //TODO: use single api to get reference data
                let result = await MediaService.getAnnouncementAudios();
                HandleServerResponse(result, "", dispatch);

                if (result.success && active) {
                    setAnnouncementAudios(result.data);
                    setLoadInitialData(true);
                }

                //load all active queues from the API
                let queueList = await CallService.GetAllActiveQueues();
                HandleServerResponse(queueList, "", dispatch);
                if (queueList.success && active) {
                    setCallQueues(queueList.data);
                }

                //load Survey data if queue has configured Survey
                let callFlows = await QueueService.callFlows(props.queueId);
                HandleServerResponse(callFlows, "", dispatch);
                if (callFlows.success && callFlows.data !== null && active) {
                    setCallSurveys(callFlows.data);
                }

                let messageTemplate = await QueueService.getMessageTemplate(props.queueId);
                if (messageTemplate.success && messageTemplate.data !== null) {
                    setMessageTemplate(messageTemplate.data);
                    setMessageTemplateReferenceData(messageTemplate.referrenceData);
                }
            })();

            if (!active) { return; }
            setLoadInitialData(true);
        }
        return () => { active = false; };
    }, [dispatch])


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
                {props.waitingId !== null &&
                    <React.Fragment>
                        <Grid item >                          
                            <IconButton onClick={handleTransferCallUserOptions} size="medium" color="primary" title="Transfer" aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall} >
                                <PhoneForwarded sx={styles.iconSize} />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={transferCallUserOption2}
                                keepMounted
                                open={Boolean(transferCallUserOption2)}
                                onClose={handleCloseTransferCallQueueOptions}
                                sx={styles.menuStyles}
                            >
                                <MenuItem sx={styles.menuStyles} onClick={() => setOpenTransfer(true)}>
                                    <Person color="primary" sx={styles.iconSize} />
                                    <p className={classes.availabilityText} >Transfer to User</p>
                                </MenuItem>
                                <MenuItem sx={styles.menuStyles} onClick={() => setOpenUserTransferPstn(true)}>
                                    <CellTower color="primary" sx={styles.iconSize} />
                                    <p className={classes.availabilityText} >External Transfer</p>
                                </MenuItem>
                                {/* <MenuItem sx={styles.menuStyles} onClick={() => setOpenConsultTransfer(true)}>
                                    <PeopleAlt color="primary" sx={styles.iconSize} />
                                    <p className={classes.availabilityText} >Consult Transfer(Preview)</p>
                                </MenuItem> */}
                            </Menu>
                            {openTransfer === true && <InviteTransferDialog callTab={true} handleClose={() => setOpenTransfer(false)} searchable tenantId={tenantId} chainId={props.chainId} open={openTransfer} type={"Transfer"} small={mobile} />}
                            {/* {openConsultTransfer === true && <InviteTransferDialog callTab={true} handleClose={() => setOpenConsultTransfer(false)} searchable tenantId={tenantId} chainId={props.chainId} open={openConsultTransfer} type={"ConsultTransfer"} small={mobile} />} */}
                            {openUserTransferPstn === true && <TransferToPstnDialog callTab={true} handleClose={() => setOpenUserTransferPstn(false)} isActive={true} items={callQueues} tenantId={tenantId} chainId={props.chainId} open={openUserTransferPstn} small={mobile} />}
                        </Grid>
                        <Grid item >
                            <IconButton onClick={handleInvite} size="medium" color="primary" title="Invite" aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall}>
                                <PersonAddRounded sx={styles.iconSize} />
                            </IconButton>
                            {openInvite === true && <InviteTransferDialog callTab={true} handleClose={handleInvite} searchable tenantId={tenantId} chainId={props.chainId} open={openInvite} type={"Invite"} small={mobile} />}                           
                        </Grid>
                        {
                            callQueues.length > 0 &&
                            <Grid item >
                                <IconButton onClick={() => setOpenQueueTransfer(true)} size="medium" color="primary" title="Queue Transfer" aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall} >
                                    <LowPriority sx={styles.iconSize}  />
                                </IconButton>
                                {openQueueTransfer === true && <InviteTransferDialog callTab={true} handleClose={() => setOpenQueueTransfer(false)} items={callQueues} tenantId={tenantId} chainId={props.chainId} open={openQueueTransfer} type={"QueueTransfer"} small={mobile} />}
                            </Grid>
                        }
                    </React.Fragment>
                }
                <Grid item>
                    <IconButton onClick={handleCallOnholdOptions} size="medium" color="primary" title={props.holdMusic ? "Stop onhold music" : "Play onhold music"} aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall}>
                        {props.holdMusic ? <MusicOff sx={styles.iconSize}  /> : <MusicNote sx={styles.iconSize}  />}
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={announceAnchorE2}
                        keepMounted
                        open={Boolean(announceAnchorE2)}
                        onClose={handleCloseMoreOptions}
                        sx={styles.menuStyles}
                    >
                        <MenuItem sx={styles.menuStyles} onClick={() => handleCallOnhold(true)}>
                            <MusicNote color="primary" sx={styles.iconSize} />
                            <p className={classes.availabilityText} >Play onhold music</p>
                        </MenuItem>
                        <MenuItem sx={styles.menuStyles} onClick={() => handleCallOnhold(false)}>
                            <MusicOff color="primary" sx={styles.iconSize} />
                            <p className={classes.availabilityText} >Stop onhold music</p>
                        </MenuItem>
                    </Menu>
                </Grid>
                {/* <Grid item >
                <IconButton onClick={handleMute} size="medium" color="primary" title="Mute" aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall}>
                    {mute === false ? <MicRounded sx={styles.iconSize}  /> : <MicOffRounded sx={styles.iconSize}  />}
                </IconButton>
            </Grid> */}
                <Grid item >
                    <IconButton onClick={handleCallEnd} size="medium" color="primary" title="Hangup" aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall}>
                        <CallEndRounded sx={styles.iconSize}  />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton onClick={() => setBlockNumber(true)} size="medium" color="primary" title="Block number" aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall}>
                        <Block sx={styles.iconSize}  />
                    </IconButton>
                </Grid>
                { (messageTemplate!=null && messageTemplate.enabled && checkLicense(assignedLicenses, [LicenseType.VoicePlusDigital])) &&
                <Grid item >
                    <IconButton onClick={() => setHasMessageTemplate(true)} size="medium" color="primary" title="Send SMS" aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall}>
                        <Sms sx={styles.iconSize}  />
                    </IconButton>
                </Grid>
                }
                {
                    (callSurveys.length > 0) &&
                    <Grid item >
                        <IconButton onClick={handleCallSurvey} size="medium" color="primary" title="Transfer to survey" aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall}>
                            <ThumbsUpDown sx={styles.iconSize}  />
                        </IconButton>
                    </Grid>
                }
                <Grid item >
                    <IconButton onClick={handleOpenMoreOptions} size="medium" color="primary" title="More options" aria-label="dashboard" component="span" disabled={props.type !== CommunicationType.activecall}>
                        <MoreVert fontSize="small" />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={announceAnchorEl}
                        keepMounted
                        open={Boolean(announceAnchorEl)}
                        onClose={handleCloseMoreOptions}
                        sx={styles.menuStyles}
                    >
                        <MenuItem sx={styles.menuStyles} onClick={() => handleAnnouncement()}>
                            <VolumeUp color="primary" sx={styles.iconSize} />
                            <p className={classes.availabilityText} >Play Announcement</p>
                        </MenuItem>
                        <MenuItem sx={styles.menuStyles} onClick={() => cancelAnnouncement()}>
                            <VolumeOff color="primary" sx={styles.iconSize} />
                            <p className={classes.availabilityText} >Cancel Announcement</p>
                        </MenuItem>
                        {
                            (smsIntegration !== null && smsIntegration !== '') &&
                            <MenuItem sx={styles.menuStyles} onClick={() => handleVerificationMessage()}>
                                <MailOutline color="primary" sx={styles.iconSize} />
                                <p className={classes.availabilityText} >Verrification Message</p>
                            </MenuItem>
                        }
                    </Menu>
                    {openAnnouncement && <AnnouncementAudio chainId={props.chainId} audios={announcementAudios} open={openAnnouncement} handleOpenClose={handleAnnouncementOpenClose} referenceId={props.referenceId} small={mobile} />}
                    {openMessage && <ConfirmNumberDialog phone={props.phone} open={openMessage} handleMessageOpenClose={handleMessageOpenClose} />}
                    {openSurvey && <SurveyDialog open={openSurvey} surveys={callSurveys} handleClose={closeSurveyDialog} selectSurvey={activateCallSurvey} small={mobile} />}
                    {
                        blockNumber &&
                        <BlockNumberDialog
                            open={blockNumber}
                            phone={props.phone}
                            directBlock={blockNUmberPermission}
                            handleClose={() => setBlockNumber(false)}
                        />
                    }
                    {
                        hasMessageTemplate && messageTemplate!=null &&
                        <OutboundSmsDialog 
                            chainId={props.chainId}
                            referenceId={props.referenceId}
                            phone={props.phone}
                            messageTemplate={messageTemplate}
                            messageTemplateReferenceData={messageTemplateReferenceData}
                            queueId = {props.queueId}
                            handleClose={() => setHasMessageTemplate(false)}
                            dispatch = {dispatch}
                        />
                    }
                </Grid>
                {props.type === CommunicationType.activecall && props.activeStart !== undefined &&
                    <Grid item sx={styles.timerStyles}>
                        <CountUpTimer countFrom={props.activeStart} />
                    </Grid>
                }
            </Grid>
            {
                callTransferring && <Typography className={classes.errorMessage} color="primary" variant="body2">Transferring...</Typography>
            }
        </div>
    );
};

export default CallOptions;
import React from 'react'
import { useTheme } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import BarChart from '../../components/chart/BarChart'
import GroupBox from '../../components/GroupBox'
import QueueSelection from './components/QueueSelection'
import { Widgets } from '../../helpers/Constant';
import { saveWidgetData } from '../auth/AuthSlice';
import { checkUserPermission, convertToRGB } from '../../helpers/Common';
import { useTranslation } from 'react-i18next';

const ivrPermission = ['call.dashboardivrread'];

const CallStatus = ({ name, height, cls, hideTitle = false }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { ivrCalls, waitingCalls, activeCalls, wrapupCalls } = useSelector((state) => state.interaction);
  const { user: { widgets, queues: userQueues, permissions } } = useSelector((state) => state.auth);
  const showIvr = checkUserPermission(permissions, ivrPermission);

  const [queues, setQueues] = React.useState(() => {
    let { data } = widgets.find(x => x.key === Widgets.CallStatus);
    let arr = data === null ? [] : data.split(',');
    let holdUserQueues = [];

    //include ivr
    if (showIvr) {
      holdUserQueues.push(
        {
          id: 'ivr',
          name: "IVR",
          checked: arr.includes("ivr") ? true : false,
        }
      )
    }

    userQueues.map(queue => {
      holdUserQueues.push({
        id: queue.queueId,
        name: queue.name,
        checked: arr.includes(queue.queueId) ? true : false,
      });
    });

    return holdUserQueues;
  });

  const [chartData, setChartData] = React.useState({ labels: [], datasets: [] });

  const setCallStatusData = (queueList) => {

    let filteredQueus = queueList.filter(x => x.checked === true);
    if (filteredQueus.length !== 0) queueList = [...filteredQueus];

    let labels = [];

    let datasets = [
      {
        label: t('Waiting'),
        data: [],
        backgroundColor: convertToRGB("#444791"),
        stack: '1',
        borderRadius: 5,
      },
      {
        label: t('In a Call'),
        data: [],
        backgroundColor: convertToRGB("#5b5fc7"),
        stack: '1',
        borderRadius: 5,
      },
      {
        label: t('Wrap Up'),
        data: [],
        backgroundColor: convertToRGB("#7f85f5"),
        stack: '1',
        borderRadius: 5,
      },
    ]

    if (showIvr) {
      let ivr = {
        label: 'IVR',
        fill: false,
        data: [],
        stack: '1',
        backgroundColor: convertToRGB("#3d3e78"),
        borderRadius: 5
      }
      datasets = [ivr, ...datasets]
    }

    queueList.forEach((queue, index) => {
      //IVR call information
      if (queue.id === "ivr") {
        if (showIvr) {
          labels.push(queue.name);
          datasets[0].data[index] = ivrCalls.length;
        }
        return;
      }

      labels.push(queue.name);

      let dynamicValue = showIvr ? 1 : 0

      datasets[0 + dynamicValue].data[index] = waitingCalls.filter(x => x.queueId === queue.id).length;
      datasets[1 + dynamicValue].data[index] = activeCalls.filter(x => x.queueId === queue.id).length;
      datasets[2 + dynamicValue].data[index] = wrapupCalls.filter(x => x.queueId === queue.id).length;
    });

    setChartData({ labels: [...labels], datasets: [...datasets] })
  }

  React.useEffect(() => {
    setCallStatusData([...queues]);
  }, [ivrCalls, waitingCalls, activeCalls, wrapupCalls, queues])

  const handleQueueChange = (items) => {
    setQueues(items);
    dispatch(saveWidgetData(Widgets.CallStatus, items.map(x => { if (x.checked) return x.id }).toString()));
  }

  //show chart only in control panel
  if (hideTitle)
    return (
      <GroupBox
        headerActions={<QueueSelection onChange={handleQueueChange} data={queues} />}
        showDivider={false}
      >
        <BarChart data={chartData} />
      </GroupBox>
    )

  return (
    <GroupBox
      headerActions={<QueueSelection onChange={handleQueueChange} data={queues} />}
      sx={{ minHeight: height }}
      title={t(name)}
      gutter
      className={cls}
    >
      <BarChart data={chartData} />
    </GroupBox>
  )
}

export default CallStatus
import React from 'react';

class ConsentSuccess extends React.Component {

    render() {
        return (
            <div style={{padding:'8px 0 0 16px'}}>Successfully granted consent to the application...</div>
        );
    }
}
export default ConsentSuccess;
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import GroupBox from '../../../components/GroupBox';
import GroupContainer from '../../../components/GroupContainer';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 12
    },
    agreementInfoSection: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 8
    },
    infoOne: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
        marginBottom: 12
    },
}));

const MarketplaceSuccess = (props) => {
    const classes = useStyles();

    return (
        <GroupBox title="Activation Confirmation" gutter className={classes.root}>
            <div className={classes.info}>
                <GroupContainer>
                    <div className={classes.agreementInfoSection}>
                        <div className={classes.infoOne}>
                            <Typography variant="body2">
                                Your CentrePal SaaS subscription has been successfully configured and is now in the process of activation. Please note that it may take up to 24 hours for the license to appear in your tenancy.
                            </Typography>
                        </div>
                        <div className={classes.infoOne}>
                            <Typography variant="body2">
                                If you have any questions or need assistance, please feel free to reach out to our support team at support@centrepal.com.
                            </Typography>
                        </div>
                        <div className={classes.infoOne}>
                            <Typography variant="body2">
                                Thank you for choosing CentrePal.
                            </Typography>
                        </div>
                    </div>
                </GroupContainer>
            </div>
        </GroupBox>
    );
}

export default MarketplaceSuccess;
import React from 'react';
import * as microsoftTeams from "@microsoft/teams-js-old";
import { TeamsProvider } from '@microsoft/mgt';

class TeamsAuthHandler extends React.Component {
    componentDidMount() {
        TeamsProvider.microsoftTeamsLib = microsoftTeams;
        TeamsProvider.handleAuth();
    }

    render() {
        return (
            <div style={{ padding: '8px 0 0 16px' }}>Loading...</div>
        );
    }
}
export default TeamsAuthHandler;
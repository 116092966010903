import axios from 'axios'

const getAll = async (values) => {
    const { data } = await axios.get('/api/messagetemplate/list', { params: values }).then(result => result);
    return data;
};

const get = async (id = null) => {
    const { data } = await axios.get(`/api/messagetemplate/${id}`).then(result => result);
    return data;
}

const create = async (values) => {
    const { data } = await axios.post('/api/messagetemplate', values).then(result => result);
    return data;
};

const update = async (values) => {
    const { data } = await axios.put('/api/messagetemplate', values).then(result => result);
    return data;
};

const disable = async (id) => {
    const { data } = await axios.post(`/api/messagetemplate/${id}/disable`).then(result => result);
    return data;
};

const enable = async (id) => {
    const { data } = await axios.post(`/api/messagetemplate/${id}/enable`).then(result => result);
    return data;
};

const remove = async (id) => {
    const { data } = await axios.delete(`/api/messagetemplate/${id}`).then(result => result);
    return data;
};

const getMessageTemplatesByType = async (values) => {
    const { data } = await axios.get('/api/messagetemplate/message-templates', { params: values }).then(result => result);
    return data;
};

export const MessageTemplateService = {
    getAll,
    get,
    create,
    update,
    disable,
    enable,
    remove,
    getMessageTemplatesByType
};
import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CallService } from '../../services/CallService';
import { useDispatch } from 'react-redux';
import { getWarningMessage, HandleServerResponse} from '../../helpers/Common';
import Autocomplete from '@mui/material/Autocomplete';
import { setMessage, updateActiveCall, updateWaitingCall } from '../../features/app/InteractionSlice';
import { UserService } from '../../services/UserService';
import { updateUserCall } from '../../features/attendent-console/AcSlice';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    modalWidth: {
        width: 500
    },
    smallModal: {
        width: 250
    },
}));

export default function InviteTransferDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { open, items, type, chainId, tenantId, searchable, ac=false, callTab=false, small=false } = props;
    const [isItemSelected, setIsItemSelected] = useState(true)
    const [selectedItem, setSelectedItem] = useState('');
    const [searchItems, setSearchItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const styles = {
        actions: {
            margin: theme.spacing(1),
        },
    }

    const updateCallTrasfering = () => {
        if(ac){
            if(!props?.isActive){
                dispatch(updateWaitingCall({chainId: chainId, transferring: true}))
            }else{
                dispatch(updateUserCall({chainId: chainId, transferring: true}));
            }
        }

        else if(callTab){
            dispatch(updateActiveCall({chainId: chainId, transferring: true}))
        }
    }

	const handleItem = async () => {
        if (selectedItem === '' || selectedItem === undefined) {
            setIsItemSelected(false)
        } else {
            let params = { Id: selectedItem, TenantId: tenantId, ChainId: chainId };
            let transfered = false;
            
            if (type === "Transfer"){
                let result = await CallService.TransferToUser(params);
                if(result.success) transfered = true;
                HandleServerResponse(result, t("Call transfer request to user was sent successfully."), dispatch);
            } else if(type ==="UserVoicemail"){
                params['IsWaiting'] = !props?.isActive;
                let result = await CallService.TransferToUserVoiceMail(params);
                if(result?.success) transfered = true;
                HandleServerResponse(result, t("Call transfer request to user voicemail was sent successfully."), dispatch);
            }else if(type ==="QueueTransfer"){
                let transferToQueueParams = { QueueId: selectedItem, TenantId: tenantId, ChainId: chainId };
                let result  = null;
                if(!props?.isActive){
                    result = await CallService.TransferWaitingToQueue(transferToQueueParams);
                }else{
                    result = await CallService.TransferActiveToQueue(transferToQueueParams);
                }

                if(result?.success) transfered = true;
                HandleServerResponse(result, t("Call transfer request to queue was sent successfully."), dispatch);
            }
            else if(type ==="Answer"){
                let result = await CallService.Answer(params);

                if(!result.success && result.message === null){
                    dispatch(setMessage(getWarningMessage(t("We are unable to process your request, as this call is being offered to another agent."))));
                }else if(result.success && (result.data ?? false)){
                    HandleServerResponse(result, "", dispatch, false, true);
                }else{
                    transfered = true;
                    HandleServerResponse(result, t("Call transfer request to user was sent successfully."), dispatch);
                }
            }
            else if (type === "ConsultTransfer") {
                let result = await CallService.ConsultTransfer(params);
                if (result.success) { 
                    transfered = true;
                    HandleServerResponse(result, t("Consult transfer request to user was sent successfully."), dispatch);
                }
            }
            else{
                let result = await CallService.Invite(params);
                if(result?.success) transfered = true;
                HandleServerResponse(result, t("Call invite request to user was sent successfully."), dispatch);
            }

            if(transfered) updateCallTrasfering();
            props.handleClose()
        }
    }

    const handleItemChange = (event, value) => {
        event.persist();
        if(value === null){
            setSelectedItem('');
            setIsItemSelected(false);
        }else{
            setSelectedItem(value.id);
            setIsItemSelected(true)
        }
    }

    const handleSearchTextChange = async (event, value) => {
        if(value !== undefined && value.length >= 3){
            setLoading(true);
            let response = await UserService.acSearchAllUsers(value);
            if (Array.isArray(response?.data)) {
                setSearchItems(response.data);
            }
            setLoading(false);
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{t(`Select ${type === "QueueTransfer" ? "a queue" : "a user"} to ${type === "Invite" ? "invite" : `${type === "ConsultTransfer" ? 'consult transfer' : 'transfer'} ${type === "UserVoicemail" ? 'to user voicemail' : ''}`}`)}</DialogTitle>
                <DialogContent>
                    <div className={small ? classes.smallModal :classes.modalWidth}>
                        {searchable && <Autocomplete
                            options={searchItems}
                            onChange={handleItemChange}
                            onInputChange={handleSearchTextChange}
                            getOptionLabel={(option) => option.displayName}
                            renderInput={(params) => <TextField 
                                {...params} 
                                label={t("Agent List")} 
                                variant="outlined" 
                                InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )}} />} 
                        />}
                        {!searchable && <Autocomplete
                            options={items}
                            getOptionLabel={(option) => option.name}
                            onChange={handleItemChange}
                            renderInput={(params) => <TextField {...params} label={type === "QueueTransfer"? t("Queue List"): t("User List")} variant="outlined" />}
                        />}
                    </div>
                    {!isItemSelected && <Typography color="error" variant="body2">{t(`Please select ${type === 'QueueTransfer' ? 'a queue': 'a user'} to ${type === "Invite" ? "join" : "transfer"} this call`)}</Typography>}
                </DialogContent>
                <DialogActions sx={styles.actions}>
                    <Button onClick={handleItem} variant="contained" color="primary" disableElevation>
                        {type === "Invite"? t("Invite"): t("Transfer")}
                    </Button>
                    <Button onClick={props.handleClose} variant="contained" color="grey" disableElevation>
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import locale files
import en from "./language/en.json"
import ja from "./language/ja.json"

export const languages = {
    en,
    ja
}

i18n
  .use(initReactI18next)
  .init({
    resources: languages,
    fallbackLng: "en"
  });

export default i18n;
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { alpha, Typography } from '@mui/material';
import { ChatBubbleOutline } from "@mui/icons-material";
import { useTheme } from '@mui/styles';
import { ChatStatus, ChannelType } from '../../../helpers/Constant';
import Notification from './Notification';
import { useDispatch } from 'react-redux';
import { setCurrentConversation } from '../../conversation/ConversationSlice';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 30,
        cursor: 'pointer',
        padding: theme.spacing(0, 0.5, 0, 0.5),
        borderRadius: 4,
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity),
        },
        marginBottom: 1,
    },
    selected: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 30,
        cursor: 'pointer',
        padding: theme.spacing(0, 0.5, 0, 0.5),
        borderRadius: 4,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity),
        marginBottom: 1,
    },
    waiting: {
        color: theme.palette.error.main,
    },
    active: {
        color: theme.palette.success.main,
    },
    transferred: {
        color: theme.palette.warning.main,
    },
    complete: {
        color: theme.palette.grey.dark,
    }
}));

const Item = ({ data, onClick, selected }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const setNotification = (status, notification) => {
        if (status !== ChatStatus.Active) {
            return null;
        }

        if (notification === 0) {
            return null;
        }

        return notification;
    }

    const [count, setCount] = React.useState(setNotification(data?.status, data?.notifications));

    React.useEffect(() => { 
        setCount(setNotification(data?.status, data?.notifications))
    }, [data?.status, data?.notifications])

    const styles = {
        small: {
            width: theme.spacing(3.5),
            height: theme.spacing(3.5),
        },
        customerTypography: {
            marginLeft: theme.spacing(1),
            width: '40%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        queueTypography: {
            width: '40%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }
    }

    const iconColor = (status) => { 
        switch (status) { 
            case ChatStatus.Waiting:
                return classes.waiting;
            case ChatStatus.Active:
                return classes.active;
            case ChatStatus.ActiveAgentTransferred:
                return classes.transferred;
            case ChatStatus.Completed:
                return classes.complete;
        }
    }

    const icon = (type) => {
        const className = iconColor(data.status)
        switch (type) {
            case ChannelType.WebChat:
                return <ChatBubbleOutline fontSize='small' className={className} />
            default:
                return <ChatBubbleOutline fontSize='small' className={className} />
        }
    }

    const handleSelectedChat = (chat) => {
        dispatch(setCurrentConversation(chat));
    }

    return (
        <div className={selected ? classes.selected : classes.root} onClick={() => handleSelectedChat(data)}>
            <div style={{display: 'flex', width: '95%'}}>
                {icon()}
                <Typography sx={styles.customerTypography} variant="body2">{data.customer}</Typography>
                <Typography sx={styles.queueTypography} variant="body2">{data.queue}</Typography>
            </div>
            {
                count !== null &&
                <Notification count={count} />
            }
        </div>
    )
}

export default Item;
import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import GroupBox from '../../../components/GroupBox';

const useStyles = makeStyles(theme => ({
    registration: {
        width: '100%',
        padding: 12
    }
}));

const RegisterCompleted = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const styles = {
        loginBtn: {
            mt: theme.spacing(2),
        },
    }

    return (
        <GroupBox title="Registration Completed" gutter className={classes.registration}>
            <Typography variant="body2">Congratulations, your new CentrePal Contact Centre tenant is created.</Typography>
            <br />
            <Typography variant="body2">Thank you for registering for your 30-day free trial, We are excited to share with you the benefits of CentrePal.</Typography>
            <br />
            <Typography variant="body2">One of our contact centre specialists will follow up in 1-2 business days.</Typography>
            <br />
            <Button sx={styles.loginBtn} component={Link} to="/" variant="contained" color="primary">
                Click here to Login
            </Button>
        </GroupBox>
    );
}

export default RegisterCompleted;
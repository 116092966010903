import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Button, Checkbox, Typography } from '@mui/material';
import GroupBox from '../../../components/GroupBox';
import GroupContainer from '../../../components/GroupContainer';
import { RegisterService } from '../../../services/RegisterService';
import { Providers } from '@microsoft/mgt';
import { DecodeJWTToken, HandleServerResponse } from '../../../helpers/Common';
import { useDispatch } from 'react-redux';
import { Close, NavigateBefore } from '@mui/icons-material';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 12
    },
    info: {
        display: 'flex', 
        flexDirection: 'column'
    },
    agreementInfoSection: {
        display: 'flex', 
        flexDirection: 'column', 
        marginTop: 8
    },
    infoOne: {
        display: 'flex', 
        alignItems: 'center', 
        marginTop:12, 
        marginBottom:12
    },
    btnSection: {
        marginTop: 24,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    registerInfoRoot:{
        display: 'flex', 
        width: '100%', 
        marginBottom: 8
    },
}));

const RegisterInfo = ({ property, value }) => {
    const classes = useStyles();

    const styles = {
        registerInfoOne: {
            width: 150
        },
    }

    return (
        <div className={classes.registerInfoRoot}>
            <Typography sx={styles.registerInfoOne} variant="body2">{property}</Typography>
            <Typography variant="body2">{value}</Typography>
        </div>
    )
}

const RegistrationReviewAndSuccess = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const styles = {
        registerInfoOne: {
            width: 150
        },
        previousBtn: {
            width: 100, 
            marginRight: theme.spacing(2),
        },
        createBtn: {
            width: 100,
        },
    }
    const dispatch = useDispatch();

    const [disableNextBtn, setDisableNextBtn] = React.useState(false);
    const [sendNotification, setSendNotification] = React.useState(false);

    const handleRegistration = () => {
        (async () => {
            setDisableNextBtn(true);
            let token = null;
            let tokenData = null;

            if (Providers.globalProvider !== undefined && Providers.globalProvider !== null) {
                token = await Providers.globalProvider.getAccessToken([]);
                tokenData = DecodeJWTToken(token);
            }

            if (token !== null) {
                let params = {
                    TenantId: tokenData.payload.tid,
                    UserId: tokenData.payload.oid,
                    SSOToken: token,
                    FirstName: props.orgInformation.firstName,
                    LastName: props.orgInformation.lastName,
                    BusinessPhone: props.orgInformation.businessPhone,
                    BusinessEmail: props.orgInformation.businessEmail,
                    ComapanyName: props.orgInformation.companyName,
                    Website: props.orgInformation.website,
                    Lavel: props.orgInformation.level.name,
                    Country: props.orgInformation.country.name,
                    Timezone: props.orgInformation.timezone.id,
                    SendNotification: sendNotification,
                    SaasToken: props.saasToken
                };

                let result = await RegisterService.register(params);
                HandleServerResponse(result, "", dispatch);
                if (result.success) {
                    props.handleSelectedComponent('completed')
                }else{
                    setDisableNextBtn(false);
                }
            }
        })();
    }

    const handleRecieveNotification = (e) => {
        setSendNotification(!sendNotification)
    }

    return (
        <GroupBox title="Review and create" gutter className={classes.root}>
            <div className={classes.info}>
                <GroupContainer>
                    <RegisterInfo property="Organisation Name" value={props.orgInformation.companyName} />
                    <RegisterInfo property="First Name" value={props.orgInformation.firstName} />
                    <RegisterInfo property="Last Name" value={props.orgInformation.lastName} />
                    <RegisterInfo property="Business Phone" value={props.orgInformation.businessPhone} />
                    <RegisterInfo property="Business Email" value={props.orgInformation.businessEmail} />
                    <RegisterInfo property="Website" value={props.orgInformation.website} />
                    <RegisterInfo property="Level" value={props.orgInformation.level.name} />
                    <RegisterInfo property="Country" value={props.orgInformation.country.name} />
                    <RegisterInfo property="Timezone" value={props.orgInformation.timezone.name} />

                    <div className={classes.agreementInfoSection}>
                        <div className={classes.infoOne}>
                            <Checkbox
                                onChange={handleRecieveNotification}
                                checked={sendNotification}
                                className={classes.agreementCheckbox}
                                sx={styles.agreementCheckbox}
                                size="small"
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Typography variant="body2">
                                Yes, I would like to receive information, tips, and offers about CentrePal products and services.
                            </Typography>
                        </div>
                        <Typography variant="body2">Choosing "Create" means that you agree to the CentrePal Services Agreement and privacy and cookies statement.</Typography>
                    </div>

                </GroupContainer>
                <div className={classes.btnSection}>
                    <div>
                    <Button
                        startIcon={<NavigateBefore/>}
                        sx={styles.previousBtn}
                        onClick={() => props.handleSelectedComponent('organisation-info', false)}
                        variant="contained"
                        color='grey'
                        component="span">
                        Previous
                    </Button>
                    <Button sx={styles.createBtn} onClick={handleRegistration} disabled={disableNextBtn} variant="contained" color="primary" component="span">
                        Create
                    </Button>
                    </div>
                    <Button
                        sx={styles.createBtn}
                        endIcon={<Close/>}
                        variant="contained"
                        component={Link}
                        color="grey"
                        to="/"
                        className={classes.nextBtn}>
                        Close
                    </Button>
                </div>
            </div>
        </GroupBox>
    );
}

export default RegistrationReviewAndSuccess;
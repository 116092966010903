import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Card, CardContent, Link, Typography } from '@mui/material';
import { AttachFileOutlined } from '@mui/icons-material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '../../../components/CKEditor5/CustomCKEditor';
import SkeletonThreadItem from '../components/skeletons/SkeletonThreadItem';
import { checkUserPermission, formatToLongDateTime } from '../../../helpers/Common';
import { useDispatch, useSelector } from 'react-redux';
import { reduceUnreadCount } from '../ConversationSlice';
import { ConversationService } from '../../../services/ConversationService';
import { ConversationType } from '../../../helpers/Constant';

const config = {
    toolbar: false,
    language: 'tr',
    baseFloatZIndex: '9999',
    isReadOnly: true,
};

const DarkTheme = React.lazy(() => import('../../settings/list/ckeditorTheme/DarkTheme'));

const Thread = (props) => {
    const CardRoot = styled(Card)(({ theme }) => ({
        width: '100%',
        margin: '10px auto',
    }));
    const Content = styled(CardContent)(({ theme }) => ({
        paddingBottom: '10px !important'
    }));

    const [thread, setThread] = React.useState(() => {
        let item = {
            loading: false,
            loaded: false,
            expand: false,
            content: {
                body: null,
                attachments: [],
                to: [],
                cc: [],
                read: false
            }
        }

        if (props.isNew) {
            item.content.body = props.body
        }

        return item;
    })

    const selectedConversation = useSelector((state) => state.conversation.current);
    const dispatch = useDispatch();

    const handleExpand = (e) => {
        e.preventDefault();

        setThread((prev) => ({
            ...prev,
            expand: !prev.expand
        }));
    };

    useEffect(() => {
        let active = true;
        (async () => {
            if (props.isNew) {
                return;
            }

            if (thread.expand && !thread.loaded) {
                setThread((prev) => ({
                    ...prev,
                    loading: true,
                    content: {
                        ...prev.content,
                    }
                }))

                let params = {
                    ConversationId: selectedConversation?.id,
                    EmailId: props.id,
                };

                let result = await ConversationService.LoadMessage(params);

                setThread((prev) => ({
                    ...prev,
                    loaded: result.success,
                    loading: false,
                    content: result?.data
                }))

                if (result.success && result?.data.markedAsRead) {
                    dispatch(reduceUnreadCount({ id: props.id }));
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [thread.expand, dispatch]);

    return (
        <CardRoot variant="outlined" elevation={1}>
            <Content>
                {thread.loading && <SkeletonThreadItem />}
                {!thread.expand && !thread.loading && <Collapsed {...props} handleExpand={handleExpand} />}
                {thread.expand && !thread.loading && <Expanded {...props} content={thread.content} conversationId={selectedConversation?.id} handleExpand={handleExpand} />}
            </Content>
        </CardRoot>
    );
};

const Collapsed = ({ createdBy, hasAttachments, messagePreview, date, handleExpand, read }) => {
    const Root = styled('div')(({ theme }) => ({
        cursor: 'pointer',
    }));
    const StyledBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 5,
    }));
    const AttachFileIcon = styled(AttachFileOutlined)(({ theme }) => ({
        justifyContent: 'end',
        color: 'gray',
        fontSize: 15,
    }));
    const EllipsisTypography = styled(Typography)(({ theme }) => ({
        flex: '80%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }));

    const FixedWidthTypography = styled(Typography)(({ theme }) => ({
        flex: '20%',
        textAlign: 'right',
    }));

    const Header = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center'
    }));

    const UnreadIcon = styled('div')(({ theme }) => ({
        height: '10px',
        width: '10px',
        backgroundColor: '#0288d1',
        borderRadius: '50%',
        marginRight: '5px'
    }));

    return (
        <Root onClick={(e) => handleExpand(e)}>
            <StyledBox>
                <Header>
                    {!read && <UnreadIcon />}
                    <Typography variant="body1" component="div" fontWeight={read ? 'normal' : 'bold'}>{createdBy}</Typography>
                </Header>
                {hasAttachments && <AttachFileIcon />}
            </StyledBox>
            <StyledBox>
                <EllipsisTypography variant="body2" color="text.secondary" fontWeight={read ? 'normal' : 'bold'}>{messagePreview}</EllipsisTypography>
                <FixedWidthTypography variant="body2" color="text.secondary" fontWeight={read ? 'normal' : 'bold'}>{formatToLongDateTime(date)}</FixedWidthTypography>
            </StyledBox>
        </Root>
    )
}

const Expanded = (props) => {
    const CKEditorRoot = styled('div')(({ theme }) => ({
        width: '100%',
        '& .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content': {
            border: 'none',
        },
        '& .ck-read-only': {
            border: 'none',
            paddingLeft: '0px',
        },
    }));

    return (
        <>
            <ExpandedHeader {...props} />
            <CKEditorRoot>
                <CKEditor editor={Editor} data={props?.content?.body} disabled={true} config={config} />
            </CKEditorRoot>
            <Attachments attachments={props?.content?.attachments} messageId={props?.content?.emailId} conversationId={props?.conversationId} />
        </>
    )
}

const ExpandedHeader = ({ hasAttachments, content, handleExpand }) => {
    const Root = styled('div')(({ theme }) => ({
        cursor: 'pointer'
    }));
    const UserAndAttachment = styled('div')(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 5,
    }));
    const RecipientAndDate = styled('div')(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between'
    }));
    const AttachFileIcon = styled(AttachFileOutlined)(({ theme }) => ({
        justifyContent: 'end',
        color: 'gray',
        fontSize: 15,
    }));

    return (
        <Root onClick={(e) => handleExpand(e)}>
            <UserAndAttachment>
                <Typography variant="body1" component="div">{content?.fromName ?? content?.fromEmail}</Typography>
                {hasAttachments && <AttachFileIcon />}
            </UserAndAttachment>
            <RecipientAndDate>
                <div>
                    <Typography variant="body2" color="text.secondary">To: {content?.to.join(', ')}</Typography>
                    {content?.cc.length > 0 &&
                        <Typography variant="body2" color="text.secondary" sx={{ mb: '6px' }}>Cc: {content?.cc.join(', ')}</Typography>
                    }
                </div>
                <Typography variant="body2" color="text.secondary">{formatToLongDateTime(content?.created)}</Typography>
            </RecipientAndDate>
        </Root>
    )
}

const Attachments = ({ attachments, messageId, conversationId }) => {
    const downloadAttachmentPermissions = ["conversation.downloadAttachment"];
    const { user: { permissions } } = useSelector((state) => state.auth);

    const Root = styled('div')(({ theme }) => ({
        display: 'flex',
        marginBottom: 8
    }));
    const StyledLink = styled(Link)(({ theme }) => ({     
        display: 'flex',
        flexBasis: 'column',
        alignItems: 'center',
        margin: (0, 12, 6, 0),
        marginLeft: -4,
        cursor: checkUserPermission(permissions, downloadAttachmentPermissions) ? 'pointer' : 'default',
    }));
    const AttachementName = styled(Link)(({ theme }) => ({
        color: !checkUserPermission(permissions, downloadAttachmentPermissions) ? 'gray' : null,
        marginRight: 12
    }));
    const AttachFileIcon = styled(AttachFileOutlined)(({ theme }) => ({
        color: !checkUserPermission(permissions, downloadAttachmentPermissions) ? 'gray' : null,
        fontSize: 16
    }));

    //Downwload audo file
    const downloadFile = (file) => {
        (async () => {
            var params = {
                Id: conversationId,
                ConversationType: ConversationType.Email,
                MessageId: messageId,
                AttachmentId: file.id
            }

            let result = await ConversationService.DownloadAttachment(params);
            const url = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();
        })();
    }

    if (attachments === null || attachments.length === 0)
        return <></>

    return (
        <Root>
            {attachments.map((attachment) => (
                <StyledLink component='button' disabled={!checkUserPermission(permissions, downloadAttachmentPermissions)} key={attachment?.id} onClick={() => downloadFile(attachment)} color='primary'>
                    <AttachFileIcon fontSize='small' />
                    <AttachementName variant="body2">{attachment?.name}</AttachementName>
                </StyledLink>
            ))}
        </Root>
    )
};

export default Thread;

import axios from 'axios'

const getAll = async (values) => {
     const { data } = await axios.get('/api/media/list',{params: values}).then(result => result);
     return data;
};

const get = async (id=null) => {
     const { data } = await axios.get(`/api/media/${id}`).then(result => result);
     return data;
};

const create = async (values) => {
     const {data} = await axios.post('/api/media',values, {headers :{ 'Content-Type': 'multipart/form-data'}}).then(result => result);
     return data;
};

const update = async (values) => {
     const {data} = await axios.put('/api/media',values, {headers :{ 'Content-Type': 'multipart/form-data'}}).then(result => result);
     return data;
};

const remove = async (queueId) => {
     const { data } = await axios.delete(`/api/media/${queueId}`).then(result => result);
     return data;
 };

 const getAnnouncementAudios = async () => {
     const { data } = await axios.get(`/api/media/announcementaudios`).then(result => result);
     return data;
};

const GetAudio = async (id) => {
     const { data } = await axios.get(`/api/media/audio/${id}`,{responseType: 'blob'}).then(result => result);
     return data;
};

export const MediaService = {
    getAll,
    get,
    create,
    update,
    remove,
    getAnnouncementAudios,
    GetAudio,
};
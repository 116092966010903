import React, { Component } from 'react';
import * as MicrosoftTeams from "@microsoft/teams-js-old";
import { Providers, TeamsProvider, MsalProvider, ProviderState } from '@microsoft/mgt';
import { login, logoutSuccess } from './features/auth/AuthSlice';
import LoginComponent from './features/auth/LoginComponent';
import { removeLocalStorage, LogInformation, setLocale } from './helpers/Common';
import Environment from './Environment';

export function oldWithAuth(HocComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                providerState: ProviderState.Loading,
                rootPath: props?.match?.path === "/" ? true : false,
            };
        }

        async componentDidMount() {
            let isRootPath = this.props?.match?.path === "/" ? true : false;
            this.setState({
                rootPath: isRootPath
            });
            LogInformation("Initializing provider");
            TeamsProvider.microsoftTeamsLib = MicrosoftTeams;

            if (TeamsProvider.isAvailable) {
                MicrosoftTeams.initialize();
                LogInformation("Initializing TeamsProvider");

                Providers.globalProvider = new TeamsProvider({
                    clientId: Environment.config.AZURE_APP_ID,
                    authPopupUrl: window.location.origin + '/auth',
                    scopes: [Environment.config.AZURE_APP_SCOPE],
                });
            } else {
                LogInformation("Initializing MsalProvider");
                Providers.globalProvider = new MsalProvider({
                    clientId: Environment.config.AZURE_APP_ID,
                    redirectUri: window.location.origin,
                    scopes: [Environment.config.AZURE_APP_SCOPE],
                });
            }

            //set local time format
            if (navigator.languages.length > 0) {
                setLocale(navigator.languages[0]);
            }

            this.setState({
                providerState: Providers.globalProvider.state,
            });


            if (Providers.globalProvider.state === ProviderState.SignedIn && this.state.rootPath) {
                LogInformation("Initial ProviderState is SignedIn");

                //Trigger login
                const account = Providers.globalProvider._userAgentApplication.getAccount();
                this.props.dispatch(login({ oid: account?.idToken?.oid, tid: account?.idToken?.tid }, await Providers.globalProvider.getAccessToken(), TeamsProvider.isAvailable, false));
            } else if (Providers.globalProvider.state === ProviderState.SignedOut && this.state.rootPath) {
                //Remove user if token is expired
                LogInformation("Initial ProviderState is SignedOut");
                removeLocalStorage("cc.user");
                this.props.dispatch(logoutSuccess());
            }

            Providers.globalProvider.onStateChanged(async (e) => {
                LogInformation("Provider state changed to " + Providers.globalProvider.state);

                this.setState({
                    providerState: Providers.globalProvider.state
                });

                if (Providers.globalProvider.state === ProviderState.SignedIn && this.state.rootPath) {
                    const account = Providers.globalProvider._userAgentApplication.getAccount();
                    this.props.dispatch(login({ oid: account?.idToken?.oid, tid: account?.idToken?.tid }, await Providers.globalProvider.getAccessToken(), TeamsProvider.isAvailable, false));
                } else if (Providers.globalProvider.state === ProviderState.SignedOut && this.state.rootPath) {
                    //Remove user if token is expired
                    removeLocalStorage("cc.user");
                    this.props.dispatch(logoutSuccess());
                }
            });
        }

        render() {
            if (this.state.providerState === ProviderState.SignedIn && (this.props.isAuthUser || !this.state.rootPath)) {
                return <HocComponent {...this.props} />;
            } else {
                return <LoginComponent
                    showSignIn={this.state.providerState === ProviderState.SignedOut}
                    error={this.state.rootPath ? this.props.error : null}
                    registered={this.state.rootPath ? this.props.registered : false}
                    verified={this.state.rootPath ? this.props.verified : false}
                />;
            }
        }
    };
}
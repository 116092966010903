import { Menu, MenuItem, Typography } from '@mui/material';
import React from 'react'

const TemplateMenu = ({ anchorEl, open, handleClose, setSelectedSignatureOption, templates }) => {

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: 80 * 4.5,
                    width: '240px',
                },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Typography variant='body1' color='primary' style={{ padding: '8px 16px' }}>Global</Typography>
            {templates?.global?.map((option, index) => (
                <MenuItem
                    key={index}
                    onClick={() => {
                        setSelectedSignatureOption(option);
                        handleClose();
                    }}
                >
                    <Typography variant='body2'>{option.name}</Typography>
                </MenuItem>
            ))}
            <Typography variant='body1' color='primary' style={{ padding: '8px 16px' }}>User</Typography>
            {templates?.userSignatures?.map((option, index) => (
                <MenuItem
                    key={index}
                    onClick={() => {
                        setSelectedSignatureOption(option);
                        handleClose();
                    }}
                >
                    <Typography variant='body2'>{option.name}</Typography>
                </MenuItem>
            ))}
        </Menu>
    )
}

export default TemplateMenu
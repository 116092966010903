import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TabPanel from '../../components/TabPanel';
import Communication from './Communication';

const useStyles = makeStyles(theme => ({
    root: {
      height: '100%',
      margin: theme.spacing(0),
    },
}));

export default function CommunicationPanel(props) { 
    const classes = useStyles();

    return (
        <React.Fragment>
            {props.tabs.map((tab, index) => {
                return (
                    <TabPanel className={classes.root} key={`${tab.id}-tab`} id={`${tab.id}-tab`} value={props.tabIndex} index={index}>
                        <Communication {...tab} largeScreen={props.largeScreen} />
                    </TabPanel>
                )
            })}
        </React.Fragment>
    );
}


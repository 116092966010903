import React from "react";
import PowerBIColorIcon from "../../../../components/customicons/PowerBIColorIcon";
import { Typography } from "@mui/material";

const PowerBIBranding = () => {

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'self-end', paddingBottom: '2px', borderBottom: '2px solid #6264a7' }}>
            <PowerBIColorIcon />
            <Typography sx={{ ml: "4px" }}>PowerBI Dashboard</Typography>
        </div>
    )
}

export default PowerBIBranding
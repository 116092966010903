import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Done } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { HandleServerResponse, LogInformation } from '../../../helpers/Common';
import { UserService } from '../../../services/UserService';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        overflowY: 'auto',
        padding: theme.spacing(0, 0, 2, 0),
        height: '43vh',
        display: 'flex',
        flexDirection: 'column',
    },
}));

const NewTodo = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        actions: {
            margin: theme.spacing(1),
        },
    }

    const dispatch = useDispatch();

    var today = new Date();

    let y = today.getFullYear();
    let month = today.getMonth() + 1; // add 1 to month since getMonth start the count from 0
    let d = today.getDate();
    let h = today.getHours();
    let min = today.getMinutes();

    //set default due date time
    let formatedToday = `${y}-${month < 10 ? `0${month}` : month}-${d < 10 ? `0${d}` : d}T${h < 10 ? `0${h}` : h}:${min < 10 ? `0${min}` : min}`;

    const [values, setValues] = React.useState({ todo: '', due: formatedToday });
    const [todoRequired, setTodoRequired] = React.useState(false);

    const handleData = (e) => {
        let { name, value } = e.target;
        if (name === "todo" && value !== "") {
            setTodoRequired(false);
        }
        setValues({ ...values, [name]: value })
    }

    const handleAdd = () => {
        if (values.todo === "") {
            setTodoRequired(true);
            return;
        }

        (async () => {
            let params = { Todo: values.todo, Due: new Date(values.due) }
            let result = await UserService.createTodo(params);
            LogInformation("Todos create", result);
            HandleServerResponse(result, t("New todo added successfully"), dispatch);
            if (result.success) {
                props.handleClose("added");
            }
        })();
    }

    const handleChange = (e) => {
        let fieldName = "due"
        setValues({ ...values, [fieldName]: e })
    }

    return (
        <div className={classes.root}>
            <Dialog className={classes.btnStyles} open={props.open} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
                <DialogTitle id="form-dialog-title">{t("Add a to-do")}</DialogTitle>
                <DialogContent>
                    <Box alignItems="center">
                        <TextField
                            id="due-date-time"
                            label={t("Due Date Time")}
                            type="datetime-local"
                            style={{ width: '100%' }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={values.due}
                            onChange={(e) => { handleChange(e.target.value) }}
                        />
                        <TextField
                            required={true}
                            rows={5}
                            multiline={true}
                            style={{ width: '100%' }}
                            label={t("To-do")}
                            name={'todo'}
                            onChange={handleData}
                            value={values.todo}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    {todoRequired && <Typography variant="body2" color="error">{t("Please add Todo to continue")}</Typography>}
                </DialogContent>
                <DialogActions sx={styles.actions}>
                    <Button onClick={handleAdd} startIcon={<Done />}
                        variant="contained" color="primary" disableElevation>{t("Add")}</Button>
                    <Button
                        onClick={() => props.handleClose()}
                        style={{ marginRight: 8 }}
                        startIcon={<Close />}
                        variant="contained"
                        color="grey"
                        disableElevation>{t("Close")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default NewTodo
import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DropDown from '../Inputs/DropDown';
import { useTheme } from '@mui/styles';
import { isMobileDevice } from '../../helpers/Common';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dialogContent: {
        width: 500,
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center'
    },
    samllDialogContent: {
        width: 250,
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center'
    },
    showTimer: {
        display: 'flex',
        alignItems: 'center'
    },
    dropdownStype: {
        width: 140
    },
}));

export default function ConfirmWithTimer(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const times = [
        { id: 0, name: t('Never') },
        { id: 5, name: t('5 minutes') },
        { id: 10, name: t('10 minutes') },
        { id: 15, name: t('15 minutes') },
        { id: 30, name: t('30 minutes') },
        { id: 45, name: t('45 minutes') },
        { id: 60, name: t('1 hour') },
        { id: 120, name: t('2 hours') },
    ]

    const styles = {
        actions: {
            margin: theme.spacing(1),
        },
        resetLabel: {
            marginBottom: theme.spacing(0),
            marginRight: theme.spacing(1)
        }
    }

    const [time, setTime] = React.useState(0);
    const { open, handleClose, confirmChange, header, availability, showTimer = false } = props;
    const mobile = isMobileDevice();

    return (
        <div>
            <Dialog
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
                <DialogContent>
                    <div className={mobile ? classes.samllDialogContent : classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            {t("Are you sure you want to change status to")} {t(availability)}
                        </DialogContentText>
                        {
                            showTimer &&
                            <div className={classes.showTimer}>
                                <DialogContentText sx={styles.resetLabel} id="reset-status-label">
                                    {t("Reset status after")}
                                </DialogContentText>
                                <DropDown
                                    className={classes.dropdownStype}
                                    size="small"
                                    value={time}
                                    onChange={(e) => setTime(e.target.value)}
                                    values={times}
                                />
                            </div>
                        }
                    </div>
                </DialogContent>
                <DialogActions sx={styles.actions}>
                    <Button onClick={(e) => confirmChange(time)} variant="contained" disableElevation>
                        {t("Yes")}
                    </Button>
                    <Button onClick={handleClose} color="grey" variant="contained" disableElevation>
                        {t("No")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmWithTimer.defaultProps = {
    header: "",
    message: "",
};
import React from "react";
import {FormControl, FormControlLabel, Switch} from "@mui/material";
import { useTheme } from '@mui/styles';

export default function SwitchBtn(props){
    const theme = useTheme();
    const styles = {
        alignSwitch: {
            alignItems: 'flex-start'
        },
        labelMargin: {
            marginLeft: theme.spacing(0),
        }
    }

    const {name, label, value, size="medium", onChange, disable=false, id=null} = props;

    let width = '50%'

    if(size === 'large'){
        width = '100%'
    }
    else if(size === "small"){
        width = "30%"
    }
    else if(size === "extraSmall"){
        width = "20%"
    }

    const convertTODefEventParam = (name, value) => {
        return {target: {
            name, value
        }}
    }

    return(
        <FormControl sx={styles.alignSwitch} style={{width: width}}>
            <FormControlLabel
                sx={styles.labelMargin}
                checked={value}
                control={<Switch id={id} disabled={disable} name={name} checked={value} onChange={(e) => onChange(convertTODefEventParam(e.target.name,e.target.checked))} color="primary" />}
                label={label}
                name={name}
                onChange={(e) => onChange(convertTODefEventParam(e.target.name,e.target.checked))}
                labelPlacement="start"
            />
        </FormControl>
    )
}
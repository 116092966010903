import React from "react";
import { TextField, FormControl, CircularProgress } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { emailValidation } from "../../helpers/Common";

export default function AutoCompleteSearch({ options, value, label, full, name, onChange, onClick = null, multiple = false, error = null, disabled = false, required = false, loading=false }) {
    const [isEmail, setIsEmail] = React.useState(false);
    
    let inputWidth = '50%';

    if (full) {
        inputWidth = '100%'
    }

    const convertToDefEventParam = (event, selected, name) => {
        event.persist();
        let value = null;

        if (!multiple) {
            value = selected
        } else {
            value = [...selected]
        }

        return {
            target: {
                name, value
            }
        }
    }

    const changeValue = (event, values) => {
        if (onClick !== null && event !== null) {
            event.preventDefault();

            if (event.type === "click") {
                return;
            }

            setIsEmail((prev) => {
                return emailValidation(values) === "";
            })
            
            onClick(values);
        }
    }

    return (
        <FormControl style={{ width: inputWidth, margin: 0, paddingBottom: 0 }}>
            <Autocomplete
                multiple={multiple}
                defaultValue={value}
                // value={defaultValue}
                disabled={disabled}
                id="size-small-outlined-multi"
                loading={loading}
                size="small"
                // freeSolo
                options={options === null ? [] : options}
                onChange={(event, newValue) => onChange(convertToDefEventParam(event, newValue, name))}
                onInputChange={(event, newInputValue) => changeValue(event, newInputValue)}
                getOptionLabel={(option) => isEmail ? option.userPrincipleName : (option.name ?? "")}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                renderInput={(params) => <TextField
                    {...params}
                    required={required}
                    style={{ width: '100%', paddingRight: 0 }}
                    label={label}
                    variant="outlined"
                    {...(error && { error: true, helperText: error })}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="primary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
                }
            />
        </FormControl>
    )
}
import React from 'react';
import { Redirect } from 'react-router-dom';
import ErrorDialog from '../components/ErrorDialog';
import { IssueService } from '../services/IssueService';

class GlobalErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
        this.handleError = this.handleError.bind(this)
    }

    // static getDerivedStateFromError(error) {
    //     return { hasError: true };
    // }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error: error });
    }

    handleError = () => {
        try {
            (async () => {
                let params = {
                    Summary: `System generated issue - ${new Date()}`,
                    Description: `${this.state.error.name} - ${this.state.error.message} - ${this.state.error?.stack}`,
                    SystemGenerated: true,
                    Severity: 2
                }

                await IssueService.ReportIssue(params);
            })();
            this.setState({ hasError: false, error: null });
            return <Redirect to={"/"} />
        } catch (error) {
            return <Redirect to={"/"} />
        }
    }

    render() {
        if (this.state.hasError) {
            return <ErrorDialog open={this.state.hasError} closeDialog={this.handleError} />
        }
        return this.props.children;
    }
}

export default GlobalErrorHandler
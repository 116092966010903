import React from "react";
import { styled } from '@mui/material/styles';
import { Typography } from "@mui/material";

const Property = ({ name, value, children, isCentre=false }) => {
    const Root = styled('div')(({ theme }) => ({
        display: 'flex',
        width: '100%',
        paddingBottom: 4,
        alignItems: isCentre ? "center" :'unset'
    }));

    const Name = styled(Typography)(({ theme }) => ({
        minWidth: 60,
        fontWeight: 600
    }));

    const Value = styled(Typography)(({ theme }) => ({
        marginLeft: 8
    }));

    return (
        <Root >
            <Name variant="body2" color="textSecondary">{name}:</Name>
            <Value variant="body2">{value}</Value>
            {
                children
            }
        </Root>
    )
}

export default Property;
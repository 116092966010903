import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import GroupBox from '../../../components/GroupBox';
import GroupContainer from '../../../components/GroupContainer';
import { RegisterService } from '../../../services/RegisterService';
import { Close } from '@mui/icons-material';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 12
    },
    info: {
        display: 'flex',
        flexDirection: 'column'
    },
    agreementInfoSection: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 8
    },
    infoOne: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
        marginBottom: 12
    },
    btnSection: {
        marginTop: 24,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    registerInfoRoot: {
        display: 'flex',
        width: 500,
        marginBottom: 8
    },
}));

const MarketpalceActivation = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const styles = {
        registerInfoOne: {
            width: 150
        },
        previousBtn: {
            width: 100,
            marginRight: theme.spacing(2),
        },
        createBtn: {
            width: 100,
        },
    }
    const [activation, setActivation] = React.useState({
        disableButton: false,
        error: null,
    });
    
    const handleRegistration = () => {
        (async () => {
            setActivation(prev => ({
                ["disableButton"] : true, ["error"]: null
            }));
            if (props.saasToken !== null) {
                let params = {
                    SaasToken: props.saasToken
                };
                let result = await RegisterService.MarketPlaceExistingCustomer(params);
                setActivation(prev => ({
                    ["disableButton"] : false, ["error"]: !result.success ? result.message : ""
                }))

                if (result.success) {
                    props.completed(true)
                }
            }
        })();
    }

    return (
        <GroupBox title="Review and Process" gutter className={classes.root}>
            <div className={classes.info}>
                <GroupContainer>
                    <div className={classes.agreementInfoSection}>
                        <div className={classes.infoOne}>
                            <Typography variant="body2">Thank you for purchasing the SaaS subscription through Azure Marketplace.</Typography>
                        </div>
                        <div className={classes.infoOne}>
                            <Typography variant="body2">
                                {
                                    activation.error === null 
                                    ? `To complete your purchase and activate your SaaS subscription, please click the "Proceed" button below.`
                                    : activation.error
                                }
                            </Typography>
                        </div>
                    </div>
                </GroupContainer>
                <div className={classes.btnSection}>
                    <div>
                        <Button sx={styles.createBtn} onClick={handleRegistration} disabled={activation.disableButton} variant="contained" color="primary" component="span">
                            Proceed
                        </Button>
                    </div>
                    <Button
                        sx={styles.createBtn}
                        endIcon={<Close />}
                        variant="contained"
                        component={Link}
                        color="grey"
                        to="/"
                        className={classes.nextBtn}>
                        Close
                    </Button>
                </div>
            </div>
        </GroupBox>
    );
}

export default MarketpalceActivation;
import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1, 0),
        width: '100%'
    },
    fill: {
        height: '100%',
        width: '100%'
    },
    top: {
        marginTop: theme.spacing(1)
    }
}));

export default function TabPanel(props) {
    const { children, value, index, className, fill = false, top = false, ...other } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            className={clsx(className, { [classes.root]: !fill }, { [classes.fill]: fill }, { [classes.top]: top })}
            {...other}
        >
            {(value === index) && (children)}
        </div>
    );
}
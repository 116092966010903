import React from 'react';
import { HandleServerResponse } from '../../helpers/Common';
import { UserService } from "../../services/UserService";
import { useDispatch } from "react-redux"
import { UserAvailability } from '../../helpers/Constant';
import AvailabilityDropDown from './AvailabilityDropDown';
import ConfirmWithTimer from './ConfirmWithTimer';
import { useTranslation } from 'react-i18next';

export default function AgentAvailability({ userId, currentAvailability, handleSuccessEvent, supervisor = false, handleModalWidth, dashboard=false }) {
    const dispatch = useDispatch();
    const [openConfirmation, handleOpenConfirmation] = React.useState(false);
    const [availability, setAvailability] = React.useState(currentAvailability);
    const [showTimer, setShowTimer] = React.useState(false);
    const [changedAvailability, setChangedAvailability] = React.useState(null);

    const { t } = useTranslation();

    const changeAvailability = (time) => {
        (async () => {
            let availabilityObj = {
                Id: userId,
                Availability: changedAvailability.availability,
                ForceUpdate: supervisor,
                Interval: (showTimer && time !== 0) ? time : null,
            }

            if (dashboard) {
                handleSuccessEvent()
            }

            let result = await UserService.setAvailability(availabilityObj);
            let confiramationText = supervisor ? t("You changed the agent availability") : t("Your availability changed successfully");

            HandleServerResponse(result, confiramationText, dispatch);
            if (result.success) {
                setChangedAvailability(null);
                
                if (dashboard) {
                    handleSuccessEvent()
                }
            }
        })();

        handleOpenConfirmation(false);
    }

    const handleAvailablity = (value) => {
        if (value.availability === availability) return;

        if (value.availability === "OnBreak" || value.availability === "OnBreakCustom1" || value.availability === "OnBreakCustom2") {
            setShowTimer(true);
        } else {
            setShowTimer(false);
        }

        setChangedAvailability(value);
        handleOpenConfirmation(true);
    }

    const handleOpenClose = () => {
        handleOpenConfirmation(!openConfirmation);
    }

    React.useEffect(() => {
        setAvailability(currentAvailability)
    }, [currentAvailability])

    return (
        <React.Fragment>
            <AvailabilityDropDown handleAvailablity={handleAvailablity} handleModalWidth={handleModalWidth} selectedValue={availability} disabled={currentAvailability === UserAvailability.offline} supervisor={supervisor} dashboard={dashboard} />
            {(openConfirmation && changedAvailability !== null) &&
                <ConfirmWithTimer
                    open={openConfirmation}
                    handleClose={handleOpenClose}
                    confirmChange={changeAvailability}
                    availability={changedAvailability.name}
                    showTimer={showTimer}
                />}
        </React.Fragment>
    );
}
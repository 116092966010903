import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: 8
    },
    textContainer: {
        fontSize: '0.5rem'
    },
}));

const dialPadItems = [
    [{ id: 1, text: '' }, { id: 2, text: 'ABC' }, { id: 3, text: 'DEF' }],
    [{ id: 4, text: 'GHI' }, { id: 5, text: 'JKL' }, { id: 6, text: 'MNO' }],
    [{ id: 7, text: 'PQRS' }, { id: 8, text: 'TUV' }, { id: 9, text: 'WXYZ' }],
    [{ id: '*' }, { id: 0, text: '+' }, { id: '#' }]
]

const ButtonContainer = React.memo(({ handleClick }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [isCommandHandled, setIsCommandHandled] = React.useState(true);
    const [buttonPressTimer, setButtonPressTimer] = React.useState(null);

    const handleButtonPress = function (e) {
        if (buttonPressTimer) clearTimeout(buttonPressTimer);
        setIsCommandHandled(false);
        setButtonPressTimer(setTimeout(handleLongPress, 1000, e));
    };

    const handleLongPress = e => {
        if (!isCommandHandled) {
            setIsCommandHandled(true);
            return;
        }

        handleClick('+');
    };

    const handleButtonRelease = function (e) {
        if (!isCommandHandled) {
            setIsCommandHandled(true);
        }
        clearTimeout(buttonPressTimer);
    };

    const styles = {
        button: {
            width: '100%',
            backgroundColor: theme.palette.mode === "light" ? '#f9fafb' : "",
            fontWeight: 600,
            '& .MuiButton-label': {
                fontWeight: 600,
            },
            '&:hover': {
                backgroundColor: '#e1e6eb',
            },
        },
    }

    return (
        dialPadItems.map((items, key) => (
            <div key={key} className={classes.buttonContainer}>
                {
                    items.map((item, key) => (
                        <Button
                            id={`${item.id}-${key}`}
                            key={key}
                            sx={styles.button}
                            onClick={() => handleClick(item.id)}
                            color="primary"
                            onMouseDown={item.id == 0 ? handleButtonPress : null}
                            onMouseUp={item.id == 0 ? handleButtonRelease : null}
                        >
                            <div>
                                <div>{item.id}</div>
                                <div className={classes.textContainer}>{item.text}</div>
                            </div>

                        </Button>
                    ))
                }
            </div>
        ))
    )
})

export default ButtonContainer;
import { Switch } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GroupBox from '../../components/GroupBox';
import { getLocalStorage, setLocalStorage } from '../../helpers/Common';
import { UserService } from '../../services/UserService';
import AgentSummary from './components/AgentSummary';
import QueueSummary from './components/QueueSummary';

export default function Summary() {
    const [queueSummary, setQueueSummary] = useState(() => getLocalStorage("cc-queue-stats") ?? false);
    const { t } = useTranslation();

    const handleCheckBox = (value) => {
        setQueueSummary((prev) => !prev);
        setLocalStorage("cc-queue-stats", value);
    }

    const handleStateReload = () => {
        (async () => {
            if (queueSummary) {
                await UserService.queueStats();
            } else {
                await UserService.myStats();
            }
        })();
    }

    return (
        <GroupBox title={queueSummary ? t("Queue Summary") : t("Summary")} flex center gutter showStatReleoad={true} handleStateReload={handleStateReload} headerActions={<Switch
            checked={queueSummary}
            onChange={() => handleCheckBox(!queueSummary)}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />}>
            {
                queueSummary ? <QueueSummary /> : <AgentSummary />
            }
        </GroupBox>
    )
}
import axios from 'axios'

const dashboards = async () => {
     const { data } = await axios.get('/api/powerbi/dashboards').then(result => result);
     return data;
};

const token = async (id) => {
    const { data } = await axios.get(`/api/powerbi/token/${id}`).then(result => result);
    return data;
};

const refresh = async (id) => {
    const {data} =  await axios.post(`/api/powerbi/refresh/${id}`).then(result => result);
    return data;
};

export const PowerBiService = {
    dashboards,
    token,
    refresh
};
import { Checkbox, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react'
import { setLocalStorage } from '../../../../helpers/Common';

const QueueSelector = ({ openQueueSelector, anchorRef, queues, onChange }) => {
    const theme = useTheme();

    const styles = {
        paper: {
            width: '200px',
            padding: '10px'
        },
        popper: {
            width: 185,
            marginLeft: 30,
            pr: theme.spacing(2)
        },
        menuItem: {
            height: 30,
            padding: theme.spacing(0),
            minHeight: 0,
            display: 'flex',
            alignItems: 'center'
        },
        checkBox: {
            '& .MuiSvgIcon-root': {
                fontSize: 20
            },
            padding: 0
        },
        text: {
            marginLeft: "10px"
        }
    }

    const handleChange = (item, index) => {
        item.checked = !item.checked
        queues[index] = item;
        onChange([...queues]);

        setLocalStorage('cc-leaderboard-queues', [...queues]);
    }

    return (
        <React.Fragment>
            <Popper sx={styles.popper} open={openQueueSelector} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper sx={styles.paper}>
                            <MenuList id="queue-button-menu" >
                                {
                                    queues.map((item, index) => (
                                        <MenuItem
                                            key={item.id}
                                            sx={styles.menuItem}
                                            onClick={() => handleChange(item, index)}
                                        >
                                            <Checkbox
                                                checked={item?.checked ?? false}
                                                sx={styles.checkBox}
                                            />
                                            <Typography variant='body2' sx={styles.text}>{item.name}</Typography>
                                        </MenuItem>
                                    ))
                                }
                            </MenuList>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}

export default QueueSelector
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';
import { ThemeProvider as MaterialUITheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, } from "react-router-dom";
import initApi from "./services/initApi";
import App from './App';
import TeamsAuthHandler from './features/auth/TeamsAuthHandler';
import theme from './theme'
import store from "./store";
import './index.css';
import RegisterComponent from "./features/auth/RegisterComponent";
import ConsentSuccess from "./features/auth/ConsentSuccess";
import GlobalErrorHandler from './helpers/GlobalErrorHandler';
import JiraConsent from './features/auth/Jira/JiraConsent';
import i18n from './i18n';
import Environment from './Environment';
import { getLocalStorage } from './helpers/Common';

const urlParams = new URLSearchParams(window.location.search);
let locale = urlParams.get('locale');

if(locale != null){
  i18n.changeLanguage(locale);
}

Environment.Load(getLocalStorage("cc-multi-region"));
initApi(store);

//remove loader once react app loaded
const loader = document.getElementById("loader-id");
loader?.remove();

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <GlobalErrorHandler>
      <Router>
        <MaterialUITheme theme={theme}>
          <Route exact path="/" component={App} />
          <Route exact path="/auth" component={TeamsAuthHandler} />
          <Route exact path="/consent" component={ConsentSuccess} />
          <Route exact path="/jira/consent" component={JiraConsent} />
          <Route exact path="/register" component={RegisterComponent} />
        </MaterialUITheme>
        {/* <NorthStarTheme theme={teamsTheme}>
          <Route exact path="/side-panel" component={SidePanel} />
        </NorthStarTheme> */}
      </Router>
    </GlobalErrorHandler>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();

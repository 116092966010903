import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { QueueService } from "../../../services/QueueService";
import PersonInfo from "../../../components/PersonInfo";
import Filter from "./components/FilterLeaderboard";
import { extractTimeFromSeconds, getLocalStorage, HandleServerResponse } from "../../../helpers/Common";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Leaderboard({ reload }) {
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        headerCell: {
            fontWeight: 500,
            backgroundColor: theme.palette.background.default
        },
        topBar: {
            width: '100%',
            height: theme.spacing(6),
            display: 'flex',
            justifyContent: 'space-between'
        },
        heading: {
            padding: theme.spacing(2.5)
        },
        filter: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2),
            marginRight: theme.spacing(1)
        }
    }
    const [leaderboardStats, setLeaderboardStats] = useState([]);
    const [callDirections, setCallDirections] = useState(() => getLocalStorage('cc-leaderboard-directions'));
    const [queues, setQueues] = useState(() => getLocalStorage('cc-leaderboard-queues'));
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        async function GetLeaderboardStats() {
            setLoading(true);

            let callDirectionParam = null;
            let queueParams = [];

            if (callDirections !== null) {
                if (callDirections[0]?.checked) callDirectionParam = 1;

                if (callDirections[1]?.checked) callDirectionParam = 2;

                if (callDirections[0]?.checked && callDirections[1]?.checked) callDirectionParam = null;
            }

            if (queues !== null) {
                queueParams = queues.map(x => {
                    if (x.checked) {
                        return x.id
                    }
                }).filter(x => x);
            }

            let params = {
                callDirection: callDirectionParam,
                Queues: queueParams?.length === 0 ? (queues?.map(x => x.id) ?? []) : queueParams
            }

            // Get stats from AppService
            const result = await QueueService.queueLeaderboardStats(params);

            if (result.success) {
                setLeaderboardStats(result.data);
                setCallDirections((prev) => {
                    if (prev === null) {
                        return result.referrenceData["callDirections"];
                    }
                    return prev;
                });
                setQueues((prev) => {
                    if (prev === null) {
                        return result.referrenceData["currentQueues"];
                    } else if(prev.length > result.referrenceData["currentQueues"].length){
                        return prev.filter(({id : id1}) => result.referrenceData["currentQueues"].some(({id : id2}) => id2 === id1))
                    }else if(prev.length < result.referrenceData["currentQueues"].length){
                        let newValues = result.referrenceData["currentQueues"].filter(({id : id1}) => !prev.some(({id : id2}) => id2 === id1))
                        prev.push(...newValues)
                    }
                    return prev;
                });
            } else {
                HandleServerResponse(result, "", dispatch)
            }
            setLoading(false);
        }

        GetLeaderboardStats();
        const interval = setInterval(GetLeaderboardStats, 300000);

        return () => clearInterval(interval);
    }, [callDirections, queues, reload]);

    return (
        <React.Fragment>
            <Box sx={styles.topBar}>
                <Typography sx={styles.heading} variant="h6">{t("Top 5 Today")}</Typography>
                <Box sx={styles.filter}>
                    <Filter
                        callDirections={callDirections}
                        setCallDirections={setCallDirections}
                        queues={queues}
                        setQueues={setQueues}
                        loading={loading}

                    />
                </Box>
            </Box>
            <Table aria-label="leaderboard-stats">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={styles.headerCell}>{t("Agent")}</TableCell>
                        <TableCell align="center" sx={styles.headerCell}>{t("Handled")}</TableCell>
                        <TableCell align="center" sx={styles.headerCell}>{t("Average Consult Time")}</TableCell>
                        <TableCell align="center" sx={styles.headerCell}>{t("Abandoned Rate")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(leaderboardStats).map(key => {
                        return (
                            <TableRow hover key={key}>
                                <TableCell ><PersonInfo id={leaderboardStats[key].userId} /> </TableCell>
                                <TableCell align="center">{leaderboardStats[key].totalHandled}</TableCell>
                                <TableCell align="center">{extractTimeFromSeconds(leaderboardStats[key].avgConsultTime)}</TableCell>
                                <TableCell align="center">{leaderboardStats[key].abandonedRate}</TableCell>
                            </TableRow>)
                    })}
                </TableBody>
            </Table>
        </React.Fragment >
    )
}
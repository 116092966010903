import React from 'react'
import { formatToLongDateTimeChat } from '../../../helpers/Common';
import { useTheme } from '@mui/styles';

const PartyAndDateTime = ({ title, time = null }) => {
    const theme = useTheme();

    const styles = {
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        time: {
            color: theme.palette.mode === "light" ? "gray" : "lightGray",
            fontWeight: 'normal',
            marginLeft: '8px'
        },
        title: {
            color: theme.palette.mode === "dark" ? "lightBlue" : "",
        }
    }
    if (time === null) {
        time = new Date().toLocaleString();
    }
    return (
        <div style={styles.root}>
            <div style={styles.title}>{title}</div>
            <div style={styles.time}>{formatToLongDateTimeChat(time)}</div>
        </div>
    )
}

export default PartyAndDateTime
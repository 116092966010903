import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import GroupBox from '../../../components/GroupBox';
import Form from '../../../components/form/Form';
import GroupContainer from '../../../components/GroupContainer';
import Input from '../../../components/Inputs/Input';
import UseForm from '../../../components/form/UseForm';
import { Button } from '@mui/material';
import AutoComplete from "../../../components/Inputs/AutoComplete";
import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    organisationInfo: {
        width: '100%',
        padding: 12
    },
    btnDiv: {
        marginTop: 16,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },

}));

var initialValues = {
    firstName: '',
    lastName: '',
    businessPhone: '',
    businessEmail: '',
    companyName: '',
    website: '',
    level: '',
    country: '',
    timezone: ''
}

export default function RegisterOrganizationInfo(props) {
    const classes = useStyles();
    const theme = useTheme();
    const styles = {
        previousBtn: {
            marginRight: theme.spacing(2),
            width: 100
        },
        nextBtn:{
            width: 100
        }
    }

    const [loading, setLoading] = React.useState(false);
    
    const emailValidation = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return "";
        }
        return "Enter valid email address";
    }

    const validate = (fieldValues = values) => {
        let validationProps = { ...errors };

        if ('firstName' in fieldValues)
            validationProps.firstName = fieldValues.firstName !== '' ? "" : "Required."

        if ('lastName' in fieldValues)
            validationProps.lastName = fieldValues.lastName !== '' ? "" : "Required."

        if ('businessPhone' in fieldValues)
            validationProps.businessPhone = fieldValues.businessPhone !== '' ? "" : "Required."

        if ('businessEmail' in fieldValues){
            if (fieldValues.businessEmail === '') {
                validationProps.businessEmail = "Required.";
            } else {
                validationProps.businessEmail = emailValidation(fieldValues.businessEmail);
            }
        }

        if ('companyName' in fieldValues)
            validationProps.companyName = fieldValues.companyName !== '' ? "" : "Required."

        if ('level' in fieldValues)
            validationProps.level = fieldValues.level !== '' ? "" : "Required."

        if ('country' in fieldValues)
            validationProps.country = fieldValues.country !== '' ? "" : "Required."

        if ('timezone' in fieldValues)
            validationProps.timezone = fieldValues.timezone !== '' ? "" : "Required."

        setErrors({
            ...validationProps
        });

        if (fieldValues === values)
            return Object.values(validationProps).every(x => x === "");
    }

    const {
        values,
        setValues,
        handleInputChanges,
        errors,
        setErrors,
    } = UseForm(initialValues, true, validate);

    const handleFormData = (e) => {
        e.preventDefault();

        if(validate()){
            
            props.handleOrganisationInfo(values);
            props.handleSelectedComponent('review')
        }
    }

    React.useState(() => {
        if(props.orgInformation !== null){
            setLoading(true);
            setValues(props.orgInformation)
            setLoading(false);
        }
    },[])

    return (
        <GroupBox title="Organisation Information" gutter className={classes.organisationInfo}>
            <Form>
                {!loading && 
                <GroupContainer>
                    <Input
                        required
                        size="large"
                        name='companyName'
                        label="Organisation Name"
                        onChange={handleInputChanges}
                        value={values.companyName}
                        error={errors.companyName}
                    />
                    <Input
                        required
                        size="large"
                        name='firstName'
                        label="First Name"
                        onChange={handleInputChanges}
                        value={values.firstName}
                        error={errors.firstName}
                    />
                    <Input
                        required
                        size="large"
                        name='lastName'
                        label="Last Name"
                        onChange={handleInputChanges}
                        value={values.lastName}
                        error={errors.lastName}
                    />
                    <AutoComplete
                        required
                        full={true}
                        onChange={handleInputChanges}
                        options={props.references.levels}
                        label="Level"
                        name="level"
                        defaultValue={values.level}
                        error={errors.level}
                    />
                    <Input
                        required
                        size="large"
                        name='businessPhone'
                        label="Business Phone"
                        onChange={handleInputChanges}
                        value={values.businessPhone}
                        error={errors.businessPhone}
                    />
                    <Input
                        required
                        size="large"
                        name='businessEmail'
                        label="Business Email"
                        onChange={handleInputChanges}
                        value={values.businessEmail}
                        error={errors.businessEmail}
                    />
                    <Input
                        size="large"
                        name='website'
                        label="Website"
                        onChange={handleInputChanges}
                        value={values.website}
                    />
                    <AutoComplete
                        required
                        full={true}
                        onChange={handleInputChanges}
                        options={props.references.countries}
                        label="Country"
                        name="country"
                        defaultValue={values.country}
                        error={errors.country}
                    />
                    <AutoComplete
                        required
                        full={true}
                        onChange={handleInputChanges}
                        options={props.references.timezones}
                        label="Default Time Zone"
                        name="timezone"
                        defaultValue={values.timezone}
                        error={errors.timezone}
                    />
                    <div className={classes.btnDiv}>
                        <div>
                            <Button
                                startIcon={<NavigateBefore/>}
                                sx={styles.previousBtn}
                                onClick={() => props.handleSelectedComponent('consent',false)}
                                variant="contained"
                                color='grey'
                                component="span">
                                Previous
                            </Button>
                            <Button endIcon={<NavigateNext/>} sx={styles.nextBtn} onClick={handleFormData} variant="contained" color="primary" component="span">
                                Next
                            </Button>
                        </div>
                        <Button
                            style={{marginRight:16}}
                            endIcon={<Close/>}
                            variant="contained"
                            component={Link}
                            to="/"
                            color='grey'
                            sx={styles.nextBtn}
                        >
                            Close
                        </Button>
                    </div>
                </GroupContainer>
                }
            </Form>
        </GroupBox>
    );
}
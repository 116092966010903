import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Table, TableBody, TableRow, TableCell, TableHead, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    warning: {
        color: theme.palette.warning.main,
    },
    info: {
        color: theme.palette.info.main,
    },
    errorAnimation: {
        animation: "$blinker 1s linear infinite",
        color: theme.palette.error.main,
    },
    "@keyframes highlighter": {
        '0%': {
          filter: "drop-shadow(0px 0px 1px)"
        },
        '35%': {
          filter: "drop-shadow(0px 0px 3px)"
        },
        '70%': {
          filter: "drop-shadow(0px 0px 6px)"
        },
        '100%': {
          filter: "drop-shadow(0px 0px 3px)"
        },
      },
      "@keyframes blinker": {
        from: {
          opacity: 0.2,
        },
        to: {
          opacity: 1.5,
        },
      },
}));

export default function WbQueues({ queueStats , queues}) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    
    const styles = {
        headerCell: {
            fontWeight: 500,
            backgroundColor: theme.palette.background.default
        },
    }

    const getClassName = (sla, warning) => {
        //default warning period is 5 percent and warning period ignotred if the configured SLA warning is greater than 90
        let slaWarningPeriod = warning >= 90 ? 0 : 5;

        if ((sla > (warning + slaWarningPeriod) || sla === 'N/A')) {
            return classes.info;
        } else if (slaWarningPeriod > 0 && sla <= (warning + slaWarningPeriod) && sla >= warning) { 
            return classes.warning;
        } else { 
            return classes.errorAnimation;
        }
    }

    const getQueueSLA = (stats, formular) => {

        if (formular === 0) return "N/A";

        let {
            tot_waiting_answered_within_sla,
            tot_waiting_answered,
            tot_callback,
            tot_decline,
            tot_transfer,
            tot_short_abandon,
            tot_abandon
        } = stats;

        if (tot_waiting_answered_within_sla === 0) return 0;

        let tot = 0;
        let flowouts = tot_callback + tot_decline + tot_transfer;

        switch (formular) {
            case 1:
                tot = tot_waiting_answered + tot_abandon;
                return tot === 0 ? 100 : Math.floor(tot_waiting_answered_within_sla / tot * 100);
        
            case 2:
                tot = tot_waiting_answered + tot_abandon + flowouts;
                return tot === 0 ? 100 : Math.floor(tot_waiting_answered_within_sla / tot * 100);
            
            case 3:
                tot = tot_waiting_answered + tot_abandon + tot_short_abandon
                return tot === 0 ? 100 : Math.floor(tot_waiting_answered_within_sla / tot * 100);
            
            case 4:
                tot = tot_waiting_answered + tot_abandon + flowouts + tot_short_abandon;
                return tot === 0 ? 100 : Math.floor(tot_waiting_answered_within_sla / tot * 100);
        }
    }

    return (
        <Table aria-label="calls">
            <TableHead>
                <TableRow>
                    <TableCell align="center" sx={styles.headerCell}>&nbsp;</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >SLA</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Call Waiting")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("On a Call")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Answered")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Abandon")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Decline")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Avg Waiting")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Avg Consult")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Max Waiting")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Max Consult")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Agents Logged In")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Agents Ready")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(queueStats).map(key => {
                    const avgWaiting = queueStats[key].tot_waiting === 0 ? 0 : Math.floor(queueStats[key].tot_waiting_time / queueStats[key].tot_waiting / 60);
                    const avgConsult = queueStats[key].tot_consult === 0 ? 0 : Math.floor(queueStats[key].tot_consult_time / queueStats[key].tot_consult / 60);
                    const queue = queues.find(x => x.id === key);
                    const sla = getQueueSLA(queueStats[key], queue?.formular);
                    return (
                        <TableRow hover key={key}>
                            <TableCell ><Typography className={getClassName(sla, queue?.warning)}>{queueStats[key].name}</Typography> </TableCell>
                            <TableCell align="center">{`${sla}${sla === 'N/A' ? '' : '%'}`}</TableCell>
                            <TableCell align="center">{queueStats[key].cur_waiting}</TableCell>
                            <TableCell align="center">{queueStats[key].cur_answere}</TableCell>
                            <TableCell align="center">{queueStats[key].tot_answere + queueStats[key].tot_transfer}</TableCell>
                            <TableCell align="center">{queueStats[key].tot_abandon + queueStats[key].tot_short_abandon}</TableCell>
                            <TableCell align="center">{queueStats[key].tot_decline}</TableCell>
                            <TableCell align="center">{avgWaiting}</TableCell>
                            <TableCell align="center">{avgConsult}</TableCell>
                            <TableCell align="center">{Math.floor(queueStats[key].max_waiting_time / 60)}</TableCell>
                            <TableCell align="center">{Math.floor(queueStats[key].max_consult_time / 60)}</TableCell>
                            <TableCell align="center">{queueStats[key].cur_users}</TableCell>
                            <TableCell align="center">{queueStats[key].avai_users}</TableCell>
                        </TableRow>)
                })}
            </TableBody>
        </Table>
    );
}
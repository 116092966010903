import { useTheme } from '@emotion/react';
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import TabPanel from '../../../components/TabPanel';
import JiraForm from './JiraForm';
import JiraRequestList from './JiraRequestList';
import { Cancel, Close } from '@mui/icons-material';
import { getLocalStorage } from '../../../helpers/Common';
import { JiraService } from '../../../services/JiraService';
import { JiraInputTypes } from '../../../helpers/Constant';
import JiraViewIssue from './JiraViewIssue';

const basicInfo = {
    requestType: null
}

const JiraDialog = ({ open, closeModal, jiraConnector, chainId, referenceId }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        dialog: {
            margin: theme.spacing(2),
            '& .MuiDialog-paperWidthMd': {
                width: 500,
            },
        },
        actions: {
            margin: theme.spacing(2),
        },
        tabs: {
            mt: theme.spacing(1),
            height: '20px'
        },
        tabError: {
            color: theme.palette.secondary.light,
        },
        addBtn: {
            width: '48%',
            marginRight: "4%"
        },
        cancelBtn: {
            width: '48%',
        },
        dividerMargin: {
            marginBottom: theme.spacing(1.5)
        },
        closeTab: {
            display: 'flex',
            alignItems: 'center',
        },
        smallIcon: {
            fontSize: '1.1rem',
        },
        dialogTitle: {
            mr: theme.spacing(2),
            ml: theme.spacing(2),
            pb: theme.spacing(1)
        },
        dialogContent: {
            height: '65vh',
            ml: theme.spacing(2),
            mr: theme.spacing(2),
            overflow: 'hidden'
        }
    }

    const [tabs, setTabs] = React.useState([]);
    const [tabIndex, setTabIndex] = React.useState(0);

    const [requestTypeBasicInfo, setRequestTypeBasicInfo] = useState(basicInfo);
    const [requestTypes, setRequestTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [issueValues, setIssueValues] = React.useState({});
    const [requestTypeTemplate, setRequestTypeTemplate] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [issue, setIssue] = React.useState({ search: '', list: [], type: 1 });

    React.useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            let params = {
                ConnectorId: jiraConnector.connectorId,
                ReferenceId: referenceId,
                ChainId: chainId
            };

            if (jiraConnector.authType === 1) {
                const jiraCredentials = getLocalStorage('jira-auth')
                params['AccessToken'] = jiraCredentials.accessToken
            }

            let result = await JiraService.GetJiraReferenceData(params);

            if (result.success && active) {
                setRequestTypes(result.data?.requestTypes ?? [])
                setTabs((prev) => {
                    if (result?.data?.issues !== null && result.data?.issues?.length === 0) {
                        return prev;
                    }

                    if(result?.data?.issues !== null){
                        return result.data?.issues.map((item) => (
                            {
                                id: item.issueId,
                                name: item.issueKey,
                                close: true,
                                data: null,
                            }
                        ));
                    }
                });

                if (result?.data?.issues !== null && result.data?.issues?.length > 0) {
                    setTabIndex(2)
                }
            }

            if (!active) { return; }
            setLoading(false);
        })();

        return () => {
            active = false
        }
    }, []);

    const handleSelectRequestType = (e) => {
        const { name, value } = e.target;

        (async () => {
            if (value !== null) {
                setRequestTypeBasicInfo({ ...requestTypeBasicInfo, [name]: value })
                setLoading(true);
                setErrors({});

                let valueProperties = { ...issueValues };

                let params = {
                    ConnectorId: jiraConnector.connectorId,
                    RequestTypeId: value?.id,
                }

                if (jiraConnector.authType === 1) {
                    const jiraCredentials = getLocalStorage('jira-auth');
                    params['AccessToken'] = jiraCredentials.accessToken;
                }

                let result = await JiraService.GetAllRequestTypeFields(params);

                if (result.success) {
                    result.data.forEach(item => {
                        if (item.type === JiraInputTypes.DropdownSingle || item.type === JiraInputTypes.Priority) {
                            valueProperties[item.field] = item.defaultValues[0]?.id;
                        } else if (item.type === JiraInputTypes.Array) {
                            valueProperties[item.field] = item.defaultValues
                        } else {
                            valueProperties[item.field] = null
                        }
                    });

                    setRequestTypeTemplate(result.data)
                }

                setIssueValues({ ...valueProperties })
                setLoading(false);
            } else {
                setRequestTypeBasicInfo({ ...requestTypeBasicInfo, [name]: null })
            }
        })();

    }

    const handleTabChange = (event, newValue) => {
        event.stopPropagation();
        setTabIndex(() => newValue);
    };

    const addNewTab = (payload) => {
        const index = tabs.findIndex(item => item.id === payload.id);

        if (index === -1) {
            setTabs((prev) => {
                let current = [...prev]

                current.push({
                    id: payload.id,
                    name: payload.issueKey,
                    close: true,
                    data: null,
                })

                return current;
            })

        }
        setTabIndex((prev) => {
            return (index === -1) ? (tabs.length + 2) : (index + 2)
        })
    }

    const handleTabClose = (e, payload) => {
        e.stopPropagation();
        const index = tabs.findIndex(x => x.id === payload) + 2;

        if (tabIndex >= index) {
            setTabIndex((prev) => {
                return tabIndex - 1
            });
        }

        let filteredTabs = tabs.filter(item => item.id !== payload);
        setTabs(filteredTabs);
    }

    return (
        <Dialog sx={styles.dialog} open={open} fullWidth={true} maxWidth={"lg"}>
            <DialogTitle sx={styles.dialogTitle}>
                <Typography variant='h4' color={'gray'}>{t("Jira Service Management")}</Typography>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={styles.dialogContent}>
                <Tabs
                    indicatorColor="primary"
                    aria-label="user control panel"
                    variant="scrollable"
                    onChange={handleTabChange}
                    value={tabIndex}
                    sx={styles.tabs}
                >
                    <Tab id="create" title="Create" aria-controls="aria-panel-create" label={t("Create Issue")} />
                    <Tab id="search" title="Search" aria-controls="aria-panel-search" label={t("Search")} />
                    {
                        tabs.map(tab => {
                            let label = <span className={styles.closeTab}>{tab.name}&nbsp;
                                <IconButton onClick={(event) => handleTabClose(event, tab.id)} size="small" color="inherit">
                                    <Cancel sx={styles.smallIcon} />
                                </IconButton>
                            </span>

                            return <Tab iconPosition="start" key={tab.id} component={"div"} id={tab.id} label={label} />
                        })
                    }
                </Tabs>
                <Divider sx={styles.dividerMargin} light />
                <Grid container sx={{ overflow: 'auto', height: '55vh' }}>
                    <TabPanel id="tab-panel-create" aria-labelledby="jira-create-issue-tab" value={tabIndex} index={0} fill>
                        <JiraForm
                            jiraConnector={jiraConnector}
                            closeModal={closeModal}
                            requestTypeBasicInfo={requestTypeBasicInfo}
                            setRequestTypeBasicInfo={setRequestTypeBasicInfo}
                            requestTypes={requestTypes}
                            handleSelectRequestType={handleSelectRequestType}
                            loading={loading}
                            requestTypeTemplate={requestTypeTemplate}
                            setRequestTypeTemplate={setRequestTypeTemplate}
                            issueValues={issueValues}
                            setIssueValues={setIssueValues}
                            errors={errors}
                            setErrors={setErrors}
                            chainId={chainId}
                            referenceId={referenceId}
                        />
                    </TabPanel>
                    <TabPanel id="tab-panel-search" aria-labelledby="jira-search-issue-tab" value={tabIndex} index={1} fill>
                        <JiraRequestList
                            jiraConnector={jiraConnector}
                            addNewTab={addNewTab}
                            issue={issue}
                            setIssue={setIssue}
                        />
                    </TabPanel>
                    {
                        tabs.map((tab, key) => (
                            <TabPanel key={`${tab.id}-tab`} id={`${tab.id}-tab`} value={tabIndex} index={key + 2} fill>
                                <JiraViewIssue
                                    jiraConnector={jiraConnector}
                                    data={tab}
                                />
                            </TabPanel>
                        ))
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default JiraDialog;
import React from 'react';
import { PowerBIEmbed, EmbedType } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import "./PowerBi.css"

export default function Embed({ id, embedUrl, token }) {
  return (
    <PowerBIEmbed
      embedConfig={{
        pageView:"fitToWidth",
        type: EmbedType.Dashboard,
        id: id,
        embedUrl: embedUrl,
        accessToken: token,
        tokenType: models.TokenType.Embed,
        filterPaneEnabled: true,
        settings: {
          panes: {
            filters: {
              expanded: true,
              visible: true
            },
          },
          background: models.BackgroundType.Default,
          // zoomLevel: 3,
          // localeSettings:{
          //   language:"",
          //   formatLocale:""
          // }
        },
      }}

      eventHandlers={
        new Map([
          ['loaded', () => {/*handle embed loaded*/}],
          ['rendered', () => {/*handle embed rendered*/}],
          ['error', () => {/*handle embed error*/}],
          ['visualClicked', () => {/*handle embed visualClicked*/}],
          ['pageChanged', () => {/*handle embed pageChanged*/}],
        ])
      }

      cssClassName={"bi-embedded"}

      getEmbeddedComponent={(embeddedReport) => {
        window.report = embeddedReport;
      }}
    />
  );
}
import env from "./environment.json";
import { getLocalStorage } from "./helpers/Common";
import Crypto from "./helpers/Crypto";
import { setApiDefault } from "./services/initApi";

export default class Environment {
    static get config() {
        return this._config;
    }

    static Load(userRegion = null) {
        const region = userRegion ?? (process.env.NODE_ENV === "production" ? env.REGION : "LOCAL");
        var pilot = getLocalStorage('P1') ?? false;
        const regions = {
            LOCAL: {
                AZURE_APP_ID: process.env["REACT_APP_LOCAL_AZURE_APP_ID"],
                AZURE_APP_SCOPE: process.env["REACT_APP_LOCAL_AZURE_APP_ID"] + "/.default",
                AZURE_APP_EXPOSE_API_SCOPE: `api://${process.env["REACT_APP_LOCAL_DOMAIN"]}/${process.env["REACT_APP_LOCAL_AZURE_APP_ID"]}/user_access`,
                API_BASE_URL: process.env["REACT_APP_LOCAL_API_BASE_URL"],
                HUB_URL: process.env["REACT_APP_LOCAL_API_BASE_URL"] + "/callhub",
                MESSAGE_API_BASE_URL: process.env["REACT_APP_LOCAL_MESSAGE_API_BASE_URL"] + "/api",
                MESSAGE_HUB_URL: process.env["REACT_APP_LOCAL_MESSAGE_API_BASE_URL"] + "/chathub",
                REPORT_API_BASE_URL: process.env["REACT_APP_LOCAL_REPORT_API_BASE_URL"],
                VERSION: process.env["REACT_APP_LOCAL_VERSION"],
                REGION:process.env["REACT_APP_LOCAL_REGION"],
                DEBUG: pilot,
            },
            STG: {
                AZURE_APP_ID: Crypto.decrypt(process.env["REACT_APP_STG_AZURE_APP_ID"]),
                AZURE_APP_SCOPE: Crypto.decrypt(process.env["REACT_APP_STG_AZURE_APP_ID"]) + "/.default",
                AZURE_APP_EXPOSE_API_SCOPE: `api://${Crypto.decrypt(process.env["REACT_APP_STG_DOMAIN"])}/${Crypto.decrypt(process.env["REACT_APP_STG_AZURE_APP_ID"])}/user_access`,
                API_BASE_URL: Crypto.decrypt(process.env["REACT_APP_STG_API_BASE_URL"]),
                HUB_URL: Crypto.decrypt(process.env["REACT_APP_STG_API_BASE_URL"]) + "/callhub",
                MESSAGE_API_BASE_URL: Crypto.decrypt(process.env["REACT_APP_STG_MESSAGE_API_BASE_URL"]) + "/api",//
                MESSAGE_HUB_URL: Crypto.decrypt(process.env["REACT_APP_STG_MESSAGE_API_BASE_URL"]) + "/chathub",//
                REPORT_API_BASE_URL: Crypto.decrypt(process.env["REACT_APP_STG_REPORT_API_BASE_URL"]),//
                VERSION: Crypto.decrypt(process.env["REACT_APP_STG_VERSION"]),
                REGION:Crypto.decrypt(process.env["REACT_APP_STG_REGION"]),
                DEBUG: pilot
            },
            AUEA: {
                AZURE_APP_ID: Crypto.decrypt(process.env["REACT_APP_AUEA_AZURE_APP_ID"]),
                AZURE_APP_SCOPE: Crypto.decrypt(process.env["REACT_APP_AUEA_AZURE_APP_ID"]) + "/.default",
                AZURE_APP_EXPOSE_API_SCOPE: `api://${Crypto.decrypt(process.env["REACT_APP_AUEA_DOMAIN"])}/${Crypto.decrypt(process.env["REACT_APP_AUEA_AZURE_APP_ID"])}/user_access`,
                API_BASE_URL: Crypto.decrypt(process.env["REACT_APP_AUEA_API_BASE_URL"]),
                HUB_URL: Crypto.decrypt(process.env["REACT_APP_AUEA_API_BASE_URL"]) + "/callhub",
                MESSAGE_API_BASE_URL: Crypto.decrypt(process.env["REACT_APP_AUEA_MESSAGE_API_BASE_URL"]) + "/api",//
                MESSAGE_HUB_URL: Crypto.decrypt(process.env["REACT_APP_AUEA_MESSAGE_API_BASE_URL"]) + "/chathub",//
                REPORT_API_BASE_URL: Crypto.decrypt(process.env["REACT_APP_AUEA_REPORT_API_BASE_URL"]),//
                VERSION: Crypto.decrypt(process.env["REACT_APP_AUEA_VERSION"]),
                REGION:Crypto.decrypt(process.env["REACT_APP_AUEA_REGION"]),
                DEBUG: pilot
            },
            UKSO: {
                AZURE_APP_ID: Crypto.decrypt(process.env["REACT_APP_UKSO_AZURE_APP_ID"]),
                AZURE_APP_SCOPE: Crypto.decrypt(process.env["REACT_APP_UKSO_AZURE_APP_ID"]) + "/.default",
                AZURE_APP_EXPOSE_API_SCOPE: `api://${Crypto.decrypt(process.env["REACT_APP_UKSO_DOMAIN"])}/${Crypto.decrypt(process.env["REACT_APP_UKSO_AZURE_APP_ID"])}/user_access`,
                API_BASE_URL: Crypto.decrypt(process.env["REACT_APP_UKSO_API_BASE_URL"]),
                HUB_URL: Crypto.decrypt(process.env["REACT_APP_UKSO_API_BASE_URL"]) + "/callhub",
                MESSAGE_API_BASE_URL: Crypto.decrypt(process.env["REACT_APP_UKSO_MESSAGE_API_BASE_URL"]) + "/api",//
                MESSAGE_HUB_URL: Crypto.decrypt(process.env["REACT_APP_UKSO_MESSAGE_API_BASE_URL"]) + "/chathub",//
                REPORT_API_BASE_URL: Crypto.decrypt(process.env["REACT_APP_UKSO_REPORT_API_BASE_URL"]),//
                VERSION: Crypto.decrypt(process.env["REACT_APP_UKSO_VERSION"]),
                REGION:Crypto.decrypt(process.env["REACT_APP_UKSO_REGION"]),
                DEBUG: pilot
            }
        }

        this._config = regions[region];

        if (userRegion ?? false) { 
            setApiDefault();
        }
    }
}

import { styled } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.background.default
    }
}));

const MuiDataGrid = ({
    loading = false,
    rows = [],
    columns = [],
    onPageChange,
    onSortModelChange,
    rowCount = 0,
    hideColumns = {},
    page = 0,
    pageSize = 15,
    footerComponent
}) => {
    const [paginationModel, setPaginationModel] = React.useState({
        page: page,
        pageSize: pageSize,
    });

    const [grid, setGrid] = React.useState(() => ({
        hide: hideColumns,
        columns: columns
    }))

    React.useEffect(() => {
        setPaginationModel(prev => ({ ...prev, page: page }));
    }, [page]);

    const columnResize = (params) => {
        setGrid((prev) => {
            let info = { ...prev }
            let index = info.columns.findIndex(x => x.field === params.colDef.field);

            if (index === -1)
                return prev;

            info.columns[index].width = params.colDef.width;
            info.columns[index].flex = 0;
            return info;
        })
    };

    return <StyledDataGrid
        loading={loading}
        slotProps={{
            loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
            },
        }}
        initialState={{
            pinnedColumns: {
                left: ['desk'],
            },
        }}
        autoHeight
        density="compact"
        editMode="row"
        rows={rows}
        columns={grid.columns}
        paginationMode="server"
        hideFooterSelectedRowCount
        paginationModel={paginationModel}
        onPaginationModelChange={(data) => {
            setPaginationModel(data)
            onPageChange(data.page)
        }}
        onSortModelChange={(data) => {
            if (data.length === 1)
                onSortModelChange([{ field: data[0].field.charAt(0).toUpperCase() + data[0].field.slice(1), sort: data[0].sort }])
        }
        }
        sortingMode="server"
        rowCount={rowCount}
        columnVisibilityModel={grid.hide}
        onColumnVisibilityModelChange={(newModel) => {
            setGrid(prev => {
                let info = { ...prev };
                info.hide = newModel;
                return info;
            })
        }}
        onColumnResize={columnResize}
        slots={{
            footer: footerComponent,
        }}
        disableColumnFilter
    />
}

export default MuiDataGrid;
import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { HandleServerResponse } from '../../helpers/Common';
import Input from '../../components/Inputs/Input';
import DropDown from '../../components/Inputs/DropDown';
import { makeStyles } from '@mui/styles';
import { ExternalConnectorService } from '../../services/ExternalConnectorService';

const useStyles = makeStyles(theme => ({
    dialog: {
        margin: 16,
        '& .MuiDialog-paperWidthMd': {
            width: 500
        }
    },
    actions: {
        margin: theme.spacing(2),
    },
}));

export default function OutboundSmsDialog({
    messageTemplate,
    messageTemplateReferenceData,
    chainId,
    referenceId,
    queueId,
    phone,
    handleClose,
    dispatch
    }){

    const classes = useStyles();
    const [values, setValues] = React.useState({
        templateId:messageTemplate.templateId,
        message:messageTemplateReferenceData.messageTemplates.find(m=>m.id == messageTemplate.templateId).text,
        phone: phone
    });
    const [errors, setErrors] = React.useState({
        message:'',
        phone:''
    });
    const [isValid, setIsValid] = React.useState(true);

    React.useEffect(() => {
        validate()
    }, [values]);

    const handleMessageInputChanges = (event) => {
        let { name, value } = event.target;
        setValues({ ...values, [name]: value })
    }

    const handleMessageTemplateChanges = (event) => {
        let { name, value } = event.target;
        setValues({ ...values, [name]: value, "message":  messageTemplateReferenceData.messageTemplates.find(m=>m.id == value).text})
    }

    const validate = () =>{
        let isValid = true;
        if (values.message == "") {
            setErrors({ ...errors, "message": "Required" })
            isValid = false;
        }
        if (values.message.length > 320) {
            setErrors({ ...errors, "message": "Maximum allowed character count is 320" })
            isValid = false;
        }
        if (values.phone == "") {
            setErrors({ ...errors, "phone": "Required" })
            isValid = false;
        }
        if(isValid){
            setErrors({...errors , "message":"", "phone":"" })
        }
        setIsValid(isValid);
    }

    const sendSms = () => {
        (async() => {
            if(isValid){
            let data = {
                ChainId: chainId,
                ReferenceId: referenceId,
                QueueId: queueId,
                Phone: values.phone,
                Message: values.message,
            }

            let result = await ExternalConnectorService.sendSms(data);

            HandleServerResponse(result, `SMS sent successfully to ${values.phone}`, dispatch);

            if (result.success) {
                handleClose();
            }
        }
        })();
    }

return(
    <React.Fragment>
        <Dialog className={classes.dialog} open={messageTemplate} aria-labelledby="form-dialog-title" maxWidth={"md"}>
                <DialogTitle id="form-dialog-title">Send Outbound SMS</DialogTitle>
                <DialogContent>
                        <DropDown
                            size="large"
                            required
                            label={"Message Template"}
                            value={values.templateId}
                            onChange={(e) => handleMessageTemplateChanges(e, false)}
                            values={messageTemplateReferenceData.messageTemplates}
                            name="templateId"
                        />
                        <Input
                                paddingRight={0}
                                multiline={true}
                                size="large"
                                name='message'
                                label="Message"
                                value={values.message}
                                onChange={(e) => handleMessageInputChanges(e)}
                                error={errors.message}
                        />
                        <DropDown
                            size="large"
                            disabled={true}
                            required
                            label={"SMS Connector"}
                            value={messageTemplate.connectorId}
                            onChange={(e) => handleMessageTemplateChanges(e, false)}
                            values={messageTemplateReferenceData.connectors}
                            name="connectorId"
                        />
                        <DropDown
                            size="large"
                            disabled={true}
                            required
                            label={"SMS Sender"}
                            value={messageTemplate.senderId}
                            onChange={(e) => handleMessageTemplateChanges(e, false)}
                            values={messageTemplateReferenceData.senders}
                            name="senderId"
                        />
                        <Input
                                paddingRight={0}
                                size="large"
                                name='phone'
                                label="To"
                                value={values.phone}
                                onChange={(e) => handleMessageInputChanges(e)}
                                error={errors.phone}
                        />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={sendSms} variant="contained" color="primary" disableElevation>
                        Send
                    </Button>
                    <Button onClick={handleClose} variant="contained" disableElevation>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
    </React.Fragment>
)
}
import React from "react";
import { Alert } from '@mui/material';

const EmergencyRedirectionOn = props => {
    return (
        <Alert severity="error">
            To ensure there is no service impact during any major outage (or maintenance), 
            all inbound customer calls now being redirected to {props.emergencyRedirectionTarget}. 
            Please contact your Administrator for more information
        </Alert>
    );
}

export default EmergencyRedirectionOn;
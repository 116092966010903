import axios from 'axios'

const Answer = async (values) => {
    const {data} =  await axios.post('api/call/answer',values).then(result => result);
    return data;
};  

const Invite = async (values) => {
    const {data} =  await axios.post('api/call/invite',values).then(result => result);
    return data;
};  

const WaitingVerify = async (values) => {
    const {data} =  await axios.post('api/call/waitingverify',values).then(result => result);
    return data;
};

const ActiveVerify = async (values) => {
    const {data} =  await axios.post('api/call/activeverify',values).then(result => result);
    return data;
};

const Mute = async (values) => {
    const {data} =  await axios.post('api/call/mute',values).then(result => result);
    return data;
};

const Unmute = async (values) => {
    const {data} =  await axios.post('api/call/unmute',values).then(result => result);
    return data;
};

const Hangup = async (values) => {
    const {data} =  await axios.post('api/call/hangup',values).then(result => result);
    return data;
};

const TransferToApplication = async (values) => {
    const {data} =  await axios.post('api/call/transfertoapplication',values).then(result => result);
    return data;
};

const TransferToUser = async (values) => {
    const {data} =  await axios.post('api/call/transfertouser',values).then(result => result);
    return data;
};

const Bargedirect = async (values) => {
    const {data} =  await axios.post('api/call/bargedirect',values).then(result => result);
    return data;
};


const Monitoring = async (values) => {
    const {data} =  await axios.post('api/call/monitoring',values).then(result => result);
    return data;
};


const Whisper = async (values) => {
    const {data} =  await axios.post('api/call/whisper',values).then(result => result);
    return data;
};

const TransferWaitingToQueue = async (values) => {
    const {data} =  await axios.post('api/call/transferwaitingtoqueue',values).then(result => result);
    return data;
};

const TransferActiveToQueue = async (values) => {
    const {data} =  await axios.post('api/call/transferactivetoqueue',values).then(result => result);
    return data;
};

const TransferToMailbox = async (values) => {
    const {data} =  await axios.post('api/call/transfertomailbox',values).then(result => result);
    return data;
};


const TransferToUserVoiceMail = async (values) => {
    const {data} =  await axios.post('api/call/transfertouservoicemail',values).then(result => result);
    return data;
};

const WrapupExtend = async (values) => {
    const { data } = await axios.post('api/call/wrapupextend', values).then(result => result);
    return data;
};

const WrapupEnd = async (values) => {
    const { data } = await axios.post('api/call/wrapupend', values).then(result => result);
    return data;
};

const CreateNewCall = async (values) => {
    const { data } = await axios.post('api/call/phone', values).then(result => result);
    return data;
};

const WaitingEnd = async (values) => {
    const { data } = await axios.post('api/call/waitingend', values).then(result => result);
    return data;
};

const ActiveEnd = async (values) => {
    const { data } = await axios.post('api/call/activeend', values).then(result => result);
    return data;
};

const SaveCallNote = async (values) => {
    const {data} =  await axios.post('api/call/note',values).then(result => result);
    return data;
};

const dashboardStats = async (values) => {
    const { data } = await axios.get('/api/call/dashboardstats').then(result => result);
    return data;
};

const CallUsers = async () => {
    const { data } = await axios.get('/api/call/supervisorusers').then(result => result);
    return data;
};

const GetAllActiveQueues = async () => {
    const { data } = await axios.get('/api/call/allqueues').then(result => result);
    return data;
};

const CallHistoryQueues = async () => {
    const { data } = await axios.get('/api/call/callhistoryqueues').then(result => result);
    return data;
};

const GetUserCallHitory = async (values) => {
    const { data } = await axios.get('/api/call/userhistory',{params: values}).then(result => result);
    return data;
};

const GetCalleCallHitory = async (values) => {
    const { data } = await axios.get(`/api/call/phonehistory`,{params: values}).then(result => result);
    return data;
};

const GetMainCallHistory = async (values) => {
    const { data } = await axios.get(`/api/call/search`,{params: values}).then(result => result);
    return data;
};

const GetUserCallRecord = async (id) => {
    const { data } = await axios.get(`/api/call/history/${id}`).then(result => result);
    return data;
};

const PlayAnnouncment = async (values) => {
    const { data } = await axios.get(`/api/call/announcement/play`, {params: values}).then(result => result);
    return data;
};

const CancelAnnouncement = async (values) => {
    const { data } = await axios.get(`/api/call/announcement/cancel`, {params: values}).then(result => result);
    return data;
};

const CallbackList = async (values) => {
    const { data } = await axios.get(`/api/call/callback/list`, {params: values}).then(result => result);
    return data;
};

const CallbackAccept = async (id) => {
    const { data } = await axios.get(`/api/call/callback/accept/${id}`).then(result => result);
    return data;
};

const CallbackReject = async (id) => {
    const { data } = await axios.get(`/api/call/callback/reject/${id}`).then(result => result);
    return data;
};

const CallbackCount = async () => {
    const { data } = await axios.get(`/api/call/callback/count`).then(result => result);
    return data;
};

const GetOutcomesByQueue = async (queueId, oldCall=false) => {
    const { data } = await axios.get(`/api/call/outcomes/${queueId}`,{params: oldCall}).then(result => result);
    return data;
};

const SaveOutcome = async (values) => {
    const result =  await axios.post('api/call/outcome',values).then(result => result);
    return result;
};

const GetCallHistoryByChainId = async (chainId,oldCall=false) => {
    const { data } = await axios.get(`/api/call/currenetcall/${chainId}`,{params: oldCall}).then(result => result);
    return data;
};

const hangupWithCallFlow = async (values) => {
    const { data } =  await axios.post('api/call/hangupwithcallflow',values).then(result => result);
    return data;
};

const updateCallIntegrationData = async (values) => {
    const { data } =  await axios.post('api/call/integration',values).then(result => result);
    return data;
};

const callOnhold = async (values) => {
    const { data } =  await axios.post('api/call/onhold',values).then(result => result);
    return data;
};

const ConsultTransfer = async (values) => {
    const { data } = await axios.post('api/call/consulttransfer', values).then(result => result);
    return data;
};

const GetInternalUserDisplayName = async (id) => {
    const { data } = await axios.get(`api/call/internaluser/${id}`).then(result => result);
    return data;
};

const GetActiveCallReferences = async (id) => {
    const { data } = await axios.get(`api/call/references`).then(result => result);
    return data;
};

const CallJourney = async (id) => {
    const { data } = await axios.get(`api/call/${id}/journey`).then(result => result);
    return data;
};

export const CallService = {
    Answer,
    Invite,
    WaitingVerify,
    ActiveVerify,
    Mute,
    Unmute,
    Hangup,
    TransferToApplication,
    TransferToUser,
    Bargedirect,
    Monitoring,
    Whisper,
    TransferWaitingToQueue,
    TransferActiveToQueue,
    TransferToMailbox,
    TransferToUserVoiceMail,
    WrapupExtend,
    WrapupEnd,
    CreateNewCall,
    WaitingEnd,
    ActiveEnd,
    SaveCallNote,
    dashboardStats,
    CallUsers,
    GetAllActiveQueues,
    CallHistoryQueues,
    GetUserCallHitory,
    GetCalleCallHitory,
    GetMainCallHistory,
    GetUserCallRecord,
    PlayAnnouncment,
    CancelAnnouncement,
    CallbackList,
    CallbackAccept,
    CallbackReject,
    CallbackCount,
    GetOutcomesByQueue,
    SaveOutcome,
    GetCallHistoryByChainId,
    hangupWithCallFlow,
    updateCallIntegrationData,
    callOnhold,
    ConsultTransfer,
    GetInternalUserDisplayName,
    GetActiveCallReferences,
    CallJourney
};
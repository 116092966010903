import axios from "axios";

const ConnectJira = async (values) => {
    const { data } = await axios.post('/api/jira/connect-jira', values).then(result => result);
    return data;
}

const GetProjectList = async (values) => {
    const { data } = await axios.get(`/api/jira/get-projects`, { params: values }).then(result => result);
    return data;
}

const GetJiraReferenceData = async (values) => {
    const { data } = await axios.get(`/api/jira/references`, { params: values }).then(result => result);
    return data;
}

const GetAllRequestTypeFields = async (values) => {
    const { data } = await axios.get('/api/jira/fields', { params: values }).then(result => result);
    return data;
}

const CreateNewIssue = async (values) => {
    const { data } = await axios.post('/api/jira/create', values).then(result => result);
    return data;
}

const ViewIssue = async (values) => {
    const { data } = await axios.get('/api/jira/view-issue', { params: values }).then(result => result);
    return data;
}

const SearchIssues = async (values) => {
    const { data } = await axios.get('/api/jira/search-issues', { params: values }).then(result => result);
    return data;
}

const GetTransitions = async (values) => {
    const { data } = await axios.get('/api/jira/transitions', { params: values }).then(result => result);
    return data;
}

const PerformTransition = async (values) => {
    const { data } = await axios.post('/api/jira/transition', values).then(result => result);
    return data;
}

const AddComment = async (values) => {
    const { data } = await axios.post('/api/jira/add-comment', values).then(result => result);
    return data;
}

const GetCustomers = async (values) => {
    const { data } = await axios.get('/api/jira/get-customers', { params: values }).then(result => result);
    return data;
}

const SearchAssets = async (values) => {
    const { data } = await axios.get('/api/jira/assets', { params: values }).then(result => result);
    return data;
}

const GetAccessToken = async (values) => {
    const { data } = await axios.post('/api/jira/get-access-token', values).then(result => result);
    return data;
}

export const JiraService = {
    ConnectJira,
    GetProjectList,
    GetJiraReferenceData,
    GetAllRequestTypeFields,
    CreateNewIssue,
    ViewIssue,
    SearchIssues,
    GetTransitions,
    AddComment,
    GetAccessToken,
    GetCustomers,
    SearchAssets,
    PerformTransition
};
import { Box } from '@mui/system';
import React from 'react'
import { useTranslation } from 'react-i18next';
import JiraFields from './JiraFields';
import AutoCompleteSearch from '../../../components/Inputs/AutoCompleteSearch';

const JiraForm = ({
    jiraConnector,
    closeModal,
    requestTypeBasicInfo,
    requestTypes,
    handleSelectRequestType,
    loading,
    requestTypeTemplate,
    setRequestTypeTemplate,
    issueValues,
    setIssueValues,
    errors,
    setErrors,
    chainId,
    referenceId,
    setCall
}) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: '50%' }}>
                    <AutoCompleteSearch
                        required
                        full={true}
                        disabled={loading}
                        value={requestTypeBasicInfo.requestType}
                        onChange={(e) => handleSelectRequestType(e)}
                        options={requestTypes}
                        label={t("Request Type")}
                        name={"requestType"}
                    />
                    {requestTypeBasicInfo.requestType !== null &&
                        <JiraFields
                            requestTypeId={requestTypeBasicInfo?.requestType?.id}
                            jiraConnector={jiraConnector}
                            closeModal={closeModal}
                            requestTypeTemplate={requestTypeTemplate}
                            setRequestTypeTemplate={setRequestTypeTemplate}
                            issueValues={issueValues}
                            setIssueValues={setIssueValues}
                            errors={errors}
                            setErrors={setErrors}
                            loading={loading}
                            chainId={chainId}
                            referenceId={referenceId}
                            setCall={setCall}
                        />
                    }
                </Box>
            </Box>
        </React.Fragment >
    )
}

export default JiraForm;
import React from "react";
import { useTheme } from "@mui/styles";
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { Box } from "@mui/system";
import CallDirectionSelector from "./CallDirectionSelector";
import QueueSelector from "./QueueSelector";
import { useTranslation } from "react-i18next";

function Filter({ callDirections, loading, queues, setCallDirections, setQueues }) {
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        actionButton: {
            minWidth: 25
        },
        popper: {
            width: 185,
            pr: theme.spacing(2)
        },
    }

    const [open, setOpen] = React.useState(false);
    const [openDirection, setOpenDirection] = React.useState(false);
    const [openQueueSelector, setOpenQueueSelector] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleMenuClose = (event) => {
        if (open) {
            setOpen(false);
            return;
        }

        if (openDirection) {
            setOpenDirection(false);
            setOpen(true);
            return;
        }

        if (openQueueSelector) {
            setOpenQueueSelector(false);
            setOpen(true);
            return;
        }
    }

    const handleOpenDirectionMenu = () => {
        setOpen(false);
        setOpenDirection(true);
    };

    const handleOpenQueueSelectorMenu = () => {
        setOpen(false);
        setOpenQueueSelector(true);
    };

    return (
        <React.Fragment>
            <ClickAwayListener onClickAway={(e) => handleMenuClose(e)}>
                <div>
                    <Button
                        disabled={loading || openDirection || openQueueSelector}
                        sx={styles.actionButton}
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={() => setOpen((prev) => !prev)}
                        title={t("Filter")}
                        ref={anchorRef}
                    >
                        <FilterAlt />
                    </Button>
                    <Popper sx={styles.popper} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <Box>
                                        <Box sx={{ position: 'relative' }}>
                                            <MenuList>
                                                <MenuItem
                                                    id="composition-button"
                                                    aria-controls={open ? 'composition-menu' : undefined}
                                                    aria-expanded={open ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={handleOpenDirectionMenu}
                                                >
                                                    {t("Directions")}
                                                </MenuItem>
                                                <MenuItem
                                                    id="composition-button"
                                                    aria-controls={open ? 'composition-menu' : undefined}
                                                    aria-expanded={open ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={handleOpenQueueSelectorMenu}
                                                >
                                                    {t("Queues")}
                                                </MenuItem>
                                            </MenuList>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <CallDirectionSelector
                        callDirections={callDirections}
                        onChange={setCallDirections}
                        openDirection={openDirection}
                        anchorRef={anchorRef}
                    />
                    <QueueSelector
                        queues={queues}
                        openQueueSelector={openQueueSelector}
                        anchorRef={anchorRef}
                        onChange={setQueues}
                    />
                </div>
            </ClickAwayListener>
        </React.Fragment >
    )
}

export default Filter

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ data, indexAxis = 'x' }) => {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    indexAxis: indexAxis,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
          boxHeight: 10,
          padding: 15
        },
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            //special condition to hide other call labels on IVR bar in call status widget
            if (context.label === "IVR") {
              if (context.dataset.label === "IVR") {
                return [`${context.dataset.label}: ${context.formattedValue}`];
              }
              return;
            }

            return [`${context.dataset.label}: ${context.formattedValue}`];
          }
        }
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      axis: indexAxis,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          stepSize: 1
        }
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1
        }
      },
    },
  };

  return <Bar options={options} data={data} />;
}

export default BarChart
import React from 'react'
import { Card, Grid, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import UserOrQueueSelectDialog from '../components/dialogs/UserOrQueueSelectDialog'
import CompleteDialog from '../components/dialogs/CompleteDialog'
import { updateCurrentStatus, UpdateStatus } from '../ConversationSlice'
import { HandleServerResponse } from '../../../helpers/Common'
import { ConversationOperation, ConversationType } from '../../../helpers/Constant'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import { ConversationService } from '../../../services/ConversationService'
import BasicSummaryInfo from './BasicSummaryInfo'
import AiSummaryInfo from '../ai/AiSummaryInfo'

const Summary = () => {
    const Root = styled(Card)(({ theme }) => ({
        width: '100%',
        borderRadius: '4px',
        margin: '0 auto 10px',
        paddingBottom: 8
    }));

    const dispatch = useDispatch();

    const [openQueueUserTransfer, setOpenQueueUserTransfer] = React.useState(false);
    const [openMarkCompleteDialog, setOpenMarkCompleteDialog] = React.useState(false);
    const [selectedTransferType, setSelectedTransferType] = React.useState(null);
    const [openMarkAsSpam, setOpenMarkAsSpam] = React.useState(false);

    const { id, status, queueId } = useSelector(state => state.conversation.current);

    const closePopup = () => {
        setOpenQueueUserTransfer(false);
    }

    const handleMarkAsSpam = async () => {
        let params = {
            Id: id,
            ConversationType: ConversationType.Email,
            ConversationOperation: ConversationOperation.MarkAsSpam
        }

        var result = await ConversationService.HandleConversation(params);
        HandleServerResponse(result, "Conversation marked as spam", dispatch);

        if (result?.success) {
            let obj = {
                id: id,
                type: ConversationType.Email
            }
            dispatch(updateCurrentStatus(obj));
        }
        setOpenMarkAsSpam(false)
    };

    return (
        <Root variant="outlined" >
            <Grid container padding={2} spacing={2} paddingBottom={0}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <BasicSummaryInfo />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AiSummaryInfo />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openQueueUserTransfer &&
                <UserOrQueueSelectDialog
                    handleClose={closePopup}
                    conversationId={id}
                    conversationStatus={status}
                    queueId={queueId}
                    open={openQueueUserTransfer}
                    type={selectedTransferType}
                />
            }
            {openMarkCompleteDialog &&
                <CompleteDialog
                    open={openMarkCompleteDialog}
                    setOpenMarkCompleteDialog={setOpenMarkCompleteDialog}
                    queueId={queueId}
                />
            }
            {openMarkAsSpam &&
                <ConfirmationDialog
                    open={openMarkAsSpam}
                    handleClose={async () => setOpenMarkAsSpam(false)}
                    confirmChange={async () => handleMarkAsSpam()}
                    message={"Are you sure you want to mark this conversation as spam?"}
                />
            }
        </Root>
    )
}

export default Summary;
import React from 'react'

const Notification = ({ count }) => {
    const styles = {
        count: {
            borderRadius: '50%',
            height: '20px',
            width: '20px',
            textAlign: 'center',
            background: 'gray', //TODO update color
            color: 'white',
            fontSize: '10px',
            padding: '4px 0px 0px 0px'
        },
    }

    return <div style={styles.count}>{count}</div>
}

export default Notification
import React from 'react'
import Property from './SummaryProperty';
import { formatToLongDateTime } from '../../../helpers/Common';
import { useSelector } from 'react-redux';

const BasicSummaryInfo = () => {
    const { subject, from, to, cc, date } = useSelector(state => state.conversation.current);

    return (
        <div>
            <Property name={"Subject"} value={subject} />
            <Property name={"From"} value={from?.join(', ')} />
            <Property name={"To"} value={to?.join(', ')} />
            <Property name={"Cc"} value={cc?.join(', ')} />
            <Property name={"Date"} value={formatToLongDateTime(date)} />
        </div>
    )
};

export default BasicSummaryInfo;
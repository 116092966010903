import { Providers } from "@microsoft/mgt";
import Environment from "../Environment";
import { LogInformation, getLocalStorage } from "../helpers/Common";

const getToken = async () => { 
    if (Providers.globalProvider) {
        const token = await Providers.globalProvider.getAccessToken([]);

        if (token) {
            return `Bearer ${token}`;
        }
    } else {
        const token = getLocalStorage('access-token');
        if (token) { 
            return `Bearer ${token}`;
        }
    }

    return "";
}

const LoadChats = async () => {
    let options = {
        headers: { Authorization: await getToken() }
    }

    let result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/list`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => {LogInformation("error occured when loading chat.", error)}
    );
    
    return result;
}; 

const AcceptChat = async (chatId) => {
    let options = {
        method: 'POST',
        headers: { Authorization: await getToken() },
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/accept/${chatId}`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => {LogInformation("error occured when chat accepted.", error)}
    );
    
    return result;
}; 

const EndChat = async (id) => {
    let options = {
        method: 'POST',
        headers: { Authorization: await getToken() },
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/agent-end/${id}`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => { LogInformation("error occured when chat ended.", error) }
        );

    return result;
}; 

const Outcomes = async (queueId) => {
    let options = {
        method: 'GET',
        headers: { Authorization: await getToken() },
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/outcomes/${queueId}`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => { LogInformation("error occured when loading outcomes.", error) }
        );

    return result;
};

const Summarize = async (id) => {
    let options = {
        method: 'GET',
        headers: { Authorization: await getToken() },
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/summarize/${id}`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => { LogInformation("error occured when generating summary.", error) }
        );

    return result;
};

const SetChatAction = async (id, values) => {
    let options = {
        method: 'POST',
        headers: { Authorization: await getToken(), 'Content-Type': 'application/json' },
        body: JSON.stringify(values), 
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/action/${id}`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => { LogInformation("error occured in chat action.", error) }
        );

    return result;
}; 

const Queues = async (queueId) => {
    let options = {
        method: 'GET',
        headers: { Authorization: await getToken() },
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/queues/${queueId}`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => { LogInformation("error occured when getting queues.", error) }
        );

    return result;
};

const TransferToQueue = async (values) => {
    let options = {
        method: 'POST',
        headers: { Authorization: await getToken(), 'Content-Type': 'application/json' },
        body: JSON.stringify(values), 
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/transferToQueue`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => { LogInformation("error occured when transfering to queue.", error) }
        );

    return result;
};

const Users = async (values) => {
    let options = {
        method: 'POST',
        headers: { Authorization: await getToken(), 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/search`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => { LogInformation("error occured when getting users.", error) }
        );

    return result;
};

const TransferToAgent = async (chatId, userId) => {
    let options = {
        method: 'POST',
        headers: { Authorization: await getToken() },
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/transferToAgent/${chatId}/${userId}`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => { LogInformation("error occured when transfering to user.", error) }
        );

    return result;
};

const StartTimer = async (chatId) => {
    let options = {
        method: 'POST',
        headers: { Authorization: await getToken() },
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/startTimer/${chatId}`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => { LogInformation("error occured when starting timer.", error) }
        );

    return result;
};

const RejectChat = async (chatId) => {
    let options = {
        method: 'POST',
        headers: { Authorization: await getToken() },
    }

    var result = await fetch(`${Environment.config.API_BASE_URL}/api/agent/reject/${chatId}`, options)
        .then(res => res.json())
        .then(
            (result) => result,
            (error) => {LogInformation("error occured when chat rejected.", error)}
    );
    
    return result;
};

export const ChatService = {
    LoadChats,
    AcceptChat,
    EndChat,
    SetChatAction,
    Outcomes,
    Summarize,
    Queues,
    TransferToQueue,
    Users,
    TransferToAgent,
    StartTimer,
    RejectChat
}
import { FiberManualRecord } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Header = ({ waiting = 0, active = 0, wrapup = 0, transferred = 0 }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        root: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 4,
            paddingBottom: 4
        },
        headerText: {
            ml: theme.spacing(1),
            mr: theme.spacing(2),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        activeIcon: {
            color: theme.palette.success.main,
            fontSize: theme.spacing(2)
        },
        activeCount: {
            marginLeft: theme.spacing(0),
            mr: theme.spacing(1)
        },
        wrapupIcon: {
            color: theme.palette.grey.dark,
            fontSize: theme.spacing(2)
        },
        wrapupCount: {
            marginLeft: theme.spacing(0),
            mr: theme.spacing(1)
        },
        errorIcon: {
            color: theme.palette.error.main,
            fontSize: theme.spacing(2)
        },
        errorCount: {
            marginLeft: theme.spacing(0),
            mr: theme.spacing(1)
        },
        transferIcon: {
            color: theme.palette.warning.main,
            fontSize: theme.spacing(2)
        },
        transfereCount: {
            marginLeft: theme.spacing(0),
            mr: theme.spacing(1)
        },
    }

    return (
        <div>
            <div style={styles.root}>
                <Typography sx={styles.headerText} variant="h6" color="textSecondary">
                    {t("Message interactions")}
                </Typography>
                <FiberManualRecord sx={styles.errorIcon} />
                <Typography sx={styles.errorCount} variant="body2">{waiting}</Typography>
                <FiberManualRecord sx={styles.activeIcon} />
                <Typography sx={styles.activeCount} variant="body2">{active}</Typography>
                <FiberManualRecord sx={styles.transferIcon} />
                <Typography sx={styles.transfereCount} variant="body2">{transferred}</Typography>
                <FiberManualRecord sx={styles.wrapupIcon} />
                <Typography sx={styles.wrapupCount} variant="body2">{wrapup}</Typography>
            </div>
            <Divider />
        </div>
    )
}

export default Header;
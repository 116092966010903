import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: "50%",
            padding: theme.spacing(0,2,1,0)
        }   
    }
}));


export default function Form(props) {
    const classes = useStyles();
    const {children, ...other} = props;
    return (
        <form autoComplete="off" className={classes.root} {...other}>
            {children}
        </form>
    )
}
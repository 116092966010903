import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import InteractionSlice from "./features/app/InteractionSlice";
import AuthSlice from "./features/auth/AuthSlice";
import AcSlice from "./features/attendent-console/AcSlice";

import SignalRMiddleware from './SignalRMiddleware';
import ChatSlice from "./features/chat/ChatSlice";
import ConversationSlice from "./features/conversation/ConversationSlice";

const rootReducer = combineReducers({
    auth: AuthSlice,
    interaction: InteractionSlice,
    ac: AcSlice,
    chat: ChatSlice,
    conversation: ConversationSlice
});

export default configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(SignalRMiddleware),
});
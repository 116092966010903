import React from 'react'
import "../styles/index.css"
import { useTheme } from '@mui/styles';
import { Send } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import { ChatStatus } from '../../../helpers/Constant';
import { useTranslation } from 'react-i18next';
import { checkUserPermission } from '../../../helpers/Common';

const Message = ({ send, status, permissions }) => {
    const [value, setValue] = React.useState("");
    const theme = useTheme();
    const { t } = useTranslation();

    const styles = {
        root: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            marginTop: theme.spacing(1),
            width: 'auto',
            display: 'flex',
            // justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px'
        },
        input: {
            resize: 'none',
            width: '90%',
            margin: 0,
            // backgroundColor: '#ffffff',
        },
        sendButton: {
            height: '32px', 
            marginLeft: theme.spacing(0.5), 
            minWidth: '8%'
        }
    }

    const sendMessage = () => {
        if (value !== "") {
            send(value);
            setValue(() => "");
        }
    }

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            sendMessage();
        } else {
            setValue(e.target.value)
        }
    }

    return (
        <div style={styles.root}>
            <TextField
                sx={styles.input}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={onKeyDown}
                disabled={status === ChatStatus.Waiting || status === ChatStatus.ActiveAgentTransferred || !checkUserPermission(permissions, ["conversation.chat"])}
                placeholder={t("Write a message...")}
            />
            <Button
                sx={styles.sendButton}
                onClick={() => sendMessage()}
                disabled={value === "" || status === ChatStatus.Waiting || status === ChatStatus.ActiveAgentTransferred || !checkUserPermission(permissions, ["conversation.chat"])}
            >
                <Send fontSize='small' />
            </Button>
        </div>
    )
}

export default Message
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DropDown from "./DropDown";

const countryCodes = [
      {
        "id": "+7 840",
        "name": "Abkhazia"
      },
      {
        "id": "+93",
        "name": "Afghanistan"
      },
      {
        "id": "+355",
        "name": "Albania"
      },
      {
        "id": "+213",
        "name": "Algeria"
      },
      {
        "id": "+1 684",
        "name": "American Samoa"
      },
      {
        "id": "+376",
        "name": "Andorra"
      },
      {
        "id": "+244",
        "name": "Angola"
      },
      {
        "id": "+1 264",
        "name": "Anguilla"
      },
      {
        "id": "+1 268",
        "name": "Antigua and Barbuda"
      },
      {
        "id": "+54",
        "name": "Argentina"
      },
      {
        "id": "+374",
        "name": "Armenia"
      },
      {
        "id": "+297",
        "name": "Aruba"
      },
      {
        "id": "+247",
        "name": "Ascension"
      },
      {
        "id": "+61",
        "name": "Australia"
      },
      {
        "id": "+672",
        "name": "Australian External Territories"
      },
      {
        "id": "+43",
        "name": "Austria"
      },
      {
        "id": "+994",
        "name": "Azerbaijan"
      },
      {
        "id": "+1 242",
        "name": "Bahamas"
      },
      {
        "id": "+973",
        "name": "Bahrain"
      },
      {
        "id": "+880",
        "name": "Bangladesh"
      },
      {
        "id": "+1 246",
        "name": "Barbados"
      },
      {
        "id": "+1 268",
        "name": "Barbuda"
      },
      {
        "id": "+375",
        "name": "Belarus"
      },
      {
        "id": "+32",
        "name": "Belgium"
      },
      {
        "id": "+501",
        "name": "Belize"
      },
      {
        "id": "+229",
        "name": "Benin"
      },
      {
        "id": "+1 441",
        "name": "Bermuda"
      },
      {
        "id": "+975",
        "name": "Bhutan"
      },
      {
        "id": "+591",
        "name": "Bolivia"
      },
      {
        "id": "+387",
        "name": "Bosnia and Herzegovina"
      },
      {
        "id": "+267",
        "name": "Botswana"
      },
      {
        "id": "+55",
        "name": "Brazil"
      },
      {
        "id": "+246",
        "name": "British Indian Ocean Territory"
      },
      {
        "id": "+1 284",
        "name": "British Virgin Islands"
      },
      {
        "id": "+673",
        "name": "Brunei"
      },
      {
        "id": "+359",
        "name": "Bulgaria"
      },
      {
        "id": "+226",
        "name": "Burkina Faso"
      },
      {
        "id": "+257",
        "name": "Burundi"
      },
      {
        "id": "+855",
        "name": "Cambodia"
      },
      {
        "id": "+237",
        "name": "Cameroon"
      },
      {
        "id": "+1",
        "name": "Canada"
      },
      {
        "id": "+238",
        "name": "Cape Verde"
      },
      {
        "id": "+ 345",
        "name": "Cayman Islands"
      },
      {
        "id": "+236",
        "name": "Central African Republic"
      },
      {
        "id": "+235",
        "name": "Chad"
      },
      {
        "id": "+56",
        "name": "Chile"
      },
      {
        "id": "+86",
        "name": "China"
      },
      {
        "id": "+57",
        "name": "Colombia"
      },
      {
        "id": "+269",
        "name": "Comoros"
      },
      {
        "id": "+242",
        "name": "Congo"
      },
      {
        "id": "+243",
        "name": "Congo, Dem. Rep. of (Zaire)"
      },
      {
        "id": "+682",
        "name": "Cook Islands"
      },
      {
        "id": "+506",
        "name": "Costa Rica"
      },
      {
        "id": "+385",
        "name": "Croatia"
      },
      {
        "id": "+53",
        "name": "Cuba"
      },
      {
        "id": "+599",
        "name": "Curacao"
      },
      {
        "id": "+537",
        "name": "Cyprus"
      },
      {
        "id": "+420",
        "name": "Czech Republic"
      },
      {
        "id": "+45",
        "name": "Denmark"
      },
      {
        "id": "+246",
        "name": "Diego Garcia"
      },
      {
        "id": "+253",
        "name": "Djibouti"
      },
      {
        "id": "+1 767",
        "name": "Dominica"
      },
      {
        "id": "+1 809",
        "name": "Dominican Republic"
      },
      {
        "id": "+670",
        "name": "East Timor"
      },
      {
        "id": "+56",
        "name": "Easter Island"
      },
      {
        "id": "+593",
        "name": "Ecuador"
      },
      {
        "id": "+20",
        "name": "Egypt"
      },
      {
        "id": "+503",
        "name": "El Salvador"
      },
      {
        "id": "+240",
        "name": "Equatorial Guinea"
      },
      {
        "id": "+291",
        "name": "Eritrea"
      },
      {
        "id": "+372",
        "name": "Estonia"
      },
      {
        "id": "+251",
        "name": "Ethiopia"
      },
      {
        "id": "+500",
        "name": "Falkland Islands"
      },
      {
        "id": "+298",
        "name": "Faroe Islands"
      },
      {
        "id": "+679",
        "name": "Fiji"
      },
      {
        "id": "+358",
        "name": "Finland"
      },
      {
        "id": "+33",
        "name": "France"
      },
      {
        "id": "+596",
        "name": "French Antilles"
      },
      {
        "id": "+594",
        "name": "French Guiana"
      },
      {
        "id": "+689",
        "name": "French Polynesia"
      },
      {
        "id": "+241",
        "name": "Gabon"
      },
      {
        "id": "+220",
        "name": "Gambia"
      },
      {
        "id": "+995",
        "name": "Georgia"
      },
      {
        "id": "+49",
        "name": "Germany"
      },
      {
        "id": "+233",
        "name": "Ghana"
      },
      {
        "id": "+350",
        "name": "Gibraltar"
      },
      {
        "id": "+30",
        "name": "Greece"
      },
      {
        "id": "+299",
        "name": "Greenland"
      },
      {
        "id": "+1 473",
        "name": "Grenada"
      },
      {
        "id": "+590",
        "name": "Guadeloupe"
      },
      {
        "id": "+1 671",
        "name": "Guam"
      },
      {
        "id": "+502",
        "name": "Guatemala"
      },
      {
        "id": "+224",
        "name": "Guinea"
      },
      {
        "id": "+245",
        "name": "Guinea-Bissau"
      },
      {
        "id": "+595",
        "name": "Guyana"
      },
      {
        "id": "+509",
        "name": "Haiti"
      },
      {
        "id": "+504",
        "name": "Honduras"
      },
      {
        "id": "+852",
        "name": "Hong Kong SAR China"
      },
      {
        "id": "+36",
        "name": "Hungary"
      },
      {
        "id": "+354",
        "name": "Iceland"
      },
      {
        "id": "+91",
        "name": "India"
      },
      {
        "id": "+62",
        "name": "Indonesia"
      },
      {
        "id": "+98",
        "name": "Iran"
      },
      {
        "id": "+964",
        "name": "Iraq"
      },
      {
        "id": "+353",
        "name": "Ireland"
      },
      {
        "id": "+972",
        "name": "Israel"
      },
      {
        "id": "+39",
        "name": "Italy"
      },
      {
        "id": "+225",
        "name": "Ivory Coast"
      },
      {
        "id": "+1 876",
        "name": "Jamaica"
      },
      {
        "id": "+81",
        "name": "Japan"
      },
      {
        "id": "+962",
        "name": "Jordan"
      },
      {
        "id": "+7 7",
        "name": "Kazakhstan"
      },
      {
        "id": "+254",
        "name": "Kenya"
      },
      {
        "id": "+686",
        "name": "Kiribati"
      },
      {
        "id": "+965",
        "name": "Kuwait"
      },
      {
        "id": "+996",
        "name": "Kyrgyzstan"
      },
      {
        "id": "+856",
        "name": "Laos"
      },
      {
        "id": "+371",
        "name": "Latvia"
      },
      {
        "id": "+961",
        "name": "Lebanon"
      },
      {
        "id": "+266",
        "name": "Lesotho"
      },
      {
        "id": "+231",
        "name": "Liberia"
      },
      {
        "id": "+218",
        "name": "Libya"
      },
      {
        "id": "+423",
        "name": "Liechtenstein"
      },
      {
        "id": "+370",
        "name": "Lithuania"
      },
      {
        "id": "+352",
        "name": "Luxembourg"
      },
      {
        "id": "+853",
        "name": "Macau SAR China"
      },
      {
        "id": "+389",
        "name": "Macedonia"
      },
      {
        "id": "+261",
        "name": "Madagascar"
      },
      {
        "id": "+265",
        "name": "Malawi"
      },
      {
        "id": "+60",
        "name": "Malaysia"
      },
      {
        "id": "+960",
        "name": "Maldives"
      },
      {
        "id": "+223",
        "name": "Mali"
      },
      {
        "id": "+356",
        "name": "Malta"
      },
      {
        "id": "+692",
        "name": "Marshall Islands"
      },
      {
        "id": "+596",
        "name": "Martinique"
      },
      {
        "id": "+222",
        "name": "Mauritania"
      },
      {
        "id": "+230",
        "name": "Mauritius"
      },
      {
        "id": "+262",
        "name": "Mayotte"
      },
      {
        "id": "+52",
        "name": "Mexico"
      },
      {
        "id": "+691",
        "name": "Micronesia"
      },
      {
        "id": "+1 808",
        "name": "Midway Island"
      },
      {
        "id": "+373",
        "name": "Moldova"
      },
      {
        "id": "+377",
        "name": "Monaco"
      },
      {
        "id": "+976",
        "name": "Mongolia"
      },
      {
        "id": "+382",
        "name": "Montenegro"
      },
      {
        "id": "+1664",
        "name": "Montserrat"
      },
      {
        "id": "+212",
        "name": "Morocco"
      },
      {
        "id": "+95",
        "name": "Myanmar"
      },
      {
        "id": "+264",
        "name": "Namibia"
      },
      {
        "id": "+674",
        "name": "Nauru"
      },
      {
        "id": "+977",
        "name": "Nepal"
      },
      {
        "id": "+31",
        "name": "Netherlands"
      },
      {
        "id": "+599",
        "name": "Netherlands Antilles"
      },
      {
        "id": "+1 869",
        "name": "Nevis"
      },
      {
        "id": "+687",
        "name": "New Caledonia"
      },
      {
        "id": "+64",
        "name": "New Zealand"
      },
      {
        "id": "+505",
        "name": "Nicaragua"
      },
      {
        "id": "+227",
        "name": "Niger"
      },
      {
        "id": "+234",
        "name": "Nigeria"
      },
      {
        "id": "+683",
        "name": "Niue"
      },
      {
        "id": "+672",
        "name": "Norfolk Island"
      },
      {
        "id": "+850",
        "name": "North Korea"
      },
      {
        "id": "+1 670",
        "name": "Northern Mariana Islands"
      },
      {
        "id": "+47",
        "name": "Norway"
      },
      {
        "id": "+968",
        "name": "Oman"
      },
      {
        "id": "+92",
        "name": "Pakistan"
      },
      {
        "id": "+680",
        "name": "Palau"
      },
      {
        "id": "+970",
        "name": "Palestinian Territory"
      },
      {
        "id": "+507",
        "name": "Panama"
      },
      {
        "id": "+675",
        "name": "Papua New Guinea"
      },
      {
        "id": "+595",
        "name": "Paraguay"
      },
      {
        "id": "+51",
        "name": "Peru"
      },
      {
        "id": "+63",
        "name": "Philippines"
      },
      {
        "id": "+48",
        "name": "Poland"
      },
      {
        "id": "+351",
        "name": "Portugal"
      },
      {
        "id": "+1 787",
        "name": "Puerto Rico"
      },
      {
        "id": "+974",
        "name": "Qatar"
      },
      {
        "id": "+262",
        "name": "Reunion"
      },
      {
        "id": "+40",
        "name": "Romania"
      },
      {
        "id": "+7",
        "name": "Russia"
      },
      {
        "id": "+250",
        "name": "Rwanda"
      },
      {
        "id": "+685",
        "name": "Samoa"
      },
      {
        "id": "+378",
        "name": "San Marino"
      },
      {
        "id": "+966",
        "name": "Saudi Arabia"
      },
      {
        "id": "+221",
        "name": "Senegal"
      },
      {
        "id": "+381",
        "name": "Serbia"
      },
      {
        "id": "+248",
        "name": "Seychelles"
      },
      {
        "id": "+232",
        "name": "Sierra Leone"
      },
      {
        "id": "+65",
        "name": "Singapore"
      },
      {
        "id": "+421",
        "name": "Slovakia"
      },
      {
        "id": "+386",
        "name": "Slovenia"
      },
      {
        "id": "+677",
        "name": "Solomon Islands"
      },
      {
        "id": "+27",
        "name": "South Africa"
      },
      {
        "id": "+500",
        "name": "South Georgia and the South Sandwich Islands"
      },
      {
        "id": "+82",
        "name": "South Korea"
      },
      {
        "id": "+34",
        "name": "Spain"
      },
      {
        "id": "+94",
        "name": "Sri Lanka"
      },
      {
        "id": "+249",
        "name": "Sudan"
      },
      {
        "id": "+597",
        "name": "Suriname"
      },
      {
        "id": "+268",
        "name": "Swaziland"
      },
      {
        "id": "+46",
        "name": "Sweden"
      },
      {
        "id": "+41",
        "name": "Switzerland"
      },
      {
        "id": "+963",
        "name": "Syria"
      },
      {
        "id": "+886",
        "name": "Taiwan"
      },
      {
        "id": "+992",
        "name": "Tajikistan"
      },
      {
        "id": "+255",
        "name": "Tanzania"
      },
      {
        "id": "+66",
        "name": "Thailand"
      },
      {
        "id": "+670",
        "name": "Timor Leste"
      },
      {
        "id": "+228",
        "name": "Togo"
      },
      {
        "id": "+690",
        "name": "Tokelau"
      },
      {
        "id": "+676",
        "name": "Tonga"
      },
      {
        "id": "+1 868",
        "name": "Trinidad and Tobago"
      },
      {
        "id": "+216",
        "name": "Tunisia"
      },
      {
        "id": "+90",
        "name": "Turkey"
      },
      {
        "id": "+993",
        "name": "Turkmenistan"
      },
      {
        "id": "+1 649",
        "name": "Turks and Caicos Islands"
      },
      {
        "id": "+688",
        "name": "Tuvalu"
      },
      {
        "id": "+1 340",
        "name": "U.S. Virgin Islands"
      },
      {
        "id": "+256",
        "name": "Uganda"
      },
      {
        "id": "+380",
        "name": "Ukraine"
      },
      {
        "id": "+971",
        "name": "United Arab Emirates"
      },
      {
        "id": "+44",
        "name": "United Kingdom"
      },
      {
        "id": "+1",
        "name": "United States"
      },
      {
        "id": "+598",
        "name": "Uruguay"
      },
      {
        "id": "+998",
        "name": "Uzbekistan"
      },
      {
        "id": "+678",
        "name": "Vanuatu"
      },
      {
        "id": "+58",
        "name": "Venezuela"
      },
      {
        "id": "+84",
        "name": "Vietnam"
      },
      {
        "id": "+1 808",
        "name": "Wake Island"
      },
      {
        "id": "+681",
        "name": "Wallis and Futuna"
      },
      {
        "id": "+967",
        "name": "Yemen"
      },
      {
        "id": "+260",
        "name": "Zambia"
      },
      {
        "id": "+255",
        "name": "Zanzibar"
      },
      {
        "id": "+263",
        "name": "Zimbabwe"
      }
]

export default function CountryCodes({ handleCountryCodeChanges, countryCode, setValues }) {
    const { user: { country } } = useSelector((state) => state.auth);
    const { t } = useTranslation();

    React.useEffect(() => {
        let countryCode = null;
        countryCode = countryCodes.find(x => x.name === country)

        if (countryCode ?? false) {
            let newValues = {
                pstnNumber: countryCode.id,
                countryCode: countryCode.id
            }
            setValues(newValues)
        }
    }, [])

    const styles = {
        countryCode: {
            marginRight: '10px',
            minWidth: '142px'
        }
    }

    return (
        <DropDown
            label={t("Country Code")}
            value={countryCode}
            onChange={(e) => handleCountryCodeChanges(e, false)}
            name="countryCode"
            values={countryCodes}
            style={styles.countryCode}
        />
    )
}